import { Component, Input } from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "gym-card",
  templateUrl: "./gym-card.html",
  styleUrls: ["./gyms.scss"],
})
export class GymCard {
  @Input() gym;

  constructor(public util: Utilities) {}

  catMap = this.util.gymCategories.reduce((acc, category) => {
    acc[category.name] = category.image;
    return acc;
  }, {});

  gymRoute(gym) {
    this.util.closeAllDialogs();
    this.util.router.navigate([`gyms/${gym.id}`]);
  }
}
