<div *ngLet="util.storey.partner_db as partner" class="">
  <div *ngIf="partner === '' || !partner" class="con-fin mt-2 p-2 csw">
    <div class="partner_banner bg-ctr p-0 m-4">
      <!-- Row  -->
      <div class="row p-0">
        <div class="col-md-6 pb-5">
          <p-new></p-new>
        </div>
        <!-- Column -->
        <div class="col-md-6 message bg-lin1 text-center py-4">
          <h3 class="display-4 fw-6 b2 my-5">
            FTNSS <br />
            PARTNERS
          </h3>
          <h3 class="cw px-5 fw-7 h1">
            Unlock new <span class="b3 fw-9">revenue streams</span> with
            flexible passes on the ultimate fitness platform.
          </h3>
        </div>
      </div>
    </div>
  </div>
  <div class="" *ngIf="partner">
    <ng-container>
      <p-dash></p-dash>
    </ng-container>
  </div>
</div>
