<div class="pb-4">
  <form
    class="text-center"
    [formGroup]="partner_edit_form"
    (ngSubmit)="updateProfile()"
  >
    <div>
      <div>
        <p class="mt-5 h2 form-p">
          <i class="fi fi-sr-user form-icon"></i>
          <input
            required
            class="bol-b9 p-3 csw bg-bg1 shadow brads"
            formControlName="partner_name"
            type="text"
            placeholder="Full Name"
            autocomplete="new-partner-name"
          />
        </p>
        <small
          class="ccr"
          *ngIf="pf.partner_name.dirty && pf.partner_name.errors"
          >Full Name is required</small
        >
      </div>
      <div>
        <p class="mt-3 h2 form-p">
          <i class="fi fi-sr-gym form-icon"></i>
          <input
            required
            class="bol-b9 p-3 csw bg-bg1 shadow brads"
            formControlName="gym_name"
            type="text"
            placeholder="Gym Name"
            autocomplete="new-gym-name"
          />
        </p>
        <small class="ccr" *ngIf="pf.gym_name.dirty && pf.gym_name.errors"
          >Gym Name is required</small
        >
      </div>
      <div>
        <p class="mt-3 h2 form-p">
          <i class="fi fi-sr-marker form-icon"></i>
          <input
            required
            class="bol-b9 p-3 csw bg-bg1 shadow brads"
            formControlName="gym_address"
            type="text"
            placeholder="Gym Address"
            autocomplete="new-gym-address"
          />
        </p>
        <small class="ccr" *ngIf="pf.gym_address.dirty && pf.gym_address.errors"
          >Gym Address is required</small
        >
      </div>
      <div class="row form-row">
        <div class="col-4">
          <p class="fw-7">Day</p>
          <p class="mt-3 h2 form-p">
            <i class="fi fi-sr-usd-circle form-icon"></i>
            <input
              required
              class="bol-b9 p-3 c0 bg-bg1 fw-7 shadow brads"
              formControlName="price_day"
              type="number"
              placeholder="Daily Price"
            />
          </p>
        </div>
        <div class="col-4">
          <p class="fw-7">Week</p>
          <p class="mt-3 h2 form-p">
            <i class="fi fi-sr-usd-circle form-icon"></i>
            <input
              required
              class="bol-b9 p-3 c0 bg-bg1 fw-7 shadow brads"
              formControlName="price_week"
              type="number"
              placeholder="Weekly Price"
            />
          </p>
        </div>
        <div class="col-4">
          <p class="fw-7">Month</p>
          <p class="mt-3 h2 form-p">
            <i class="fi fi-sr-usd-circle form-icon"></i>
            <input
              required
              class="bol-b9 p-3 c0 bg-bg1 fw-7 shadow brads"
              formControlName="price_month"
              type="number"
              placeholder="Monthly Price"
            />
          </p>
        </div>
      </div>
    </div>
    <div class="text-center mt-5">
      <button
        class="mx-auto brad3 px-5 py-0 bg-bg1 h3 b4 bo3-cwe"
        *ngIf="loader"
      >
        <loader [size]="30"></loader>
      </button>
      <button
        *ngIf="!loader"
        class="mx-auto brad3 px-5 py-3 bg-c0 h3 wim3 bg1 bo3-cwe"
        type="submit"
        [disabled]="partner_edit_form.invalid"
      >
        Update Profile
      </button>
    </div>
  </form>
</div>
