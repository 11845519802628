import { Pipe, PipeTransform } from "@angular/core";
// import { Utilities } from "@services/utilities";

@Pipe({
  name: "dox",
})
export class DoxPipe implements PipeTransform {
  // constructor(private util: Utilities) {}

  async transform(value: string, coll: string) {
    // let dox = await this.util.readLink(`${coll}/${value}`);
    // return dox;
    return "";
  }
}
