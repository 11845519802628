import { Component, Input } from "@angular/core";

@Component({
  selector: "loader",
  templateUrl: "./loader.html",
  styles: [""],
})
export class Loader {
  @Input() size = 80;
  constructor() {}
}
