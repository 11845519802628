import { Component, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";
import { partner_button_list, timeq } from "./p-data";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "p-home",
  templateUrl: "./p-home.html",
  styleUrls: ["../partners.scss"],
})
export class PartnerHome implements OnInit {
  p_home_options: any = partner_button_list;
  dummy_images = ["assets/pimg.jpg", "assets/pimg.jpg", "assets/pimg.jpg"];
  current_dialog;
  current_gym;
  is_verified;
  mainImages: { [key: string]: string } = {};

  constructor(public util: Utilities) {}

  ngOnInit(): void {
    this.checkVerified();
  }

  async checkVerified() {
    const user = await firstValueFrom(this.util.afa.user);
    this.is_verified = user.emailVerified;
  }

  setMainImage(gymId: string, image: string) {
    this.mainImages[gymId] = image;
  }

  getMainImage(gymId: string, images: string[]): string {
    return this.mainImages[gymId] || images[0];
  }

  openMenu(name, gym, dlog) {
    switch (name) {
      case "Finances":
        this.openStripe();
        break;
      case "Edit":
      case "Photos":
      case "Inventory":
        this.current_dialog = name;
        this.current_gym = gym;
        this.util.dlogtr(dlog);
        break;

      case "Passes":
        this.util.router.navigate(["/partner/passes"]);
        break;
      default:
        this.util.warningToast("Coming Soon", "");
    }
  }

  addGym(dlog) {
    if (this.is_verified) {
      this.current_dialog = "NewGym";
      this.util.dlogtr(dlog);
    } else {
      this.util.warningToast(
        "Verify Email",
        "Verify your email to setup your Stripe account"
      );
    }
  }

  async verifyEmail() {
    await (await this.util.afa.currentUser).sendEmailVerification();
    this.util.successToast(
      "Verification Sent",
      "Check your eamil for verification link"
    );
  }

  async openStripe() {
    if (this.is_verified) {
      let stripe_acc_no = this.util.storey.partner_db.stripe_account;

      let stripe_login: any = await firstValueFrom(
        this.util.http.post(`${this.util.apiUrl}/jWNKxvtLDd`, {
          account_no: stripe_acc_no,
          refresh_url: window.location.href,
          return_url: window.location.href,
        })
      );
      window.open(stripe_login.url, "_blank");
    } else {
      this.util.warningToast(
        "Verify Email",
        "Verify your email to setup your Stripe account"
      );
    }
  }
}
