import { Component, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "partners",
  templateUrl: "./partners.html",
  styleUrls: ["./partners.scss"],
})
export class Partners implements OnInit {
  constructor(public util: Utilities) {}

  ngOnInit(): void {}
}
