import { Component, Input, OnInit } from "@angular/core";
import { gymCategories, amenities } from "@models/cats";
import { Utilities } from "@services/utilities";

@Component({
  selector: "p-inventory",
  templateUrl: "./p-inventory.html",
  styleUrls: ["../partners.scss"],
})
export class PartnerInventory implements OnInit {
  @Input() gym;
  categories = gymCategories;
  amenities = amenities;
  my_categories: string[];
  my_amenities: string[];

  constructor(public util: Utilities) {}

  ngOnInit(): void {
    this.my_categories = [...(this.gym.categories ?? [])];
    this.my_amenities = [...(this.gym.amenities ?? [])];
  }

  toggleCategory(category: string) {
    if (this.my_categories.includes(category)) {
      this.my_categories = this.my_categories.filter((cat) => cat !== category);
    } else {
      this.my_categories.push(category);
    }
  }

  toggleAmenity(amenity: string) {
    if (this.my_amenities.includes(amenity)) {
      this.my_amenities = this.my_amenities.filter((am) => am !== amenity);
    } else {
      this.my_amenities.push(amenity);
    }
  }

  async updateInventory() {
    console.log("Updated Inventory:", {
      categories: this.my_categories,
      amenities: this.my_amenities,
    });

    await this.util.afd.object(`v2/gyms/${this.gym.id}`).update({
      categories: this.my_categories,
      amenities: this.my_amenities,
    });
    this.util.successToast("Success", "Your Inventory has been updated");
    this.util.closeAllDialogs();
  }
}
