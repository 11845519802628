<div class="p-4">
  <h4 class="my-3 text-center">Edit Gym Photos</h4>

  <!-- Message when no images are available -->
  <p
    class="text-center fw-6 my-4"
    *ngIf="!gym.images || gym.images.length === 0"
  >
    No images available. Please add some photos.
  </p>

  <!-- Input for adding image URLs -->
  <div class="upload-section text-center">
    <input
      type="text"
      placeholder="Enter image URL"
      [(ngModel)]="newImageUrl"
      class="my-3 p-2 brad bo3-b9 shadow-sm imput"
    />
    <input
      type="file"
      accept="image/*"
      (change)="onFileSelected($event)"
      class="my-3"
    />
    <button
      class="mx-auto brad3 px-3 py-2 bg-c0 h3 bg1 bo3-cwe"
      (click)="addImageUrl()"
      [disabled]="!newImageUrl"
    >
      Add Image
    </button>
  </div>

  <!-- Display current images -->
  <div *ngIf="gym.images && gym.images.length > 0" class="image-list bg-cwe">
    <div *ngFor="let image of gym.images; let i = index" class="image-item">
      <img [src]="image" alt="Gym Photo" class="brads m-3" />
      <div class="text-center fs12">
        <button class="c0" (click)="moveImageUp(i)" [disabled]="i === 0">
          <i class="fi fi-sr-angle-circle-up"></i>
        </button>
        <button
          class="c0"
          (click)="moveImageDown(i)"
          [disabled]="i === gym.images.length - 1"
        >
          <i class="fi fi-sr-angle-circle-down"></i>
        </button>
        <button class="ccr" (click)="deleteImage(i)">
          <i class="fi fi-sr-trash-xmark"></i>
        </button>
      </div>
    </div>
  </div>

  <!-- Button to update gym photos -->
  <p class="text-center my-3">
    <button
      class="mx-auto brad3 px-5 py-3 bg-c0 h3 wim3 bg1 bo3-cwe"
      (click)="updatePhotos()"
    >
      Update Images
    </button>
  </p>
</div>
