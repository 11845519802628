import { Component, OnInit } from "@angular/core";
import { mo_social } from "@models/social.mo";
import { Utilities } from "@services/utilities";
import * as actions from "@store/ftnss.actions";
import { DeviceDetectorService } from "ngx-device-detector";

@Component({
  selector: "main-footer",
  templateUrl: "./main-footer.html",
  styleUrls: ["./main-footer.scss"],
})
export class MainFooter implements OnInit {
  version = this.util.snappy().version;

  isIos;
  links = [
    { url: "/privacy", text: "Privacy" },
    { url: "/terms", text: "Terms" },
    { url: "", text: "FAQ" },
  ];
  socials = mo_social;

  expanded = false;
  theme;

  constructor(public util: Utilities, private devi: DeviceDetectorService) {}

  async ngOnInit() {
    let dev_info = this.devi.getDeviceInfo();
    this.isIos = dev_info.os === "iOS";
    this.util.selly().subscribe((x) => {
      this.theme = x.theme === "dark";
    });
  }

  //* THEME
  async switchTheme() {
    let new_theme = this.theme ? "light" : "dark";
    await this.util.sto.dispatch(new actions.SetTheme(new_theme));
  }
}
