<div class="ftnss-box">
  <div *ngIf="passes.length > 0" class="psRow p-3">
    <div class="psCol p-1" *ngFor="let ps of passes">
      <div class="bg-cw b4 pBox rBor shadow bol-cmain p-2">
        <div class="row m-0">
          <div class="col-6 bor-cwd pl-1 my-auto">
            <p
              (click)="gymRoute(ps.data.partner_id)"
              class="c0 fw-7 m-0 pt-1 pname poi"
            >
              {{ps.data.partner_name}}
            </p>
            <hr />
            <p class="b9 h6 fw-4 mt-3 spa3">
              {{ps.data.pass_type | uppercase}} PASS
            </p>
          </div>
          <div class="col-6 p-0 text-right pr-0 my-auto">
            <p class="fw-8 display-4 b4 my-0">{{ps.data.unit_amount}}</p>
            <p *ngIf="!checkActive(ps)[0]" class="text-right mt-1 mb-1 p-0">
              <button
                class="bg-ccgb b2 fw-8 cw px-3 py-2 brads m-0 spa3 text-right"
              >
                ACTIVE
                <br />
                <span class="spa1 fw-4 fs09">EXP: {{checkActive(ps)[1]}}</span>
              </button>
            </p>

            <p *ngIf="checkActive(ps)[0]" class="mt-4 mb-2 text-right">
              <button
                (click)="checkIn(ps.id, dlog_checkin)"
                class="bg-c0 fw-6 cw px-3 py-2 brad2 spa2"
              >
                CHECK IN
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="passes.length < 1" class="p-5 text-center mx-auto">
    <h3 class="csw">You have no passes.</h3>

    <button
      class="bg-c0 bg1 px-4 py-3 m-2 brad2 h3 poi fw-7 fs8"
      routerLink="/gyms"
    >
      FIND GYMS
    </button>
  </div>
</div>

<ng-template #dlog_checkin>
  <div class="position-relative p-1 brad bg-bg1 shadow wim6 text-center csw">
    <span matDialogClose class="h3 poi exiter"
      ><i class="fi fi-sr-times-hexagon"></i
    ></span>
    <div class="p-5">
      <h4 class="text-center spa2 fw-4">ACTIVATION CODE</h4>

      <h3 class="pcode display-4 c0 fw-6 my-4">{{current_code | uppercase}}</h3>
      <p class="text-center my-2">
        <img [src]="qr_code_url" width="100px" height="100px" alt="" />
      </p>
    </div>
  </div>
</ng-template>
