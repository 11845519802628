import { Component, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "p-dash",
  templateUrl: "./p-dash.html",
  styleUrls: ["../partners.scss"],
})
export class PartnerDash implements OnInit {
  navs = [
    {
      name: "Home",
      link: "/partner/home",
      icon: "fi fi-sr-house-blank",
    },
    {
      name: "Passes",
      link: "/partner/passes",
      icon: "fi fi-sr-ticket",
    },
  ];

  constructor(public util: Utilities) {}

  ngOnInit(): void {}
}
