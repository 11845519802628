<div class="container-xl">
  <div class="csw mt-4 p-4 mx-auto">
    <h1 class="">Ftnss Cookie Policy</h1>

    <p>Last Updated: January 1, 2022</p>
    <p>
      Ftnss uses cookies and similar technologies to help provide, protect, and
      improve the Ftnss Platform. This policy explains how and why we use these
      technologies and the choices you have.
    </p>
    <p>
      A cookie is a small data file that is transferred to your device (e.g.
      your phone or your computer). For example, a cookie could allow us to
      recognize your browser, while another could store your preferences. There
      are two types of cookies used on the Ftnss Platform: (1) “session cookies”
      and (2) “persistent cookies.” Session cookies normally expire when you
      close your browser, while persistent cookies remain on your device after
      you close your browser, and can be used again the next time you access the
      Ftnss Platform.
    </p>
    <p>
      We also use other technologies with similar functionality to cookies, such
      as web beacons, pixels, mobile identifiers, and tracking URLs, to obtain
      Log Data (as described in the
      <a href="/privacy-policy">Privacy Policy</a>). For example, our email
      messages may contain web beacons and tracking URLs to determine whether
      you have opened a certain message or accessed a certain link.
    </p>
    <h4><a name="sec0"></a>Why Ftnss Uses These Technologies</h4>
    <p>We use these technologies for a number of purposes, such as:</p>
    <ul>
      <li>
        To enable you to use and access the Ftnss Platform and the Payment
        Services.
      </li>
      <li>
        To enable, facilitate and streamline the functioning of and your access
        to the Ftnss Platform.
      </li>
      <li>
        To better understand how you navigate through and interact with the
        Ftnss Platform and to improve the Ftnss Platform.
      </li>
      <li>
        To serve you tailored advertising (such as on the Ftnss Platform, emails
        and on third party websites).
      </li>
      <li>
        To show you content (e.g., advertisements) that is more relevant to you.
      </li>
      <li>
        To monitor and analyze the performance, operation, and effectiveness of
        the Ftnss Platform and Ftnss advertisements.
      </li>
      <li>
        To enforce legal agreements that govern use of the Ftnss Platform.
      </li>
      <li>
        For fraud detection and prevention, trust and safety, and
        investigations.
      </li>
      <li>
        For purposes of our own customer support, analytics, research, product
        development, and regulatory compliance.
      </li>
    </ul>
    <h4><a name="sec1"></a>Cookies</h4>
    <p>
      When you visit our websites we place cookies on your computer. Cookies are
      small text files that websites send to your computer or other
      Internet-connected device to uniquely identify your browser or to store
      information or settings in your browser. Cookies allow us to recognize you
      when you return. They also help us provide a customized experience and can
      enable us to detect certain kinds of fraud.
    </p>
    <p>
      In many cases you can manage cookie preferences and opt-out of having
      cookies and other data collection technologies used by adjusting the
      settings on your browser. All browsers are different so visit the “help”
      section of your browser when to learn about cookie preferences and other
      privacy settings that may be available. Please note that if you choose to
      remove or reject cookies or clear local storage this could affect the
      features, availability, and functionality of our websites.
    </p>
    <h4><a name="sec2"></a>Flash Cookies</h4>
    <p>
      We may use Flash Cookies, also known as Local Stored Objects, and similar
      technologies to personalize and enhance your online experience. A Flash
      cookie is a small data file placed on a computer using Adobe Flash
      technology. The Adobe Flash Player is an application that allows rapid
      development of dynamic content, such as video clips and animation.
    </p>
    <p>
      We use Flash cookies to personalize and enhance your online experience and
      to deliver content for Flash players. We may also use Flash cookies for
      security purposes, to gather certain website metrics and to help remember
      settings and preferences. Flash cookies are managed through a different
      interface than the one provided by your web browser. To manage Flash
      cookies, please visit
      <a
        href="https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html"
      >
        Adobe’s website</a
      >.
    </p>
    <p>
      If you disable Flash cookies or other similar technologies, please note
      that you may not have access to certain content and product features such
      as your device remembering a Listing that you viewed during a previous
      visit.
    </p>
    <h4><a name="sec3"></a>Pixel Tags and Web Beacons</h4>
    <p>
      Pixel tags and web beacons are tiny graphic images and small blocks of
      code placed on website pages, ads, or in our emails that allow us to
      determine whether you performed a specific action. When you access these
      pages, or when you open an email, the pixel tags and web beacons let us
      know you have accessed the web page or opened the email. These tools help
      us measure response to our communications and improve our web pages and
      promotions.
    </p>
    <h4><a name="sec4"></a>Server Logs and Other Technologies</h4>
    <p>
      We collect many different types of information from server logs and other
      technologies. For example, we collect information about the device you use
      to access our website, your operating system type, browser type, domain,
      and other system settings, as well as the language your system uses and
      the country and time zone where your device is located. Our server logs
      also record the IP address of the device you use to connect to the
      Internet. An IP address is a unique identifier that devices require to
      identify and communicate with each other on the Internet. We may also
      collect information about the website you were visiting before you came to
      our website and the website you visit after you leave our site. These
      tools help us improve user experience and deliver our services.
    </p>
    <h4><a name="sec5"></a>Device Information</h4>
    <p>
      We may use device-related information to authenticate users. For example,
      we may use your IP address, browser information, or other data provided by
      your browser or device to identify the device being used to access our
      platform. We may also use these device-related techniques for associating
      you with different devices that you may use to access our content
      including for fraud-protection purposes and to better target advertising.
    </p>
    <h4><a name="sec6"></a>Third Parties</h4>
    <p>
      We may also allow certain business partners to place these technologies on
      the Ftnss Platform. These partners use these technologies to (1) help us
      analyze how you use the Ftnss Platform, such as by noting the third party
      services from which you arrived, (2) market and advertise Ftnss services
      to you on the Ftnss Platform and third party websites, (3) help us detect
      or prevent fraud or conduct risk assessments, or (4) collect information
      about your activities on the Ftnss Platform, other sites, and/or the ads
      you have clicked on. For example, to help us better understand how people
      use the Ftnss Platform, we work with a number of analytics partners,
      including Google Analytics. To prevent Google Analytics from using your
      information for analytics, you may install the Google Analytics Opt-Out
      Browser by
      <a href="https://tools.google.com/dlpage/gaoptout">clicking here</a>.
    </p>
    <p>
      Third parties may also use such tracking technologies to serve ads that
      they believe are most likely to be of interest to you and measure the
      effectiveness of their ads both on the Ftnss Platform and on other
      websites and online services. Targeting and advertising cookies we use may
      include Google, and other advertising networks and services we use from
      time to time. For more information about targeting and advertising cookies
      and how you can opt out, you can visit the
      <a href="https://www.networkadvertising.org/choices/"
        >Network Advertising Initiative’s opt-out page</a
      >, the
      <a href="https://www.aboutads.info/choices/"
        >Digital Advertising Alliance’s opt-out page</a
      >, or
      <a href="https://youronlinechoices.eu/">http://youronlinechoices.eu</a>.
      To opt out of Google Analytics for display advertising or customize Google
      display network ads, you can visit the
      <a href="https://www.google.com/settings/ads">Google Ads Settings</a>
      page. To the extent advertising technology is integrated into the Ftnss
      Platform and you opt-out of tailored advertising, you may still receive
      advertising content. In that case, the advertising content will just not
      be tailored to your interests. Also, we do not control any of these
      opt-out links and are not responsible for the availability or accuracy of
      these mechanisms.
    </p>
    <h4><a name="sec7"></a>Third Party Social Plugins</h4>
    <p>
      The Ftnss Platform may use social plugins provided and operated by third
      parties, such as Facebook’s Like Button. As a result of this, you may send
      to the third party the information that you are viewing on a certain part
      of the Ftnss Platform. If you are not logged into your account with the
      third party, then the third party may not know your identity. If you are
      logged in to your account with the third party, then the third party may
      be able to link information or actions about your interactions with the
      Ftnss Platform to your account with them. Please refer to the third
      party’s privacy policies to learn more about its data practices.
    </p>
    <h4><a name="sec8"></a>Your Choices</h4>
    <!-- OneTrust Cookies Settings button start -->
    <!-- OneTrust Cookies Settings button end -->
    <p>
      Most browsers automatically accept cookies, but you can modify your
      browser setting to decline cookies by visiting the Help portion of your
      browser’s toolbar.
    </p>
    <p>
      Flash cookies operate differently than browser cookies, and cookie
      management tools available in a web browser will not remove flash cookies.
      To learn more about how to manage flash cookies, you can visit the
      <a href="https://www.adobe.com/">Adobe website</a>
      and make changes at the
      <a
        href="https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager02.html"
        >Global Privacy Settings Panel</a
      >.
    </p>
    <p>
      Your mobile device may allow you to control cookies through its settings
      function. Refer to your device manufacturer’s instructions for more
      information.
    </p>
    <p>
      If you choose to decline cookies, some parts of the Ftnss Platform may not
      work as intended or may not work at all.
    </p>
    <br /><br /><br />
  </div>
</div>
