<div class="p-4">
  <div>
    <h4>Select Categories</h4>
    <div class="cats-list">
      <div *ngFor="let cat of categories" class="cats-item">
        <div
          class="shadow-sm brad2 bg-cw px-3 py-2 fw-6"
          [ngClass]="my_categories.includes(cat.name) ? 'bo3-c0 c0' : 'bo3-cwd csw'"
          (click)="toggleCategory(cat.name)"
        >
          <img
            [ngClass]="my_categories.includes(cat.name) ? 'active' : ''"
            [src]="cat.image"
            alt=""
          />
          {{ cat.name }}
        </div>
      </div>
    </div>
  </div>

  <!-- Amenities Section -->
  <div>
    <h4>Select Amenities</h4>
    <div class="cats-list">
      <div *ngFor="let am of amenities" class="cats-item">
        <div
          class="shadow-sm brad2 bg-cw px-3 py-2 fw-6"
          [ngClass]="my_amenities.includes(am.name) ? 'bo3-c0 c0' : 'bo3-cwd csw'"
          (click)="toggleAmenity(am.name)"
        >
          <img
            [ngClass]="my_amenities.includes(am.name) ? 'active' : ''"
            [src]="am.image"
            alt=""
          />
          {{ am.name }}
        </div>
      </div>
    </div>
  </div>

  <!-- Update Inventory Button -->
  <p class="text-center my-4">
    <button
      (click)="updateInventory()"
      class="mx-auto brad3 px-5 py-3 bg-c0 h3 wim3 bg1 bo3-cwe"
    >
      Update Inventory
    </button>
  </p>
</div>
