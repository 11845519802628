import { Component, Input, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";
import { SearchParams } from "@services/interfaces";

@Component({
  selector: "search-bar",
  templateUrl: "./search-bar.html",
  styleUrls: ["./search-bar.scss"],
})
export class SearchBar implements OnInit {
  @Input() search_type: SearchParams;
  categories;
  scrollx = [0, 0, 4000];
  current_filter_template;
  consoles;
  av_dates = this.util.getAvailableDates();
  search_str = "";
  filexpand = false;
  animationState = "out";
  selected = {};

  constructor(public util: Utilities) {}

  ngOnInit() {
    this.search_str = this.util.searchy[this.search_type.name];
    this.selected = { ...this.util.storey.filters[this.search_type.name] };
  }

  updateCategories(cat) {
    this.selected[cat] = !Boolean(this.selected[cat]);
    this.util.sto.dispatch(
      new this.util.axs.SetFilter({ ...this.selected }, this.search_type.name)
    );
  }
}
