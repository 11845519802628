export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyAvNHKQYftRP4GvgBmH1l5rFroNftJ5gGQ",
    authDomain: "ftnss-2.firebaseapp.com",
    databaseURL: "https://ftnss-2-default-rtdb.firebaseio.com",
    projectId: "ftnss-2",
    storageBucket: "ftnss-2.appspot.com",
    messagingSenderId: "260800519881",
    appId: "1:260800519881:web:8a3a766139ba039e5bb718",
    measurementId: "G-NRLGVSS25Q",
  },
  stripe:
    "pk_test_51OeqrrAzajXq52uJriqY5Psu15Saa2KyIO91yh3S5rKQBWikW5FO8AHuAUd59d8EeJmCL74Elhwyhv6OvhHBCBe0004n29E3Ai",
  stripe_live:
    "pk_live_51OeqrrAzajXq52uJuA90uzFxVTkDYa7yuXNzi4SJgEh3JFfKha9duVUT7dxIw9f60zumWvd3qO5pMcQo8unHwclj00d2EZd3XG",
  mapbox: {
    access:
      "pk.eyJ1IjoiY2FsZWJmaXQiLCJhIjoiY2xxbWM2a2RrMjV0dzJtcGhmM3cwcXN6bSJ9.Q5pHIliqRfFf2-9F2_Bl7g",
    geocoder: "",
  },
};
