export const mo_social = [
  {
    href: "https://www.facebook.com/1FTNSS",
    image: "assets/social/facebook.svg",
  },
  // {
  //   href: "https://twitter.com/ftnss",
  //   image: "assets/social/twitter.svg",
  // },
  {
    href: "https://www.linkedin.com/company/ftnss/",
    image: "assets/social/linkedin.svg",
  },
  // {
  //   href: "https://discord.gg/K2cmehUr",
  //   image: "assets/social/discord.svg",
  // },
  {
    href: "https://www.instagram.com/ftnss.fit/",
    image: "assets/social/instagram.svg",
  },
  // {
  //   href: "https://www.youtube.com/channel/UCijLBmohIoWASSHijUybREQ",
  //   image: "assets/social/youtube.svg",
  // },
  // {
  //   href: "https://www.twitch.tv/ftnss",
  //   image: "assets/social/twitch.svg",
  // },
  {
    href: "https://www.reddit.com/r/FTNSS/s/wTyndp2AR0",
    image: "assets/social/reddit.svg",
  },
];
