<div class="container-xl">
  <div class="csw mt-4 p-4 mx-auto">
    <h1 class="c">User Agreement for Ftnss</h1>
    <h4>Updated 1/1/2022</h4>

    <h5>1</h5>
    <p>
      This User Agreement governs your relationship with Ftnss and your use of
      and access to all services and products provided by Ftnss. You agree that
      by accessing or using any part of the Services you are bound by the terms
      of this User Agreement (the Terms), including the applicable Privacy
      Policy and Community Guidelines incorporated herein.
    </p>

    <h5>2</h5>
    <p>
      You may not use the Services unless all of the following apply to you, and
      you affirm that all of the following apply to you: <br />
      <strong>2.1 </strong>You are at least 12 years old;
      <br />
      <strong>2.2</strong> You are legally allowed to use the Services where you
      live; <br />
      <strong>2.3</strong> You are not using the Services or accepting the Terms
      on behalf of any other entity, such as a company or organization, unless
      you have authority to bind that entity to these Terms; <br />
      <strong>2.4 </strong>You have not been banned by Ftnss from using the
      Services.
    </p>

    <h5>3</h5>
    <p>
      The Ftnss Privacy Policy (https://ftnss.fit/privacy) describes what Ftnss
      can do with information about you received by Ftnss when you use the
      Services. You agree to the terms of the Privacy Policy.
    </p>

    <h5>4</h5>
    <p>
      Any content that you post to the Services must satisfy all of the
      following criteria, and you affirm that any content posted, submitted, or
      otherwise provided by you to the Services satisfies these criteria: <br />
      <strong>4.1 </strong>You have the legal right to post the content to the
      Services. <br />
      <strong>4.2 </strong>The content and the purpose for posting it complies
      with all laws, rules, and regulations that may apply. <br />
      <strong>4.3</strong> The content does not infringe the intellectual
      property rights (such as copyrights and trademark rights) of any other
      person or entity.
      <br />
      <strong>4.4</strong> The content does not include non-public personal
      private information belonging to someone else, such as another person’s
      birthdate, home address, or telephone number. <br />
      <strong>4.5</strong> The content must abide by the following : <br />
      <em>4.51</em> You may not threaten violence against an individual or a
      group of people. We also prohibit the glorification of violence. <br />
      <em>4.52</em>You may not threaten or promote terrorism or violent
      extremism.
      <br />
      <em>4.53</em> We have zero tolerance for child sexual exploitation on
      Ftnss. <br />
      <em>4.54</em> You may not engage in the targeted harassment of someone, or
      incite other people to do so. This includes wishing or hoping that someone
      experiences physical harm. <br />
      <em>4.55</em> You may not promote violence against, threaten, or harass
      other people on the basis of race, ethnicity, national origin, caste,
      sexual orientation, gender, gender identity, religious affiliation, age,
      disability, or serious disease. <br />
      <em>4.56</em> You may not promote or encourage suicide or self-harm.
      <br />
      <em>4.57</em> You may not post media that is excessively gory or share
      violent or adult content within live video or in profile or header images.
      Media depicting sexual violence and/or assault is also not permitted.
      <br />
      <em>4.58</em> You may not use our service for any unlawful purpose or in
      furtherance of illegal activities. <br />

      <strong>4.6</strong> The content complies with the Ftnss Community
      Guidelines ( https://ftnss.fit/community_guidelines ). You are responsible
      for your use of the Services and for any content that you post. All
      content is the sole responsibility of the person who originated the
      content, and Ftnss does not take responsibility for such content. <br />
    </p>

    <h5>5</h5>

    <p>
      You grant to Ftnss a license to any content posted by you to the Services,
      including a worldwide, non-exclusive, royalty-free license (with the right
      to sublicense) to use, copy, reproduce, process, adapt, modify, publish,
      transmit, display and distribute your content. You agree that Ftnss or its
      service providers or partners may display advertising in connection with
      your content and otherwise monetize your content without compensation to
      you. You warrant that you have all rights necessary to grant these rights
      to Ftnss and Ftnss users. You also grant a limited non-exclusive,
      royalty-free license to any user of the Services to use, copy, reproduce,
      process, adapt, modify, publish, transmit, display, and distribute any
      content posted by you to the Services solely in connection with that users
      use of the Services. The licenses granted by you hereunder do not include
      any moral rights or right of attribution.
    </p>

    <h5>6</h5>
    <h6>Ftnss Passes</h6>

    <p>
      You understand that when you use certain features of the Services, you are
      awarded a certain amount of Ftnss Passes as decided by Ftnss. You agree
      and acknowledge that you do not in fact own the Ftnss Passes and the
      amounts of Ftnss Passes do not refer to any credit balance of real
      currency or its equivalent. Any Ftnss Pass balance shown in your account
      does not constitute a real-world balance or reflect any stored value, but
      instead constitutes a measurement of the extent of your ability to procure
      such limited license to use certain features made available by Ftnss. From
      time to time Ftnss may make available a feature where Ftnss Passes may be
      redeemed for cash. Ftnss prohibits and does not recognize any purported
      transfers of Ftnss Passes effectuated outside of the Services, or the
      purported sale, gift, or trade in the real world of anything that appears
      or originates in the Services, unless otherwise expressly authorized by
      Ftnss in writing. Accordingly, you may not sublicense, trade, sell, or
      attempt to sell Ftnss Passes for real money, or exchange Ftnss Passes for
      value of any kind outside of the Services, without Ftnss's written
      permission. Any such transfer or attempted transfer is prohibited and
      void, and will subject your user account to termination. You are
      responsible for all taxes arising out of your use of the Services,
      including without limitation any taxes due upon your redemption of the
      Ftnss Passes for cash. Ftnss may file an IRS form 1099 or similar form
      with the Internal Revenue Service or the appropriate tax filing with a
      governmental entity for the fair market value of any cash redemptions
      issued to you in exchange for the Ftnss Passes.
    </p>

    <h5>7</h5>

    <p>
      Copyright infringement is not allowed on the Services, and Ftnss will, in
      appropriate circumstances, terminate the account of any repeat infringer.
      If your copyright has been infringed by any content on the Services and
      you did not grant a license for this use by uploading your copyrighted
      work to the Services, you may submit a notice that meets all of the
      requirements of the Digital Millennium Copyright Act (DMCA), 17 U.S.C
      512(c)(3), to our Resolutions Team at support&#64;ftnss.fit or 209 S.
      Stephanie St., B135, Henderson, NV 89012. Your notice must include: <br />
      (1) electronic or physical signature of the copyrighted work owner (or
      person authorized by the copyright owner), <br />
      (2) a description of the copyrighted work, including the URL where the
      infringing content is available, or a copy of it, <br />
      (3) contact details of the person submitting the notice, including email
      address, telephone, and mailing address,
      <br />
      (4) statement in “good faith belief” that the work is not authorized by
      the copyright owner, and <br />
      (5) a statement by the sender that all of the above information is
      accurate, and that the person sending the notice is either the copyright
      owner or is authorized to act on behalf of the copyright owner. Upon
      receiving a notice satisfying these requirements, Ftnss will take whatever
      action, in its sole discretion, it deems appropriate, including removal of
      the challenged content from the services.
    </p>

    <h5>8</h5>

    <p>
      You may not interfere with the Services in any way, such as by accessing
      the Services through automated means in a manner that puts excessive
      demand on the Services; by hacking the Services; by accessing without
      authorization areas of the Services that are protected by technical
      measures designed to prevent unauthorized access; by testing the
      vulnerability of the Services; by impersonating Ftnss or the Services; by
      accessing the Services for any purpose that competes with the interests of
      Ftnss; by spamming the users of the Services; by failing to respond to
      operational communications or requests from Ftnss; or through any other
      type of interference with the Services or Ftnss's relationships with
      others.
    </p>

    <h5>9</h5>
    <p>
      Ftnss may remove any content and terminate your access to the Services at
      any time and for any reason to the extent Ftnss reasonably believes
      <br />
      (a) you have violated these Terms or Ftnss's Community Guidelines,
      <br />
      (b) you create risk or possible legal exposure for Ftnss, or <br />(c) you
      are otherwise engaging in unlawful conduct—although Ftnss endeavors to
      allow all free speech that is lawful and does not infringe the legal
      rights of others. <br />
      Any invitation made by Ftnss to you to use the Services or submit content
      to the Services, or the fact that Ftnss may receive a benefit from your
      use of the Services or provision of content to the Services, will not
      obligate Ftnss to maintain any content or maintain your access to the
      Services. Ftnss will have no liability to you for removing any content,
      for terminating your access to the Services, or for modifying or
      terminating the Services.
    </p>

    <h5>10</h5>

    <p>
      You agree to receive communications from Ftnss, including communications
      sent by email, or other means of communication. If you provided a phone
      number to Ftnss, you are required to notify Ftnss when you cease to own or
      control that number to help prevent Ftnss from sending communications to
      others who may acquire that number.
    </p>

    <h5>11</h5>

    <p>
      The Services are provided to you as-is and at your own risk. The Services
      come with no express or implied warranties, except those that cannot be
      disclaimed under the law. SMARTBATE DISCLAIMS ALL EXPRESS OR IMPLIED
      WARRANTIES AND CONDITIONS, SUCH AS MERCHANTABILITY, FITNESS FOR A
      PARTICULAR PURPOSE, AND NON-INFRINGEMENT. Ftnss makes no representation or
      endorsement about the function of the Services or any content available
      through the Services. Ftnss has no responsibility or liability to you
      arising from your use of the Services. Ftnss has no responsibility or
      liability to you arising from content provided by you or any other person,
      even if such content is untrue, harmful, damaging, offensive,
      inappropriate, fraudulent, tortious, unlawful, contrary to social norms,
      etc. Although Ftnss may make efforts to review or monitor content, you
      agree that you will not rely on this fact for any purpose. Ftnss has no
      responsibility or liability to you arising from hacking event, data
      breach, theft, misuse of information, conspiracy, racket, fraud, act of
      terrorism, misappropriation of information, technical malfunction,
      interruption of service, or similar event that may cause you to suffer
      damage, loss, or injury, including without limitation any damage to or
      loss of your personal property, data, operations, information, reputation,
      goodwill, profits, etc.
      <br />
      TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, AND REGARDLESS OF THE
      NATURE OF THE CAUSE OF ACTION, SMARTBATE WILL NOT BE LIABLE TO YOU FOR ANY
      DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, OR PUNITIVE DAMAGES,
      OR FOR ANY LOST PROFITS, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR FOR
      INTANGIBLE LOSSES, ARISING FROM <br />
      (a) YOUR ACCESS TO OR USE OF (OR INABILITY TO ACCESS OR USE) THE SERVICES;
      <br />
      (b) FROM THE ACTS OR OMISSIONS OF ANY OTHER PERSON OR THIRD PARTY,
      INCLUDING, WITHOUT LIMITATION, ANY DEFAMATORY, OFFENSIVE, OR ILLEGAL
      CONDUCT OF OTHER PERSONS OR THIRD PARTIES; <br />
      (c) ANY CONTENT OBTAINED FROM THE SERVICES; OR <br />
      (d) UNAUTHORIZED ACCESS, USE, OR ALTERATION OF YOUR CONTENT OR
      COMMUNICATIONS THROUGH THE SERVICES. IN NO EVENT SHALL THE AGGREGATE
      LIABILITY OF SMARTBATE ARISING OUT OF OR RELATING TO THESE TERMS OR THE
      SERVICES EXCEED THE GREATER OF TWO U.S. DOLLARS (U.S. $2.00) OR THE AMOUNT
      YOU PAID SMARTBATE, IF ANY, IN THE PAST SIX MONTHS FOR THE SERVICES GIVING
      RISE TO THE CLAIM. THE LIMITATIONS OF THIS SECTION SHALL APPLY TO ANY
      THEORY OF LIABILITY, WHETHER BASED ON WARRANTY, CONTRACT, STATUTE, TORT
      (INCLUDING NEGLIGENCE), OR OTHERWISE, AND SHALL FURTHER APPLY WHETHER OR
      NOT SMARTBATE HAS BEEN INFORMED OF THE POSSIBLITY OF ANY SUCH DAMAGES AND
      EVEN IF A REMEDY LAID OUT IN THESE TERMS IS FOUND TO HAVE FAILED ITS
      ESSENTIAL PURPOSE.
    </p>
  </div>
</div>
