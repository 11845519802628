<div *ngLet="util.storey.all_db as db " class="container-xl pt-3 px-2">
  <ng-container *ngLet="util.storey.user_db.parties as parties ">
    <div class="slotlist">
      <ng-container *ngIf="!(util.lo_isEmpty(parties))">
        <div *ngFor="let party of parties | keyvalue" class="slotcard">
          <div
            *ngLet="db.parties[party.key] as pa"
            class="brad overflow-hidden shadow-sm text-center p-0 bg-bg1 csw bo-bg4"
          >
            <img
              class="image"
              [src]="db.games[pa.game].image"
              [style.object-position]="db.games[pa.game].css_class ?? 'top'"
              (dblclick)="util.show(pa)"
            />
            <p
              class="fs8 fw-6 mm1 text-truncate px-2"
              (dblclick)="util.show(filters)"
            >
              {{ db.games[pa.game].name }}
            </p>
            <p class="mm1 fw-6 ccr">
              <i class="fas fa-stopwatch"></i> &nbsp; {{pa.deadline | dayF :
              "MMM do y" }}
            </p>
            <!-- <p class="mm1 fw-7 fs8">
              {{pa.player_list.length}} / {{pa.players}}
              players
            </p> -->
            <p
              class="bg-ccg bg1 px-2 py-2 m-2 brad2 poi fw-7 fs8"
              (click)="util.singleLink('parties',pa.id)"
            >
              <span> OPEN </span>
              &nbsp; &nbsp;
              <i class="fas fa-sign-in-alt"></i>
            </p>
          </div>
        </div>
      </ng-container>
    </div>
    <div *ngIf="util.lo_isEmpty(parties)" class="p-5 text-center">
      <h3 class="csw">You have not booked any sporting events</h3>
      <button
        class="bg-ccg bg1 px-4 py-3 m-2 brad2 poi fw-7 h3"
        routerLink="/sports"
      >
        FIND SPORTS
      </button>
    </div>
  </ng-container>
</div>
