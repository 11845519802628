import { Component, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "u-favs",
  templateUrl: "./u-favs.html",
  styleUrls: ["../../widgets/gyms/gyms.scss"],
})
export class UserFavs implements OnInit {
  constructor(public util: Utilities) {}

  ngOnInit() {}
}
