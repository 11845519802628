import { Component, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "sport",
  templateUrl: "./sport.html",
  styleUrls: ["../gyms/gyms.scss"],
})
export class Sport implements OnInit {
  curr_user = this.util.storey.user_db;
  joining = false;

  days = [
    { name: "Sunday", hours: "7:00 AM - 7:00 PM" },
    { name: "Monday", hours: "5:00 AM - 9:00 PM" },
    { name: "Tuesday", hours: "5:00 AM - 9:00 PM" },
    { name: "Wednesday", hours: "5:00 AM - 9:00 PM" },
    { name: "Thursday", hours: "5:00 AM - 9:00 PM" },
    { name: "Friday", hours: "5:00 AM - 9:00 PM" },
    { name: "Saturday", hours: "7:00 AM - 7:00 PM" },
  ];

  social = [
    {
      icon: "fi fi-sr-share",
      bg: "bg-b4 cw",
    },
    {
      icon: "fi fi-sr-comments",
      bg: "bg-b4 cw",
    },
    {
      icon: "fi fi-sr-trophy-star",
      bg: "bg-b4 cw",
    },
    {
      icon: "fi fi-sr-navigation",
      bg: "bg-b4 cw",
    },
  ];

  fixtures = [
    { team1: "Mimosa", team2: "Jetsons", date: "2024-02-15", time: "10:00 AM" },
    { team1: "Ravens", team2: "Eagles", date: "2024-02-16", time: "3:00 PM" },
    { team1: "Bulls", team2: "Real Cats", date: "2024-02-17", time: "1:30 PM" },
    { team1: "Squid", team2: "Django", date: "2024-02-18", time: "11:00 AM" },
    { team1: "Zebras", team2: "Jacksons", date: "2024-02-19", time: "2:00 PM" },
  ];

  leaderboard = [
    { team: "Ravens", wins: 8, losses: 1, draws: 0, points: 24 },
    { team: "Mimosa", wins: 7, losses: 2, draws: 1, points: 22 },
    { team: "Eagles", wins: 6, losses: 3, draws: 0, points: 18 },
    { team: "Jetsons", wins: 5, losses: 4, draws: 1, points: 16 },
    { team: "Bulls", wins: 4, losses: 4, draws: 1, points: 13 },
    { team: "Real Cats", wins: 3, losses: 6, draws: 0, points: 9 },
  ];

  catMap = this.util.sportsCategories.reduce((acc, category) => {
    acc[category.name] = category;
    return acc;
  }, {});

  constructor(public util: Utilities) {}

  ngOnInit(): void {}

  joinParty(party) {
    // if (this.util.is_connected) {
    //   let funded =
    //     this.util.storey.wallet.fingz >= party.entry_fingz;
    //   if (funded) {
    //     this.util.openConfirm({
    //       type: "party",
    //       party: party,
    //     });
    //   } else {
    //     this.util.openBuyer();
    //     this.util.warningToast(
    //       "BUY FINGZ",
    //       "You don't have enough $FINGZ or gas to join this party"
    //     );
    //   }
    // } else {
    //   this.util.openAuth("in");
    //   this.util.warningToast("Sign In", "Sign In to Join Party");
    // }
  }
}
