import { Component, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "u-dash",
  templateUrl: "./u-dash.html",
  styleUrls: ["./u-dash.scss"],
})
export class UserDash implements OnInit {
  navs = [
    {
      name: "Account",
      link: "/user/home",
      icon: "fi fi-sr-house-blank",
      active: "bob-c0 c0",
    },
    {
      name: "Passes",
      link: "/user/passes",
      icon: "fi fi-sr-ticket",
      active: "bob-c0 c0",
    },
    {
      name: "Favorites",
      link: "/user/favs",
      icon: "fi fi-sr-heart",
      active: "bob-c0 c0",
    },
  ];

  constructor(public util: Utilities) {}

  ngOnInit(): void {
    if (
      !this.util.env.production &&
      this.util.storey.user.email === "caleb.softdev@gmail.com"
    ) {
      this.navs.push({
        name: "Adminy",
        link: "/user/adminy",
        icon: "fi fi-sr-shield-keyhole",
        active: "bob-ccr ccr",
      });
    }
  }
}
