<div class="h-new p-0 text-center">
  <!-- * SIGN IN  -->
  <form
    class="text-center"
    [formGroup]="partner_form"
    (ngSubmit)="authenticate()"
  >
    <div>
      <div *ngIf="mode === 'signup'">
        <p class="mt-5 h2 form-p">
          <i class="fi fi-sr-user form-icon"></i>
          <input
            required
            class="bol-b9 p-3 csw bg-bg1 shadow brads"
            formControlName="partner_name"
            type="text"
            placeholder="Full Name"
            autocomplete="new-partner-name"
          />
        </p>
        <small
          class="ccr"
          *ngIf="pf.partner_name.dirty && pf.partner_name.errors"
          >Full Name is required</small
        >
      </div>
      <div *ngIf="mode === 'signup'">
        <p class="mt-3 h2 form-p">
          <i class="fi fi-sr-gym form-icon"></i>
          <input
            required
            class="bol-b9 p-3 csw bg-bg1 shadow brads"
            formControlName="gym_name"
            type="text"
            placeholder="Gym Name"
            autocomplete="new-gym-name"
          />
        </p>
        <small class="ccr" *ngIf="pf.gym_name.dirty && pf.gym_name.errors"
          >Gym Name is required</small
        >
      </div>
      <div *ngIf="mode === 'signup'">
        <p class="mt-3 h2 form-p">
          <i class="fi fi-sr-marker form-icon"></i>
          <input
            required
            class="bol-b9 p-3 csw bg-bg1 shadow brads"
            formControlName="gym_address"
            type="text"
            placeholder="Gym Address"
            autocomplete="new-gym-address"
          />
        </p>
        <small class="ccr" *ngIf="pf.gym_address.dirty && pf.gym_address.errors"
          >Gym Address is required</small
        >
      </div>
      <div>
        <p class="mt-3 h2 form-p">
          <i class="fi fi-sr-envelope form-icon"></i>
          <input
            required
            class="bol-b9 p-3 csw bg-bg1 shadow brads"
            formControlName="email"
            type="email"
            placeholder="Email"
            autocomplete="new-email"
          />
        </p>
        <small class="ccr" *ngIf="pf.email.dirty && pf.email.errors"
          >Please provide a valid email address</small
        >
      </div>
      <div *ngIf="mode !== 'reset'">
        <p class="mt-3 h2 form-p">
          <i class="fi fi-sr-lock form-icon"></i>
          <input
            required
            class="bol-b9 p-3 csw bg-bg1 shadow brads"
            formControlName="password"
            type="password"
            placeholder="Password"
            autocomplete="new-password"
          />
        </p>
        <small class="ccr" *ngIf="pf.password.dirty && pf.password.errors"
          >Password must be at least 6 characters</small
        >
      </div>
      <div *ngIf="mode === 'signup'">
        <p class="mt-3 h2 form-p">
          <i class="fi fi-sr-lock form-icon"></i>
          <input
            required
            class="bol-b9 p-3 csw bg-bg1 shadow brads"
            formControlName="password_confirm"
            type="password"
            placeholder="Confirm Password"
            autocomplete="new-password-confirm"
          />
        </p>
        <small
          class="ccr"
          *ngIf="pf.password_confirm.dirty && partner_form.errors !== null"
          >Passwords must match</small
        >
      </div>
      <p class="mt-4">
        <span
          class="ccr poi fw-7"
          *ngIf="mode === 'signin'"
          (click)="mode = 'reset'"
        >
          Forgot Password?
        </span>
      </p>
    </div>
    <div class="text-center mt-5">
      <button
        class="mx-auto brad3 px-5 py-0 bg-bg1 h3 b4 bo3-cwe"
        *ngIf="loader"
      >
        <loader [size]="30"></loader>
      </button>
      <button
        *ngIf="!loader"
        class="mx-auto brad3 px-5 py-3 bg-c0 h3 bg1 bo3-cwe"
        type="submit"
        [disabled]="partner_form.invalid "
      >
        {{mode === 'signup' ? 'Create Account' : mode === 'reset' ? 'Reset
        Password' : 'Sign in'}}
      </button>
    </div>
  </form>

  <div *ngIf="!loader" class="text-center mt-3">
    <button
      class="mx-3 brad3 px-5 py-3 bg-bg1 h3 c0 bo3-cwe"
      (click)="switchAuth()"
    >
      {{ mode !== 'signin' ? 'Sign in ' : 'Become a Partner'}}
    </button>
  </div>
</div>
