import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { Terms } from "./components/pages/terms/terms";
import { Cookies } from "./components/pages/cookies/cookies";
import { Privacy } from "./components/pages/privacy/privacy";
import { Community } from "./components/pages/community/community";
import { EULA } from "./components/pages/eula/eula";
import { Land } from "@components/widgets/land/land";
import {
  AdminGuard,
  AuthGuard,
  FtnssUserGuard,
  FtnssPartnerGuard,
  ENVGuard,
} from "@services/guard.service";
import { Partners } from "@components/partner/partners";
import { PartnerPasses } from "@components/partner/p-passes/p-passes";
import { PartnerHome } from "@components/partner/p-home/p-home";
import { UserDash } from "@components/user/u-dash/u-dash";
import { UserHome } from "@components/user/u-home/u-home";
import { UserPasses } from "@components/user/u-passes/u-passes";
import { Gyms } from "@components/widgets/gyms/gyms";
import { FtnssHelp } from "@components/pages/help/help";
import { Faq } from "@components/widgets/faq/faq";
import { About } from "@components/pages/about/about";
import { Gym } from "@components/widgets/gyms/gym";
import { NewPartner } from "@components/partner/p-new/p-new";
import { UserFavs } from "@components/user/u-favs/u-favs";
import { AuthC } from "@components/widgets/auth/auth";
import { UserDelete } from "@components/user/u-delete/u-delete";
import { Adminy } from "@components/widgets/adminy/adminy";

import { environment as env } from "@env/environment";

// ***** ROUTES ****
const routes: Routes = [
  {
    path: "",
    component: Land,
    data: {
      title: "Ftnss: Stay Fit",
    },
  },
  {
    path: "signup",
    component: AuthC,
    canActivate: [AuthGuard],
    data: {
      title: "Ftnss Signup",
    },
  },
  {
    path: "signin",
    component: AuthC,
    canActivate: [AuthGuard],
    data: {
      title: "Ftnss Signin",
    },
  },
  {
    path: "gyms",
    component: Gyms,
    data: {
      title: "Ftnss Gyms",
    },
  },
  {
    path: "gyms/:id",
    component: Gym,
  },
  {
    path: "user",
    component: UserDash,
    canActivate: [FtnssUserGuard],
    children: [
      {
        path: "home",
        component: UserHome,
      },
      {
        path: "favs",
        component: UserFavs,
      },
      {
        path: "passes",
        component: UserPasses,
      },
      {
        path: "delete",
        component: UserDelete,
      },
      {
        path: "adminy",
        component: Adminy,
        canActivate: [ENVGuard],
      },
      {
        path: "**",
        redirectTo: "home",
        pathMatch: "full",
      },
    ],
  },
  {
    path: "partner",
    component: Partners,
    children: [
      {
        path: "new",
        component: NewPartner,
      },
      {
        path: "home",
        component: PartnerHome,
        canActivate: [FtnssPartnerGuard],
      },
      {
        path: "passes",
        component: PartnerPasses,
        canActivate: [FtnssPartnerGuard],
      },
      // {
      //   path: "equipment",
      //   component: PartnerEquipment,
      //   canActivate: [FtnssPartnerGuard],
      // },
      // {
      //   path: "finances",
      //   component: PartnerFinances,
      //   canActivate: [FtnssPartnerGuard],
      // },
    ],
  },
  {
    path: "help",
    component: FtnssHelp,
    data: {
      title: "Help & Support | Ftnss",
    },
    children: [
      {
        path: "faq",
        component: Faq,
      },
      {
        path: "about",
        component: About,
      },
      {
        path: "privacy",
        component: Privacy,
      },
      {
        path: "terms",
        component: Terms,
      },
      {
        path: "comms",
        component: Community,
      },
      {
        path: "eula",
        component: EULA,
      },
      {
        path: "",
        redirectTo: "faq",
        pathMatch: "full",
      },
    ],
  },
  {
    path: "cookies",
    component: Cookies,
    data: {
      title: "Cookie Policy | Ftnss",
    },
  },
  {
    path: "privacy",
    component: Privacy,
    data: {
      title: "Privacy Policy | Ftnss",
    },
  },
  {
    path: "terms",
    component: Terms,
    data: {
      title: "Terms of Service | Ftnss",
    },
  },
  {
    path: "comms",
    component: Community,
    data: {
      title: "Community Guidelines | Ftnss",
    },
  },
  {
    path: "eula",
    component: EULA,
    data: {
      title: "End User License Agreement | Ftnss",
    },
  },
  {
    path: "**",
    redirectTo: "",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: "enabled",
      scrollPositionRestoration: "enabled",
      scrollOffset: [0, 0],
    }),
  ],
  exports: [RouterModule],
})
export class FtnssRouting {}
