<div *ngLet="myPasses(util.storey.payments) as passes">
  <div class="pt-5">
    <div
      class="hai bg-bg1 bo-bg3 shadow-sm csw  {{ !util.isM ? 'h6' : 'fs09' }} fw-3 px-1"
    >
      <input
        class="c0 fw-7 fs2 spa8 px-4 text-uppercase"
        type="text"
        [(ngModel)]="activationCode"
        placeholder="Enter code"
      />
      <div class="searchx">
        <button (click)="scanQR()" class="shadow-sm bg-c0 cw">
          <i class="fi fi-sr-camera-viewfinder"></i>
        </button>
      </div>
    </div>
    <div class="text-center mt-3">
      <button
        *ngIf="is_loading"
        class="spa2 brad3 h3 px-1 py-0 shadow-sm bg-cwe"
      >
        <loader [size]="30"></loader>
      </button>
      <button
        *ngIf="!is_loading"
        (click)="activatePass()"
        class="spa2 brad3 h3 px-5 py-4 shadow-sm bg-b4 cw"
      >
        <i class="fi fi-sr-user-key"></i> ACTIVATE CODE
      </button>
    </div>
  </div>

  <hr />
  <div *ngIf="passes.length > 0" class="psRow p-0">
    <div class="psCol p-1" *ngFor="let ps of passes">
      <div class="bg-cw b4 pBox rBor shadow bol-cmain p-0">
        <div class="row m-0">
          <div class="col-12">
            <p class="c0 fw-7 m-0 pt-4 text-center h3 poi">
              {{ps.data.user_name}}
            </p>
            <hr />
          </div>
          <div class="col-12 p-0">
            <p class="b9 h5 fw-6 spa5 text-center">
              {{ps.data.pass_type | uppercase}} PASS
            </p>
            <p class="text-center fw-8 ccr">EXP: {{checkActive(ps)[1]}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="passes.length < 1" class="mt-5 text-center mx-auto">
    <h3 class="csw spa2">NO ACTIVE PASSES</h3>
  </div>

  <hr class="mt-5" />

  <div class="mt-5 text-center mx-auto">
    <h3 class="csw spa2">{{registered_passes ?? 0}} PURCHASED PASSES</h3>
  </div>
</div>
