import { Component, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "u-delete",
  templateUrl: "./u-delete.html",
  styleUrls: ["../../widgets/gyms/gyms.scss"],
})
export class UserDelete implements OnInit {
  constructor(public util: Utilities) {}

  ngOnInit() {}

  requestDelete(mode) {
    const userId = this.util.storey.user_db;

    this.util.afd.object(`v2/users/${this.util.storey.user.uid}`).update({
      delete: mode,
    });

    mode
      ? this.util.warningToast(
          "Account Deletion",
          `You have requested to delete your account. We will respond shortly`
        )
      : this.util.warningToast(
          "Account Deletion",
          `You have revoked your request to delete your account. We will respond shortly`
        );
  }
}
