import { Component } from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "p-page",
  templateUrl: "./p-page.html",
  styleUrls: ["../partners.scss"],
})
export class PartnerPage {
  constructor(public util: Utilities) {}
}
