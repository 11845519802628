<div>
  <img class="flogo" src="/assets/logo.png" height="40px" alt="FTNSS" />
  <!-- <img
    *ngIf="!util.isM"
    src="/assets/ftnsstext.png"
    class="mx-2"
    height="22px"
    alt=""
  /> -->
</div>
