<div class="fcol p-2 csw poi">
  <div class="slotslide brad">
    <span *ngIf="util.is_connected" class="slotfav">
      <i
        class="fi fi-sr-heart fs15"
        [ngClass]="util.checkFav(gym.id) ? 'c0' : 'cbtr'"
      ></i>
    </span>
    <span *ngIf="util.is_connected" (click)="util.addFav(gym)" class="slotfav">
      <i class="fi fi-rr-heart cw fs15"></i>
    </span>
    <span class="slotrating fw-6">
      <i
        *ngFor="let i of util.repeat(gym.rating)"
        class="fi fi-sr-star c0 mr-1"
      ></i>
      <br />
      {{gym .reviews}}
      <span class="c0">Reviews</span>
    </span>
    <img
      (click)="gymRoute(gym)"
      [src]="gym.images[0]"
      class="brad ftimg"
      alt=""
    />
    <div class="px-3 py-2 b9">
      <p (click)="gymRoute(gym)" class="text-truncate fw-8 my-1 h5 b2">
        {{gym.name}}
      </p>
      <p class="fw-6 my-1 fs09">{{gym.location}}</p>
      <p class="fw-7 c0 my-1">
        <img
          *ngFor="let i of gym.categories"
          width="20px"
          class="mr-3 conimg"
          [src]="catMap[i]"
          alt=""
        />
      </p>
      <p class="mt-3 mb-0 fw-8">
        <span class="c0 fw-8 h5">${{gym.price_day}}</span>
        <span class="fs09"> /day</span> &nbsp;
        <span class="c0 fw-8 h5">${{gym.price_week}}</span>
        <span class="fs09"> /wk</span> &nbsp;
        <span class="c0 fw-8 h5">${{gym.price_month}}</span>
        <span class="fs09"> /mo</span>
      </p>
      <p class="mt-3 text-right">
        <button
          (click)="gymRoute(gym)"
          class="brad2 px-4 py-3 shadow bg-cw fw-7 b3"
        >
          <span><i class="fi fi-sr-hourglass-start c0 mr-2"></i></span>
          Reserve
        </button>
      </p>
    </div>
  </div>
</div>
