import { Component } from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "u-purchases",
  templateUrl: "./u-purchases.html",
  styleUrls: ["./u-purchases.scss"],
})
export class UserPurchases {
  constructor(public util: Utilities) {}
}
