<div class="admin-container">
  <!-- Sidebar for node navigation -->
  <div class="sidebar">
    <ul>
      <li
        (click)="changeNode('users')"
        [class.active]="currentNode === 'users'"
      >
        Users
      </li>
      <li
        (click)="changeNode('partners')"
        [class.active]="currentNode === 'partners'"
      >
        Partners
      </li>
      <li (click)="changeNode('gyms')" [class.active]="currentNode === 'gyms'">
        Gyms
      </li>
      <li
        (click)="changeNode('payments')"
        [class.active]="currentNode === 'payments'"
      >
        Payments
      </li>
    </ul>
  </div>

  <!-- Main content area for displaying data -->
  <div class="content">
    <h3>
      {{ currentNode | uppercase }}
      <button (click)="syncer()" class="p-3 brad2">SYNCER</button>
    </h3>

    <!-- Table to display data from Firebase -->
    <table *ngIf="currentdb" class="fs07">
      <thead>
        <tr>
          <th>*</th>
          <th *ngFor="let key of key_map[currentNode].headers">{{ key }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          [ngClass]="{'bo3-b9 c0' : isSelected(item[key_map[currentNode].tracker])}"
          *ngFor="let item of currentdb; let i = index"
        >
          <td>
            <input
              type="checkbox"
              (change)="toggleSelection(item[key_map[currentNode].tracker])"
            />
          </td>
          <td *ngFor="let kex of key_map[currentNode].headers">
            {{ getNestedValue(item, kex) }}
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Buttons for performing CRUD operations -->
    <div class="action-buttons">
      <button (click)="deleteSelected()">Delete Selected</button>
      <!-- You can add buttons for Add and Update here -->
    </div>
  </div>
</div>
