<div
  *ngLet="util.getId(util.storey.router.url.split('?')[0]) as sid"
  class="con-fin pt-5"
>
  <ng-container *ngLet="util.storey?.gyms[sid] as gym">
    <div class="ffrow">
      <div class="ffcol">
        <div class="p-0">
          <img
            width="100%"
            class="brad mimg"
            [src]="getMainImage(gym.id, gym.images)"
            alt=""
          />
        </div>

        <div class="thumbs-container mt-2">
          <div class="thumbs">
            <img
              class="bo3-b9 brads mx-1"
              [ngClass]="{'active': getMainImage(gym.id, gym.images) === image}"
              *ngFor="let image of gym.images"
              [src]="image"
              alt="Gym Thumbnail"
              (click)="setMainImage(gym.id, image)"
            />
          </div>
        </div>
      </div>

      <div class="ffcol">
        <div class="p-1 csw">
          <h3 class="py-1 my-1 fw-6 h2">{{gym.name}}</h3>
          <h6 class="py-1 my-1 fw-4">{{gym.location}}</h6>
          <p class="fw-6 my-2">
            <i
              *ngFor="let i of util.repeat(gym.rating)"
              class="fi fi-sr-star c0 mr-1"
            ></i>
            &nbsp; &nbsp; {{gym .reviews}}
            <span class="c0">Reviews</span>
          </p>

          <p class="my-4" [ngClass]="{'transp' : !gym.onboard}">
            <button
              class="brad2 px-3 py-2 shadow bg-c0 fw-4 cwe bo3-cl2 m-2"
              (click)="bookPass(gym.price_day, 'Day', gym.onboard, bklog)"
            >
              <span class="cw fw-7 pr-1 h5"> $ {{gym.price_day}}</span>/day
            </button>
            <button
              class="brad2 px-3 py-2 shadow bg-c0 fw-4 cwe bo3-cl2 m-2"
              (click)="bookPass(gym.price_week, 'Week', gym.onboard, bklog)"
            >
              <span class="cw fw-7 pr-1 h5">$ {{gym.price_week}}</span>/wk
            </button>
            <button
              class="brad2 px-3 py-2 shadow bg-c0 fw-4 cwe bo3-cl2 m-2"
              (click)="bookPass(gym.price_month, 'Month', gym.onboard, bklog)"
            >
              <span class="cw fw-7 pr-1 h5">$ {{gym.price_month}}</span>/mo
            </button>
          </p>

          <h5 class="py-0 my-3">
            <button
              class="box2 px-3 py-2 brad mx-2"
              [ngClass]="util.checkFav(gym.id) ? 'bg-c0 bg1' : 'bg-bg1 c0 shadow'"
              (click)="util.addFav(gym)"
            >
              <i class="fi fi-rr-heart"></i>
            </button>
            <button
              class="box2 px-3 py-2 brads mx-2"
              [ngClass]="so.bg"
              *ngFor="let so of social"
            >
              <i [ngClass]="so.icon"></i>
            </button>
          </h5>
        </div>
      </div>
      <div class="ffcol p-1">
        <div class="pt-3 px-3">
          <hr class="my-4" />
          <h3 class="csw fw-7 mb-4">Equipments & Amenities</h3>
          <div class="row">
            <div class="col-6 my-3" *ngFor="let i of gym.categories">
              <img
                width="30px"
                class="mr-2 conimg"
                [src]="catMap[i].image"
                alt=""
              />
              {{catMap[i].name}}
            </div>
            <div class="col-6 my-3 b4" *ngFor="let am of util.amenities">
              <i class="mr-2 h3" [class]="am.icon" alt=""></i>
              {{am.name}}
            </div>
          </div>
        </div>
      </div>
      <div class="ffcol p-1">
        <div class="pt-3 px-3">
          <hr class="my-4" />
          <h3 class="csw fw-7 mb-4">Schedule</h3>
          <table>
            <tr>
              <th class="p-3">Day</th>
              <th class="p-3">Hours</th>
            </tr>
            <tr *ngFor="let day of days">
              <td class="p-2 fw-6">{{ day.name }}</td>
              <td class="p-2">{{ day.hours }}</td>
            </tr>
          </table>
          <hr class="my-3" />
          <h3 class="my-4 fw-7 csw">Cancellation Policy</h3>
          <p class="p-2">
            Our cancellation policy states that all sales of passes are final,
            and no refunds will be issued. However, if a pass has not been
            activated, users may transfer it to another gym. Once a pass is
            activated, it is considered final and cannot be canceled, refunded,
            or transferred. We encourage users to review their plans carefully
            and ensure they choose the correct gym before activating a pass.
          </p>
        </div>
      </div>
    </div>

    <!-- * BOOK DIALOG  -->
    <ng-template #bklog>
      <div
        class="position-relative p-1 brad bg-bg1 shadow wim6 text-center csw"
      >
        <span matDialogClose class="h3 poi exiter"
          ><i class="fi fi-sr-times-hexagon"></i
        ></span>
        <div class="pt-5 px-2">
          <div>
            <h3 class="pb-4">
              <button
                (click)="total_time = total_time - 1"
                [disabled]="total_time < 2"
                class="sqb bradd p-3 mx-2 shadow-sm bg-c0 bo3-cl2 cw fw-6"
              >
                <i class="fi fi-sr-minus"></i>
              </button>
              <button class="px-4 py-3 b9 brad shadow bo3-cwe bg-cw">
                <span class="fw-7 c0">{{total_time}}</span> {{total_time < 2 ?
                pass_type : pass_type+'s'}}
              </button>
              <button
                (click)="total_time = total_time + 1"
                [disabled]="total_time > 4"
                class="sqb bradd p-3 mx-2 shadow-sm bg-c0 bo3-cl2 cw fw-6"
              >
                <i class="fi fi-sr-plus"></i>
              </button>
            </h3>

            <hr />
            <div class="text-center py-2">
              <h3>
                <button
                  class="brad3 px-2 py-3 shadow bg-c0 bo3-cl2 cw fw-6"
                  (click)="buyPass(gym)"
                >
                  <span class="fw-7 h3 bg-cw c0 py-2 px-3 brad2"
                    >$ {{total_time * pass_cost }}</span
                  >
                  <span class="px-2">Buy Pass</span>
                </button>
              </h3>
              <p class="my-4 b9 fw-5 px-3">
                By clicking the <span class="c0 fw-6">Buy Pass</span> button,
                you agree to all terms and conditions related to waivers and
                insurance of <span class="c0 fw-6">{{gym.name}}</span>
              </p>

              <!-- Checkbox for Beta Access and Newsletter -->
              <p class="form-check text-center my-4 b4 mx-3">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="betaNewsletterCheckbox"
                  [(ngModel)]="subscribeToNewsletter"
                />
                <label class="form-check-label" for="betaNewsletterCheckbox">
                  I would like to sign up for beta access and receive the
                  newsletter.
                </label>
              </p>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
</div>
