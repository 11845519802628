//* MODULES
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { provideHttpClient } from "@angular/common/http";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { NgxMapboxGLModule } from "ngx-mapbox-gl";
import { Mat } from "./material";
import { CarouselModule } from "ngx-owl-carousel-o";
import { TabsModule } from "ngx-bootstrap/tabs";
import { NgxStripeModule } from "ngx-stripe";

import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { environment as env } from "@env/environment";
//* WIDGETS
import { AuthC } from "./widgets/auth/auth";
import { Buyer } from "./widgets/buyer/buyer";
import { CalendarHeader } from "./widgets/cal-header";
import { ConfirmPay } from "./widgets/confirmpay/confirmpay";
import { Content } from "./widgets/content";
import { Faq } from "./widgets/faq/faq";
import { Gym } from "./widgets/gyms/gym";
import { GymBook } from "./widgets/gyms/gym-book";
import { GymCard } from "./widgets/gyms/gym-card";
import { Gyms } from "./widgets/gyms/gyms";
import { Land } from "./widgets/land/land";
import { Loader } from "./widgets/loader/loader";
import { Maps } from "./widgets/maps/maps";
import { SearchBar } from "./widgets/search-bar/search-bar";
import { Sport } from "./widgets/sports/sport";
import { Sports } from "./widgets/sports/sports";
import { TOS } from "./widgets/tos/tos";
//* USER WIDGETS
import { UserDash } from "./user/u-dash/u-dash";
import { UserHome } from "./user/u-home/u-home";
import { UserPasses } from "./user/u-passes/u-passes";
import { UserSports } from "./user/u-sports/u-sports";
import { UserPurchases } from "./user/u-purchases/u-purchases";
import { UserFavs } from "./user/u-favs/u-favs";
import { UserDelete } from "./user/u-delete/u-delete";
import { Adminy } from "./widgets/adminy/adminy";
//* PARTNER WIDGETS
import { Partners } from "./partner/partners";
import { NewPartner } from "./partner/p-new/p-new";
import { PartnerDash } from "./partner/p-dash/p-dash";
import { PartnerPasses } from "./partner/p-passes/p-passes";
import { PartnerHome } from "./partner/p-home/p-home";
import { PartnerPage } from "./partner/p-page/p-page";
import { PartnerInventory } from "./partner/p-home/p-inventory";
import { PartnerEdit } from "./partner/p-home/p-edit";
import { PartnerPhotoEdit } from "./partner/p-home/p-photo-edit";
import { PartnerAddGym } from "./partner/p-home/p-add-gym";
//* NAVS
import { Logo } from "./navs/logo/logo";
import { MainFooter } from "./navs/main-footer/main-footer";
import { MobileFooter } from "./navs/mobile-footer/mobile-footer";
import { Navbar } from "./navs/navbar/navbar";
//*PAGES
import { FtnssHelp } from "./pages/help/help";
import { About } from "./pages/about/about";
import { Cookies } from "./pages/cookies/cookies";
import { NotFound } from "./pages/not-found/not-found";
import { Privacy } from "./pages/privacy/privacy";
import { Terms } from "./pages/terms/terms";
import { Community } from "./pages/community/community";
import { EULA } from "./pages/eula/eula";
//* SERVICES
import { Utilities } from "@services/utilities";
import {
  AdminGuard,
  AuthGuard,
  FtnssUserGuard,
  FtnssPartnerGuard,
  ENVGuard,
} from "@services/guard.service";
//* DIRECTIVES
import { NumberDirective } from "@directives/numbers.directive";
import { PhoneDirective } from "@directives/phone.dir";
import { LetDirective } from "@directives/let.directive";
//* PIPES
import { OrderPipe } from "@pipes/order-by.pipe";
import { DoxPipe } from "@pipes/dox.pipe";
import { FilterPipe } from "@pipes/filter.pipe";
import { RandomOrderPipe, SievePipe } from "@pipes/sieve.pipe";
import { RandomPipe } from "@pipes/random.pipe";
import { DistancePipe } from "@pipes/distance.pipe";
import { DayPipe } from "@pipes/calendar/day.pipe";
import { StringDatePipe } from "@pipes/calendar/stringDate.pipe";
import { SearchFilterPipe } from "@pipes/search.pipe";
import { IosBottomDirective } from "@directives/ios.dir";
import { GoogleMapsModule } from "@angular/google-maps";

const modules = [
  CommonModule,
  NgbModule,
  RouterModule,
  ReactiveFormsModule,
  FormsModule,
  TabsModule,
  NgxMaterialTimepickerModule,
  Mat,
  CarouselModule,
  GoogleMapsModule,
];

const rootModules = [
  NgxStripeModule.forRoot(env.stripe_live),
  CalendarModule.forRoot({
    provide: DateAdapter,
    useFactory: adapterFactory,
  }),
  NgxMapboxGLModule.withConfig({
    accessToken: env.mapbox.access,
  }),
];

//* prettier-ignore
const components = [
  //* widgets
  AuthC,
  Content,
  TOS,
  Land,
  Sports,
  Sport,
  Maps,
  // CustomMarkerC,
  Loader,
  SearchBar,
  Faq,
  Gyms,
  Gym,
  GymBook,
  GymCard,
  CalendarHeader,
  Buyer,
  // Shop,
  // Product,
  ConfirmPay,
  //* user-widgets
  UserDash,
  UserHome,
  UserPasses,
  UserSports,
  UserPurchases,
  UserFavs,
  UserDelete,
  Adminy,
  //* partner-widgets
  Partners,
  NewPartner,
  PartnerDash,
  PartnerPasses,
  PartnerHome,
  PartnerEdit,
  PartnerPhotoEdit,
  PartnerInventory,
  PartnerPage,
  PartnerAddGym,
  //* nav
  Logo,
  MobileFooter,
  MainFooter,
  Navbar,
  //* pages
  FtnssHelp,
  About,
  NotFound,
  Cookies,
  Privacy,
  Terms,
  Community,
  EULA,
];

//* prettier-ignore
const pipes2 = [
  OrderPipe,
  DoxPipe,
  FilterPipe,
  SievePipe,
  RandomPipe,
  DistancePipe,
  // DayPipe,
  StringDatePipe,
  SearchFilterPipe,
  RandomOrderPipe,
];

const directives = [
  NumberDirective,
  PhoneDirective,
  LetDirective,
  IosBottomDirective,
  ...pipes2,
];

//* prettier-ignore
const services = [Utilities];

const guards = [
  AuthGuard,
  FtnssUserGuard,
  FtnssPartnerGuard,
  AdminGuard,
  ENVGuard,
];

const pipes = [];

@NgModule({
  declarations: [...components, ...pipes, ...directives],
  imports: [...modules, ...rootModules],
  exports: [...components, ...modules, ...pipes, ...directives],
  providers: [...services, ...pipes, ...guards, provideHttpClient()],
})
export class Components {}
