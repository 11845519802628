<div
  class="con-fin"
  *ngLet="util.map2Arr(util.storey.user_db.favorites, 1) as favs"
>
  <div *ngIf="favs.length > 0" class="frow px-3">
    <ng-container *ngFor="let fav of favs">
      <gym-card
        *ngIf="!util.storey.gyms[fav].inactive"
        [gym]="util.storey.gyms[fav]"
      ></gym-card>
    </ng-container>
  </div>
  <div *ngIf="favs.length < 1" class="p-5 text-center mx-auto">
    <h3 class="csw">You have no favorite gyms.</h3>

    <button
      class="bg-c0 bg1 px-4 py-3 m-2 brad2 h3 poi fw-7 fs8"
      routerLink="/games"
    >
      FIND GYMS
    </button>
  </div>
</div>
