<div *ngLet="util.storey.partner_db as partner" class="">
  <div *ngIf="partner === '' || !partner" class="ftnss-box p-2 csw">
    <div class="partner-onboard">
      <div class="row mt-5 pt-2">
        <div class="col-12 col-md-6 text-center my-auto">
          <p class="fw-7 c0 h2 my-1">Host your Gym</p>
          <p class="fw-7 b4 display-4 my-1">You could earn</p>
          <p class="b3 my-3">
            <span class="display-2 fw-8">${{pass_no * 10}}</span>
            <span class="fw-6">weekly</span>
          </p>
          <p class="h5">
            <span class="c0 fw-8">{{pass_no}} passes</span> at an estimated
            <span class="c0 fw-8">$10</span> per daily pass.
          </p>
          <p>
            <mat-slider min="50" max="500" step="50">
              <input class="wim3" matSliderThumb [(ngModel)]="pass_no" />
            </mat-slider>
          </p>
          <p>
            <button
              (click)="util.dlogtr(partner_auth)"
              class="brad2 shadow bg-c0 bg1 px-5 py-3 h4 my-2"
            >
              Setup your Gym
            </button>
          </p>
        </div>
        <div class="col-12 col-md-6 pat-map">
          <maps
            [data]="filtered_gyms"
            [map_init]="map_init"
            map_type="gyms"
          ></maps>
        </div>
      </div>

      <div class="my-4 py-5">
        <faq is_partner="true"></faq>
      </div>
    </div>

    <main-footer></main-footer>
    <!-- <div class="partner_banner bg-ctr p-0 m-4">
      <div class="row p-0">
        <div class="col-md-6 pb-5">
          <p-new></p-new>
        </div>
        <div class="col-md-6 message bg-lin1 text-center py-4">
          <h3 class="display-4 fw-6 b2 my-5">
            FTNSS <br />
            PARTNERS
          </h3>
          <h3 class="cw px-5 fw-7 h1">
            Unlock new <span class="b3 fw-9">revenue streams</span> with
            flexible passes on the ultimate fitness platform.
          </h3>
        </div>
      </div>
    </div> -->
  </div>
  <div class="" *ngIf="partner">
    <ng-container>
      <p-dash></p-dash>
    </ng-container>
  </div>
</div>

<ng-template #partner_auth>
  <div class="position-relative p-1 brad bg-bg1 shadow wimf5 text-center csw">
    <span matDialogClose class="h3 poi exiter"
      ><i class="fi fi-sr-times-hexagon"></i
    ></span>
    <span class="spa3 fw-6 b4 h4 dlog-header">PARTNER LOGIN</span>
    <div class="pt-5">
      <p-new></p-new>
    </div>
  </div>
</ng-template>
