<div *ngLet="util.storey.filters[search_type.name] as filx">
  <div class="bg-bg1 shadow-sm scrollmenu">
    <div class="con-fin">
      <div class="conbox">
        <div
          class="conlist"
          [id]="search_type.name"
          #conscroll
          (scroll)="util.conscroller(scrollx, conscroll)"
        >
          <ng-container *ngFor="let co of search_type.categories">
            <div
              class="bg-bg1 mx-3 csw p-0 pt-2 poi text-center catitem"
              [ngClass]=" selected[co.name] ? 'catActive' : 'catItem'"
              (click)="updateCategories(co.name)"
            >
              <img width="25px" class="m-2" [src]="co.image" alt="" />
              <h6 class="brad p-2 text-truncate">{{co.name}}</h6>
            </div>
          </ng-container>
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        </div>
        <div
          class="conleft shadow-sm bg-bg2 csw poi"
          (click)="util.conScrollLeft(scrollx, conscroll)"
          *ngIf="scrollx[0] > 20 && !util.isM"
        >
          <i class="fi fi-sr-angle-left"></i>
        </div>

        <div
          class="conright shadow-sm bg-bg2 csw poi text-center"
          (click)="util.conScrollRight(scrollx, conscroll)"
          *ngIf="scrollx[0] < (scrollx[2] - 20) && !util.isM"
        >
          <i class="fi fi-sr-angle-right"></i>
        </div>
      </div>
    </div>
  </div>
</div>
