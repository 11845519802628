import { Component, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "navbar",
  templateUrl: "./navbar.html",
  styleUrls: ["./navbar.scss"],
})
export class Navbar implements OnInit {
  constructor(public util: Utilities) {}

  ngOnInit() {}
}
