export const faqs = [
  {
    question: "What is FTNSS?",
    answer: `FTNSS is an app that makes it easy to buy a short-term gym membership anywhere in the world.

    We also connect athletes with one another and venues, all in real-time.
    
    Hate hotel gyms? We do too. We believe that everyone should have access to fitness..`,
  },
  {
    question: "How do single day memberships work?",
    answer: `Rates for short-term gym memberships are set by FTNSS dynamically.

    We want to make fitness as accessible and convenient as possible, so all of our passes are valid for 30 days based on the start date users select.
    
    Users who book by the day may visit a gym anytime within the 30 day window they select, even on non-consecutive days.
    
    For gym partners, FTNSS is simply a new, free revenue stream that's focused on travelers who otherwise would not be interested in signing up for a monthly membership.  `,
  },
  {
    question: "What features have been developed for the LGBTQ+ community?",
    answer: `From Jordan Robinson, CEO/Founder🌈 :

    The story of FTNSS is inspired wholeheartedly by my experience as queer person who has gone on a significant fitness journey, traveled the world and played competitive basketball my entire life.
    
    FTNSS is the world's first technology company designed from the ground up to serve the fitness and athletic needs of the LGBTQ+ community.
    
    FTNSS Sports will launch in Q2 2024 with LGBTQ+ and Women's Sports Leagues.
    
    Discover and register for a league, pay dues, manage and grow your league in one central location.
    
    Today, many of our queer sports leagues face extinction simply because of discoverability.
    When our queer sports leagues disappear our local communities become a little less vibrant and connected.
    
    FTNSS is the solution for athletes worldwide.
    `,
  },
  {
    question: "How do I register my gym?",
    answer: `Easy! Click here to register your gym and learn more about how we can increase your monthly recurring revenue for free.`,
  },
];

export const partner_faqs = [
  {
    question: "How can I sign up as a gym partner?",
    answer:
      "To sign up, click on the 'Setup your Gym' button on our website, complete the registration form with your gym's details, and submit the required documents for verification.",
  },
  {
    question: "What documents are required for registration?",
    answer:
      "You will need to provide proof of gym ownership or authorization, a valid business license, and photos of your gym facilities.",
  },
  {
    question: "Is there a fee for joining FTNSS as a gym partner?",
    answer:
      "There is no upfront cost to join FTNSS. We operate on a revenue-sharing model, where a small commission is deducted from each booking made through our platform.",
  },
  {
    question: "How will I receive payments for bookings?",
    answer:
      "Payments are processed weekly and deposited directly into your registered bank account setup with Stripe.",
  },
  {
    question: "Can I set my own pricing for day and week passes?",
    answer:
      "Yes, you have full control over the pricing of your day and week passes. However, we recommend competitive pricing to attract more users.",
  },
  {
    question: "How can I update my gym's information on FTNSS?",
    answer:
      "You can log into your partner dashboard to edit details such as pricing, gym hours, facilities, and images at any time.",
  },
  {
    question: "What if I need assistance or have technical issues?",
    answer:
      "Our support team is available 24/7 to assist you. You can reach us via the support section in your partner dashboard or email us at support@ftnss.com.",
  },
  {
    question: "How does FTNSS handle cancellations or no-shows?",
    answer:
      "FTNSS has a clear cancellation policy. Users must cancel within the allowed time frame to receive a refund. For no-shows, the gym will still receive payment as per the terms.",
  },
];
