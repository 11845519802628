import { Component } from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "u-sports",
  templateUrl: "./u-sports.html",
  styleUrls: ["./u-sports.scss"],
})
export class UserSports {
  constructor(public util: Utilities) {}
}
