import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Utilities } from "@services/utilities";

@Component({
  selector: "tos",
  templateUrl: "./tos.html",
  styleUrls: ["./tos.scss"],
})
export class TOS implements OnInit {
  html;
  dlogref;
  isAuth = false;

  list = [
    {
      text: "Terms of Service",
      form: new FormControl(false),
      html: "terms",
    },
    {
      text: "User Agreement",
      form: new FormControl(false),
      html: "eula",
    },
    {
      text: "Community Guidelines",
      form: new FormControl(false),
      html: "comm",
    },
    {
      text: "Privacy Policy",
      form: new FormControl(false),
      html: "priv",
    },
  ];

  constructor(public util: Utilities) {}

  ngOnInit() {
    this.isAuth = window.location.pathname.includes("signup");
  }

  reedy(html, temp) {
    this.html = html;
    this.dlogref = this.util.dialog.open(temp, {
      width: "98vw",
      height: "95vh",
      maxWidth: "800px",
    });
  }

  async agree() {
    if (this.isAuth) {
      // this.util.sto.dispatch(new SeTOS(true));
      this.util.closeAllDialogs();
    } else {
      // let agree = await this.util.auth.setTos();
      // if (agree) {
      //   this.util.closeAllDialogs();
      //   this.util.snack.open(
      //     "Awesome!!! Keep Voting",
      //     "OK",
      //     "snagreen"
      //   );
      // } else {
      //   this.util.snack.open(
      //     "Something went wrong !!! Try Again",
      //     "OK",
      //     "snared"
      //   );
      // }
    }
  }
}
