import { Component, Inject, Input, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Utilities } from "@services/utilities";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "confirmpay",
  templateUrl: "./confirmpay.html",
  styleUrls: ["./confirmpay.scss"],
})
export class ConfirmPay implements OnInit {
  amount = 0;
  curr_user = this.util.storey.user_db;
  game_data;

  constructor(
    public util: Utilities,
    @Inject(MAT_DIALOG_DATA) public dlogdata: any
  ) {}

  ngOnInit(): void {
    if (this.dlogdata?.type === "party") {
      this.amount = this.dlogdata.party.entry_fingz;
    } else if (this.dlogdata?.type === "game") {
      this.amount = this.dlogdata.cost;
      this.game_data = { ...this.dlogdata };
    }
  }

  async confirm() {
    if (this.dlogdata?.type === "party") {
      await this.bookParty(this.dlogdata.party);
      this.util.closeAllDialogs();
    } else if (this.dlogdata?.type === "game") {
      await this.bookGame();
    }
  }

  async bookParty(party) {
    let pbk_id = `${this.curr_user.uid}_${party.id}`;

    let booking: any = await firstValueFrom(
      this.util.http.post("https://api.techceed.org:5201/bookParty", {
        uid: this.util.storey.user.uid,
        pbk_id: pbk_id,
        cost: this.amount,
        party_id: party.id,
      })
    );
    console.log(booking);

    if (booking.success) {
      this.util.closeAllDialogs();
      this.util.successToast("Success", "Your payment was successful");
    } else {
      this.util.errorToast("Error", "Couldn't join party! Try Again");
    }
  }

  getNewSchedule(bkid) {
    let new_sc = this.util.lo_cloneDeep(this.game_data.slot.schedules);
    Object.entries(this.game_data.schedule).forEach((d) => {
      Object.keys(d[1]).forEach((t) => {
        new_sc[d[0]][t].title = "Booked";
        new_sc[d[0]][t].booking = bkid;
      });
    });
    return new_sc;
  }

  async bookGame() {
    let timex = new Date().getTime();
    let gbk_id = `${this.curr_user.uid}_${this.game_data.slot.id}_${timex}`;
    let new_scs = this.getNewSchedule(gbk_id);

    let booking: any = await firstValueFrom(
      this.util.http.post("https://api.techceed.org:5201/bookGame", {
        uid: this.util.storey.user.uid,
        gbk_id: gbk_id,
        cost: this.amount,
        slot: this.game_data.slot,
        schedule: this.game_data.schedule,
        new_sk: new_scs,
      })
    );
    console.log(booking);

    if (booking.success) {
      this.util.closeAllDialogs();
      this.util.successToast("Success", "Your payment was successful");
    } else {
      this.util.errorToast("Error", "Couldn't book game! Try Again");
    }
  }
}
