<div #mapContainer id="map" class="map-container"></div>

<ng-template #currentGym>
  <div class="position-relative p-1 brad bg-bg1 shadow wim6 csw">
    <span matDialogClose class="h3 poi exiter"
      ><i class="fi fi-sr-times-hexagon"></i
    ></span>
    <div class="mt-5 p-2">
      <gym-card [gym]="current_gym"></gym-card>
    </div>
  </div>
</ng-template>
