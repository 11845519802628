import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  TemplateRef,
} from "@angular/core";
import { Utilities } from "@services/utilities";
import { darkModeStyle } from "./darkmap";
import MarkerClusterer from "@googlemaps/markerclustererplus";

declare const google: any;

class CustomOverlayView extends google.maps.OverlayView {
  marker?: google.maps.Marker;
}

@Component({
  selector: "maps",
  templateUrl: "./maps.html",
  styleUrls: ["./maps.scss"],
})
export class Maps implements OnInit {
  @Input() data: any = {};
  @Input() map_init: any;
  @Input() map_type: string;

  current_gym;

  @ViewChild("mapContainer", { static: true }) mapContainer: ElementRef;

  @ViewChild("currentGym") currentGymTemplate: TemplateRef<any>;

  map!: google.maps.Map;
  center: google.maps.LatLngLiteral = { lat: 39.8283, lng: -98.5795 };
  zoom = 10;
  options: google.maps.MapOptions = {
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
    zoom: 3,
    minZoom: 3,
    center: this.center,
    styles: darkModeStyle,
  };

  markerCluster!: MarkerClusterer;

  constructor(public util: Utilities) {}

  async ngOnInit() {
    this.center = {
      lat: this.map_init.lat,
      lng: this.map_init.lng,
    };

    this.zoom = this.map_init.zoom;

    this.initMap();
    this.markerLoader();
  }

  initMap() {
    this.map = new google.maps.Map(this.mapContainer.nativeElement, {
      center: this.center,
      zoom: this.zoom,
      ...this.options,
    });
  }

  markerLoader() {
    const markers = Object.values(this.data).map((location: any) => {
      const position = new google.maps.LatLng({
        lat: location.coordinates[1],
        lng: location.coordinates[0],
      });
      const marker = new google.maps.Marker({
        position: position,
        label: {
          text: `$${location.price_day.toString()}`,
          color: "white",
          fontFamily: "Inconsolata",
          fontWeight: "700",
          fontSize: "18px",
        },
        icon: {
          url: "assets/marker.png",
          scaledSize: new google.maps.Size(50, 30),
        },
        map: this.map,
      });
      google.maps.event.addListener(marker, "click", () => {
        this.current_gym = location;
        this.util.dlogtr(this.currentGymTemplate);
      });

      return marker;
    });

    // Create the MarkerClusterer with the dummy markers
    this.markerCluster = new MarkerClusterer(this.map, markers, {
      imagePath:
        "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
    });
  }
}
