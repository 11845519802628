import { Component, OnDestroy, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";
import { ddd2 } from "../adminy/dummy2";

@Component({
  selector: "gyms",
  templateUrl: "./gyms.html",
  styleUrls: ["./gyms.scss"],
})
export class Gyms implements OnInit, OnDestroy {
  search_params = {
    name: "gyms",
    title: "Gyms",
    color: "c0",
    border: "bot-c0 opa",
    border_l: "bol-c0",
    profile_icon: "fas fa-calendar-check",
    profile_bg: "bg-c0",
    profile_link: "/profile/booking",
    categories: this.util.gymCategories,
  };
  curr_slot;
  search;
  filters;
  local_store;
  gyms;
  filtered_gyms;
  empty_gym_list;
  catMap = this.util.gymCategories.reduce((acc, category) => {
    acc[category.name] = category.image;
    return acc;
  }, {});

  map_init = {
    lat: 30.2672,
    lng: -37.7431,
    zoom: 2,
  };
  randomGyms = [];

  constructor(public util: Utilities) {}

  ngOnInit() {
    this.local_store = this.util.selly().subscribe((x) => {
      this.gyms = x.gyms;
      this.filtered_gyms = this.filterGymsByCategories(x.filters.gyms, x.gyms);
      this.empty_gym_list = Object.keys(this.filtered_gyms).length < 1;
    });
  }

  filterGymsByCategories(filter: Record<string, boolean>, gyms) {
    const allFalse = Object.values(filter ?? {}).every((value) => !value);
    const activeGyms = Object.keys(gyms)
      .filter((key) => !gyms[key].inactive)
      .reduce((acc, key) => {
        acc[key] = gyms[key];
        return acc;
      }, {});
    if (allFalse) {
      return activeGyms;
    }
    const trueCategories = Object.keys(filter ?? {}).filter(
      (key) => filter[key]
    );
    return Object.fromEntries(
      Object.entries(activeGyms).filter(([key, value]: any) =>
        trueCategories.every((category) => value.categories.includes(category))
      )
    );
  }

  fisherYatesShuffle(array: any[]): any[] {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  gymRoute(gym) {
    this.util.router.navigate([`gyms/${gym.id}`]);
  }

  ngOnDestroy() {
    this.local_store.unsubscribe();
  }
}
