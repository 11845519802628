import { Component, OnInit } from "@angular/core";
import {
  Validators,
  FormGroup,
  AbstractControl,
  ValidatorFn,
} from "@angular/forms";
import { Utilities } from "@services/utilities";

import { GoogleAuthProvider, OAuthProvider } from "@angular/fire/auth";

@Component({
  selector: "auth",
  templateUrl: "./auth.html",
  styleUrls: ["./auth.scss"],
})
export class AuthC implements OnInit {
  auth_form: FormGroup;
  auth_type;
  verify = false;
  verifyUser;
  modex;
  is_link = false;
  curr_out;

  constructor(public util: Utilities) {}

  ngOnInit() {
    if (window.location.pathname.substring(0, 7) === "/signup") {
      this.modex = "up";
      this.is_link = true;
    } else if (window.location.pathname.substring(0, 7) === "/signin") {
      this.modex = "in";
      this.is_link = true;
    }

    this.auth_type = this.modex === "up" ? "Sign up" : "Sign in";
    this.setForm();
  }

  get af() {
    return this.auth_form.controls;
  }

  authy() {
    switch (this.auth_type) {
      case "Sign in":
        this.signIn();
        break;
      case "Sign up":
        this.signUp();
        break;
      case "Reset":
        this.recover();
        break;

      default:
        break;
    }
  }

  setForm() {
    this.auth_form = this.util.fb.group({
      fullname: this.auth_type === "Sign up" ? ["", Validators.required] : [""],
      email: [
        "",
        [
          Validators.required,
          Validators.email,
          Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$"),
        ],
      ],
      password: !(this.auth_type === "Reset")
        ? ["", [Validators.required, Validators.minLength(6)]]
        : [""],
      passwordConfirm:
        this.auth_type === "Sign up" ? ["", Validators.required] : [""],
    });

    this.pValidator(this.auth_type === "Sign up");
  }

  switchAuth() {
    switch (this.auth_type) {
      case "Sign in":
        this.auth_type = "Sign up";
        break;
      case "Sign up":
        this.auth_type = "Sign in";
        break;
      case "Reset":
        this.auth_type = "Sign in";
        break;

      default:
        break;
    }
    this.setForm();
    this.auth_form.markAsPristine();
  }

  pValidator(add) {
    if (add) {
      this.auth_form.addValidators(
        matchValidator(
          this.auth_form.get("password"),
          this.auth_form.get("passwordConfirm")
        )
      );
    } else {
      this.auth_form.setValidators(null);
    }
  }

  signIn() {
    this.util.afa
      .signInWithEmailAndPassword(
        this.auth_form.value.email,
        this.auth_form.value.password
      )
      .then(async (x) => {
        await this.util.syncUser(x.user);
        this.util.successToast("Success", "");
      })
      .catch((e) => {
        this.util.errorToast("Error", e.message);
      });
  }

  async signUp() {
    this.util.afa
      .createUserWithEmailAndPassword(
        this.auth_form.value.email,
        this.auth_form.value.password
      )
      .then(async (x) => {
        x.user.updateProfile({
          displayName: this.auth_form.value.fullname,
        });

        await this.util.afd.object(`v2/users/${x.user.uid}`).update({
          email: x.user.email,
          uid: x.user.uid,
          name: this.auth_form.value.fullname,
        });
        this.util.successToast("Success", "");
      })
      .catch((e) => {
        this.util.errorToast("Error", e.message);
      });
  }

  async googleAuth() {
    const provider = new GoogleAuthProvider();
    try {
      const result = await this.util.afa.signInWithPopup(provider);
      const user = result.user;

      await this.util.syncUser(user, true);
    } catch (e) {
      this.util.errorToast("Error", e.message);
    }
  }

  async appleAuth() {
    const provider = new OAuthProvider("apple.com");
    try {
      const result = await this.util.afa.signInWithPopup(provider);
      const user = result.user;

      await this.util.syncUser(user, true);
    } catch (e) {
      this.util.errorToast("Error", e.message);
    }
  }

  recover() {
    this.util.afa
      .sendPasswordResetEmail(this.auth_form.value.email)
      .then((x) => {
        this.util.successToast(
          "Success",
          "Check your email and reset password"
        );

        this.switchAuth();
      })
      .catch((e) => {
        this.util.errorToast("Error", e.message);
      });
  }
}

function matchValidator(
  control: AbstractControl,
  controlTwo: AbstractControl
): ValidatorFn {
  return () => {
    if (control.value !== controlTwo.value)
      return { match_error: "Value does not match" };
    return null;
  };
}
