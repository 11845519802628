<div class="py-4">
  <form class="text-center" [formGroup]="add_gym_form" (ngSubmit)="addNewGym()">
    <div>
      <div>
        <p class="mt-3 h2 form-p">
          <i class="fi fi-sr-gym form-icon"></i>
          <input
            required
            class="bol-b9 p-3 csw bg-bg1 shadow brads"
            formControlName="gym_name"
            type="text"
            placeholder="Gym Name"
            autocomplete="new-gym-name"
          />
        </p>
        <small class="ccr" *ngIf="agf.gym_name.dirty && agf.gym_name.errors"
          >Gym Name is required</small
        >
      </div>
      <div>
        <p class="mt-3 h2 form-p">
          <i class="fi fi-sr-marker form-icon"></i>
          <input
            required
            class="bol-b9 p-3 csw bg-bg1 shadow brads"
            formControlName="gym_address"
            type="text"
            placeholder="Gym Address"
            autocomplete="new-gym-address"
          />
        </p>
        <small
          class="ccr"
          *ngIf="agf.gym_address.dirty && agf.gym_address.errors"
          >Gym Address is required</small
        >
      </div>
      <div class="row form-row">
        <div class="col-4">
          <p class="fw-7">Day</p>
          <p class="mt-3 h2 form-p">
            <i class="fi fi-sr-usd-circle form-icon"></i>
            <input
              required
              class="bol-b9 p-3 c0 bg-bg1 fw-7 shadow brads"
              formControlName="price_day"
              type="number"
              placeholder="Daily Price"
            />
          </p>
        </div>
        <div class="col-4">
          <p class="fw-7">Week</p>
          <p class="mt-3 h2 form-p">
            <i class="fi fi-sr-usd-circle form-icon"></i>
            <input
              required
              class="bol-b9 p-3 c0 bg-bg1 fw-7 shadow brads"
              formControlName="price_week"
              type="number"
              placeholder="Weekly Price"
            />
          </p>
        </div>
        <div class="col-4">
          <p class="fw-7">Month</p>
          <p class="mt-3 h2 form-p">
            <i class="fi fi-sr-usd-circle form-icon"></i>
            <input
              required
              class="bol-b9 p-3 c0 bg-bg1 fw-7 shadow brads"
              formControlName="price_month"
              type="number"
              placeholder="Monthly Price"
            />
          </p>
        </div>
      </div>
    </div>
    <div class="text-center mt-5">
      <button
        class="mx-auto brad3 px-5 py-3 bg-c0 h3 wim3 bg1 bo3-cwe"
        type="submit"
        [disabled]="add_gym_form.invalid"
      >
        Create New Gym
      </button>
    </div>
  </form>
</div>
