export const faqs = [
  {
    question: "What is FTNSS?",
    answer: `FTNSS is an app that makes it easy to buy a short-term gym membership anywhere in the world.

    We also connect athletes with one another and venues, all in real-time.
    
    Hate hotel gyms? We do too. We believe that everyone should have access to fitness..`,
  },
  {
    question: "How do single day memberships work?",
    answer: `Rates for short-term gym memberships are set by FTNSS dynamically.

    We want to make fitness as accessible and convenient as possible, so all of our passes are valid for 30 days based on the start date users select.
    
    Users who book by the day may visit a gym anytime within the 30 day window they select, even on non-consecutive days.
    
    For gym partners, FTNSS is simply a new, free revenue stream that's focused on travelers who otherwise would not be interested in signing up for a monthly membership.  `,
  },
  {
    question: "What features have been developed for the LGBTQ+ community?",
    answer: `From Jordan Robinson, CEO/Founder🌈 :

    The story of FTNSS is inspired wholeheartedly by my experience as queer person who has gone on a significant fitness journey, traveled the world and played competitive basketball my entire life.
    
    FTNSS is the world's first technology company designed from the ground up to serve the fitness and athletic needs of the LGBTQ+ community.
    
    FTNSS Sports will launch in Q2 2024 with LGBTQ+ and Women's Sports Leagues.
    
    Discover and register for a league, pay dues, manage and grow your league in one central location.
    
    Today, many of our queer sports leagues face extinction simply because of discoverability.
    When our queer sports leagues disappear our local communities become a little less vibrant and connected.
    
    FTNSS is the solution for athletes worldwide.
    `,
  },
  {
    question: "How do I register my gym?",
    answer: `Easy! Click here to register your gym and learn more about how we can increase your monthly recurring revenue for free.`,
  },
];
