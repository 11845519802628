import { inject } from "@angular/core";
import { CanActivateFn } from "@angular/router";
import { Utilities } from "./utilities";

export function AuthGuard(): CanActivateFn {
  return () => {
    const util: Utilities = inject(Utilities);
    const role = util.snappy().role;

    if (role === "guest") {
      return true;
    }
    util.router.navigate([role]);
    return false;
  };
}

export function FtnssUserGuard(): CanActivateFn {
  return () => {
    const util: Utilities = inject(Utilities);
    const role = util.snappy().role;

    if (role === "user") {
      return true;
    }
    util.router.navigate(["/signin"]);
    return false;
  };
}

export function ENVGuard(): CanActivateFn {
  return () => {
    const util: Utilities = inject(Utilities);

    if (!util.env.production) {
      return true;
    }

    util.router.navigate(["/user"]);
    return false;
  };
}

export function FtnssPartnerGuard(): CanActivateFn {
  return () => {
    const util: Utilities = inject(Utilities);
    const role = util.snappy().role;

    if (role === "partner") {
      return true;
    }
    util.router.navigate(["/partner"]);
    return false;
  };
}

export function AdminGuard(): CanActivateFn {
  return () => {
    const util: Utilities = inject(Utilities);
    const role = util.snappy().role;

    if (role === "admin") {
      return true;
    }
    util.warningToast("Error", "You are not an Admin");
    util.router.navigate(["/signin"]);
    return false;
  };
}
