import { Component, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "partners",
  templateUrl: "./partners.html",
  styleUrls: ["./partners.scss"],
})
export class Partners implements OnInit {
  filtered_gyms = Object.fromEntries(
    Object.entries(this.util.storey.gyms).filter(
      ([key, gym]: [string, any]) => !gym.inactive
    )
  );
  map_init = {
    lat: 39.8283,
    lng: -94.5795,
    zoom: 4,
  };
  pass_no = 150;

  constructor(public util: Utilities) {}

  ngOnInit(): void {
    console.log(this.filtered_gyms);
  }

  formatLabel(value: number): string {
    if (value >= 1000) {
      return Math.round(value / 1000) + "k";
    }

    return `${value}`;
  }
}
