export const ddd2 = {
  London: [
    {
      "Record ID": "18607107879",
      company_name: "GoodLife Fitness",
      "Create Date": "2024-01-04 00:09",
      Type: "Vendor",
      "Street Address": "710 Proudfoot Lane",
      "Street Address 2": "",
      city: "London",
      "State/Region": "Ontario",
      "Postal Code": "N6H 5G5",
      "Country/Region": "Canada",
      "Time Zone": "America/Toronto",
      "Phone Number": "5194384135",
      Industry: "Health, Wellness and Fitness",
      "Lifecycle Stage": "Other",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-81.288041, 42.986956],
      images: [
        "https://live.staticflickr.com/65535/54087985075_200cf68bc3.jpg",
      ],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Special", "Cardio", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "goodlife_fitness_london",
      name: "GoodLife Fitness",
      location: "London, Ontario, Canada",
    },
    {
      "Record ID": "23080314958",
      company_name: "The Vault Gym",
      "Create Date": "2024-09-17 17:07",
      Type: "Vendor",
      "Street Address": "5-25 Scrutton Street",
      "Street Address 2": "",
      city: "London",
      "State/Region": "Greater London",
      "Postal Code": "EC2A 4HJ",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-0.083802, 51.52348],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Courts", "LGBTQIA+", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "the_vault_gym_london",
      images: [
        "https://live.staticflickr.com/65535/53992715153_500d1f54b1.jpg",
      ],
      name: "The Vault Gym",
      location: "London, Greater London, United Kingdom",
    },
    {
      "Record ID": "23090042432",
      company_name: "FITNASTIC HEALTH CLUB",
      "Create Date": "2024-09-17 17:12",
      Type: "Vendor",
      "Street Address": "44 Tottenham Lane",
      "Street Address 2": "",
      city: "London",
      "State/Region": "Greater London",
      "Postal Code": "N8 7EA",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-0.122033, 51.581149],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Powerlifting", "Machines", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "fitnastic_health_club_london",
      images: [
        "https://live.staticflickr.com/65535/53949586939_a5a2c7440a.jpg",
      ],
      name: "FITNASTIC HEALTH CLUB",
      location: "London, Greater London, United Kingdom",
    },
    {
      "Record ID": "23090042419",
      company_name: "W6 Gym",
      "Create Date": "2024-09-17 17:12",
      Type: "Vendor",
      "Street Address": "200 Prebend Gardens",
      "Street Address 2": "",
      city: "London",
      "State/Region": "Greater London",
      "Postal Code": "W6 0XT",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-0.247159, 51.495223],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Machines", "Cardio", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "w6_gym_london",
      images: [
        "https://live.staticflickr.com/65535/53944264473_17def033b9.jpg",
      ],
      name: "W6 Gym",
      location: "London, Greater London, United Kingdom",
    },
    {
      "Record ID": "23090042713",
      company_name: "MANOR London",
      "Create Date": "2024-09-17 17:18",
      Type: "Vendor",
      "Street Address": "86 Eccleston Square",
      "Street Address 2": "",
      city: "London",
      "State/Region": "Greater London",
      "Postal Code": "SW1V 1PH",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-0.143714, 51.492942],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Pool", "Powerlifting", "Courts"],
      rating: 5,
      reviews: 0,
      id: "manor_london_london",
      images: [
        "https://live.staticflickr.com/65535/53938675062_675af9b8a3.jpg",
      ],
      name: "MANOR London",
      location: "London, Greater London, United Kingdom",
    },
    {
      "Record ID": "23080315467",
      company_name: "Physical Culture Gym",
      "Create Date": "2024-09-17 17:18",
      Type: "Vendor",
      "Street Address": "",
      "Street Address 2": "",
      city: "London",
      "State/Region": "Greater London",
      "Postal Code": "SW15 2LW",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-0.099076, 51.509648],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Female Only", "LGBTQIA+", "Machines"],
      rating: 5,
      reviews: 0,
      id: "physical_culture_gym_london",
      images: [
        "https://live.staticflickr.com/65535/53847920900_df2144a90f.jpg",
      ],
      name: "Physical Culture Gym",
      location: "London, Greater London, United Kingdom",
    },
    {
      "Record ID": "23080317058",
      company_name: "GYMNATION",
      "Create Date": "2024-09-17 17:58",
      Type: "Vendor",
      "Street Address": "26 Druid Street",
      "Street Address 2": "",
      city: "London",
      "State/Region": "Greater London",
      "Postal Code": "SE1 2AW",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-0.12574, 51.50853],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Female Only", "Cardio", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "gymnation_london",
      images: [
        "https://live.staticflickr.com/65535/53810622943_9a72981752.jpg",
      ],
      name: "GYMNATION",
      location: "London, Greater London, United Kingdom",
    },
    {
      "Record ID": "23080317098",
      company_name: "URBANFITNESS London",
      "Create Date": "2024-09-17 17:59",
      Type: "Vendor",
      "Street Address": "75 Hooper Street",
      "Street Address 2": "",
      city: "London",
      "State/Region": "Greater London",
      "Postal Code": "E1 8BP",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-0.12574, 51.50853],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Courts", "Pool", "Weights"],
      rating: 5,
      reviews: 0,
      id: "urbanfitness_london_london",
      images: [
        "https://live.staticflickr.com/65535/53795934461_407ca6b5fb.jpg",
      ],
      name: "URBANFITNESS London",
      location: "London, Greater London, United Kingdom",
    },
    {
      "Record ID": "23080317107",
      company_name: "Roar Fitness",
      "Create Date": "2024-09-17 18:00",
      Type: "Vendor",
      "Street Address": "24-26 Bevis Marks",
      "Street Address 2": "",
      city: "London",
      "State/Region": "Greater London",
      "Postal Code": "EC3A 7JB",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-0.12574, 51.50853],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Accessibility", "LGBTQIA+", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "roar_fitness_london",
      images: [
        "https://live.staticflickr.com/65535/53793118593_096be2e0ef.jpg",
      ],
      name: "Roar Fitness",
      location: "London, Greater London, United Kingdom",
    },
    {
      "Record ID": "23080317209",
      company_name: "The Circle Gym and Spa",
      "Create Date": "2024-09-17 18:01",
      Type: "Vendor",
      "Street Address": "",
      "Street Address 2": "",
      city: "London",
      "State/Region": "Greater London",
      "Postal Code": "SE1 2JE",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-0.099076, 51.509648],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Cardio", "Weights", "Special"],
      rating: 5,
      reviews: 0,
      id: "the_circle_gym_and_spa_london",
      images: [
        "https://live.staticflickr.com/65535/53728382946_cc11cc25a8.jpg",
      ],
      name: "The Circle Gym and Spa",
      location: "London, Greater London, United Kingdom",
    },
    {
      "Record ID": "23080317254",
      company_name: "BodyWorksWest",
      "Create Date": "2024-09-17 18:02",
      Type: "Vendor",
      "Street Address": "11 Lambton Place",
      "Street Address 2": "",
      city: "London",
      "State/Region": "Greater London",
      "Postal Code": "W11 2SH",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-0.199856, 51.514126],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Cardio", "Weights", "Special"],
      rating: 5,
      reviews: 0,
      id: "bodyworkswest_london",
      images: [
        "https://live.staticflickr.com/65535/53675423031_127cafe1c5.jpg",
      ],
      name: "BodyWorksWest",
      location: "London, Greater London, United Kingdom",
    },
    {
      "Record ID": "23080317246",
      company_name: "1Rebel UK",
      "Create Date": "2024-09-17 18:02",
      Type: "Vendor",
      "Street Address": "63 Saint Mary Axe",
      "Street Address 2": "",
      city: "London",
      "State/Region": "Greater London",
      "Postal Code": "EC3A 8AA",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-0.080048, 51.515618],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Weights", "Pool", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "1rebel_uk_london",
      images: [
        "https://live.staticflickr.com/65535/53675423001_3a84e9722c.jpg",
      ],
      name: "1Rebel UK",
      location: "London, Greater London, United Kingdom",
    },
    {
      "Record ID": "23080317301",
      company_name: "EVOKE",
      "Create Date": "2024-09-17 18:04",
      Type: "Vendor",
      "Street Address": "307A Seven Sisters Road",
      "Street Address 2": "",
      city: "London",
      "State/Region": "Greater London",
      "Postal Code": "N4 1QR",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-0.090733, 51.572772],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Pool", "Machines", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "evoke_london",
      images: [
        "https://live.staticflickr.com/65535/53670905125_67d52d6615.jpg",
      ],
      name: "EVOKE",
      location: "London, Greater London, United Kingdom",
    },
    {
      "Record ID": "23080317308",
      company_name: "Mindfit London",
      "Create Date": "2024-09-17 18:04",
      Type: "Vendor",
      "Street Address": "371 Brockley Road",
      "Street Address 2": "",
      city: "London",
      "State/Region": "Greater London",
      "Postal Code": "SE4 2BT",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "+44 7720 545362",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-0.0368027, 51.455376],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Female Only", "Weights", "Pool"],
      rating: 5,
      reviews: 0,
      id: "mindfit_london_london",
      images: [
        "https://live.staticflickr.com/65535/53669579142_4192e774c8.jpg",
      ],
      name: "Mindfit London",
      location: "London, Greater London, United Kingdom",
    },
    {
      "Record ID": "23080317340",
      company_name: "Fuse London",
      "Create Date": "2024-09-17 18:05",
      Type: "Vendor",
      "Street Address": "234-244 Stockwell Road",
      "Street Address 2": "",
      city: "London",
      "State/Region": "Greater London",
      "Postal Code": "SW9 9SP",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-0.115063, 51.464692],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "Cardio", "Powerlifting", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "fuse_london_london",
      images: [
        "https://live.staticflickr.com/65535/53669578722_f66a454cc4.jpg",
      ],
      name: "Fuse London",
      location: "London, Greater London, United Kingdom",
    },
    {
      "Record ID": "23080317330",
      company_name: "iGym London",
      "Create Date": "2024-09-17 18:05",
      Type: "Vendor",
      "Street Address": "1 Victoria Road",
      "Street Address 2": "",
      city: "London",
      "State/Region": "Greater London",
      "Postal Code": "W3 6FA",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [0.004492, 51.553965],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Female Only", "Machines", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "igym_london_london",
      images: [
        "https://live.staticflickr.com/65535/53668311559_26daa510a2.jpg",
      ],
      name: "iGym London",
      location: "London, Greater London, United Kingdom",
    },
    {
      "Record ID": "23080317321",
      company_name: "Evolution Fitness Studio London",
      "Create Date": "2024-09-17 18:05",
      Type: "Vendor",
      "Street Address": "40 Sheen Lane",
      "Street Address 2": "",
      city: "London",
      "State/Region": "Greater London",
      "Postal Code": "SW14 8LW",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-0.26706, 51.46667],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Female Only", "Courts", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "evolution_fitness_studio_london_london",
      images: [
        "https://live.staticflickr.com/65535/53667430726_a02866907c.jpg",
      ],
      name: "Evolution Fitness Studio London",
      location: "London, Greater London, United Kingdom",
    },
    {
      "Record ID": "23113324897",
      company_name: "Pumping Iron Gym",
      "Create Date": "2024-09-18 16:42",
      Type: "Vendor",
      "Street Address": "31 Oakleigh Road South",
      "Street Address 2": "",
      city: "London",
      "State/Region": "Greater London",
      "Postal Code": "N11 1LD",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Health, Wellness and Fitness",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-0.143376, 51.617294],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Weights", "Female Only", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "pumping_iron_gym_london",
      images: [
        "https://live.staticflickr.com/65535/53666562892_13ec814821.jpg",
      ],
      name: "Pumping Iron Gym",
      location: "London, Greater London, United Kingdom",
    },
    {
      "Record ID": "23109352516",
      company_name: "BXR London",
      "Create Date": "2024-09-18 16:43",
      Type: "Vendor",
      "Street Address": "24 Paddington Street",
      "Street Address 2": "",
      city: "London",
      "State/Region": "Greater London",
      "Postal Code": "W1U 5QX",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "2.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-0.1554093, 51.5205176],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Pool", "Weights", "Special"],
      rating: 5,
      reviews: 0,
      id: "bxr_london_london",
      images: [
        "https://live.staticflickr.com/65535/53667647628_ee72ed57b3.jpg",
      ],
      name: "BXR London",
      location: "London, Greater London, United Kingdom",
    },
    {
      "Record ID": "23113324938",
      company_name: "London Fields Fitness Studio",
      "Create Date": "2024-09-18 16:43",
      Type: "Vendor",
      "Street Address": "380 Mentmore Terrace",
      "Street Address 2": "379",
      city: "London",
      "State/Region": "Greater London",
      "Postal Code": "",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-0.057339, 51.540745],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Special", "LGBTQIA+", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "london_fields_fitness_studio_london",
      images: [
        "https://live.staticflickr.com/65535/53667430621_2185310713.jpg",
      ],
      name: "London Fields Fitness Studio",
      location: "London, Greater London, United Kingdom",
    },
    {
      "Record ID": "23109352604",
      company_name: "Yorkys Gym",
      "Create Date": "2024-09-18 16:45",
      Type: "Vendor",
      "Street Address": "88 Charlmont Road",
      "Street Address 2": "",
      city: "London",
      "State/Region": "Greater London",
      "Postal Code": "SW17 9AB",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-0.164748, 51.424438],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Powerlifting", "Weights", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "yorkys_gym_london",
      images: [
        "https://live.staticflickr.com/65535/53664406935_c47e5c1990.jpg",
      ],
      name: "Yorkys Gym",
      location: "London, Greater London, United Kingdom",
    },
    {
      "Record ID": "23109352581",
      company_name: "The Gym Factory",
      "Create Date": "2024-09-18 16:45",
      Type: "Vendor",
      "Street Address": "7 Sebert Road",
      "Street Address 2": "",
      city: "London",
      "State/Region": "Greater London",
      "Postal Code": "E7 0NG",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [0.025984, 51.550668],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Machines", "Special", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "the_gym_factory_london",
      images: [
        "https://live.staticflickr.com/65535/53663948186_4b33d7c769.jpg",
      ],
      name: "The Gym Factory",
      location: "London, Greater London, United Kingdom",
    },
    {
      "Record ID": "23113325200",
      company_name: "Blueprint Fitness",
      "Create Date": "2024-09-18 16:47",
      Type: "Vendor",
      "Street Address": "4 Raven Road",
      "Street Address 2": "Unit 11",
      city: "London",
      "State/Region": "Greater London",
      "Postal Code": "E18 1HB",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [0.036161, 51.594721],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Cardio", "Machines", "Courts"],
      rating: 5,
      reviews: 0,
      id: "blueprint_fitness_london",
      images: [
        "https://live.staticflickr.com/65535/53664406730_054767a7a3.jpg",
      ],
      name: "Blueprint Fitness",
      location: "London, Greater London, United Kingdom",
    },
    {
      "Record ID": "23168950056",
      company_name: "UN1T",
      "Create Date": "2024-09-21 18:26",
      Type: "",
      "Street Address": "140-148 Borough High Street",
      "Street Address 2": "",
      city: "London",
      "State/Region": "Greater London",
      "Postal Code": "SE1 1LB",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Opportunity",
      "Number of Associated Contacts": "2.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-0.092582, 51.502347],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Pool", "Female Only", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "un1t_london",
      images: [
        "https://live.staticflickr.com/65535/53663948006_8f3590f813.jpg",
      ],
      name: "UN1T",
      location: "London, Greater London, United Kingdom",
    },
    {
      "Record ID": "23169013030",
      company_name: "Third Space",
      "Create Date": "2024-09-21 21:32",
      Type: "",
      "Street Address": "2b More London Riverside",
      "Street Address 2": "",
      city: "London",
      "State/Region": "Greater London",
      "Postal Code": "SE1 2AP",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-0.079741, 51.505235],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Cardio", "Pool", "Machines"],
      rating: 5,
      reviews: 0,
      id: "third_space_london",
      images: [
        "https://live.staticflickr.com/65535/53664163273_668012bea7.jpg",
      ],
      name: "Third Space",
      location: "London, Greater London, United Kingdom",
    },
    {
      "Record ID": "23169013031",
      company_name: "Lanserhof at The Arts Club",
      "Create Date": "2024-09-21 21:33",
      Type: "",
      "Street Address": "18 Dover Street",
      "Street Address 2": "17",
      city: "London",
      "State/Region": "Greater London",
      "Postal Code": "W1S 4RR",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Hospital & Health Care",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-0.142231, 51.50865],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Accessibility", "Powerlifting", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "lanserhof_at_the_arts_club_london",
      images: [
        "https://live.staticflickr.com/65535/53663947916_c86af62acf.jpg",
      ],
      name: "Lanserhof at The Arts Club",
      location: "London, Greater London, United Kingdom",
    },
    {
      "Record ID": "23168950838",
      company_name: "KX Life",
      "Create Date": "2024-09-21 21:35",
      Type: "",
      "Street Address": "151 Draycott Avenue",
      "Street Address 2": "",
      city: "London",
      "State/Region": "Greater London",
      "Postal Code": "SW3 3AL",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-0.167087, 51.493158],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Machines", "Female Only", "Weights"],
      rating: 5,
      reviews: 0,
      id: "kx_life_london",
      images: [
        "https://live.staticflickr.com/65535/53663947826_f7b793f10d.jpg",
      ],
      name: "KX Life",
      location: "London, Greater London, United Kingdom",
    },
    {
      "Record ID": "23168950847",
      company_name: "The Body Lab",
      "Create Date": "2024-09-21 21:38",
      Type: "",
      "Street Address": "153 Kensington High Street",
      "Street Address 2": "",
      city: "London",
      "State/Region": "Greater London",
      "Postal Code": "W8 6SU",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "+44 20 7937 5853",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-0.194304, 51.50045],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Powerlifting", "Accessibility", "Special"],
      rating: 5,
      reviews: 0,
      id: "the_body_lab_london",
      images: [
        "https://live.staticflickr.com/65535/53664163143_e2aba081bd.jpg",
      ],
      name: "The Body Lab",
      location: "London, Greater London, United Kingdom",
    },
  ],
  Toronto: [
    {
      "Record ID": "18607967848",
      company_name: "UNITY Fitness - Harbourfront",
      "Create Date": "2024-01-04 00:10",
      Type: "Vendor",
      "Street Address": "",
      "Street Address 2": "",
      city: "Toronto",
      "State/Region": "ON",
      "Postal Code": "",
      "Country/Region": "Canada",
      "Time Zone": "America/Regina",
      "Phone Number": "",
      Industry: "Health, Wellness and Fitness",
      "Lifecycle Stage": "Other",
      "Number of Associated Contacts": "3.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-79.38171, 43.64877],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Special", "Pool", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "unity_fitness_harbourfront_toronto",
      images: ["https://live.staticflickr.com/0/54045689353_08e67e6d2a.jpg"],
      name: "UNITY Fitness - Harbourfront",
      location: "Toronto, ON, Canada",
    },
    {
      "Record ID": "18607967856",
      company_name: "SkyClub Inc.",
      "Create Date": "2024-01-04 00:11",
      Type: "Vendor",
      "Street Address": "",
      "Street Address 2": "",
      city: "Toronto",
      "State/Region": "ON",
      "Postal Code": "M5G 1V2",
      "Country/Region": "Canada",
      "Time Zone": "America/Regina",
      "Phone Number": "+1 416-979-9100",
      Industry: "Health, Wellness and Fitness",
      "Lifecycle Stage": "Other",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-79.386653, 43.657246],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Special", "Female Only", "Weights"],
      rating: 5,
      reviews: 0,
      id: "skyclub_inc__toronto",
      images: ["https://live.staticflickr.com/0/54045436956_6de462b647.jpg"],
      name: "SkyClub Inc.",
      location: "Toronto, ON, Canada",
    },
    {
      "Record ID": "23142978673",
      company_name: "All Day Fit",
      "Create Date": "2024-09-19 18:40",
      Type: "",
      "Street Address": "487 King Street East",
      "Street Address 2": "",
      city: "Toronto",
      "State/Region": "Ontario",
      "Postal Code": "M5A 1L9",
      "Country/Region": "Canada",
      "Time Zone": "America/Toronto",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-79.360094, 43.654295],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Cardio", "Female Only", "Courts"],
      rating: 5,
      reviews: 0,
      id: "all_day_fit_toronto",
      images: ["https://live.staticflickr.com/0/54045749949_56d08fc337.jpg"],
      name: "All Day Fit",
      location: "Toronto, Ontario, Canada",
    },
    {
      "Record ID": "23142958119",
      company_name: "Fit Factory Fitness",
      "Create Date": "2024-09-19 18:41",
      Type: "",
      "Street Address": "373 King Street West",
      "Street Address 2": "",
      city: "Toronto",
      "State/Region": "Ontario",
      "Postal Code": "M5V 1K1",
      "Country/Region": "Canada",
      "Time Zone": "America/Toronto",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "2.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-79.392846, 43.645693],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Courts", "Accessibility", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "fit_factory_fitness_toronto",
      images: ["https://live.staticflickr.com/0/54045679328_467d83c95f.jpg"],
      name: "Fit Factory Fitness",
      location: "Toronto, Ontario, Canada",
    },
    {
      "Record ID": "23142958129",
      company_name: "Recess Fit Club",
      "Create Date": "2024-09-19 18:42",
      Type: "",
      "Street Address": "653 Bloor Street West",
      "Street Address 2": "",
      city: "Toronto",
      "State/Region": "Ontario",
      "Postal Code": "M6G 1L1",
      "Country/Region": "Canada",
      "Time Zone": "America/Toronto",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-79.41539, 43.664056],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "Courts", "Weights", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "recess_fit_club_toronto",
      images: ["https://live.staticflickr.com/0/54044556207_0a059e3b56.jpg"],
      name: "Recess Fit Club",
      location: "Toronto, Ontario, Canada",
    },
    {
      "Record ID": "23142958143",
      company_name: "Thrive Fit",
      "Create Date": "2024-09-19 18:43",
      Type: "",
      "Street Address": "2461 Queen Street East",
      "Street Address 2": "",
      city: "Toronto",
      "State/Region": "Ontario",
      "Postal Code": "M4E 1H8",
      "Country/Region": "Canada",
      "Time Zone": "America/Toronto",
      "Phone Number": "+1 416 792 0707",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-79.283298, 43.673406],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Special", "Weights", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "thrive_fit_toronto",
      images: ["https://live.staticflickr.com/0/54045682798_d85ee0f37b.jpg"],
      name: "Thrive Fit",
      location: "Toronto, Ontario, Canada",
    },
    {
      "Record ID": "23142958164",
      company_name: "Lyft Fitness",
      "Create Date": "2024-09-19 18:44",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Toronto",
      "State/Region": "",
      "Postal Code": "",
      "Country/Region": "Canada",
      "Time Zone": "America/Toronto",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-79.38171, 43.64877],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Special", "Pool", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "lyft_fitness_toronto",
      images: ["https://live.staticflickr.com/0/54045747589_98fa875181.jpg"],
      name: "Lyft Fitness",
      location: "Toronto, , Canada",
    },
    {
      "Record ID": "23142978866",
      company_name: "Body + Soul Fitness",
      "Create Date": "2024-09-19 18:46",
      Type: "",
      "Street Address": "378 Eglinton Avenue West",
      "Street Address 2": "",
      city: "Toronto",
      "State/Region": "Ontario",
      "Postal Code": "M5N 1A2",
      "Country/Region": "Canada",
      "Time Zone": "America/Toronto",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "0.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-79.40965, 43.704574],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Powerlifting", "LGBTQIA+", "Courts"],
      rating: 5,
      reviews: 0,
      id: "body_soul_fitness_toronto",
      images: ["https://live.staticflickr.com/0/54045679358_88040e442c.jpg"],
      name: "Body + Soul Fitness",
      location: "Toronto, Ontario, Canada",
    },
    {
      "Record ID": "23142958343",
      company_name: "One Academy",
      "Create Date": "2024-09-19 18:49",
      Type: "",
      "Street Address": "858 Eastern Avenue",
      "Street Address 2": "",
      city: "Toronto",
      "State/Region": "Ontario",
      "Postal Code": "M4L 1A1",
      "Country/Region": "Canada",
      "Time Zone": "America/Toronto",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "2.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-79.327084, 43.662284],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Special", "Weights", "Pool"],
      rating: 5,
      reviews: 0,
      id: "one_academy_toronto",
      images: ["https://live.staticflickr.com/0/54045683303_b652301366.jpg"],
      name: "One Academy",
      location: "Toronto, Ontario, Canada",
    },
    {
      "Record ID": "23142958365",
      company_name: "Hart House",
      "Create Date": "2024-09-19 18:50",
      Type: "",
      "Street Address": "7 Hart House Circle",
      "Street Address 2": "",
      city: "Toronto",
      "State/Region": "Ontario",
      "Postal Code": "M5S 3H3",
      "Country/Region": "Canada",
      "Time Zone": "America/Toronto",
      "Phone Number": "",
      Industry: "Higher Education",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-79.395133, 43.664035],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Cardio", "Special", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "hart_house_toronto",
      images: ["https://live.staticflickr.com/0/54045432501_8741ed4da9.jpg"],
      name: "Hart House",
      location: "Toronto, Ontario, Canada",
    },
    {
      "Record ID": "23142978946",
      company_name: "Fortis Fitness",
      "Create Date": "2024-09-19 18:51",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Toronto",
      "State/Region": "",
      "Postal Code": "M4M 2R6",
      "Country/Region": "Canada",
      "Time Zone": "America/Toronto",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "2.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-79.338932, 43.657016],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Accessibility", "Weights", "Pool"],
      rating: 5,
      reviews: 0,
      id: "fortis_fitness_toronto",
      images: ["https://live.staticflickr.com/0/54045753799_79747573f8.jpg"],
      name: "Fortis Fitness",
      location: "Toronto, , Canada",
    },
    {
      "Record ID": "23142978967",
      company_name: "Vive Fitness 24/7 Lakeshore",
      "Create Date": "2024-09-19 18:52",
      Type: "",
      "Street Address": "807 Bathurst Street",
      "Street Address 2": "",
      city: "Toronto",
      "State/Region": "Ontario",
      "Postal Code": "M5R 3G2",
      "Country/Region": "Canada",
      "Time Zone": "America/Toronto",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "5.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-79.411044, 43.665643],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Cardio", "Courts", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "vive_fitness_24_7_lakeshore_toronto",
      images: ["https://live.staticflickr.com/0/54045683833_f3aa5240f9.jpg"],
      name: "Vive Fitness 24/7 Lakeshore",
      location: "Toronto, Ontario, Canada",
    },
    {
      "Record ID": "23142958466",
      company_name: "Back Alley Barbell",
      "Create Date": "2024-09-19 18:54",
      Type: "",
      "Street Address": "1175 Bathurst Street",
      "Street Address 2": "",
      city: "Toronto",
      "State/Region": "Ontario",
      "Postal Code": "M5R 3H3",
      "Country/Region": "Canada",
      "Time Zone": "America/Toronto",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-79.414619, 43.674524],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Accessibility", "LGBTQIA+", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "back_alley_barbell_toronto",
      images: ["https://live.staticflickr.com/0/54045435841_38c270ab1e.jpg"],
      name: "Back Alley Barbell",
      location: "Toronto, Ontario, Canada",
    },
    {
      "Record ID": "23142979140",
      company_name: "Toronto Athletic Club",
      "Create Date": "2024-09-19 18:58",
      Type: "",
      "Street Address": "79 Wellington Street West",
      "Street Address 2": "",
      city: "Toronto",
      "State/Region": "Ontario",
      "Postal Code": "M5K 1B1",
      "Country/Region": "Canada",
      "Time Zone": "America/Toronto",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "2.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-79.381423, 43.646669],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "LGBTQIA+", "Pool", "Machines"],
      rating: 5,
      reviews: 0,
      id: "toronto_athletic_club_toronto",
      images: ["https://live.staticflickr.com/0/54044566217_33ae26f0f7.jpg"],
      name: "Toronto Athletic Club",
      location: "Toronto, Ontario, Canada",
    },
    {
      "Record ID": "23142958639",
      company_name: "Fitness In Place",
      "Create Date": "2024-09-19 18:59",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Toronto",
      "State/Region": "Ontario",
      "Postal Code": "",
      "Country/Region": "Canada",
      "Time Zone": "America/Toronto",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-79.38171, 43.64877],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Special", "Courts", "Machines"],
      rating: 5,
      reviews: 0,
      id: "fitness_in_place_toronto",
      images: [
        "https://live.staticflickr.com/65535/54045883490_5af0ea3945.jpg",
      ],
      name: "Fitness In Place",
      location: "Toronto, Ontario, Canada",
    },
    {
      "Record ID": "23142979165",
      company_name: "Jimmy's Athletics",
      "Create Date": "2024-09-19 19:00",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Toronto",
      "State/Region": "",
      "Postal Code": "",
      "Country/Region": "",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-79.38171, 43.64877],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Weights", "Special", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "jimmy_s_athletics_toronto",
      images: [
        "https://live.staticflickr.com/65535/54045883455_844d3d99ac.jpg",
      ],
      name: "Jimmy's Athletics",
      location: "Toronto, , ",
    },
    {
      "Record ID": "23142958661",
      company_name: "Hone Fitness",
      "Create Date": "2024-09-19 19:01",
      Type: "",
      "Street Address": "7 Isabella Street",
      "Street Address 2": "",
      city: "Toronto",
      "State/Region": "Ontario",
      "Postal Code": "M4Y 1M7",
      "Country/Region": "Canada",
      "Time Zone": "America/Toronto",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "2.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-79.3852, 43.667537],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Weights", "LGBTQIA+", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "hone_fitness_toronto",
      images: [
        "https://live.staticflickr.com/65535/54045688748_61616f9264.jpg",
      ],
      name: "Hone Fitness",
      location: "Toronto, Ontario, Canada",
    },
    {
      "Record ID": "23142958734",
      company_name: "West End Athletic Club",
      active: true,
      "Create Date": "2024-09-19 19:05",
      Type: "",
      "Street Address": "283 Bering Avenue",
      "Street Address 2": "",
      city: "Toronto",
      "State/Region": "Ontario",
      "Postal Code": "M8Z 3A5",
      "Country/Region": "Canada",
      "Time Zone": "America/Toronto",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-79.526045, 43.63797],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Accessibility", "Special", "Pool"],
      rating: 5,
      reviews: 0,
      id: "west_end_athletic_club_toronto",
      images: [
        "https://live.staticflickr.com/65535/54044567427_c9c935a602.jpg",
      ],
      name: "West End Athletic Club",
      location: "Toronto, Ontario, Canada",
    },
    {
      "Record ID": "23142958756",
      company_name: "Limber",
      active: true,
      "Create Date": "2024-09-19 19:07",
      Type: "",
      "Street Address": "334 Adelaide Street West",
      "Street Address 2": "210",
      city: "Toronto",
      "State/Region": "Ontario",
      "Postal Code": "",
      "Country/Region": "Canada",
      "Time Zone": "America/Toronto",
      "Phone Number": "+1 647 948 8721",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-79.392555, 43.64753],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Special", "Powerlifting", "Weights"],
      rating: 5,
      reviews: 0,
      id: "limber_toronto",
      images: [
        "https://live.staticflickr.com/65535/54045761854_cc6d663ebf.jpg",
      ],
      name: "Limber",
      location: "Toronto, Ontario, Canada",
    },
  ],
  Indianapolis: [
    {
      "Record ID": "18792136194",
      company_name: "Indianapolis Healthplex",
      active: true,
      "Create Date": "2024-01-20 21:28",
      Type: "Vendor",
      "Street Address": "3660 Guion Road",
      "Street Address 2": "",
      city: "Indianapolis",
      "State/Region": "IN",
      "Postal Code": "46222",
      "Country/Region": "United States",
      "Time Zone": "America/Indiana/Indianapolis",
      "Phone Number": "",
      Industry: "Health, Wellness and Fitness",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "3.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-86.2248930499999, 39.8216708200001],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Courts", "Special", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "indianapolis_healthplex_indianapolis",
      images: [
        "https://live.staticflickr.com/65535/53749990787_df1b17c7aa.jpg",
      ],
      name: "Indianapolis Healthplex",
      location: "Indianapolis, IN, United States",
    },
    {
      "Record ID": "23169012362",
      company_name: "Indy City Barbell",
      "Create Date": "2024-09-21 18:58",
      Type: "",
      "Street Address": "609 North Delaware Street",
      "Street Address 2": "",
      city: "Indianapolis",
      "State/Region": "IN",
      "Postal Code": "46204",
      "Country/Region": "United States",
      "Time Zone": "America/Indiana/Indianapolis",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-86.153866314301, 39.7758090814365],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "Pool", "Accessibility", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "indy_city_barbell_indianapolis",
      images: [
        "https://live.staticflickr.com/65535/52757973313_7687d2fc72.jpg",
      ],
      name: "Indy City Barbell",
      location: "Indianapolis, IN, United States",
    },
    {
      "Record ID": "23169012368",
      company_name: "Iron Valley Barbell",
      "Create Date": "2024-09-21 19:00",
      Type: "",
      "Street Address": "5339 West 86th Street",
      "Street Address 2": "",
      city: "Indianapolis",
      "State/Region": "IN",
      "Postal Code": "46268",
      "Country/Region": "United States",
      "Time Zone": "America/Indiana/Indianapolis",
      "Phone Number": "+1 317-756-9084",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-86.2527572459434, 39.9094265],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Machines", "Weights", "Pool"],
      rating: 5,
      reviews: 0,
      id: "iron_valley_barbell_indianapolis",
      images: [
        "https://live.staticflickr.com/65535/52680788952_7695c49b13.jpg",
      ],
      name: "Iron Valley Barbell",
      location: "Indianapolis, IN, United States",
    },
    {
      "Record ID": "23169012373",
      company_name: "Legacy Barbell Club",
      "Create Date": "2024-09-21 19:02",
      Type: "",
      "Street Address": "7216 North Keystone Avenue",
      "Street Address 2": "b",
      city: "Indianapolis",
      "State/Region": "IN",
      "Postal Code": "46240",
      "Country/Region": "United States",
      "Time Zone": "America/Indiana/Indianapolis",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-86.122685250641, 39.8849315],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "LGBTQIA+", "Pool", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "legacy_barbell_club_indianapolis",
      images: [
        "https://live.staticflickr.com/65535/52655162911_37ec6f5bf8.jpg",
      ],
      name: "Legacy Barbell Club",
      location: "Indianapolis, IN, United States",
    },
    {
      "Record ID": "23168950295",
      company_name: "American Muscle Factory",
      "Create Date": "2024-09-21 19:04",
      Type: "",
      "Street Address": "7747 Shelby Street",
      "Street Address 2": "",
      city: "Indianapolis",
      "State/Region": "IN",
      "Postal Code": "46227",
      "Country/Region": "United States",
      "Time Zone": "America/Indiana/Indianapolis",
      "Phone Number": "+1 317-361-4563",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-86.1361923217773, 39.6540603637695],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Special", "Accessibility", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "american_muscle_factory_indianapolis",
      images: [
        "https://live.staticflickr.com/65535/52655275199_d3d9415357.jpg",
      ],
      name: "American Muscle Factory",
      location: "Indianapolis, IN, United States",
    },
  ],
  Nashville: [
    {
      "Record ID": "18792136205",
      company_name: "GYM 5",
      "Create Date": "2024-01-20 21:30",
      Type: "Vendor",
      "Street Address": "952 Main Street",
      "Street Address 2": "",
      city: "Nashville",
      "State/Region": "TN",
      "Postal Code": "37206",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "+1 615-226-5559",
      Industry: "Health, Wellness and Fitness",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "2.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-86.7531227358101, 36.177101],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Special", "Machines", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "gym_5_nashville",
      images: [
        "https://live.staticflickr.com/65535/53980193723_c85ee16f50.jpg",
      ],
      name: "GYM 5",
      location: "Nashville, TN, United States",
    },
  ],
  Tadley: [
    {
      "Record ID": "23080311090",
      company_name: "Aspects of Fitness",
      "Create Date": "2024-09-17 16:08",
      Type: "Vendor",
      "Street Address": "",
      "Street Address 2": "",
      city: "Tadley",
      "State/Region": "Hampshire",
      "Postal Code": "RG26 3JG",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "",
      coordinates: [-1.143388, 51.355866],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Pool", "Cardio", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "aspects_of_fitness_tadley",
      images: [
        "https://live.staticflickr.com/65535/52809856218_d578c0af0a.jpg",
      ],
      name: "Aspects of Fitness",
      location: "Tadley, Hampshire, United Kingdom",
    },
  ],
  Bishops_Waltham: [
    {
      "Record ID": "23090039199",
      company_name: "Ludus Fitness",
      "Create Date": "2024-09-17 16:20",
      Type: "Vendor",
      "Street Address": "2 Claylands Road",
      "Street Address 2": "",
      city: "Bishop's Waltham",
      "State/Region": "Hampshire",
      "Postal Code": "SO32 1BH",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "",
      coordinates: [-1.221438, 50.957283],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Accessibility", "Pool", "Machines"],
      rating: 5,
      reviews: 0,
      id: "ludus_fitness_bishop_s_waltham",
      images: [null],
      name: "Ludus Fitness",
      location: "Bishop's Waltham, Hampshire, United Kingdom",
    },
  ],
  Windsor: [
    {
      "Record ID": "23090039159",
      company_name: "Five Star Gym",
      "Create Date": "2024-09-17 16:20",
      Type: "Vendor",
      "Street Address": "54 Vale Road",
      "Street Address 2": "",
      city: "Windsor",
      "State/Region": "Berkshire",
      "Postal Code": "SL4 5LA",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "",
      coordinates: [-0.634565, 51.482001],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Accessibility", "LGBTQIA+", "Courts"],
      rating: 5,
      reviews: 0,
      id: "five_star_gym_windsor",
      images: [
        "https://live.staticflickr.com/65535/53487527102_21f3f5371b.jpg",
      ],
      name: "Five Star Gym",
      location: "Windsor, Berkshire, United Kingdom",
    },
    {
      "Record ID": "23168950324",
      company_name: "Hidden Strength",
      "Create Date": "2024-09-21 19:14",
      Type: "",
      "Street Address": "13 Vansittart Estate",
      "Street Address 2": "",
      city: "Windsor",
      "State/Region": "Windsor and Maidenhead",
      "Postal Code": "SL4 1SE",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Individual & Family Services",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-0.66667, 51.46667],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "LGBTQIA+", "Special", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "hidden_strength_windsor",
      images: [
        "https://live.staticflickr.com/65535/53142958999_8c2158aff1.jpg",
      ],
      name: "Hidden Strength",
      location: "Windsor, Windsor and Maidenhead, United Kingdom",
    },
  ],
  Park_Gate: [
    {
      "Record ID": "23090039330",
      company_name: "Atlas Gym Southampton",
      "Create Date": "2024-09-17 16:21",
      Type: "Vendor",
      "Street Address": "89 Southampton Road",
      "Street Address 2": "",
      city: "Park Gate",
      "State/Region": "Hampshire",
      "Postal Code": "SO31 6AF",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-1.40428, 50.90395],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Machines", "Powerlifting", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "atlas_gym_southampton_park_gate",
      images: [
        "https://live.staticflickr.com/65535/52505825610_48d46d79d2.jpg",
      ],
      name: "Atlas Gym Southampton",
      location: "Park Gate, Hampshire, United Kingdom",
    },
  ],
  Hook: [
    {
      "Record ID": "23080312073",
      company_name: "ArenaFit",
      "Create Date": "2024-09-17 16:22",
      Type: "Vendor",
      "Street Address": "",
      "Street Address 2": "",
      city: "Hook",
      "State/Region": "",
      "Postal Code": "RG29 1HA",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-0.3065, 51.36803],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Pool", "LGBTQIA+", "Weights"],
      rating: 5,
      reviews: 0,
      id: "arenafit_hook",
      images: [
        "https://live.staticflickr.com/65535/54005518880_17fb7cb8b6.jpg",
      ],
      name: "ArenaFit",
      location: "Hook, , United Kingdom",
    },
  ],
  Ewell: [
    {
      "Record ID": "23080313232",
      company_name: "VFit Ewell",
      "Create Date": "2024-09-17 16:39",
      Type: "Vendor",
      "Street Address": "57-61 High Street",
      "Street Address 2": "",
      city: "Ewell",
      "State/Region": "Surrey",
      "Postal Code": "KT17 1RX",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "",
      coordinates: [-4.86051, 50.34456],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Cardio", "Pool", "Weights"],
      rating: 5,
      reviews: 0,
      id: "vfit_ewell_ewell",
      images: ["https://live.staticflickr.com/1963/30582409837_e2480806c2.jpg"],
      name: "VFit Ewell",
      location: "Ewell, Surrey, United Kingdom",
    },
  ],
  Shaftesbury: [
    {
      "Record ID": "23080313208",
      company_name: "Hawkers Hill Gym",
      "Create Date": "2024-09-17 16:39",
      Type: "Vendor",
      "Street Address": "",
      "Street Address 2": "",
      city: "Shaftesbury",
      "State/Region": "Dorset",
      "Postal Code": "SP7 8DQ",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Health, Wellness and Fitness",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "",
      coordinates: [-2.193445, 51.007891],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Powerlifting", "Accessibility", "Pool"],
      rating: 5,
      reviews: 0,
      id: "hawkers_hill_gym_shaftesbury",
      images: [
        "https://live.staticflickr.com/65535/52618393224_f0b1a13f33.jpg",
      ],
      name: "Hawkers Hill Gym",
      location: "Shaftesbury, Dorset, United Kingdom",
    },
  ],
  Gosport: [
    {
      "Record ID": "23080313352",
      company_name: "Train N Gain",
      "Create Date": "2024-09-17 16:40",
      Type: "Vendor",
      "Street Address": "8 Alphage Road",
      "Street Address 2": "",
      city: "Gosport",
      "State/Region": "Hampshire",
      "Postal Code": "PO12 4DU",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Recreational Facilities and Services",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "",
      coordinates: [-1.12902, 50.79509],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Powerlifting", "Accessibility", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "train_n_gain_gosport",
      images: ["https://live.staticflickr.com/4463/24072394898_4e16a723ba.jpg"],
      name: "Train N Gain",
      location: "Gosport, Hampshire, United Kingdom",
    },
  ],
  Hornchurch: [
    {
      "Record ID": "23090040499",
      company_name: "Girls Allowed Gyms",
      "Create Date": "2024-09-17 16:40",
      Type: "Vendor",
      "Street Address": "",
      "Street Address 2": "",
      city: "Hornchurch",
      "State/Region": "Greater London",
      "Postal Code": "RM12 5RX",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "",
      coordinates: [-0.16667, 51.5],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Female Only", "Machines", "Courts"],
      rating: 5,
      reviews: 0,
      id: "girls_allowed_gyms_hornchurch",
      images: ["https://live.staticflickr.com/4022/4626791201_9b9fb73dca.jpg"],
      name: "Girls Allowed Gyms",
      location: "Hornchurch, Greater London, United Kingdom",
    },
  ],
  Eastleigh: [
    {
      "Record ID": "23080313367",
      company_name: "Titan Gym",
      "Create Date": "2024-09-17 16:41",
      Type: "Vendor",
      "Street Address": "",
      "Street Address 2": "",
      city: "Eastleigh",
      "State/Region": "Hampshire",
      "Postal Code": "SO50 4ET",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "",
      coordinates: [-1.36919, 50.976314],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Female Only", "Accessibility", "Courts"],
      rating: 5,
      reviews: 0,
      id: "titan_gym_eastleigh",
      images: [
        "https://live.staticflickr.com/65535/50634006347_25324f43be.jpg",
      ],
      name: "Titan Gym",
      location: "Eastleigh, Hampshire, United Kingdom",
    },
  ],
  Winchester: [
    {
      "Record ID": "23080313434",
      company_name: "Elevate Fitness Studios",
      "Create Date": "2024-09-17 16:42",
      Type: "Vendor",
      "Street Address": "1898 Mentor Avenue",
      "Street Address 2": "",
      city: "Winchester",
      "State/Region": "OH",
      "Postal Code": "44077",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-81.2861712475346, 41.6923905],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Female Only", "Courts", "Weights"],
      rating: 5,
      reviews: 0,
      id: "elevate_fitness_studios_winchester",
      images: [
        "https://live.staticflickr.com/65535/53362168121_affa49f731.jpg",
      ],
      name: "Elevate Fitness Studios",
      location: "Winchester, OH, United States",
    },
  ],
  Bristol: [
    {
      "Record ID": "23080313486",
      company_name: "Ministry of Fitness",
      "Create Date": "2024-09-17 16:43",
      Type: "Vendor",
      "Street Address": "15 Douglas Road",
      "Street Address 2": "1",
      city: "Bristol",
      "State/Region": "South Gloucestershire",
      "Postal Code": "BS15 8NH",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-2.508923, 51.458376],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Cardio", "Accessibility", "Weights"],
      rating: 5,
      reviews: 0,
      id: "ministry_of_fitness_bristol",
      images: [
        "https://live.staticflickr.com/65535/53800597832_87846be9f9.jpg",
      ],
      name: "Ministry of Fitness",
      location: "Bristol, South Gloucestershire, United Kingdom",
    },
    {
      "Record ID": "23080313611",
      company_name: "Trojan Fitness",
      "Create Date": "2024-09-17 16:44",
      Type: "Vendor",
      "Street Address": "204 South Liberty Lane",
      "Street Address 2": "",
      city: "Bristol",
      "State/Region": "City of Bristol",
      "Postal Code": "BS3 2TY",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "+44 117 966 1683",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-2.629523, 51.428642],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Accessibility", "Machines", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "trojan_fitness_bristol",
      images: [
        "https://live.staticflickr.com/65535/53794459369_3351c9623f.jpg",
      ],
      name: "Trojan Fitness",
      location: "Bristol, City of Bristol, United Kingdom",
    },
    {
      "Record ID": "23090040835",
      company_name: "Bristol Co-operative Gym",
      "Create Date": "2024-09-17 16:44",
      Type: "Vendor",
      "Street Address": "13 All Hallows Road",
      "Street Address 2": "",
      city: "Bristol",
      "State/Region": "Bristol City",
      "Postal Code": "BS5 0HH",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-2.56501, 51.462306],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Accessibility", "Machines", "Courts"],
      rating: 5,
      reviews: 0,
      id: "bristol_co_operative_gym_bristol",
      images: [
        "https://live.staticflickr.com/65535/53773428951_4e4597e8a9.jpg",
      ],
      name: "Bristol Co-operative Gym",
      location: "Bristol, Bristol City, United Kingdom",
    },
    {
      "Record ID": "23090040890",
      company_name: "THE FITNESS YARD",
      "Create Date": "2024-09-17 16:45",
      Type: "Vendor",
      "Street Address": "67 Henleaze Road",
      "Street Address 2": "",
      city: "Bristol",
      "State/Region": "Bristol City",
      "Postal Code": "BS9 4JT",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-2.612136, 51.485323],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "LGBTQIA+", "Powerlifting", "Pool"],
      rating: 5,
      reviews: 0,
      id: "the_fitness_yard_bristol",
      images: [
        "https://live.staticflickr.com/65535/53773629928_00240581e0.jpg",
      ],
      name: "THE FITNESS YARD",
      location: "Bristol, Bristol City, United Kingdom",
    },
    {
      "Record ID": "23090040967",
      company_name: "Workout Bristol",
      "Create Date": "2024-09-17 16:47",
      Type: "Vendor",
      "Street Address": "",
      "Street Address 2": "",
      city: "Bristol",
      "State/Region": "City of Bristol",
      "Postal Code": "BS3 1JS",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "+44 117 963 3070",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "2.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-2.616256, 51.442641],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "LGBTQIA+", "Special", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "workout_bristol_bristol",
      images: [
        "https://live.staticflickr.com/65535/53478632009_23d49c6cae.jpg",
      ],
      name: "Workout Bristol",
      location: "Bristol, City of Bristol, United Kingdom",
    },
    {
      "Record ID": "23090041215",
      company_name: "Gloucester Road Fitness",
      "Create Date": "2024-09-17 16:51",
      Type: "Vendor",
      "Street Address": "306 Gloucester Road",
      "Street Address 2": "302",
      city: "Bristol",
      "State/Region": "Bristol City",
      "Postal Code": "BS7 8PD",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Health, Wellness and Fitness",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-2.588052, 51.48177],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Machines", "Cardio", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "gloucester_road_fitness_bristol",
      images: [
        "https://live.staticflickr.com/65535/53445750679_c931c3366c.jpg",
      ],
      name: "Gloucester Road Fitness",
      location: "Bristol, Bristol City, United Kingdom",
    },
    {
      "Record ID": "23776151558",
      company_name: "Severnside Fitness Hub",
      "Create Date": "2024-10-11 10:12",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Bristol",
      "State/Region": "Bristol City",
      "Postal Code": "BS11 8DP",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Health, Wellness and Fitness",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-2.597087, 51.474771],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Pool", "Machines", "Special"],
      rating: 5,
      reviews: 0,
      id: "severnside_fitness_hub_bristol",
      images: [
        "https://live.staticflickr.com/65535/53438204878_b4739d7f88.jpg",
      ],
      name: "Severnside Fitness Hub",
      location: "Bristol, Bristol City, United Kingdom",
    },
  ],
  Pontprennau: [
    {
      "Record ID": "23090040751",
      company_name: "GymVision",
      "Create Date": "2024-09-17 16:44",
      Type: "Vendor",
      "Street Address": "",
      "Street Address 2": "",
      city: "Pontprennau",
      "State/Region": "Cardiff",
      "Postal Code": "CF23 8HE",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-3.146309, 51.535192],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Pool", "LGBTQIA+", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "gymvision_pontprennau",
      images: [null],
      name: "GymVision",
      location: "Pontprennau, Cardiff, United Kingdom",
    },
  ],
  Ottery_Saint_Mary: [
    {
      "Record ID": "23090040869",
      company_name: "Move Forward Gym",
      "Create Date": "2024-09-17 16:45",
      Type: "Vendor",
      "Street Address": "",
      "Street Address 2": "",
      city: "Ottery Saint Mary",
      "State/Region": "Devon",
      "Postal Code": "EX11 1NR",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-3.287742, 50.750011],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Powerlifting", "Female Only", "Special"],
      rating: 5,
      reviews: 0,
      id: "move_forward_gym_ottery_saint_mary",
      images: [null],
      name: "Move Forward Gym",
      location: "Ottery Saint Mary, Devon, United Kingdom",
    },
  ],
  Cardiff: [
    {
      "Record ID": "23080313672",
      company_name: "Aspire Fitness",
      "Create Date": "2024-09-17 16:46",
      Type: "Vendor",
      "Street Address": "",
      "Street Address 2": "",
      city: "Cardiff",
      "State/Region": "Cardiff",
      "Postal Code": "CF11 8DG",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-3.2179, 51.4803],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Female Only", "Powerlifting", "Weights"],
      rating: 5,
      reviews: 0,
      id: "aspire_fitness_cardiff",
      images: [
        "https://live.staticflickr.com/65535/53811600749_cefe686658.jpg",
      ],
      name: "Aspire Fitness",
      location: "Cardiff, Cardiff, United Kingdom",
    },
  ],
  Pontyclun: [
    {
      "Record ID": "23080313944",
      company_name: "Lyons Den Gym",
      "Create Date": "2024-09-17 16:49",
      Type: "Vendor",
      "Street Address": "",
      "Street Address 2": "",
      city: "Pontyclun",
      "State/Region": "Rhondda Cynon Taff",
      "Postal Code": "CF72 9HG",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-3.44885, 51.65896],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Courts", "Accessibility", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "lyons_den_gym_pontyclun",
      images: ["https://live.staticflickr.com/5465/9269414106_73237f2df8.jpg"],
      name: "Lyons Den Gym",
      location: "Pontyclun, Rhondda Cynon Taff, United Kingdom",
    },
  ],
  Exeter: [
    {
      "Record ID": "23090041181",
      company_name: "U7 GYM",
      "Create Date": "2024-09-17 16:50",
      Type: "Vendor",
      "Street Address": "7 Kennford Road",
      "Street Address 2": "",
      city: "Exeter",
      "State/Region": "Devon",
      "Postal Code": "EX2 8LY",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-3.52751, 50.7236],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Female Only", "Powerlifting", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "u7_gym_exeter",
      images: [
        "https://live.staticflickr.com/65535/53919637582_14ae2216da.jpg",
      ],
      name: "U7 GYM",
      location: "Exeter, Devon, United Kingdom",
    },
  ],
  East_Budleigh: [
    {
      "Record ID": "23080314129",
      company_name: "Total Fitness UK",
      "Create Date": "2024-09-17 16:52",
      Type: "Vendor",
      "Street Address": "",
      "Street Address 2": "",
      city: "East Budleigh",
      "State/Region": "Devon",
      "Postal Code": "EX9 7BL",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-3.31952, 50.65259],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "Female Only", "Special", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "total_fitness_uk_east_budleigh",
      images: ["https://live.staticflickr.com/4559/38496625241_2cee8ab6bb.jpg"],
      name: "Total Fitness UK",
      location: "East Budleigh, Devon, United Kingdom",
    },
  ],
  Barnstaple: [
    {
      "Record ID": "23080314291",
      company_name: "Platform Gym",
      "Create Date": "2024-09-17 16:55",
      Type: "Vendor",
      "Street Address": "",
      "Street Address 2": "",
      city: "Barnstaple",
      "State/Region": "Devon",
      "Postal Code": "EX31 1QN",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-4.081707, 51.084547],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Pool", "LGBTQIA+", "Special"],
      rating: 5,
      reviews: 0,
      id: "platform_gym_barnstaple",
      images: [
        "https://live.staticflickr.com/65535/52711516853_bee076fa14.jpg",
      ],
      name: "Platform Gym",
      location: "Barnstaple, Devon, United Kingdom",
    },
  ],
  Liskeard: [
    {
      "Record ID": "23090041518",
      company_name: "D & G Performance Gym",
      "Create Date": "2024-09-17 16:56",
      Type: "Vendor",
      "Street Address": "2b Heathlands Road",
      "Street Address 2": "",
      city: "Liskeard",
      "State/Region": "Cornwall",
      "Postal Code": "PL14 4DH",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Sporting Goods",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-4.465146, 50.450091],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "Special", "Powerlifting", "Weights"],
      rating: 5,
      reviews: 0,
      id: "d_g_performance_gym_liskeard",
      images: [null],
      name: "D & G Performance Gym",
      location: "Liskeard, Cornwall, United Kingdom",
    },
  ],
  Pontypridd: [
    {
      "Record ID": "23090041629",
      company_name: "One to One Gym",
      "Create Date": "2024-09-17 16:57",
      Type: "Vendor",
      "Street Address": "",
      "Street Address 2": "",
      city: "Pontypridd",
      "State/Region": "Rhondda Cynon Taff",
      "Postal Code": "",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-3.343317, 51.600414],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Powerlifting", "Weights", "Courts"],
      rating: 5,
      reviews: 0,
      id: "one_to_one_gym_pontypridd",
      images: [
        "https://live.staticflickr.com/65535/50667635402_c88695b72b.jpg",
      ],
      name: "One to One Gym",
      location: "Pontypridd, Rhondda Cynon Taff, United Kingdom",
    },
  ],
  Hatfield: [
    {
      "Record ID": "23080317274",
      company_name: "David Lloyd Clubs",
      "Create Date": "2024-09-17 18:03",
      Type: "Vendor",
      "Street Address": "",
      "Street Address 2": "",
      city: "Hatfield",
      "State/Region": "NY",
      "Postal Code": "AL10 9AX",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "+44 20 7399 4200",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-0.223852, 51.763139],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "Weights", "Courts", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "david_lloyd_clubs_hatfield",
      images: [
        "https://live.staticflickr.com/65535/52695090799_62f10c50e9.jpg",
      ],
      name: "David Lloyd Clubs",
      location: "Hatfield, NY, United Kingdom",
    },
  ],
  Richmond: [
    {
      "Record ID": "23113324932",
      company_name: "Majestic Fitness",
      "Create Date": "2024-09-18 16:43",
      Type: "Vendor",
      "Street Address": "",
      "Street Address 2": "1-4",
      city: "Richmond",
      "State/Region": "Greater London",
      "Postal Code": "TW9 4FG",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-0.16667, 51.5],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Machines", "Female Only", "Pool"],
      rating: 5,
      reviews: 0,
      id: "majestic_fitness_richmond",
      images: [
        "https://live.staticflickr.com/65535/54044465476_97ddd77406.jpg",
      ],
      name: "Majestic Fitness",
      location: "Richmond, Greater London, United Kingdom",
    },
    {
      "Record ID": "23169012890",
      company_name: "RVA Iron Gym",
      "Create Date": "2024-09-21 21:02",
      Type: "",
      "Street Address": "3910 Adams Road",
      "Street Address 2": "",
      city: "Richmond",
      "State/Region": "VA",
      "Postal Code": "23222",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-77.3974393079999, 37.5793606330001],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Accessibility", "Female Only", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "rva_iron_gym_richmond",
      images: [
        "https://live.staticflickr.com/65535/53977311343_3fa9e6743b.jpg",
      ],
      name: "RVA Iron Gym",
      location: "Richmond, VA, United States",
    },
  ],
  Welwyn_Garden_city: [
    {
      "Record ID": "23109352558",
      company_name: "Xcelerate Gyms",
      "Create Date": "2024-09-18 16:44",
      Type: "Vendor",
      "Street Address": "",
      "Street Address 2": "",
      city: "Welwyn Garden City",
      "State/Region": "Hertfordshire",
      "Postal Code": "",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-0.199431, 51.801242],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Weights", "Machines", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "xcelerate_gyms_welwyn_garden_city",
      images: ["https://live.staticflickr.com/4415/37180796176_c20de53c7f.jpg"],
      name: "Xcelerate Gyms",
      location: "Welwyn Garden City, Hertfordshire, United Kingdom",
    },
  ],
  Shepperton: [
    {
      "Record ID": "23109352803",
      company_name: "Fresh Gym",
      "Create Date": "2024-09-18 16:48",
      Type: "Vendor",
      "Street Address": "",
      "Street Address 2": "",
      city: "Shepperton",
      "State/Region": "",
      "Postal Code": "TW17 8AB",
      "Country/Region": "",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-0.44257, 51.398177],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Pool", "Weights", "Special"],
      rating: 5,
      reviews: 0,
      id: "fresh_gym_shepperton",
      images: ["https://live.staticflickr.com/1499/26510074561_4dc92a16bd.jpg"],
      name: "Fresh Gym",
      location: "Shepperton, , ",
    },
  ],
  Uxbridge: [
    {
      "Record ID": "23113325345",
      company_name: "Panthers Gym",
      "Create Date": "2024-09-18 16:49",
      Type: "Vendor",
      "Street Address": "",
      "Street Address 2": "",
      city: "Uxbridge",
      "State/Region": "Greater London",
      "Postal Code": "",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-0.16667, 51.5],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Weights", "LGBTQIA+", "Special"],
      rating: 5,
      reviews: 0,
      id: "panthers_gym_uxbridge",
      images: ["https://live.staticflickr.com/798/27261464338_8023b2f879.jpg"],
      name: "Panthers Gym",
      location: "Uxbridge, Greater London, United Kingdom",
    },
  ],
  Worcester_Park: [
    {
      "Record ID": "23109353362",
      company_name: "The River Club",
      "Create Date": "2024-09-18 16:58",
      Type: "Vendor",
      "Street Address": "",
      "Street Address 2": "",
      city: "Worcester Park",
      "State/Region": "Surrey",
      "Postal Code": "KT4 7PX",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-2.22001, 52.18935],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Female Only", "Pool", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "the_river_club_worcester_park",
      images: ["https://live.staticflickr.com/4328/36339627675_ae0296b28c.jpg"],
      name: "The River Club",
      location: "Worcester Park, Surrey, United Kingdom",
    },
  ],
  Byfleet: [
    {
      "Record ID": "23109353845",
      company_name: "The Warehouse Gym",
      "Create Date": "2024-09-18 17:06",
      Type: "Vendor",
      "Street Address": "7 Canada Road",
      "Street Address 2": "",
      city: "Byfleet",
      "State/Region": "Surrey",
      "Postal Code": "",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "+44 1932 320900",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-0.33333, 51.25],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Female Only", "Accessibility", "Weights"],
      rating: 5,
      reviews: 0,
      id: "the_warehouse_gym_byfleet",
      images: ["https://live.staticflickr.com/4041/4234810928_2099a8714a.jpg"],
      name: "The Warehouse Gym",
      location: "Byfleet, Surrey, United Kingdom",
    },
  ],
  Caerphilly: [
    {
      "Record ID": "23109353882",
      company_name: "Team Rees Gym",
      "Create Date": "2024-09-18 17:07",
      Type: "Vendor",
      "Street Address": "",
      "Street Address 2": "",
      city: "Caerphilly",
      "State/Region": "Caerphilly County Borough",
      "Postal Code": "",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-3.16667, 51.66667],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Courts", "Special", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "team_rees_gym_caerphilly",
      images: [
        "https://live.staticflickr.com/65535/46882174585_cc39e18e93.jpg",
      ],
      name: "Team Rees Gym",
      location: "Caerphilly, Caerphilly County Borough, United Kingdom",
    },
  ],
  Markyate: [
    {
      "Record ID": "23109353861",
      company_name: "Gymtro",
      "Create Date": "2024-09-18 17:07",
      Type: "Vendor",
      "Street Address": "131a High Street",
      "Street Address 2": "",
      city: "Markyate",
      "State/Region": "Hertfordshire",
      "Postal Code": "AL3 8JG",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-0.4597054, 51.8355931],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "LGBTQIA+", "Accessibility", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "gymtro_markyate",
      images: [null],
      name: "Gymtro",
      location: "Markyate, Hertfordshire, United Kingdom",
    },
  ],
  West_Monroe: [
    {
      "Record ID": "23113664047",
      company_name: "GLL",
      "Create Date": "2024-09-18 17:15",
      Type: "Vendor",
      "Street Address": "604 Natchitoches Street",
      "Street Address 2": "",
      city: "West Monroe",
      "State/Region": "LA",
      "Postal Code": "71291",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-92.1268997192383, 32.5002212524414],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Accessibility", "Special", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "gll_west_monroe",
      images: ["https://live.staticflickr.com/3049/3148130966_da8a971fe4.jpg"],
      name: "GLL",
      location: "West Monroe, LA, United States",
    },
  ],
  Newtown: [
    {
      "Record ID": "23109354273",
      company_name: "N-MAC Gym",
      "Create Date": "2024-09-18 17:15",
      Type: "Vendor",
      "Street Address": "",
      "Street Address 2": "",
      city: "Newtown",
      "State/Region": "Powys",
      "Postal Code": "SY16 1BS",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-3.310131, 52.512934],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Cardio", "Powerlifting", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "n_mac_gym_newtown",
      images: [
        "https://live.staticflickr.com/65535/53638379202_f3093b96e9.jpg",
      ],
      name: "N-MAC Gym",
      location: "Newtown, Powys, United Kingdom",
    },
  ],
  Manchester: [
    {
      "Record ID": "23113664103",
      company_name: "Zeno Health Club",
      "Create Date": "2024-09-18 17:16",
      Type: "Vendor",
      "Street Address": "6 Miller Street",
      "Street Address 2": "",
      city: "Manchester",
      "State/Region": "Greater Manchester",
      "Postal Code": "M4 4GE",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [144.390772, -38.160453],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Cardio", "Weights", "Pool"],
      rating: 5,
      reviews: 0,
      id: "zeno_health_club_manchester",
      images: [
        "https://live.staticflickr.com/65535/54074575180_d5db1f42ba.jpg",
      ],
      name: "Zeno Health Club",
      location: "Manchester, Greater Manchester, United Kingdom",
    },
    {
      "Record ID": "23109354308",
      company_name: "Evo Didsbury",
      "Create Date": "2024-09-18 17:16",
      Type: "Vendor",
      "Street Address": "165e Burton Road",
      "Street Address 2": "",
      city: "Manchester",
      "State/Region": "Greater Manchester",
      "Postal Code": "M20 2LN",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-2.242551, 53.426474],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "LGBTQIA+", "Special", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "evo_didsbury_manchester",
      images: [
        "https://live.staticflickr.com/65535/54044184496_9725ca0401.jpg",
      ],
      name: "Evo Didsbury",
      location: "Manchester, Greater Manchester, United Kingdom",
    },
    {
      "Record ID": "23113664079",
      company_name: "Gym72 Manchester",
      active: true,
      "Create Date": "2024-09-18 17:16",
      Type: "Vendor",
      "Street Address": "72 Sackville Street",
      "Street Address 2": "",
      city: "Manchester",
      "State/Region": "Greater Manchester",
      "Postal Code": "M1 3NJ",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Health, Wellness and Fitness",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-2.235231, 53.475079],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Machines", "Female Only", "Pool"],
      rating: 5,
      reviews: 0,
      id: "gym72_manchester_manchester",
      images: [
        "https://live.staticflickr.com/65535/53961540149_9e75c1f791.jpg",
      ],
      name: "Gym72 Manchester",
      location: "Manchester, Greater Manchester, United Kingdom",
    },
    {
      "Record ID": "23109354514",
      company_name: "RxGym",
      "Create Date": "2024-09-18 17:20",
      Type: "Vendor",
      "Street Address": "",
      "Street Address 2": "",
      city: "Manchester",
      "State/Region": "Greater Manchester",
      "Postal Code": "",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-2.298838, 53.556417],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Pool", "Cardio", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "rxgym_manchester",
      images: [
        "https://live.staticflickr.com/65535/53929831528_dd4d4a5847.jpg",
      ],
      name: "RxGym",
      location: "Manchester, Greater Manchester, United Kingdom",
    },
    {
      "Record ID": "23113664351",
      company_name: "The Olympus Projects",
      "Create Date": "2024-09-18 17:21",
      Type: "Vendor",
      "Street Address": "109 Corporation Street",
      "Street Address 2": "",
      city: "Manchester",
      "State/Region": "Greater Manchester",
      "Postal Code": "M4 4DX",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-2.239726, 53.488249],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "LGBTQIA+", "Powerlifting", "Special"],
      rating: 5,
      reviews: 0,
      id: "the_olympus_projects_manchester",
      images: [
        "https://live.staticflickr.com/65535/53890820202_b44ce34ac9.jpg",
      ],
      name: "The Olympus Projects",
      location: "Manchester, Greater Manchester, United Kingdom",
    },
    {
      "Record ID": "23113664358",
      company_name: "Muscle Manor",
      "Create Date": "2024-09-18 17:21",
      Type: "Vendor",
      "Street Address": "393 Wilmslow Road",
      "Street Address 2": "",
      city: "Manchester",
      "State/Region": "Greater Manchester",
      "Postal Code": "M20 4WA",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Health, Wellness and Fitness",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-2.221959, 53.437711],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "Powerlifting", "Pool", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "muscle_manor_manchester",
      images: [
        "https://live.staticflickr.com/65535/53891976848_84600e6b01.jpg",
      ],
      name: "Muscle Manor",
      location: "Manchester, Greater Manchester, United Kingdom",
    },
    {
      "Record ID": "23113664394",
      company_name: "Siren Asylum",
      "Create Date": "2024-09-18 17:22",
      Type: "Vendor",
      "Street Address": "",
      "Street Address 2": "",
      city: "Manchester",
      "State/Region": "Greater Manchester",
      "Postal Code": "",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Sporting Goods",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-2.298838, 53.556417],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "Powerlifting", "Courts", "Pool"],
      rating: 5,
      reviews: 0,
      id: "siren_asylum_manchester",
      images: [
        "https://live.staticflickr.com/65535/53891976808_10296728f9.jpg",
      ],
      name: "Siren Asylum",
      location: "Manchester, Greater Manchester, United Kingdom",
    },
    {
      "Record ID": "23113664563",
      company_name: "King Street Gym",
      "Create Date": "2024-09-18 17:25",
      Type: "",
      "Street Address": "55 King Street",
      "Street Address 2": "7-8",
      city: "Manchester",
      "State/Region": "Greater Manchester",
      "Postal Code": "M2 4LQ",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-2.243382, 53.481505],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Courts", "Powerlifting", "Pool"],
      rating: 5,
      reviews: 0,
      id: "king_street_gym_manchester",
      images: [
        "https://live.staticflickr.com/65535/53890820092_4d419305ed.jpg",
      ],
      name: "King Street Gym",
      location: "Manchester, Greater Manchester, United Kingdom",
    },
  ],
  Rhos_on_Sea: [
    {
      "Record ID": "23109354280",
      company_name: "Le Sport Health & Fitness",
      "Create Date": "2024-09-18 17:16",
      Type: "Vendor",
      "Street Address": "",
      "Street Address 2": "",
      city: "Rhos on Sea",
      "State/Region": "Conwy",
      "Postal Code": "LL28 4RB",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-3.740405, 53.309588],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Accessibility", "Weights", "Courts"],
      rating: 5,
      reviews: 0,
      id: "le_sport_health_fitness_rhos_on_sea",
      images: [null],
      name: "Le Sport Health & Fitness",
      location: "Rhos on Sea, Conwy, United Kingdom",
    },
  ],
  Liverpool: [
    {
      "Record ID": "23113664134",
      company_name: "Limitless Lifestyle Community",
      "Create Date": "2024-09-18 17:17",
      Type: "Vendor",
      "Street Address": "89 Dale Street",
      "Street Address 2": "",
      city: "Liverpool",
      "State/Region": "Merseyside",
      "Postal Code": "L2 2JD",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-2.97794, 53.41058],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Powerlifting", "Special", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "limitless_lifestyle_community_liverpool",
      images: [
        "https://live.staticflickr.com/65535/53739993100_b137a460f7.jpg",
      ],
      name: "Limitless Lifestyle Community",
      location: "Liverpool, Merseyside, United Kingdom",
    },
    {
      "Record ID": "23168950803",
      company_name: "Dedicated Supergym",
      "Create Date": "2024-09-21 21:23",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Liverpool",
      "State/Region": "Merseyside",
      "Postal Code": "",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-2.988932, 53.457143],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Courts", "Powerlifting", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "dedicated_supergym_liverpool",
      images: [
        "https://live.staticflickr.com/65535/53702357575_5d4eca2a4c.jpg",
      ],
      name: "Dedicated Supergym",
      location: "Liverpool, Merseyside, United Kingdom",
    },
  ],
  Bollington: [
    {
      "Record ID": "23113664113",
      company_name: "AW Muscle and Fitness",
      "Create Date": "2024-09-18 17:17",
      Type: "Vendor",
      "Street Address": "",
      "Street Address 2": "",
      city: "Bollington",
      "State/Region": "Cheshire East",
      "Postal Code": "SK10 5JB",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-2.106906, 53.291938],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "Powerlifting", "Weights", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "aw_muscle_and_fitness_bollington",
      images: ["https://live.staticflickr.com/4021/4313745095_6f1490fa91.jpg"],
      name: "AW Muscle and Fitness",
      location: "Bollington, Cheshire East, United Kingdom",
    },
  ],
  Bury: [
    {
      "Record ID": "23113664177",
      company_name: "Stealth Strength & Fitness",
      "Create Date": "2024-09-18 17:18",
      Type: "Vendor",
      "Street Address": "",
      "Street Address 2": "",
      city: "Bury",
      "State/Region": "",
      "Postal Code": "BL8 2SL",
      "Country/Region": "",
      "Time Zone": "London",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-2.310234, 53.594638],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "Powerlifting", "Weights", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "stealth_strength_fitness_bury",
      images: [
        "https://live.staticflickr.com/65535/53930885145_865c4ecde4.jpg",
      ],
      name: "Stealth Strength & Fitness",
      location: "Bury, , ",
    },
  ],
  Southport: [
    {
      "Record ID": "23113664371",
      company_name: "Anvil Gym Southport",
      "Create Date": "2024-09-18 17:21",
      Type: "Vendor",
      "Street Address": "",
      "Street Address 2": "",
      city: "Southport",
      "State/Region": "Merseyside",
      "Postal Code": "PR9 7SA",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "+44 7511 152482",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-3.014079, 53.633681],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Machines", "Powerlifting", "Weights"],
      rating: 5,
      reviews: 0,
      id: "anvil_gym_southport_southport",
      images: [
        "https://live.staticflickr.com/65535/53250403097_86a2ed8e38.jpg",
      ],
      name: "Anvil Gym Southport",
      location: "Southport, Merseyside, United Kingdom",
    },
  ],
  Great_Sankey: [
    {
      "Record ID": "23113664363",
      company_name: "Ascendancy Fitness Gym",
      "Create Date": "2024-09-18 17:21",
      Type: "Vendor",
      "Street Address": "",
      "Street Address 2": "",
      city: "Great Sankey",
      "State/Region": "Warrington",
      "Postal Code": "WA5 1QX",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-2.58024, 53.39254],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Machines", "Powerlifting", "Weights"],
      rating: 5,
      reviews: 0,
      id: "ascendancy_fitness_gym_great_sankey",
      images: [
        "https://live.staticflickr.com/65535/49499949803_6553d8c7b3.jpg",
      ],
      name: "Ascendancy Fitness Gym",
      location: "Great Sankey, Warrington, United Kingdom",
    },
  ],
  Chesterton: [
    {
      "Record ID": "23109354569",
      company_name: "Old School Jims",
      "Create Date": "2024-09-18 17:22",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Chesterton",
      "State/Region": "Staffordshire",
      "Postal Code": "ST5 7JB",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-2.237423, 53.046786],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Courts", "Special", "Machines"],
      rating: 5,
      reviews: 0,
      id: "old_school_jims_chesterton",
      images: [
        "https://live.staticflickr.com/65535/53706803896_bc6429e3e3.jpg",
      ],
      name: "Old School Jims",
      location: "Chesterton, Staffordshire, United Kingdom",
    },
  ],
  Speke: [
    {
      "Record ID": "23109354548",
      company_name: "Dobie Optimum Fitnes",
      "Create Date": "2024-09-18 17:22",
      Type: "Vendor",
      "Street Address": "40 Spindus Road",
      "Street Address 2": "",
      city: "Speke",
      "State/Region": "Merseyside",
      "Postal Code": "L24 1YA",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-2.862303, 53.342816],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Weights", "Pool", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "dobie_optimum_fitnes_speke",
      images: [
        "https://live.staticflickr.com/65535/52407522835_7cbf365999.jpg",
      ],
      name: "Dobie Optimum Fitnes",
      location: "Speke, Merseyside, United Kingdom",
    },
    {
      "Record ID": "23109354575",
      company_name: "Absolute Body Solutions",
      "Create Date": "2024-09-18 17:23",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Speke",
      "State/Region": "Merseyside",
      "Postal Code": "L24 9PF",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "+44 7902 016958",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-2.841, 53.34071],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Accessibility", "Special", "Pool"],
      rating: 5,
      reviews: 0,
      id: "absolute_body_solutions_speke",
      images: ["https://live.staticflickr.com/1820/43273515294_88892fff7d.jpg"],
      name: "Absolute Body Solutions",
      location: "Speke, Merseyside, United Kingdom",
    },
  ],
  Seaforth: [
    {
      "Record ID": "23113664545",
      company_name: "Steel Habitat",
      "Create Date": "2024-09-18 17:24",
      Type: "",
      "Street Address": "154 Sandy Road",
      "Street Address 2": "",
      city: "Seaforth",
      "State/Region": "Merseyside",
      "Postal Code": "L21 1AQ",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-0.28925, 52.12927],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Courts", "Machines", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "steel_habitat_seaforth",
      images: [
        "https://live.staticflickr.com/65535/53127515991_552de321b8.jpg",
      ],
      name: "Steel Habitat",
      location: "Seaforth, Merseyside, United Kingdom",
    },
  ],
  Northwich: [
    {
      "Record ID": "23113664570",
      company_name: "Daves Gym",
      "Create Date": "2024-09-18 17:25",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Northwich",
      "State/Region": "Cheshire West and Chester",
      "Postal Code": "CW9 5RB",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-2.500085, 53.261895],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Cardio", "LGBTQIA+", "Courts"],
      rating: 5,
      reviews: 0,
      id: "daves_gym_northwich",
      images: [null],
      name: "Daves Gym",
      location: "Northwich, Cheshire West and Chester, United Kingdom",
    },
  ],
  Chester: [
    {
      "Record ID": "23109354833",
      company_name: "Train Fitness Centre",
      "Create Date": "2024-09-18 17:28",
      Type: "",
      "Street Address": "3 Kitchen Street",
      "Street Address 2": "",
      city: "Chester",
      "State/Region": "Cheshire West and Chester",
      "Postal Code": "CH1 4JH",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Health, Wellness and Fitness",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-2.89189, 53.1905],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Powerlifting", "Machines", "Weights"],
      rating: 5,
      reviews: 0,
      id: "train_fitness_centre_chester",
      images: [
        "https://live.staticflickr.com/65535/53514371324_548abb0b92.jpg",
      ],
      name: "Train Fitness Centre",
      location: "Chester, Cheshire West and Chester, United Kingdom",
    },
  ],
  Sale: [
    {
      "Record ID": "23113664648",
      company_name: "Nowhere2Hyde",
      "Create Date": "2024-09-18 17:28",
      Type: "",
      "Street Address": "4 Booth Road",
      "Street Address 2": "",
      city: "Sale",
      "State/Region": "Greater Manchester",
      "Postal Code": "M33 7JS",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-2.32443, 53.42519],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "Powerlifting", "Weights", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "nowhere2hyde_sale",
      images: [
        "https://live.staticflickr.com/65535/54086319882_54867e3beb.jpg",
      ],
      name: "Nowhere2Hyde",
      location: "Sale, Greater Manchester, United Kingdom",
    },
  ],
  willenhall: [
    {
      "Record ID": "23109354870",
      company_name: "Popeyes Gym",
      "Create Date": "2024-09-18 17:29",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "willenhall",
      "State/Region": "",
      "Postal Code": "wv125ae",
      "Country/Region": "",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-2.05934, 52.58514],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Female Only", "Pool", "Machines"],
      rating: 5,
      reviews: 0,
      id: "popeyes_gym_willenhall",
      images: ["https://live.staticflickr.com/734/31725611432_c8cf7366e4.jpg"],
      name: "Popeyes Gym",
      location: "willenhall, , ",
    },
  ],
  Wilmslow: [
    {
      "Record ID": "23109354898",
      company_name: "Total Fitness",
      active: true,
      "Create Date": "2024-09-18 17:30",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Wilmslow",
      "State/Region": "Cheshire East",
      "Postal Code": "SK9 3PE",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "2.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-2.36245, 53.16702],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Courts", "Machines", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "total_fitness_wilmslow",
      images: [
        "https://live.staticflickr.com/65535/53925546232_3055e872d9.jpg",
      ],
      name: "Total Fitness",
      location: "Wilmslow, Cheshire East, United Kingdom",
    },
  ],
  Willenhall: [
    {
      "Record ID": "23109354881",
      company_name: "Paragon Gym",
      "Create Date": "2024-09-18 17:30",
      Type: "",
      "Street Address": "16 Phoenix Road",
      "Street Address 2": "",
      city: "Willenhall",
      "State/Region": "West Midlands",
      "Postal Code": "WV11 3PX",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-2.05934, 52.58514],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Courts", "Cardio", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "paragon_gym_willenhall",
      images: ["https://live.staticflickr.com/734/31725611432_c8cf7366e4.jpg"],
      name: "Paragon Gym",
      location: "Willenhall, West Midlands, United Kingdom",
    },
  ],
  Washington: [
    {
      "Record ID": "23109361841",
      company_name: "Definition of Fitness",
      "Create Date": "2024-09-18 22:08",
      Type: "",
      "Street Address": "506 K Street Northwest",
      "Street Address 2": "",
      city: "Washington",
      "State/Region": "DC",
      "Postal Code": "20001",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-77.019340515, 38.9022903440001],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Cardio", "Machines", "Courts"],
      rating: 5,
      reviews: 0,
      id: "definition_of_fitness_washington",
      images: [
        "https://live.staticflickr.com/65535/54038611069_87e60c6bc0.jpg",
      ],
      name: "Definition of Fitness",
      location: "Washington, DC, United States",
    },
    {
      "Record ID": "23109361834",
      company_name: "SWEAT DC",
      active: true,
      "Create Date": "2024-09-18 22:08",
      Type: "",
      "Street Address": "3232 Georgia Avenue Northwest",
      "Street Address 2": "105",
      city: "Washington",
      "State/Region": "DC",
      "Postal Code": "20010",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-77.0237775868518, 38.9308216985762],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Cardio", "Female Only", "Machines"],
      rating: 5,
      reviews: 0,
      id: "sweat_dc_washington",
      images: [
        "https://live.staticflickr.com/65535/54038732220_02683e2468.jpg",
      ],
      name: "SWEAT DC",
      location: "Washington, DC, United States",
    },
    {
      "Record ID": "23118605167",
      company_name: "Fit 360 DC",
      "Create Date": "2024-09-18 22:13",
      Type: "",
      "Street Address": "3058 Mount Pleasant Street Northwest",
      "Street Address 2": "",
      city: "Washington",
      "State/Region": "DC",
      "Postal Code": "20009",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 202-588-0988",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-77.037498474, 38.928359985],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Cardio", "Special", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "fit_360_dc_washington",
      images: [
        "https://live.staticflickr.com/65535/54038605924_3a19c7d18c.jpg",
      ],
      name: "Fit 360 DC",
      location: "Washington, DC, United States",
    },
    {
      "Record ID": "23109362040",
      company_name: "Urban Athletic Club",
      "Create Date": "2024-09-18 22:16",
      Type: "",
      "Street Address": "804 N Street Northwest",
      "Street Address 2": "",
      city: "Washington",
      "State/Region": "DC",
      "Postal Code": "20001",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-77.023376465, 38.907119751],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Powerlifting", "Cardio", "Special"],
      rating: 5,
      reviews: 0,
      id: "urban_athletic_club_washington",
      images: [
        "https://live.staticflickr.com/65535/54038604574_616da6efac.jpg",
      ],
      name: "Urban Athletic Club",
      location: "Washington, DC, United States",
    },
    {
      "Record ID": "23134968872",
      company_name: "Jade Fitness",
      active: true,
      "Create Date": "2024-09-19 12:15",
      Type: "",
      "Street Address": "1310 Pennsylvania Avenue Southeast",
      "Street Address 2": "",
      city: "Washington",
      "State/Region": "DC",
      "Postal Code": "20003",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "0.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-76.9877296561986, 38.8816615],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Cardio", "LGBTQIA+", "Pool"],
      rating: 5,
      reviews: 0,
      id: "jade_fitness_washington",
      images: [
        "https://live.staticflickr.com/65535/54038274621_9353707e08.jpg",
      ],
      name: "Jade Fitness",
      location: "Washington, DC, United States",
    },
    {
      "Record ID": "23135009465",
      company_name: "VIDA Fitness",
      "Create Date": "2024-09-19 12:23",
      Type: "",
      "Street Address": "1612 U Street Northwest",
      "Street Address 2": "",
      city: "Washington",
      "State/Region": "DC",
      "Postal Code": "20009",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-77.0376365, 38.916744],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Courts", "Accessibility", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "vida_fitness_washington",
      images: [
        "https://live.staticflickr.com/65535/54031923305_a0b67a3bb1.jpg",
      ],
      name: "VIDA Fitness",
      location: "Washington, DC, United States",
    },
    {
      "Record ID": "23109392977",
      company_name: "DC Fit Union Market",
      "Create Date": "2024-09-19 12:25",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Washington",
      "State/Region": "DC",
      "Postal Code": "20002",
      "Country/Region": "",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Health, Wellness and Fitness",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-76.983607, 38.905026],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "LGBTQIA+", "Machines", "Weights"],
      rating: 5,
      reviews: 0,
      id: "dc_fit_union_market_washington",
      images: [
        "https://live.staticflickr.com/65535/53959280060_68366851b6.jpg",
      ],
      name: "DC Fit Union Market",
      location: "Washington, DC, ",
    },
    {
      "Record ID": "23109393073",
      company_name: "Total Source Fitness",
      "Create Date": "2024-09-19 12:27",
      Type: "",
      "Street Address": "1271 5th Street Northeast",
      "Street Address 2": "",
      city: "Washington",
      "State/Region": "DC",
      "Postal Code": "20002",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-76.9981500237198, 38.9077685],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Powerlifting", "Courts", "Pool"],
      rating: 5,
      reviews: 0,
      id: "total_source_fitness_washington",
      images: [
        "https://live.staticflickr.com/65535/53867935719_e5003f33d3.jpg",
      ],
      name: "Total Source Fitness",
      location: "Washington, DC, United States",
    },
    {
      "Record ID": "23109393291",
      company_name: "MINT",
      "Create Date": "2024-09-19 12:30",
      Type: "",
      "Street Address": "1724 California Street Northwest",
      "Street Address 2": "",
      city: "Washington",
      "State/Region": "DC",
      "Postal Code": "20009",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-77.040893555, 38.9176216130001],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Weights", "LGBTQIA+", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "mint_washington",
      images: [
        "https://live.staticflickr.com/65535/53833333590_1939d599cb.jpg",
      ],
      name: "MINT",
      location: "Washington, DC, United States",
    },
    {
      "Record ID": "23109393462",
      company_name: "Ambitious Athletics",
      "Create Date": "2024-09-19 12:32",
      Type: "",
      "Street Address": "2021 K Street Northwest",
      "Street Address 2": "",
      city: "Washington",
      "State/Region": "DC",
      "Postal Code": "20006",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-77.045776367, 38.9029083250001],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Cardio", "Special", "Machines"],
      rating: 5,
      reviews: 0,
      id: "ambitious_athletics_washington",
      images: [
        "https://live.staticflickr.com/65535/53844574824_0cc34fe001.jpg",
      ],
      name: "Ambitious Athletics",
      location: "Washington, DC, United States",
    },
    {
      "Record ID": "23135010338",
      company_name: "Balance Gym",
      "Create Date": "2024-09-19 12:35",
      Type: "",
      "Street Address": "1339 Green Court Northwest",
      "Street Address 2": "",
      city: "Washington",
      "State/Region": "DC",
      "Postal Code": "20005",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "3.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-77.0310596202532, 38.9045855],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Machines", "Weights", "Special"],
      rating: 5,
      reviews: 0,
      id: "balance_gym_washington",
      images: [
        "https://live.staticflickr.com/65535/53787361925_84beb44351.jpg",
      ],
      name: "Balance Gym",
      location: "Washington, DC, United States",
    },
    {
      "Record ID": "23135010576",
      company_name: "Waterside Fitness & Swim",
      "Create Date": "2024-09-19 12:39",
      Type: "",
      "Street Address": "901 6th SW St",
      "Street Address 2": "",
      city: "Washington",
      "State/Region": "Washington D.C.",
      "Postal Code": "20024",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 202-488-3701",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-77.0203083777429, 38.878288],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "Pool", "Weights", "Special"],
      rating: 5,
      reviews: 0,
      id: "waterside_fitness_swim_washington",
      images: [
        "https://live.staticflickr.com/65535/53765874234_c53068cf34.jpg",
      ],
      name: "Waterside Fitness & Swim",
      location: "Washington, Washington D.C., United States",
    },
    {
      "Record ID": "23109394083",
      company_name: "ALIGN",
      "Create Date": "2024-09-19 12:43",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Washington",
      "State/Region": "DC",
      "Postal Code": "",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 202-650-0980",
      Industry: "Health, Wellness and Fitness",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-77.03637, 38.89511],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Pool", "Female Only", "Special"],
      rating: 5,
      reviews: 0,
      id: "align_washington",
      images: [
        "https://live.staticflickr.com/65535/53744173399_02f3a33d5c.jpg",
      ],
      name: "ALIGN",
      location: "Washington, DC, United States",
    },
  ],
  Annandale: [
    {
      "Record ID": "23109362020",
      company_name: "Foundation Fitness",
      "Create Date": "2024-09-18 22:15",
      Type: "",
      "Street Address": "7232 Columbia Pike",
      "Street Address 2": "",
      city: "Annandale",
      "State/Region": "VA",
      "Postal Code": "22003",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-77.1951540539999, 38.8311144640001],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Accessibility", "Weights", "Machines"],
      rating: 5,
      reviews: 0,
      id: "foundation_fitness_annandale",
      images: ["https://live.staticflickr.com/5681/29829850253_b0ab806269.jpg"],
      name: "Foundation Fitness",
      location: "Annandale, VA, United States",
    },
  ],
  Carrollton: [
    {
      "Record ID": "23134969014",
      company_name: "Onelife Fitness",
      active: true,
      "Create Date": "2024-09-19 12:18",
      Type: "",
      "Street Address": "812 South Park Street",
      "Street Address 2": "12",
      city: "Carrollton",
      "State/Region": "GA",
      "Postal Code": "30117",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 678-504-7928",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "2.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-85.0753105031089, 33.5671815],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Pool", "Courts", "Machines"],
      rating: 5,
      reviews: 0,
      id: "onelife_fitness_carrollton",
      images: [
        "https://live.staticflickr.com/65535/51412149777_8df9a306ab.jpg",
      ],
      name: "Onelife Fitness",
      location: "Carrollton, GA, United States",
    },
  ],
  Charlotte: [
    {
      "Record ID": "23134969145",
      company_name: "MADabolic",
      active: true,
      "Create Date": "2024-09-19 12:19",
      Type: "",
      "Street Address": "2610 South Boulevard",
      "Street Address 2": "",
      city: "Charlotte",
      "State/Region": "NC",
      "Postal Code": "28209",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "15713190172",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-80.8658893232482, 35.2021545],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Pool", "Cardio", "Weights"],
      rating: 5,
      reviews: 0,
      id: "madabolic_charlotte",
      images: [
        "https://live.staticflickr.com/65535/53613929547_5bd4b02ee0.jpg",
      ],
      name: "MADabolic",
      location: "Charlotte, NC, United States",
    },
  ],
  Chantilly: [
    {
      "Record ID": "23140315255",
      company_name: "IMP Fitness",
      "Create Date": "2024-09-19 14:22",
      Type: "",
      "Street Address": "14705 Willard Road",
      "Street Address 2": "",
      city: "Chantilly",
      "State/Region": "VA",
      "Postal Code": "20151",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 703-466-5560",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-77.4563674926758, 38.8870315551758],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Cardio", "LGBTQIA+", "Pool"],
      rating: 5,
      reviews: 0,
      id: "imp_fitness_chantilly",
      images: [
        "https://live.staticflickr.com/65535/49555857308_6e9d2571aa.jpg",
      ],
      name: "IMP Fitness",
      location: "Chantilly, VA, United States",
    },
  ],
  Manassas_Park: [
    {
      "Record ID": "23140315217",
      company_name: "Unleash'd Strength Gym",
      "Create Date": "2024-09-19 14:22",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Manassas Park",
      "State/Region": "VA",
      "Postal Code": "20111",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-77.46971, 38.784],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Courts", "Special", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "unleash_d_strength_gym_manassas_park",
      images: [null],
      name: "Unleash'd Strength Gym",
      location: "Manassas Park, VA, United States",
    },
  ],
  Sterling: [
    {
      "Record ID": "23140504962",
      company_name: "Underground Athlete",
      "Create Date": "2024-09-19 14:24",
      Type: "",
      "Street Address": "22923 Quicksilver Drive",
      "Street Address 2": "",
      city: "Sterling",
      "State/Region": "VA",
      "Postal Code": "20166",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 571-450-9455",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-77.478065, 38.98392],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Accessibility", "Courts", "Machines"],
      rating: 5,
      reviews: 0,
      id: "underground_athlete_sterling",
      images: [
        "https://live.staticflickr.com/65535/54025659645_a0a00d1305.jpg",
      ],
      name: "Underground Athlete",
      location: "Sterling, VA, United States",
    },
  ],
  Alexandria: [
    {
      "Record ID": "23140505088",
      company_name: "ELIN - Fitness Redefined",
      "Create Date": "2024-09-19 14:25",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Alexandria",
      "State/Region": "VA",
      "Postal Code": "",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "2.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-77.04692, 38.80484],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Machines", "Powerlifting", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "elin_fitness_redefined_alexandria",
      images: [
        "https://live.staticflickr.com/65535/54067798626_527697d3b0.jpg",
      ],
      name: "ELIN - Fitness Redefined",
      location: "Alexandria, VA, United States",
    },
  ],
  Towson: [
    {
      "Record ID": "23140315693",
      company_name: "GRIND Fitness",
      "Create Date": "2024-09-19 14:29",
      Type: "",
      "Street Address": "8625 Pleasant Plains Road",
      "Street Address 2": "Suite B",
      city: "Towson",
      "State/Region": "MD",
      "Postal Code": "21286",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-76.5676316152129, 39.3957965],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Female Only", "Accessibility", "Courts"],
      rating: 5,
      reviews: 0,
      id: "grind_fitness_towson",
      images: [
        "https://live.staticflickr.com/65535/52798442962_cc1cc4ff7c.jpg",
      ],
      name: "GRIND Fitness",
      location: "Towson, MD, United States",
    },
  ],
  Scarborough: [
    {
      "Record ID": "23142978662",
      company_name: "Elite Culture",
      "Create Date": "2024-09-19 18:39",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Scarborough",
      "State/Region": "",
      "Postal Code": "",
      "Country/Region": "Canada",
      "Time Zone": "America/Regina",
      "Phone Number": "+1 647 994 2332",
      Industry: "Sporting Goods",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-79.236905, 43.781265],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Weights", "Pool", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "elite_culture_scarborough",
      images: [
        "https://live.staticflickr.com/65535/52709158167_6f57596ef1.jpg",
      ],
      name: "Elite Culture",
      location: "Scarborough, , Canada",
    },
  ],
  Richmond_Hill: [
    {
      "Record ID": "23142958444",
      company_name: "Bodyvision Fitness",
      "Create Date": "2024-09-19 18:53",
      Type: "",
      "Street Address": "9325 Yonge Street",
      "Street Address 2": "",
      city: "Richmond Hill",
      "State/Region": "Ontario",
      "Postal Code": "L4C 0A8",
      "Country/Region": "Canada",
      "Time Zone": "America/Toronto",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-79.432663, 43.854223],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Accessibility", "Pool", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "bodyvision_fitness_richmond_hill",
      images: [
        "https://live.staticflickr.com/65535/54044465476_97ddd77406.jpg",
      ],
      name: "Bodyvision Fitness",
      location: "Richmond Hill, Ontario, Canada",
    },
  ],
  Vancouver: [
    {
      "Record ID": "23142979085",
      company_name: "Innovative Fitness",
      "Create Date": "2024-09-19 18:55",
      Type: "",
      "Street Address": "2080 West 10th Avenue",
      "Street Address 2": "",
      city: "Vancouver",
      "State/Region": "British Columbia",
      "Postal Code": "V6J 2B3",
      "Country/Region": "Canada",
      "Time Zone": "America/Vancouver",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "2.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-123.152181, 49.262729],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Courts", "Special", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "innovative_fitness_vancouver",
      images: [
        "https://live.staticflickr.com/65535/53644353588_6158075505.jpg",
      ],
      name: "Innovative Fitness",
      location: "Vancouver, British Columbia, Canada",
    },
  ],
  Brampton: [
    {
      "Record ID": "23142979333",
      company_name: "My Bollywood Body",
      "Create Date": "2024-09-19 19:06",
      Type: "",
      "Street Address": "125 Chrysler Drive",
      "Street Address 2": "9",
      city: "Brampton",
      "State/Region": "Ontario",
      "Postal Code": "L6S 6L1",
      "Country/Region": "Canada",
      "Time Zone": "America/Toronto",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-79.70423, 43.74159],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Courts", "LGBTQIA+", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "my_bollywood_body_brampton",
      images: [
        "https://live.staticflickr.com/65535/53451884047_0e19a06bde.jpg",
      ],
      name: "My Bollywood Body",
      location: "Brampton, Ontario, Canada",
    },
  ],
  Ann_Arbor: [
    {
      "Record ID": "23145225249",
      company_name: "Wildly Fit",
      "Create Date": "2024-09-19 23:11",
      Type: "",
      "Street Address": "220 Felch Street",
      "Street Address 2": "",
      city: "Ann Arbor",
      "State/Region": "MI",
      "Postal Code": "48103",
      "Country/Region": "United States",
      "Time Zone": "America/Detroit",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-83.7502639673594, 42.286707],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Courts", "Machines", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "wildly_fit_ann_arbor",
      images: [
        "https://live.staticflickr.com/65535/54048897436_d1cbcba616.jpg",
      ],
      name: "Wildly Fit",
      location: "Ann Arbor, MI, United States",
    },
    {
      "Record ID": "23145225233",
      company_name: "Valor",
      "Create Date": "2024-09-19 23:11",
      Type: "",
      "Street Address": "573 State Circle",
      "Street Address 2": "",
      city: "Ann Arbor",
      "State/Region": "MI",
      "Postal Code": "48108",
      "Country/Region": "United States",
      "Time Zone": "America/Detroit",
      "Phone Number": "+1 734-780-6953",
      Industry: "Health, Wellness and Fitness",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-83.7413239977528, 42.233763],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "Powerlifting", "Accessibility", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "valor_ann_arbor",
      images: [
        "https://live.staticflickr.com/65535/54049228354_5728d70258.jpg",
      ],
      name: "Valor",
      location: "Ann Arbor, MI, United States",
    },
    {
      "Record ID": "23145225291",
      company_name: "Wolverine Strength and Conditioning",
      "Create Date": "2024-09-19 23:15",
      Type: "",
      "Street Address": "680 State Cir",
      "Street Address 2": "",
      city: "Ann Arbor",
      "State/Region": "MI",
      "Postal Code": "48108",
      "Country/Region": "United States",
      "Time Zone": "America/Detroit",
      "Phone Number": "+1 734-222-9600",
      Industry: "Health, Wellness and Fitness",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-83.7423284146219, 42.2349145],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Courts", "Weights", "Pool"],
      rating: 5,
      reviews: 0,
      id: "wolverine_strength_and_conditioning_ann_arbor",
      images: [
        "https://live.staticflickr.com/65535/54048897261_2ba602b208.jpg",
      ],
      name: "Wolverine Strength and Conditioning",
      location: "Ann Arbor, MI, United States",
    },
    {
      "Record ID": "23145225334",
      company_name: "Optimum Fitness Training",
      "Create Date": "2024-09-19 23:18",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Ann Arbor",
      "State/Region": "MI",
      "Postal Code": "48103",
      "Country/Region": "",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-83.851822, 42.269394],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Pool", "Special", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "optimum_fitness_training_ann_arbor",
      images: [
        "https://live.staticflickr.com/65535/54049346325_c975ce168f.jpg",
      ],
      name: "Optimum Fitness Training",
      location: "Ann Arbor, MI, ",
    },
  ],
  Hamilton: [
    {
      "Record ID": "23145225258",
      company_name: "Xelf",
      "Create Date": "2024-09-19 23:12",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Hamilton",
      "State/Region": "ON",
      "Postal Code": "",
      "Country/Region": "Canada",
      "Time Zone": "America/Regina",
      "Phone Number": "+1 905 923 7595",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-80.027986, 43.276797],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Courts", "Female Only", "Weights"],
      rating: 5,
      reviews: 0,
      id: "xelf_hamilton",
      images: ["https://live.staticflickr.com/0/54040811777_d2f2e5c504.jpg"],
      name: "Xelf",
      location: "Hamilton, ON, Canada",
    },
    {
      "Record ID": "23145225306",
      company_name: "Amazing Fitness",
      active: true,
      "Create Date": "2024-09-19 23:15",
      Type: "",
      "Street Address": "702 King Street East",
      "Street Address 2": "",
      city: "Hamilton",
      "State/Region": "Ontario",
      "Postal Code": "L8M 1A3",
      "Country/Region": "Canada",
      "Time Zone": "America/Toronto",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-79.847523, 43.251603],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "Weights", "Accessibility", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "amazing_fitness_hamilton",
      images: [
        "https://live.staticflickr.com/65535/54041997684_8ebf193125.jpg",
      ],
      name: "Amazing Fitness",
      location: "Hamilton, Ontario, Canada",
    },
  ],
  Troy: [
    {
      "Record ID": "23145213992",
      company_name: "Troy City Fitness",
      "Create Date": "2024-09-19 23:17",
      Type: "",
      "Street Address": "4924 John R Road",
      "Street Address 2": "",
      city: "Troy",
      "State/Region": "MI",
      "Postal Code": "48085",
      "Country/Region": "United States",
      "Time Zone": "America/Detroit",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "0.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-83.1086284829325, 42.5917125146523],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Machines", "Weights", "Special"],
      rating: 5,
      reviews: 0,
      id: "troy_city_fitness_troy",
      images: [
        "https://live.staticflickr.com/65535/53860033205_fdd9275905.jpg",
      ],
      name: "Troy City Fitness",
      location: "Troy, MI, United States",
    },
  ],
  Detroit: [
    {
      "Record ID": "23145225367",
      company_name: "PGSK",
      "Create Date": "2024-09-19 23:20",
      Type: "",
      "Street Address": "1600 Clay Street",
      "Street Address 2": "",
      city: "Detroit",
      "State/Region": "MI",
      "Postal Code": "48211",
      "Country/Region": "United States",
      "Time Zone": "America/Detroit",
      "Phone Number": "+1 248-821-5366",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-83.0604263248485, 42.3784279680017],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "Cardio", "Weights", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "pgsk_detroit",
      images: [
        "https://live.staticflickr.com/65535/54082133105_6c879abe09.jpg",
      ],
      name: "PGSK",
      location: "Detroit, MI, United States",
    },
    {
      "Record ID": "23145214097",
      company_name: "Detroit Athletic Club",
      "Create Date": "2024-09-19 23:25",
      Type: "",
      "Street Address": "241 Madison Street",
      "Street Address 2": "",
      city: "Detroit",
      "State/Region": "MI",
      "Postal Code": "48226",
      "Country/Region": "United States",
      "Time Zone": "America/Detroit",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-83.047380442246, 42.337729],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Cardio", "Accessibility", "Special"],
      rating: 5,
      reviews: 0,
      id: "detroit_athletic_club_detroit",
      images: [
        "https://live.staticflickr.com/65535/54081671331_6906a40605.jpg",
      ],
      name: "Detroit Athletic Club",
      location: "Detroit, MI, United States",
    },
  ],
  Byron_Center: [
    {
      "Record ID": "23145225381",
      company_name: "TRVFIT",
      "Create Date": "2024-09-19 23:22",
      Type: "",
      "Street Address": "365 84th Street Southeast",
      "Street Address 2": "",
      city: "Byron Center",
      "State/Region": "MI",
      "Postal Code": "49315",
      "Country/Region": "United States",
      "Time Zone": "America/Detroit",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-85.6728823993321, 42.8126425],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "Accessibility", "Weights", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "trvfit_byron_center",
      images: ["https://live.staticflickr.com/3535/4063273364_07a179c603.jpg"],
      name: "TRVFIT",
      location: "Byron Center, MI, United States",
    },
  ],
  Plymouth: [
    {
      "Record ID": "23145214137",
      company_name: "Detroit Muscle Gym",
      "Create Date": "2024-09-19 23:28",
      Type: "",
      "Street Address": "736 Junction Street",
      "Street Address 2": "",
      city: "Plymouth",
      "State/Region": "MI",
      "Postal Code": "48170",
      "Country/Region": "United States",
      "Time Zone": "America/Detroit",
      "Phone Number": "+1 734-658-1463",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-83.4691467285156, 42.3799095153809],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "LGBTQIA+", "Special", "Courts"],
      rating: 5,
      reviews: 0,
      id: "detroit_muscle_gym_plymouth",
      images: [
        "https://live.staticflickr.com/65535/54053687102_e60b26fe4b.jpg",
      ],
      name: "Detroit Muscle Gym",
      location: "Plymouth, MI, United States",
    },
  ],
  Brook_Park: [
    {
      "Record ID": "23145214238",
      company_name: "Old School Iron Gym",
      "Create Date": "2024-09-19 23:30",
      Type: "",
      "Street Address": "5139 West 140th Street",
      "Street Address 2": "a",
      city: "Brook Park",
      "State/Region": "OH",
      "Postal Code": "44142",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 216-671-7505",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-81.7914806887543, 41.4159021765509],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Machines", "Cardio", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "old_school_iron_gym_brook_park",
      images: [null],
      name: "Old School Iron Gym",
      location: "Brook Park, OH, United States",
    },
  ],
  Cleveland: [
    {
      "Record ID": "23145225531",
      company_name: "Barbell Culture",
      "Create Date": "2024-09-19 23:31",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Cleveland",
      "State/Region": "OH",
      "Postal Code": "44115",
      "Country/Region": "",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-81.671188, 41.493539],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Female Only", "Courts", "Machines"],
      rating: 5,
      reviews: 0,
      id: "barbell_culture_cleveland",
      images: [
        "https://live.staticflickr.com/65535/53668311559_26daa510a2.jpg",
      ],
      name: "Barbell Culture",
      location: "Cleveland, OH, ",
    },
    {
      "Record ID": "23145225541",
      company_name: "Tremont Athletic Club",
      active: true,
      "Create Date": "2024-09-19 23:32",
      Type: "",
      "Street Address": "2306 West 17th Street",
      "Street Address 2": "",
      city: "Cleveland",
      "State/Region": "OH",
      "Postal Code": "44113",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "2.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-81.6961062812837, 41.480456],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Female Only", "LGBTQIA+", "Pool"],
      rating: 5,
      reviews: 0,
      id: "tremont_athletic_club_cleveland",
      images: [
        "https://live.staticflickr.com/65535/53529673576_67f71c507c.jpg",
      ],
      name: "Tremont Athletic Club",
      location: "Cleveland, OH, United States",
    },
    {
      "Record ID": "23145214278",
      company_name: "Out Fitness Collective",
      active: true,
      "Create Date": "2024-09-19 23:34",
      Type: "",
      "Street Address": "7405 Detroit Avenue",
      "Street Address 2": "",
      city: "Cleveland",
      "State/Region": "OH",
      "Postal Code": "44102",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 216-250-2098",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-81.7355439926525, 41.4824255],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Courts", "Special", "Pool"],
      rating: 5,
      reviews: 0,
      id: "out_fitness_collective_cleveland",
      images: [
        "https://live.staticflickr.com/65535/53529666531_aaa5713a12.jpg",
      ],
      name: "Out Fitness Collective",
      location: "Cleveland, OH, United States",
    },
    {
      "Record ID": "23145214271",
      company_name: "C4 Athletics",
      "Create Date": "2024-09-19 23:34",
      Type: "",
      "Street Address": "1831 Columbus Road",
      "Street Address 2": "",
      city: "Cleveland",
      "State/Region": "OH",
      "Postal Code": "44113",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 234-400-0058",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-81.6994578360894, 41.4898691955993],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Female Only", "Machines", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "c4_athletics_cleveland",
      images: [
        "https://live.staticflickr.com/65535/53530088210_f750529cff.jpg",
      ],
      name: "C4 Athletics",
      location: "Cleveland, OH, United States",
    },
    {
      "Record ID": "23145214283",
      company_name: "Ascend Fitness & Spa",
      "Create Date": "2024-09-19 23:35",
      Type: "",
      "Street Address": "100 Saint Clair Avenue Northeast",
      "Street Address 2": "3rd Floor",
      city: "Cleveland",
      "State/Region": "OH",
      "Postal Code": "44114",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-81.6950302124023, 41.5010185241699],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Female Only", "LGBTQIA+", "Weights"],
      rating: 5,
      reviews: 0,
      id: "ascend_fitness_spa_cleveland",
      images: [
        "https://live.staticflickr.com/65535/53341376677_c47e896dfd.jpg",
      ],
      name: "Ascend Fitness & Spa",
      location: "Cleveland, OH, United States",
    },
    {
      "Record ID": "23145225600",
      company_name: "Functional Fitness",
      active: true,
      "Create Date": "2024-09-19 23:37",
      Type: "",
      "Street Address": "3353 Edgecliff Terrace",
      "Street Address 2": "",
      city: "Cleveland",
      "State/Region": "OH",
      "Postal Code": "44111",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 216-738-9938",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-81.803047, 41.462698],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Accessibility", "Powerlifting", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "functional_fitness_cleveland",
      images: [
        "https://live.staticflickr.com/65535/53341061574_eb6d911fe1.jpg",
      ],
      name: "Functional Fitness",
      location: "Cleveland, OH, United States",
    },
    {
      "Record ID": "23145214321",
      company_name: "Shred Fitness",
      active: true,
      "Create Date": "2024-09-19 23:38",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Cleveland",
      "State/Region": "OH",
      "Postal Code": "",
      "Country/Region": "",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-81.65133, 41.496551],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Special", "Powerlifting", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "shred_fitness_cleveland",
      images: [
        "https://live.staticflickr.com/65535/53339850667_d72037d37b.jpg",
      ],
      name: "Shred Fitness",
      location: "Cleveland, OH, ",
    },
    {
      "Record ID": "23145214336",
      company_name: "Browns Fit",
      "Create Date": "2024-09-19 23:39",
      Type: "",
      "Street Address": "1111 West 10th Street",
      "Street Address 2": "2nd fl",
      city: "Cleveland",
      "State/Region": "OH",
      "Postal Code": "44113",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 216-938-9135",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-81.7041366923821, 41.5005950027383],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Weights", "Pool", "Special"],
      rating: 5,
      reviews: 0,
      id: "browns_fit_cleveland",
      images: [
        "https://live.staticflickr.com/65535/53281242966_4237662fbf.jpg",
      ],
      name: "Browns Fit",
      location: "Cleveland, OH, United States",
    },
    {
      "Record ID": "23145225811",
      company_name: "Be Fitness",
      "Create Date": "2024-09-19 23:49",
      Type: "",
      "Street Address": "10400 Clifton Blvd",
      "Street Address 2": "",
      city: "Cleveland",
      "State/Region": "OH",
      "Postal Code": "44102",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 216-227-9710",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-81.7564582023161, 41.4855556856465],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Machines", "Weights", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "be_fitness_cleveland",
      images: [
        "https://live.staticflickr.com/65535/53019953050_d3726e0a10.jpg",
      ],
      name: "Be Fitness",
      location: "Cleveland, OH, United States",
    },
    {
      "Record ID": "23145225852",
      company_name: "The Studio Cleveland",
      "Create Date": "2024-09-19 23:53",
      Type: "",
      "Street Address": "1395 West 10th Street",
      "Street Address 2": "120",
      city: "Cleveland",
      "State/Region": "OH",
      "Postal Code": "44113",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Health, Wellness and Fitness",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-81.7017364501953, 41.4980812072754],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Courts", "Accessibility", "Machines"],
      rating: 5,
      reviews: 0,
      id: "the_studio_cleveland_cleveland",
      images: [
        "https://live.staticflickr.com/65535/53012264567_726ff0902a.jpg",
      ],
      name: "The Studio Cleveland",
      location: "Cleveland, OH, United States",
    },
    {
      "Record ID": "23145214594",
      company_name: "ASAP Fitness",
      "Create Date": "2024-09-19 23:57",
      Type: "",
      "Street Address": "2214 Wooster Road",
      "Street Address 2": "",
      city: "Cleveland",
      "State/Region": "OH",
      "Postal Code": "44116",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 440-333-5438",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-81.8358383178711, 41.4751281738281],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Machines", "Accessibility", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "asap_fitness_cleveland",
      images: [
        "https://live.staticflickr.com/65535/52756850533_18bb52e590.jpg",
      ],
      name: "ASAP Fitness",
      location: "Cleveland, OH, United States",
    },
  ],
  Beachwood: [
    {
      "Record ID": "23145225580",
      company_name: "CORUS45",
      "Create Date": "2024-09-19 23:36",
      Type: "",
      "Street Address": "3365 Richmond Road",
      "Street Address 2": "230",
      city: "Beachwood",
      "State/Region": "OH",
      "Postal Code": "44122",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 216-801-2673",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "2.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-81.4958965043, 41.4658418473872],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Machines", "Accessibility", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "corus45_beachwood",
      images: ["https://live.staticflickr.com/4303/36071892185_8c0017f92a.jpg"],
      name: "CORUS45",
      location: "Beachwood, OH, United States",
    },
  ],
  Lakewood: [
    {
      "Record ID": "23145225595",
      company_name: "Fitness Edge",
      "Create Date": "2024-09-19 23:37",
      Type: "",
      "Street Address": "15641 Madison Avenue",
      "Street Address 2": "",
      city: "Lakewood",
      "State/Region": "OH",
      "Postal Code": "44107",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 216-678-9291",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-81.8071018857937, 41.4769503090862],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Cardio", "Pool", "Weights"],
      rating: 5,
      reviews: 0,
      id: "fitness_edge_lakewood",
      images: ["https://live.staticflickr.com/0/50289743302_9f5bf3224c.jpg"],
      name: "Fitness Edge",
      location: "Lakewood, OH, United States",
    },
  ],
  Cleveland_Heights: [
    {
      "Record ID": "23145225636",
      company_name: "Voltage Training & Fitness Center",
      "Create Date": "2024-09-19 23:40",
      Type: "",
      "Street Address": "1635 Lee Road",
      "Street Address 2": "",
      city: "Cleveland Heights",
      "State/Region": "OH",
      "Postal Code": "44118",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 216-321-1119",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-81.5686717716164, 41.5152999167951],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Powerlifting", "Machines", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "voltage_training_fitness_center_cleveland_heights",
      images: [
        "https://live.staticflickr.com/65535/53281242966_4237662fbf.jpg",
      ],
      name: "Voltage Training & Fitness Center",
      location: "Cleveland Heights, OH, United States",
    },
  ],
  Middleburg_Heights: [
    {
      "Record ID": "23145225642",
      company_name: "Cleveland Fitness Club",
      "Create Date": "2024-09-19 23:41",
      Type: "",
      "Street Address": "6600 West 130th Street",
      "Street Address 2": "",
      city: "Middleburg Heights",
      "State/Region": "OH",
      "Postal Code": "44130",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-81.7856181257485, 41.3823115],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Powerlifting", "Pool", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "cleveland_fitness_club_middleburg_heights",
      images: [
        "https://live.staticflickr.com/65535/49793030051_c6a313db9a.jpg",
      ],
      name: "Cleveland Fitness Club",
      location: "Middleburg Heights, OH, United States",
    },
  ],
  Bedford_Heights: [
    {
      "Record ID": "23145225777",
      company_name: "Kings Gym",
      "Create Date": "2024-09-19 23:47",
      Type: "",
      "Street Address": "24775 Aurora Road",
      "Street Address 2": "",
      city: "Bedford Heights",
      "State/Region": "OH",
      "Postal Code": "44146",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 440-439-5464",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-81.504049, 41.412293],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "LGBTQIA+", "Pool", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "kings_gym_bedford_heights",
      images: [null],
      name: "Kings Gym",
      location: "Bedford Heights, OH, United States",
    },
  ],
  Bay_Village: [
    {
      "Record ID": "23145225862",
      company_name: "Perfect Fit Health Club",
      active: true,
      "Create Date": "2024-09-19 23:54",
      Type: "",
      "Street Address": "27233 Wolf Road",
      "Street Address 2": "",
      city: "Bay Village",
      "State/Region": "OH",
      "Postal Code": "44140",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-81.9216414718907, 41.4843476496114],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Courts", "LGBTQIA+", "Weights"],
      rating: 5,
      reviews: 0,
      id: "perfect_fit_health_club_bay_village",
      images: ["https://live.staticflickr.com/1720/24491522079_f22714b893.jpg"],
      name: "Perfect Fit Health Club",
      location: "Bay Village, OH, United States",
    },
  ],
  Richmond_Heights: [
    {
      "Record ID": "23145214582",
      company_name: "FitWorks",
      "Create Date": "2024-09-19 23:56",
      Type: "",
      "Street Address": "26391 Curtiss Wright Parkway",
      "Street Address 2": "104",
      city: "Richmond Heights",
      "State/Region": "OH",
      "Postal Code": "44143",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-81.490018, 41.569752],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Powerlifting", "Courts", "Special"],
      rating: 5,
      reviews: 0,
      id: "fitworks_richmond_heights",
      images: [
        "https://live.staticflickr.com/65535/52854862089_7ccb00ff1b.jpg",
      ],
      name: "FitWorks",
      location: "Richmond Heights, OH, United States",
    },
  ],
  New_York: [
    {
      "Record ID": "23145225998",
      company_name: "Strong New York",
      "Create Date": "2024-09-19 23:59",
      Type: "",
      "Street Address": "660 12th Avenue",
      "Street Address 2": "",
      city: "New York",
      "State/Region": "NY",
      "Postal Code": "10019",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Education Management",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-73.997190071, 40.7657202230001],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Courts", "Female Only", "Pool"],
      rating: 5,
      reviews: 0,
      id: "strong_new_york_new_york",
      images: [
        "https://live.staticflickr.com/65535/54071435059_895f4ff1de.jpg",
      ],
      name: "Strong New York",
      location: "New York, NY, United States",
    },
    {
      "Record ID": "23145226029",
      company_name: "X93 Fitness",
      "Create Date": "2024-09-20 00:01",
      Type: "",
      "Street Address": "673 Amsterdam Avenue",
      "Street Address 2": "",
      city: "New York",
      "State/Region": "NY",
      "Postal Code": "10025",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "0.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-73.971744139, 40.791858394],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Weights", "LGBTQIA+", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "x93_fitness_new_york",
      images: [
        "https://live.staticflickr.com/65535/54019829461_e28204de59.jpg",
      ],
      name: "X93 Fitness",
      location: "New York, NY, United States",
    },
    {
      "Record ID": "23158088992",
      company_name: "TMPL Fitness Clubs",
      "Create Date": "2024-09-20 10:40",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "New York",
      "State/Region": "NY",
      "Postal Code": "",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-74.00597, 40.71427],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "LGBTQIA+", "Female Only", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "tmpl_fitness_clubs_new_york",
      images: [
        "https://live.staticflickr.com/65535/54020063483_75fb2fc9a5.jpg",
      ],
      name: "TMPL Fitness Clubs",
      location: "New York, NY, United States",
    },
    {
      "Record ID": "23156883293",
      company_name: "Absolute Power Gym",
      "Create Date": "2024-09-20 10:42",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "New York",
      "State/Region": "NY",
      "Postal Code": "",
      "Country/Region": "",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "Recreational Facilities and Services",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "2.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-73.9708, 40.68295],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "LGBTQIA+", "Machines", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "absolute_power_gym_new_york",
      images: [
        "https://live.staticflickr.com/65535/54019829426_097e8cae1b.jpg",
      ],
      name: "Absolute Power Gym",
      location: "New York, NY, ",
    },
    {
      "Record ID": "23157447302",
      company_name: "Mid City Gym Health & Fitness",
      "Create Date": "2024-09-20 10:45",
      Type: "",
      "Street Address": "345 West 42nd Street",
      "Street Address 2": "",
      city: "New York",
      "State/Region": "NY",
      "Postal Code": "10036",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "2.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-73.991580406, 40.7581672850001],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Pool", "Weights", "Special"],
      rating: 5,
      reviews: 0,
      id: "mid_city_gym_health_fitness_new_york",
      images: [
        "https://live.staticflickr.com/65535/54020272965_f5a4eff119.jpg",
      ],
      name: "Mid City Gym Health & Fitness",
      location: "New York, NY, United States",
    },
    {
      "Record ID": "23157447939",
      company_name: "Solace New York",
      "Create Date": "2024-09-20 10:55",
      Type: "",
      "Street Address": "38 East 32nd Street",
      "Street Address 2": "",
      city: "New York",
      "State/Region": "NY",
      "Postal Code": "10016",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-73.983080808, 40.7460306520001],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "LGBTQIA+", "Courts", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "solace_new_york_new_york",
      images: [
        "https://live.staticflickr.com/65535/54020272915_4f03be8369.jpg",
      ],
      name: "Solace New York",
      location: "New York, NY, United States",
    },
    {
      "Record ID": "23156884095",
      company_name: "StuyFitness",
      "Create Date": "2024-09-20 10:57",
      Type: "",
      "Street Address": "252 1st Avenue",
      "Street Address 2": "",
      city: "New York",
      "State/Region": "NY",
      "Postal Code": "10009",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Real Estate",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-73.982109, 40.731686],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Courts", "Pool", "Weights"],
      rating: 5,
      reviews: 0,
      id: "stuyfitness_new_york",
      images: [
        "https://live.staticflickr.com/65535/54020063408_453b0dc17e.jpg",
      ],
      name: "StuyFitness",
      location: "New York, NY, United States",
    },
    {
      "Record ID": "23157448751",
      company_name: "Boss NYC",
      "Create Date": "2024-09-20 11:08",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "New York",
      "State/Region": "NY",
      "Postal Code": "",
      "Country/Region": "",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "2.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-73.9708, 40.68295],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Pool", "Female Only", "Courts"],
      rating: 5,
      reviews: 0,
      id: "boss_nyc_new_york",
      images: [
        "https://live.staticflickr.com/65535/54019829341_61bb44fd7f.jpg",
      ],
      name: "Boss NYC",
      location: "New York, NY, ",
    },
    {
      "Record ID": "23156885113",
      company_name: "GYM NYC",
      "Create Date": "2024-09-20 11:12",
      Type: "",
      "Street Address": "227 Mulberry Street",
      "Street Address 2": "",
      city: "New York",
      "State/Region": "NY",
      "Postal Code": "10012",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-73.996247, 40.722572],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Cardio", "Courts", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "gym_nyc_new_york",
      images: [
        "https://live.staticflickr.com/65535/54020272910_afba93717d.jpg",
      ],
      name: "GYM NYC",
      location: "New York, NY, United States",
    },
    {
      "Record ID": "23159961628",
      company_name: "MPHC",
      "Create Date": "2024-09-20 11:26",
      Type: "",
      "Street Address": "482 West 43rd Street",
      "Street Address 2": "",
      city: "New York",
      "State/Region": "NY",
      "Postal Code": "10036",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 212-563-7001",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-73.994728347, 40.7599469990001],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Accessibility", "Female Only", "Machines"],
      rating: 5,
      reviews: 0,
      id: "mphc_new_york",
      images: [
        "https://live.staticflickr.com/65535/54019829356_a9aa9186d2.jpg",
      ],
      name: "MPHC",
      location: "New York, NY, United States",
    },
    {
      "Record ID": "23157450031",
      company_name: "The Live Well Company",
      "Create Date": "2024-09-20 11:27",
      Type: "",
      "Street Address": "256 West Street",
      "Street Address 2": "",
      city: "New York",
      "State/Region": "NY",
      "Postal Code": "10013",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 212-431-5752",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-74.011394592, 40.722556065],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Accessibility", "Special", "Machines"],
      rating: 5,
      reviews: 0,
      id: "the_live_well_company_new_york",
      images: [
        "https://live.staticflickr.com/65535/54018931977_72e2489b26.jpg",
      ],
      name: "The Live Well Company",
      location: "New York, NY, United States",
    },
    {
      "Record ID": "23157450085",
      company_name: "Chelsea Piers Fitness",
      "Create Date": "2024-09-20 11:28",
      Type: "",
      "Street Address": "62 Chelsea Piers",
      "Street Address 2": "300",
      city: "New York",
      "State/Region": "NY",
      "Postal Code": "10011",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 212-336-6800",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-74.0086414713825, 40.748247784075],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "LGBTQIA+", "Cardio", "Weights"],
      rating: 5,
      reviews: 0,
      id: "chelsea_piers_fitness_new_york",
      images: [
        "https://live.staticflickr.com/65535/54020163414_c1a81b0ed8.jpg",
      ],
      name: "Chelsea Piers Fitness",
      location: "New York, NY, United States",
    },
    {
      "Record ID": "23157450147",
      company_name: "SkyHealth NYC",
      "Create Date": "2024-09-20 11:29",
      Type: "",
      "Street Address": "251 5th Avenue",
      "Street Address 2": "7th Floor",
      city: "New York",
      "State/Region": "NY",
      "Postal Code": "10016",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-73.9870509529999, 40.744712082],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "LGBTQIA+", "Pool", "Weights"],
      rating: 5,
      reviews: 0,
      id: "skyhealth_nyc_new_york",
      images: [
        "https://live.staticflickr.com/65535/54020272820_b73e478925.jpg",
      ],
      name: "SkyHealth NYC",
      location: "New York, NY, United States",
    },
    {
      "Record ID": "23159961931",
      company_name: "CompleteBody",
      "Create Date": "2024-09-20 11:30",
      Type: "",
      "Street Address": "10 Hanover Square",
      "Street Address 2": "",
      city: "New York",
      "State/Region": "NY",
      "Postal Code": "10005",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 212-777-7705",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-74.0088295, 40.704555],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Cardio", "Courts", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "completebody_new_york",
      images: [
        "https://live.staticflickr.com/65535/54020272810_99fd99817f.jpg",
      ],
      name: "CompleteBody",
      location: "New York, NY, United States",
    },
    {
      "Record ID": "23159961987",
      company_name: "Mercedes Club",
      "Create Date": "2024-09-20 11:32",
      Type: "",
      "Street Address": "550 West 54th Street",
      "Street Address 2": "",
      city: "New York",
      "State/Region": "NY",
      "Postal Code": "10019",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-73.9913999239999, 40.7676743650001],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Accessibility", "Machines", "Pool"],
      rating: 5,
      reviews: 0,
      id: "mercedes_club_new_york",
      images: [
        "https://live.staticflickr.com/65535/54019829261_12e40f29ae.jpg",
      ],
      name: "Mercedes Club",
      location: "New York, NY, United States",
    },
    {
      "Record ID": "23157450360",
      company_name: "Monterey Health Club",
      "Create Date": "2024-09-20 11:33",
      Type: "",
      "Street Address": "175 East 96th Street",
      "Street Address 2": "",
      city: "New York",
      "State/Region": "NY",
      "Postal Code": "10128",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 212-996-8200",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "0.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-73.949754438, 40.7855711390001],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Courts", "Powerlifting", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "monterey_health_club_new_york",
      images: [
        "https://live.staticflickr.com/65535/54019829251_f3343e119a.jpg",
      ],
      name: "Monterey Health Club",
      location: "New York, NY, United States",
    },
    {
      "Record ID": "23159962386",
      company_name: "GYM U NYC",
      "Create Date": "2024-09-20 11:37",
      Type: "",
      "Street Address": "215 West 23rd Street",
      "Street Address 2": "",
      city: "New York",
      "State/Region": "NY",
      "Postal Code": "10011",
      "Country/Region": "United States",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-73.996200788, 40.7445026250001],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Cardio", "Pool", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "gym_u_nyc_new_york",
      images: [
        "https://live.staticflickr.com/65535/54018931902_5aa1e142ce.jpg",
      ],
      name: "GYM U NYC",
      location: "New York, NY, United States",
    },
    {
      "Record ID": "23159962528",
      company_name: "OUT FIT",
      "Create Date": "2024-09-20 11:40",
      Type: "",
      "Street Address": "250 West 26th Street",
      "Street Address 2": "2nd Floor",
      city: "New York",
      "State/Region": "NY",
      "Postal Code": "10001",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "2.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-73.9961852549999, 40.746704569],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Cardio", "Courts", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "out_fit_new_york",
      images: [
        "https://live.staticflickr.com/65535/54020163339_b84ba7cb35.jpg",
      ],
      name: "OUT FIT",
      location: "New York, NY, United States",
    },
    {
      "Record ID": "23159962776",
      company_name: "Gotham Gym",
      "Create Date": "2024-09-20 11:44",
      Type: "",
      "Street Address": "600 Washington Street",
      "Street Address 2": "",
      city: "New York",
      "State/Region": "NY",
      "Postal Code": "10014",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 646-490-8500",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "2.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-74.0091711869999, 40.7306189880001],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Weights", "LGBTQIA+", "Pool"],
      rating: 5,
      reviews: 0,
      id: "gotham_gym_new_york",
      images: [
        "https://live.staticflickr.com/65535/54020063208_65fa3c76d4.jpg",
      ],
      name: "Gotham Gym",
      location: "New York, NY, United States",
    },
    {
      "Record ID": "23168982334",
      company_name: "New York Sports Clubs",
      "Create Date": "2024-09-23 10:27",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "New York",
      "State/Region": "NY",
      "Postal Code": "",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-74.00597, 40.71427],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "LGBTQIA+", "Powerlifting", "Machines"],
      rating: 5,
      reviews: 0,
      id: "new_york_sports_clubs_new_york",
      images: [
        "https://live.staticflickr.com/65535/54018931882_79d8c26c6f.jpg",
      ],
      name: "New York Sports Clubs",
      location: "New York, NY, United States",
    },
  ],
  Cellar: [
    {
      "Record ID": "23157446962",
      company_name: "Presher Fitness",
      "Create Date": "2024-09-20 10:37",
      Type: "",
      "Street Address": "123 West 18th Street",
      "Street Address 2": "",
      city: "Cellar",
      "State/Region": "NY",
      "Postal Code": "10011",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 646-678-5060",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "0.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-73.9959778369999, 40.740375283],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Accessibility", "LGBTQIA+", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "presher_fitness_cellar",
      images: [
        "https://live.staticflickr.com/65535/53651668535_57703631f6.jpg",
      ],
      name: "Presher Fitness",
      location: "Cellar, NY, United States",
    },
  ],
  BROOKLYN: [
    {
      "Record ID": "23159962426",
      company_name: "Brooklyn Athletic Club",
      "Create Date": "2024-09-20 11:38",
      Type: "",
      "Street Address": "8 Berry Street",
      "Street Address 2": "",
      city: "BROOKLYN",
      "State/Region": "NY",
      "Postal Code": "11249",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 718-486-6929",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-73.9553214776786, 40.722358],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Accessibility", "Female Only", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "brooklyn_athletic_club_brooklyn",
      images: [
        "https://live.staticflickr.com/65535/54073676565_0a9d8498a0.jpg",
      ],
      name: "Brooklyn Athletic Club",
      location: "BROOKLYN, NY, United States",
    },
    {
      "Record ID": "23159962968",
      company_name: "NYC Fitness Club",
      "Create Date": "2024-09-20 11:47",
      Type: "",
      "Street Address": "4930 20th Avenue",
      "Street Address 2": "",
      city: "BROOKLYN",
      "State/Region": "NY",
      "Postal Code": "11204",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-73.9769589666667, 40.625408],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Cardio", "Accessibility", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "nyc_fitness_club_brooklyn",
      images: [
        "https://live.staticflickr.com/65535/54071435059_895f4ff1de.jpg",
      ],
      name: "NYC Fitness Club",
      location: "BROOKLYN, NY, United States",
    },
    {
      "Record ID": "23157451420",
      company_name: "Green Fitness Studio",
      "Create Date": "2024-09-20 11:49",
      Type: "",
      "Street Address": "232 Varet Street",
      "Street Address 2": "",
      city: "BROOKLYN",
      "State/Region": "NY",
      "Postal Code": "11206",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 347-599-0663",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-73.934187357, 40.703946624],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Courts", "Special", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "green_fitness_studio_brooklyn",
      images: [
        "https://live.staticflickr.com/65535/54007830009_fb31bf696a.jpg",
      ],
      name: "Green Fitness Studio",
      location: "BROOKLYN, NY, United States",
    },
    {
      "Record ID": "23169012364",
      company_name: "South Brooklyn Weightlifting Club",
      "Create Date": "2024-09-21 18:59",
      Type: "",
      "Street Address": "267 Douglass Street",
      "Street Address 2": "1",
      city: "BROOKLYN",
      "State/Region": "NY",
      "Postal Code": "11217",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-73.984643632, 40.680547765],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "Weights", "Powerlifting", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "south_brooklyn_weightlifting_club_brooklyn",
      images: [
        "https://live.staticflickr.com/65535/53816193272_2623504d9f.jpg",
      ],
      name: "South Brooklyn Weightlifting Club",
      location: "BROOKLYN, NY, United States",
    },
  ],
  Lumberton: [
    {
      "Record ID": "23159963575",
      company_name: "Big Rich Fitness",
      "Create Date": "2024-09-20 11:57",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Lumberton",
      "State/Region": "NJ",
      "Postal Code": "",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 609-265-8938",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-74.80516, 39.96595],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Accessibility", "Powerlifting", "Weights"],
      rating: 5,
      reviews: 0,
      id: "big_rich_fitness_lumberton",
      images: ["https://live.staticflickr.com/1924/31169663368_d36b582e0f.jpg"],
      name: "Big Rich Fitness",
      location: "Lumberton, NJ, United States",
    },
  ],
  North_Brunswick_Township: [
    {
      "Record ID": "23157452060",
      company_name: "Diesel Gym",
      "Create Date": "2024-09-20 11:59",
      Type: "",
      "Street Address": "1485 Livingston Avenue",
      "Street Address 2": "",
      city: "North Brunswick Township",
      "State/Region": "NJ",
      "Postal Code": "8902",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 732-940-0997",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-74.4819740817749, 40.4600005],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Courts", "LGBTQIA+", "Special"],
      rating: 5,
      reviews: 0,
      id: "diesel_gym_north_brunswick_township",
      images: [null],
      name: "Diesel Gym",
      location: "North Brunswick Township, NJ, United States",
    },
  ],
  Hoboken: [
    {
      "Record ID": "23159963757",
      company_name: "Xcel Sports and Fitness",
      "Create Date": "2024-09-20 12:00",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Hoboken",
      "State/Region": "NJ",
      "Postal Code": "7030",
      "Country/Region": "",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-74.03236, 40.74399],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Machines", "Cardio", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "xcel_sports_and_fitness_hoboken",
      images: [
        "https://live.staticflickr.com/65535/48164556071_b01f4a2772.jpg",
      ],
      name: "Xcel Sports and Fitness",
      location: "Hoboken, NJ, ",
    },
    {
      "Record ID": "23157452149",
      company_name: "M.O.V.E. NJ",
      "Create Date": "2024-09-20 12:02",
      Type: "",
      "Street Address": "909 Jefferson Street",
      "Street Address 2": "",
      city: "Hoboken",
      "State/Region": "NJ",
      "Postal Code": "7030",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 201-208-0802",
      Industry: "Health, Wellness and Fitness",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-74.0350036621094, 40.7485885620117],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Courts", "Pool", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "m_o_v_e_nj_hoboken",
      images: ["https://live.staticflickr.com/4176/34277222441_c55b4c7a06.jpg"],
      name: "M.O.V.E. NJ",
      location: "Hoboken, NJ, United States",
    },
  ],
  Irvine: [
    {
      "Record ID": "23159963802",
      company_name: "Esporta Fitness",
      "Create Date": "2024-09-20 12:01",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Irvine",
      "State/Region": "CA",
      "Postal Code": "92619",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "+1 844-359-9398",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-117.82311, 33.66946],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Accessibility", "Special", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "esporta_fitness_irvine",
      images: [
        "https://live.staticflickr.com/65535/52662956561_274e2800dc.jpg",
      ],
      name: "Esporta Fitness",
      location: "Irvine, CA, United States",
    },
  ],
  Jersey_city: [
    {
      "Record ID": "23157452107",
      company_name: "Heights Fitness",
      "Create Date": "2024-09-20 12:01",
      Type: "",
      "Street Address": "338 Central Avenue",
      "Street Address 2": "",
      city: "Jersey City",
      "State/Region": "NJ",
      "Postal Code": "7307",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-74.0488834044423, 40.7464469042319],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Cardio", "LGBTQIA+", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "heights_fitness_jersey_city",
      images: [
        "https://live.staticflickr.com/65535/53718334341_f5aea96de2.jpg",
      ],
      name: "Heights Fitness",
      location: "Jersey City, NJ, United States",
    },
  ],
  Brick_Township: [
    {
      "Record ID": "23159963973",
      company_name: "Pure Fitness Sports Club",
      "Create Date": "2024-09-20 12:03",
      Type: "",
      "Street Address": "2055 New Jersey 88",
      "Street Address 2": "",
      city: "Brick Township",
      "State/Region": "NJ",
      "Postal Code": "8724",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 732-840-5393",
      Industry: "Sporting Goods",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-74.107527, 40.075088],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Female Only", "Courts", "Special"],
      rating: 5,
      reviews: 0,
      id: "pure_fitness_sports_club_brick_township",
      images: [null],
      name: "Pure Fitness Sports Club",
      location: "Brick Township, NJ, United States",
    },
  ],
  Highland_Park: [
    {
      "Record ID": "23157452323",
      company_name: "McKenna's Gym",
      "Create Date": "2024-09-20 12:04",
      Type: "",
      "Street Address": "407 Raritan Avenue",
      "Street Address 2": "",
      city: "Highland Park",
      "State/Region": "NJ",
      "Postal Code": "8904",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-74.4259551851914, 40.4999162642115],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Female Only", "Courts", "Special"],
      rating: 5,
      reviews: 0,
      id: "mckenna_s_gym_highland_park",
      images: [
        "https://live.staticflickr.com/65535/53830422283_54db896909.jpg",
      ],
      name: "McKenna's Gym",
      location: "Highland Park, NJ, United States",
    },
  ],
  Cranford: [
    {
      "Record ID": "23157452351",
      company_name: "King Strength & Performance",
      "Create Date": "2024-09-20 12:05",
      Type: "",
      "Street Address": "8 Eastman St",
      "Street Address 2": "3",
      city: "Cranford",
      "State/Region": "NJ",
      "Postal Code": "7016",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "4.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-74.3052862192408, 40.6561321491426],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Special", "Weights", "Machines"],
      rating: 5,
      reviews: 0,
      id: "king_strength_performance_cranford",
      images: [
        "https://live.staticflickr.com/65535/52246790654_387760228a.jpg",
      ],
      name: "King Strength & Performance",
      location: "Cranford, NJ, United States",
    },
  ],
  Hanover: [
    {
      "Record ID": "23157452384",
      company_name: "IRON CULTURE",
      "Create Date": "2024-09-20 12:06",
      Type: "",
      "Street Address": "8 East Frederick Place",
      "Street Address 2": "104",
      city: "Hanover",
      "State/Region": "NJ",
      "Postal Code": "7927",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "2.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-74.0590993142857, 40.730487],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Machines", "Accessibility", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "iron_culture_hanover",
      images: [
        "https://live.staticflickr.com/65535/52977604851_4fb32c09d1.jpg",
      ],
      name: "IRON CULTURE",
      location: "Hanover, NJ, United States",
    },
  ],
  Hackensack: [
    {
      "Record ID": "23159964187",
      company_name: "Femme",
      "Create Date": "2024-09-20 12:07",
      Type: "",
      "Street Address": "34 Commerce Way",
      "Street Address 2": "",
      city: "Hackensack",
      "State/Region": "NJ",
      "Postal Code": "7601",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 201-549-5200",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-74.0320255, 40.91091],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Female Only", "Powerlifting", "Weights"],
      rating: 5,
      reviews: 0,
      id: "femme_hackensack",
      images: ["https://live.staticflickr.com/5115/14017732927_f3e79a696d.jpg"],
      name: "Femme",
      location: "Hackensack, NJ, United States",
    },
  ],
  Bloomfield: [
    {
      "Record ID": "23159964167",
      company_name: "RISE FITNESS",
      "Create Date": "2024-09-20 12:07",
      Type: "",
      "Street Address": "53 Farrand Street",
      "Street Address 2": "",
      city: "Bloomfield",
      "State/Region": "NJ",
      "Postal Code": "7003",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-68.6910340285548, 45.6609125],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Weights", "Cardio", "Special"],
      rating: 5,
      reviews: 0,
      id: "rise_fitness_bloomfield",
      images: [
        "https://live.staticflickr.com/65535/53360152887_b6b6a22cc4.jpg",
      ],
      name: "RISE FITNESS",
      location: "Bloomfield, NJ, United States",
    },
  ],
  Wayne: [
    {
      "Record ID": "23159964247",
      company_name: "Jersey Barbell",
      "Create Date": "2024-09-20 12:08",
      Type: "",
      "Street Address": "491 U.S. 46",
      "Street Address 2": "",
      city: "Wayne",
      "State/Region": "NJ",
      "Postal Code": "7470",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 973-200-0680",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.410897430147, 37.7969565],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Special", "Courts", "Weights"],
      rating: 5,
      reviews: 0,
      id: "jersey_barbell_wayne",
      images: [
        "https://live.staticflickr.com/65535/54036912368_1f30accb89.jpg",
      ],
      name: "Jersey Barbell",
      location: "Wayne, NJ, United States",
    },
  ],
  Butler: [
    {
      "Record ID": "23159964208",
      company_name: "Innovation Fitness Solutions",
      "Create Date": "2024-09-20 12:08",
      Type: "",
      "Street Address": "1574 New Jersey 23",
      "Street Address 2": "",
      city: "Butler",
      "State/Region": "NJ",
      "Postal Code": "7405",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-75.510855, 39.6605755],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Special", "Cardio", "Machines"],
      rating: 5,
      reviews: 0,
      id: "innovation_fitness_solutions_butler",
      images: [
        "https://live.staticflickr.com/65535/53711137493_6396d30c9f.jpg",
      ],
      name: "Innovation Fitness Solutions",
      location: "Butler, NJ, United States",
    },
  ],
  Edgewater: [
    {
      "Record ID": "23157452603",
      company_name: "Fitness Factory",
      "Create Date": "2024-09-20 12:10",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Edgewater",
      "State/Region": "NJ",
      "Postal Code": "7020",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "2.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.202589, 37.735804],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Cardio", "Female Only", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "fitness_factory_edgewater",
      images: [
        "https://live.staticflickr.com/65535/53787687921_78d221f4c4.jpg",
      ],
      name: "Fitness Factory",
      location: "Edgewater, NJ, United States",
    },
  ],
  North_Bergen: [
    {
      "Record ID": "23157452672",
      company_name: "Iron Arena Powerlifting & Performance",
      "Create Date": "2024-09-20 12:12",
      Type: "",
      "Street Address": "4201 Tonnele Avenue",
      "Street Address 2": "",
      city: "North Bergen",
      "State/Region": "NJ",
      "Postal Code": "7047",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-74.066055, 40.729235],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Courts", "Powerlifting", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "iron_arena_powerlifting_performance_north_bergen",
      images: ["https://live.staticflickr.com/2927/32774889911_76973b1f7e.jpg"],
      name: "Iron Arena Powerlifting & Performance",
      location: "North Bergen, NJ, United States",
    },
  ],
  Lawrenceville: [
    {
      "Record ID": "23159964502",
      company_name: "NJAC",
      "Create Date": "2024-09-20 12:13",
      Type: "",
      "Street Address": "4152 Quakerbridge Rd",
      "Street Address 2": "",
      city: "Lawrenceville",
      "State/Region": "NJ",
      "Postal Code": "8648",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-74.677554, 40.288587],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Pool", "Machines", "Weights"],
      rating: 5,
      reviews: 0,
      id: "njac_lawrenceville",
      images: ["https://live.staticflickr.com/7896/39642350743_c3482181ee.jpg"],
      name: "NJAC",
      location: "Lawrenceville, NJ, United States",
    },
  ],
  COLTS_NECK: [
    {
      "Record ID": "23159964561",
      company_name: "Aspire Fitness",
      "Create Date": "2024-09-20 12:14",
      Type: "",
      "Street Address": "273 Highway 34",
      "Street Address 2": "",
      city: "COLTS NECK",
      "State/Region": "NJ",
      "Postal Code": "7722",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 732-303-1416",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-74.9655422749584, 39.7462952924328],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Powerlifting", "LGBTQIA+", "Courts"],
      rating: 5,
      reviews: 0,
      id: "aspire_fitness_colts_neck",
      images: ["https://live.staticflickr.com/8176/7951822968_4863072597.jpg"],
      name: "Aspire Fitness",
      location: "COLTS NECK, NJ, United States",
    },
  ],
  Howell_Township: [
    {
      "Record ID": "23157452808",
      company_name: "IRON REVOLUTION",
      "Create Date": "2024-09-20 12:14",
      Type: "",
      "Street Address": "2234 U.S. 9",
      "Street Address 2": "",
      city: "Howell Township",
      "State/Region": "NJ",
      "Postal Code": "7731",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 732-414-6767",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-75.1215633161262, 40.7296585],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Pool", "Accessibility", "Machines"],
      rating: 5,
      reviews: 0,
      id: "iron_revolution_howell_township",
      images: [null],
      name: "IRON REVOLUTION",
      location: "Howell Township, NJ, United States",
    },
  ],
  Newark: [
    {
      "Record ID": "23159964686",
      company_name: "Evolution Fitness",
      "Create Date": "2024-09-20 12:15",
      Type: "",
      "Street Address": "108 Monroe Street",
      "Street Address 2": "",
      city: "Newark",
      "State/Region": "NJ",
      "Postal Code": "7105",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 973-732-4343",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-74.1602022574532, 40.728930809404],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Machines", "Weights", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "evolution_fitness_newark",
      images: [
        "https://live.staticflickr.com/65535/53601118173_2a979af7df.jpg",
      ],
      name: "Evolution Fitness",
      location: "Newark, NJ, United States",
    },
  ],
  Eatontown: [
    {
      "Record ID": "23159964718",
      company_name: "The Pit Gym",
      "Create Date": "2024-09-20 12:16",
      Type: "",
      "Street Address": "749 Hope Road",
      "Street Address 2": "a",
      city: "Eatontown",
      "State/Region": "NJ",
      "Postal Code": "7724",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-74.0749999259565, 40.2779475],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "LGBTQIA+", "Pool", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "the_pit_gym_eatontown",
      images: [null],
      name: "The Pit Gym",
      location: "Eatontown, NJ, United States",
    },
  ],
  Portslade: [
    {
      "Record ID": "23157452970",
      company_name: "Underground Gym",
      "Create Date": "2024-09-20 12:18",
      Type: "",
      "Street Address": "8 Camden Street",
      "Street Address 2": "",
      city: "Portslade",
      "State/Region": "Brighton and Hove",
      "Postal Code": "BN41 1AW",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "5.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-0.13947, 50.82838],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Accessibility", "LGBTQIA+", "Special"],
      rating: 5,
      reviews: 0,
      id: "underground_gym_portslade",
      images: ["https://live.staticflickr.com/7309/28200642741_99540e27ac.jpg"],
      name: "Underground Gym",
      location: "Portslade, Brighton and Hove, United Kingdom",
    },
  ],
  Edison: [
    {
      "Record ID": "23159964814",
      company_name: "Apollon Gym",
      "Create Date": "2024-09-20 12:19",
      Type: "",
      "Street Address": "160 Talmadge Road",
      "Street Address 2": "",
      city: "Edison",
      "State/Region": "NJ",
      "Postal Code": "8817",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 732-985-8576",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-74.3954270058082, 40.5351035],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Special", "Machines", "Pool"],
      rating: 5,
      reviews: 0,
      id: "apollon_gym_edison",
      images: [
        "https://live.staticflickr.com/65535/53985425218_5b32ce2b28.jpg",
      ],
      name: "Apollon Gym",
      location: "Edison, NJ, United States",
    },
  ],
  Maplewood: [
    {
      "Record ID": "23157453150",
      company_name: "The Gym Maplewood",
      "Create Date": "2024-09-20 12:20",
      Type: "",
      "Street Address": "1933 Springfield Avenue",
      "Street Address 2": "",
      city: "Maplewood",
      "State/Region": "NJ",
      "Postal Code": "7040",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 866-843-4966",
      Industry: "Education Management",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "3.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-74.2726821899414, 40.7200508117676],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Machines", "LGBTQIA+", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "the_gym_maplewood_maplewood",
      images: [
        "https://live.staticflickr.com/65535/53709552587_003056f560.jpg",
      ],
      name: "The Gym Maplewood",
      location: "Maplewood, NJ, United States",
    },
    {
      "Record ID": "23169012498",
      company_name: "American Strength Training Center",
      "Create Date": "2024-09-21 19:16",
      Type: "",
      "Street Address": "1636 Gervais Avenue",
      "Street Address 2": "13",
      city: "Maplewood",
      "State/Region": "MN",
      "Postal Code": "55109",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-93.031198, 45.012455],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "Powerlifting", "Cardio", "Special"],
      rating: 5,
      reviews: 0,
      id: "american_strength_training_center_maplewood",
      images: [
        "https://live.staticflickr.com/65535/51897735028_54899297ed.jpg",
      ],
      name: "American Strength Training Center",
      location: "Maplewood, MN, United States",
    },
  ],
  Darlinghurst: [
    {
      "Record ID": "23168949870",
      company_name: "City Gym",
      "Create Date": "2024-09-21 18:01",
      Type: "",
      "Street Address": "113 Crown Street",
      "Street Address 2": "107",
      city: "Darlinghurst",
      "State/Region": "New South Wales",
      "Postal Code": "2010",
      "Country/Region": "Australia",
      "Time Zone": "Australia/Sydney",
      "Phone Number": "+61 2 9360 6247",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "2.0",
      "Company owner": "Jordan Robinson",
      coordinates: [151.215858, -33.875075],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Special", "Powerlifting", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "city_gym_darlinghurst",
      images: [
        "https://live.staticflickr.com/65535/53001954837_4c45cdbee5.jpg",
      ],
      name: "City Gym",
      location: "Darlinghurst, New South Wales, Australia",
    },
    {
      "Record ID": "23169012050",
      company_name: "98 Gym",
      "Create Date": "2024-09-21 18:06",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Darlinghurst",
      "State/Region": "New South Wales",
      "Postal Code": "2010",
      "Country/Region": "Australia",
      "Time Zone": "Australia/Sydney",
      "Phone Number": "+61 402 722 505",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [151.2187, -33.8777],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "LGBTQIA+", "Accessibility", "Machines"],
      rating: 5,
      reviews: 0,
      id: "98_gym_darlinghurst",
      images: [
        "https://live.staticflickr.com/65535/48874507931_2b9f3c48b4.jpg",
      ],
      name: "98 Gym",
      location: "Darlinghurst, New South Wales, Australia",
    },
    {
      "Record ID": "23168950033",
      company_name: "Lift Gym",
      "Create Date": "2024-09-21 18:21",
      Type: "",
      "Street Address": "320 Liverpool Street",
      "Street Address 2": "318",
      city: "Darlinghurst",
      "State/Region": "New South Wales",
      "Postal Code": "2010",
      "Country/Region": "Australia",
      "Time Zone": "Australia/Sydney",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [151.222171, -33.878201],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Powerlifting", "Accessibility", "Machines"],
      rating: 5,
      reviews: 0,
      id: "lift_gym_darlinghurst",
      images: ["https://live.staticflickr.com/1831/43061049054_54e5210040.jpg"],
      name: "Lift Gym",
      location: "Darlinghurst, New South Wales, Australia",
    },
  ],
  Glebe: [
    {
      "Record ID": "23169012042",
      company_name: "King Beats Fitness",
      "Create Date": "2024-09-21 18:05",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Glebe",
      "State/Region": "New South Wales",
      "Postal Code": "2037",
      "Country/Region": "Australia",
      "Time Zone": "Australia/Sydney",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "2.0",
      "Company owner": "Jordan Robinson",
      coordinates: [151.1798, -33.881],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Powerlifting", "Accessibility", "Courts"],
      rating: 5,
      reviews: 0,
      id: "king_beats_fitness_glebe",
      images: [
        "https://live.staticflickr.com/65535/53492639894_b2d4aa6354.jpg",
      ],
      name: "King Beats Fitness",
      location: "Glebe, New South Wales, Australia",
    },
  ],
  Sydney: [
    {
      "Record ID": "23169012077",
      company_name: "Ultimate Fitness Centre",
      "Create Date": "2024-09-21 18:11",
      Type: "",
      "Street Address": "305 Kent Street",
      "Street Address 2": "",
      city: "Sydney",
      "State/Region": "New South Wales",
      "Postal Code": "2000",
      "Country/Region": "Australia",
      "Time Zone": "Australia/Sydney",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [151.204126, -33.867118],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Female Only", "Powerlifting", "Special"],
      rating: 5,
      reviews: 0,
      id: "ultimate_fitness_centre_sydney",
      images: [
        "https://live.staticflickr.com/65535/54059711708_545c572e39.jpg",
      ],
      name: "Ultimate Fitness Centre",
      location: "Sydney, New South Wales, Australia",
    },
  ],
  Surry_Hills: [
    {
      "Record ID": "23168950068",
      company_name: "Hiscoes Fitness Club",
      "Create Date": "2024-09-21 18:27",
      Type: "",
      "Street Address": "525 Crown Street",
      "Street Address 2": "",
      city: "Surry Hills",
      "State/Region": "New South Wales",
      "Postal Code": "2010",
      "Country/Region": "Australia",
      "Time Zone": "Australia/Sydney",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [151.212907, -33.889477],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Cardio", "Weights", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "hiscoes_fitness_club_surry_hills",
      images: ["https://live.staticflickr.com/7841/46385270654_18772e15b2.jpg"],
      name: "Hiscoes Fitness Club",
      location: "Surry Hills, New South Wales, Australia",
    },
  ],
  South_Granville: [
    {
      "Record ID": "23168950082",
      company_name: "Adonis Athletics",
      "Create Date": "2024-09-21 18:29",
      Type: "",
      "Street Address": "40-44 Wellington Road",
      "Street Address 2": "13",
      city: "South Granville",
      "State/Region": "New South Wales",
      "Postal Code": "2142",
      "Country/Region": "Australia",
      "Time Zone": "Australia/Sydney",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [151.011276, -33.864317],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Accessibility", "Special", "Weights"],
      rating: 5,
      reviews: 0,
      id: "adonis_athletics_south_granville",
      images: [null],
      name: "Adonis Athletics",
      location: "South Granville, New South Wales, Australia",
    },
  ],
  Coogee: [
    {
      "Record ID": "23168950087",
      company_name: "Titan Fitness",
      "Create Date": "2024-09-21 18:30",
      Type: "",
      "Street Address": "29-31 Alfreda Street",
      "Street Address 2": "",
      city: "Coogee",
      "State/Region": "New South Wales",
      "Postal Code": "2034",
      "Country/Region": "Australia",
      "Time Zone": "Australia/Sydney",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [151.256039, -33.920218],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Powerlifting", "Machines", "Courts"],
      rating: 5,
      reviews: 0,
      id: "titan_fitness_coogee",
      images: [
        "https://live.staticflickr.com/65535/54059711708_545c572e39.jpg",
      ],
      name: "Titan Fitness",
      location: "Coogee, New South Wales, Australia",
    },
  ],
  San_Francisco: [
    {
      "Record ID": "23169012265",
      company_name: "Sunset Gym",
      "Create Date": "2024-09-21 18:34",
      Type: "",
      "Street Address": "1247 9th Avenue",
      "Street Address 2": "",
      city: "San Francisco",
      "State/Region": "CA",
      "Postal Code": "94122",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.466732882353, 37.765057],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Female Only", "Machines", "Pool"],
      rating: 5,
      reviews: 0,
      id: "sunset_gym_san_francisco",
      images: [
        "https://live.staticflickr.com/65535/54059648577_88db775159.jpg",
      ],
      name: "Sunset Gym",
      location: "San Francisco, CA, United States",
    },
    {
      "Record ID": "23168950103",
      company_name: "Fitness Urbano",
      "Create Date": "2024-09-21 18:35",
      Type: "",
      "Street Address": "80 Missouri Street",
      "Street Address 2": "",
      city: "San Francisco",
      "State/Region": "CA",
      "Postal Code": "94107",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.39712, 37.765472],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Pool", "Machines", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "fitness_urbano_san_francisco",
      images: [
        "https://live.staticflickr.com/65535/54060979115_11626aaf6f.jpg",
      ],
      name: "Fitness Urbano",
      location: "San Francisco, CA, United States",
    },
    {
      "Record ID": "23168950128",
      company_name: "Live Fit Gym",
      "Create Date": "2024-09-21 18:39",
      Type: "",
      "Street Address": "301 Fell Street",
      "Street Address 2": "",
      city: "San Francisco",
      "State/Region": "CA",
      "Postal Code": "94102",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.423004, 37.77581],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Special", "Pool", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "live_fit_gym_san_francisco",
      images: [
        "https://live.staticflickr.com/65535/54060978930_b2875518a4.jpg",
      ],
      name: "Live Fit Gym",
      location: "San Francisco, CA, United States",
    },
    {
      "Record ID": "23169012294",
      company_name: "FITNESS SF",
      "Create Date": "2024-09-21 18:40",
      Type: "",
      "Street Address": "1001 Brannan Street",
      "Street Address 2": "",
      city: "San Francisco",
      "State/Region": "CA",
      "Postal Code": "94103",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "+1 415-348-6377",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.406990275424, 37.769743],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Machines", "LGBTQIA+", "Weights"],
      rating: 5,
      reviews: 0,
      id: "fitness_sf_san_francisco",
      images: [
        "https://live.staticflickr.com/65535/54060978555_287b9ba1d2.jpg",
      ],
      name: "FITNESS SF",
      location: "San Francisco, CA, United States",
    },
    {
      "Record ID": "23168950146",
      company_name: "Eden Fitness Studio",
      "Create Date": "2024-09-21 18:43",
      Type: "",
      "Street Address": "1650 Bush Street",
      "Street Address 2": "",
      city: "San Francisco",
      "State/Region": "CA",
      "Postal Code": "94109",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.42439, 37.788357],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "Special", "Cardio", "Weights"],
      rating: 5,
      reviews: 0,
      id: "eden_fitness_studio_san_francisco",
      images: [
        "https://live.staticflickr.com/65535/54060526381_e9b78e79ac.jpg",
      ],
      name: "Eden Fitness Studio",
      location: "San Francisco, CA, United States",
    },
    {
      "Record ID": "23169012315",
      company_name: "fit LOCAL fit",
      "Create Date": "2024-09-21 18:45",
      Type: "",
      "Street Address": "701 Cortland Avenue",
      "Street Address 2": "",
      city: "San Francisco",
      "State/Region": "CA",
      "Postal Code": "94110",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.415125926413, 37.739091],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "Accessibility", "Special", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "fit_local_fit_san_francisco",
      images: [
        "https://live.staticflickr.com/65535/54060977630_1367247463.jpg",
      ],
      name: "fit LOCAL fit",
      location: "San Francisco, CA, United States",
    },
    {
      "Record ID": "23168950161",
      company_name: "CUSTOM FIT",
      "Create Date": "2024-09-21 18:46",
      Type: "",
      "Street Address": "323 Octavia Street",
      "Street Address 2": "",
      city: "San Francisco",
      "State/Region": "CA",
      "Postal Code": "94102",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "2.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.4246, 37.775017],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "Pool", "LGBTQIA+", "Courts"],
      rating: 5,
      reviews: 0,
      id: "custom_fit_san_francisco",
      images: [
        "https://live.staticflickr.com/65535/54060771193_9c0e55fe5a.jpg",
      ],
      name: "CUSTOM FIT",
      location: "San Francisco, CA, United States",
    },
    {
      "Record ID": "23169012317",
      company_name: "The Culture SF",
      "Create Date": "2024-09-21 18:46",
      Type: "",
      "Street Address": "2255 Clement Street",
      "Street Address 2": "",
      city: "San Francisco",
      "State/Region": "CA",
      "Postal Code": "94121",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "+1 415-786-7195",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.48329, 37.78199],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Weights", "Female Only", "Machines"],
      rating: 5,
      reviews: 0,
      id: "the_culture_sf_san_francisco",
      images: [
        "https://live.staticflickr.com/65535/54060524541_e54225754f.jpg",
      ],
      name: "The Culture SF",
      location: "San Francisco, CA, United States",
    },
    {
      "Record ID": "23169012322",
      company_name: "Muscle Beach SF",
      "Create Date": "2024-09-21 18:47",
      Type: "",
      "Street Address": "710 La Playa Street",
      "Street Address 2": "",
      city: "San Francisco",
      "State/Region": "CA",
      "Postal Code": "94121",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.509796, 37.774536],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Weights", "Courts", "Special"],
      rating: 5,
      reviews: 0,
      id: "muscle_beach_sf_san_francisco",
      images: [
        "https://live.staticflickr.com/65535/54060524441_8dd82a766b.jpg",
      ],
      name: "Muscle Beach SF",
      location: "San Francisco, CA, United States",
    },
    {
      "Record ID": "23169012326",
      company_name: "COR Fitness",
      "Create Date": "2024-09-21 18:48",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "San Francisco",
      "State/Region": "CA",
      "Postal Code": "",
      "Country/Region": "",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.431272, 37.778008],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "Female Only", "Pool", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "cor_fitness_san_francisco",
      images: [
        "https://live.staticflickr.com/65535/54060523666_8a7cf7fe67.jpg",
      ],
      name: "COR Fitness",
      location: "San Francisco, CA, ",
    },
    {
      "Record ID": "23169012329",
      company_name: "LuxFit",
      "Create Date": "2024-09-21 18:49",
      Type: "",
      "Street Address": "432 Octavia Street",
      "Street Address 2": "1c",
      city: "San Francisco",
      "State/Region": "CA",
      "Postal Code": "94102",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.42423, 37.7764],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Powerlifting", "Machines", "Weights"],
      rating: 5,
      reviews: 0,
      id: "luxfit_san_francisco",
      images: [
        "https://live.staticflickr.com/65535/54060849419_58136ae2bf.jpg",
      ],
      name: "LuxFit",
      location: "San Francisco, CA, United States",
    },
    {
      "Record ID": "23169012345",
      company_name: "The Bay Club",
      "Create Date": "2024-09-21 18:53",
      Type: "",
      "Street Address": "1 Lombard St",
      "Street Address 2": "",
      city: "San Francisco",
      "State/Region": "CA",
      "Postal Code": "94111",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "4.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.402466, 37.80385],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "LGBTQIA+", "Accessibility", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "the_bay_club_san_francisco",
      images: [
        "https://live.staticflickr.com/65535/54060974770_133a88cd3b.jpg",
      ],
      name: "The Bay Club",
      location: "San Francisco, CA, United States",
    },
    {
      "Record ID": "23169012617",
      company_name: "Uplift Barbell",
      "Create Date": "2024-09-21 19:55",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "San Francisco",
      "State/Region": "CA",
      "Postal Code": "",
      "Country/Region": "",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.431272, 37.778008],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Accessibility", "LGBTQIA+", "Weights"],
      rating: 5,
      reviews: 0,
      id: "uplift_barbell_san_francisco",
      images: [
        "https://live.staticflickr.com/65535/54060522336_9e481c3171.jpg",
      ],
      name: "Uplift Barbell",
      location: "San Francisco, CA, ",
    },
    {
      "Record ID": "23168950457",
      company_name: "RX Strength",
      "Create Date": "2024-09-21 20:02",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "San Francisco",
      "State/Region": "CA",
      "Postal Code": "",
      "Country/Region": "",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.431272, 37.778008],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Machines", "Accessibility", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "rx_strength_san_francisco",
      images: [
        "https://live.staticflickr.com/65535/54060767868_2f57a33ac4.jpg",
      ],
      name: "RX Strength",
      location: "San Francisco, CA, ",
    },
    {
      "Record ID": "23168984406",
      company_name: "Flagship Athletic Performance",
      "Create Date": "2024-09-23 10:53",
      Type: "",
      "Street Address": "250 Montgomery Street",
      "Street Address 2": "",
      city: "San Francisco",
      "State/Region": "CA",
      "Postal Code": "94104",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Education Management",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.402275, 37.791584],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Machines", "Courts", "Special"],
      rating: 5,
      reviews: 0,
      id: "flagship_athletic_performance_san_francisco",
      images: [
        "https://live.staticflickr.com/65535/54060973955_30a9dc3cd0.jpg",
      ],
      name: "Flagship Athletic Performance",
      location: "San Francisco, CA, United States",
    },
  ],
  Anaheim: [
    {
      "Record ID": "23169012383",
      company_name: "Exceed Performance",
      "Create Date": "2024-09-21 19:05",
      Type: "",
      "Street Address": "2060 South Euclid Street",
      "Street Address 2": "K",
      city: "Anaheim",
      "State/Region": "CA",
      "Postal Code": "92802",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "+1 657-667-6202",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-117.940305861187, 33.796428],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Female Only", "Powerlifting", "Special"],
      rating: 5,
      reviews: 0,
      id: "exceed_performance_anaheim",
      images: [
        "https://live.staticflickr.com/65535/53940885634_bf98ec3359.jpg",
      ],
      name: "Exceed Performance",
      location: "Anaheim, CA, United States",
    },
  ],
  Houston: [
    {
      "Record ID": "23168950302",
      company_name: "Texas Barbell Syndicate",
      "Create Date": "2024-09-21 19:06",
      Type: "",
      "Street Address": "14503 Bammel North Houston Road",
      "Street Address 2": "350",
      city: "Houston",
      "State/Region": "TX",
      "Postal Code": "77014",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-95.4892874070248, 29.988001],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "LGBTQIA+", "Pool", "Courts"],
      rating: 5,
      reviews: 0,
      id: "texas_barbell_syndicate_houston",
      images: [
        "https://live.staticflickr.com/65535/54031244379_3f3cfc4e4d.jpg",
      ],
      name: "Texas Barbell Syndicate",
      location: "Houston, TX, United States",
    },
    {
      "Record ID": "23168956220",
      company_name: "The Preserve",
      "Create Date": "2024-09-22 17:30",
      Type: "",
      "Street Address": "3303 Audley Street",
      "Street Address 2": "",
      city: "Houston",
      "State/Region": "TX",
      "Postal Code": "77098",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "+1 713-572-2555",
      Industry: "Luxury Goods & Jewelry",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-95.4259364690236, 29.7353515],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Pool", "Powerlifting", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "the_preserve_houston",
      images: [
        "https://live.staticflickr.com/65535/53793858441_a791f957f8.jpg",
      ],
      name: "The Preserve",
      location: "Houston, TX, United States",
    },
  ],
  Los_Angeles: [
    {
      "Record ID": "23168950307",
      company_name: "Barbell Brigade",
      "Create Date": "2024-09-21 19:07",
      Type: "",
      "Street Address": "646 Gibbons Street",
      "Street Address 2": "",
      city: "Los Angeles",
      "State/Region": "CA",
      "Postal Code": "90031",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-118.223841863221, 34.0660819983631],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Pool", "Courts", "Special"],
      rating: 5,
      reviews: 0,
      id: "barbell_brigade_los_angeles",
      images: [
        "https://live.staticflickr.com/65535/54072807236_6c8189ff8f.jpg",
      ],
      name: "Barbell Brigade",
      location: "Los Angeles, CA, United States",
    },
    {
      "Record ID": "23168956237",
      company_name: "Los Angeles Athletic Club",
      "Create Date": "2024-09-22 17:32",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Los Angeles",
      "State/Region": "CA",
      "Postal Code": "",
      "Country/Region": "",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-118.25703, 34.05513],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Special", "Accessibility", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "los_angeles_athletic_club_los_angeles",
      images: [
        "https://live.staticflickr.com/65535/54048284881_f315315b59.jpg",
      ],
      name: "Los Angeles Athletic Club",
      location: "Los Angeles, CA, ",
    },
    {
      "Record ID": "23168956990",
      company_name: "Muscle Beach Venice",
      "Create Date": "2024-09-22 19:09",
      Type: "",
      "Street Address": "221 North Figueroa Street",
      "Street Address 2": "",
      city: "Los Angeles",
      "State/Region": "CA",
      "Postal Code": "90012",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Entertainment",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-118.251067772935, 34.0593336914631],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Machines", "Female Only", "Weights"],
      rating: 5,
      reviews: 0,
      id: "muscle_beach_venice_los_angeles",
      images: [
        "https://live.staticflickr.com/65535/53968487913_ee02d77e23.jpg",
      ],
      name: "Muscle Beach Venice",
      location: "Los Angeles, CA, United States",
    },
    {
      "Record ID": "23168958276",
      company_name: "Speakeasy Fitness",
      "Create Date": "2024-09-22 20:54",
      Type: "",
      "Street Address": "7869 Van Nuys Boulevard",
      "Street Address 2": "",
      city: "Los Angeles",
      "State/Region": "CA",
      "Postal Code": "91402",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-118.449440002441, 34.214111328125],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Accessibility", "Special", "Courts"],
      rating: 5,
      reviews: 0,
      id: "speakeasy_fitness_los_angeles",
      images: [
        "https://live.staticflickr.com/65535/53968487903_8e355fe494.jpg",
      ],
      name: "Speakeasy Fitness",
      location: "Los Angeles, CA, United States",
    },
    {
      "Record ID": "23168958298",
      company_name: "Hybrid Gym",
      "Create Date": "2024-09-22 20:56",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Los Angeles",
      "State/Region": "CA",
      "Postal Code": "",
      "Country/Region": "Hong Kong",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "Health, Wellness and Fitness",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [114.15769, 22.28552],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Courts", "Female Only", "Weights"],
      rating: 5,
      reviews: 0,
      id: "hybrid_gym_los_angeles",
      images: [
        "https://live.staticflickr.com/65535/53968245981_2eefaa30fc.jpg",
      ],
      name: "Hybrid Gym",
      location: "Los Angeles, CA, Hong Kong",
    },
    {
      "Record ID": "23169020683",
      company_name: "Attitude Fitness",
      "Create Date": "2024-09-22 20:57",
      Type: "",
      "Street Address": "900 Wilshire Boulevard",
      "Street Address 2": "",
      city: "Los Angeles",
      "State/Region": "CA",
      "Postal Code": "90017",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-118.260238647461, 34.0503997802734],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Machines", "Courts", "Pool"],
      rating: 5,
      reviews: 0,
      id: "attitude_fitness_los_angeles",
      images: [
        "https://live.staticflickr.com/65535/53968577779_a4f1efbe8d.jpg",
      ],
      name: "Attitude Fitness",
      location: "Los Angeles, CA, United States",
    },
    {
      "Record ID": "23168958303",
      company_name: "HEIMAT",
      "Create Date": "2024-09-22 20:57",
      Type: "",
      "Street Address": "192 Brunnenstraße",
      "Street Address 2": "",
      city: "Los Angeles",
      "State/Region": "CA",
      "Postal Code": "10119",
      "Country/Region": "Germany",
      "Time Zone": "Europe/Berlin",
      "Phone Number": "+49 2133 197849",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-118.24368, 34.05223],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Machines", "Accessibility", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "heimat_los_angeles",
      images: [
        "https://live.staticflickr.com/65535/53968577569_4bc1153746.jpg",
      ],
      name: "HEIMAT",
      location: "Los Angeles, CA, Germany",
    },
    {
      "Record ID": "23168958314",
      company_name: "LM Fitness Center",
      "Create Date": "2024-09-22 20:58",
      Type: "",
      "Street Address": "2985 Glendale Boulevard",
      "Street Address 2": "",
      city: "Los Angeles",
      "State/Region": "CA",
      "Postal Code": "90039",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-118.264287570318, 34.1151995163101],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "LGBTQIA+", "Weights", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "lm_fitness_center_los_angeles",
      images: [
        "https://live.staticflickr.com/65535/53967353892_6d0c50acfb.jpg",
      ],
      name: "LM Fitness Center",
      location: "Los Angeles, CA, United States",
    },
    {
      "Record ID": "23168958330",
      company_name: "Easton Gym Co. Hollywood",
      "Create Date": "2024-09-22 20:59",
      Type: "",
      "Street Address": "8053 Beverly Boulevard",
      "Street Address 2": "",
      city: "Los Angeles",
      "State/Region": "CA",
      "Postal Code": "90048",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-118.364991495859, 34.0762534456065],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Accessibility", "Machines", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "easton_gym_co_hollywood_los_angeles",
      images: [
        "https://live.staticflickr.com/65535/53968487603_7f3cffe2b4.jpg",
      ],
      name: "Easton Gym Co. Hollywood",
      location: "Los Angeles, CA, United States",
    },
    {
      "Record ID": "23168958335",
      company_name: "DC Fitness",
      "Create Date": "2024-09-22 21:00",
      Type: "",
      "Street Address": "2301 Bellevue Avenue",
      "Street Address 2": "",
      city: "Los Angeles",
      "State/Region": "CA",
      "Postal Code": "90026",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "+1 213-273-7061",
      Industry: "Individual & Family Services",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-118.270078974146, 34.0746165],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Female Only", "Pool", "Special"],
      rating: 5,
      reviews: 0,
      id: "dc_fitness_los_angeles",
      images: [
        "https://live.staticflickr.com/65535/53966784690_e50437cca4.jpg",
      ],
      name: "DC Fitness",
      location: "Los Angeles, CA, United States",
    },
    {
      "Record ID": "23168958358",
      company_name: "Sanctuary Fitness",
      "Create Date": "2024-09-22 21:01",
      Type: "",
      "Street Address": "718 Jackson Street",
      "Street Address 2": "",
      city: "Los Angeles",
      "State/Region": "CA",
      "Postal Code": "90012",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-118.232424054047, 34.0505265],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Special", "Accessibility", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "sanctuary_fitness_los_angeles",
      images: [
        "https://live.staticflickr.com/65535/53966580223_c084573e91.jpg",
      ],
      name: "Sanctuary Fitness",
      location: "Los Angeles, CA, United States",
    },
    {
      "Record ID": "23168958370",
      company_name: "The Peace Center Gym",
      "Create Date": "2024-09-22 21:02",
      Type: "",
      "Street Address": "4709 Melrose Avenue",
      "Street Address 2": "",
      city: "Los Angeles",
      "State/Region": "CA",
      "Postal Code": "90029",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "0.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-118.303142777273, 34.0836587941697],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Cardio", "Powerlifting", "Courts"],
      rating: 5,
      reviews: 0,
      id: "the_peace_center_gym_los_angeles",
      images: [
        "https://live.staticflickr.com/65535/53966784555_3824e15848.jpg",
      ],
      name: "The Peace Center Gym",
      location: "Los Angeles, CA, United States",
    },
    {
      "Record ID": "23168958363",
      company_name: "The Gym LA",
      "Create Date": "2024-09-22 21:02",
      Type: "",
      "Street Address": "11567 Santa Monica Boulevard",
      "Street Address 2": "",
      city: "Los Angeles",
      "State/Region": "CA",
      "Postal Code": "90025",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-118.453654337687, 34.0449992759364],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Powerlifting", "Weights", "Pool"],
      rating: 5,
      reviews: 0,
      id: "the_gym_la_los_angeles",
      images: [
        "https://live.staticflickr.com/65535/53966326236_55b9970a41.jpg",
      ],
      name: "The Gym LA",
      location: "Los Angeles, CA, United States",
    },
    {
      "Record ID": "23169020782",
      company_name: "HYPE Fitness",
      "Create Date": "2024-09-22 21:03",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Los Angeles",
      "State/Region": "CA",
      "Postal Code": "90027",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "2.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-118.24368, 34.05223],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "LGBTQIA+", "Pool", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "hype_fitness_los_angeles",
      images: [
        "https://live.staticflickr.com/65535/53966326191_56b889e7d4.jpg",
      ],
      name: "HYPE Fitness",
      location: "Los Angeles, CA, United States",
    },
    {
      "Record ID": "23168958386",
      company_name: "Pharos Athletic Club",
      "Create Date": "2024-09-22 21:04",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Los Angeles",
      "State/Region": "CA",
      "Postal Code": "",
      "Country/Region": "",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-118.25703, 34.05513],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "LGBTQIA+", "Pool", "Courts"],
      rating: 5,
      reviews: 0,
      id: "pharos_athletic_club_los_angeles",
      images: [
        "https://live.staticflickr.com/65535/53965443637_5e5ef2cb13.jpg",
      ],
      name: "Pharos Athletic Club",
      location: "Los Angeles, CA, ",
    },
    {
      "Record ID": "23169020812",
      company_name: "Hollywood Gym",
      "Create Date": "2024-09-22 21:05",
      Type: "",
      "Street Address": "1551 North La Brea Avenue",
      "Street Address 2": "",
      city: "Los Angeles",
      "State/Region": "CA",
      "Postal Code": "90028",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-118.344804, 34.099548],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Cardio", "Courts", "Pool"],
      rating: 5,
      reviews: 0,
      id: "hollywood_gym_los_angeles",
      images: [
        "https://live.staticflickr.com/65535/53966784130_fb25c621da.jpg",
      ],
      name: "Hollywood Gym",
      location: "Los Angeles, CA, United States",
    },
    {
      "Record ID": "23169020824",
      company_name: "ICONFIT",
      "Create Date": "2024-09-22 21:06",
      Type: "",
      "Street Address": "12100 West Olympic Boulevard",
      "Street Address 2": "",
      city: "Los Angeles",
      "State/Region": "CA",
      "Postal Code": "90064",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "+1 310-836-8000",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-118.453965101466, 34.0321495366246],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Powerlifting", "Accessibility", "Weights"],
      rating: 5,
      reviews: 0,
      id: "iconfit_los_angeles",
      images: [
        "https://live.staticflickr.com/65535/53966325731_406ffe0dab.jpg",
      ],
      name: "ICONFIT",
      location: "Los Angeles, CA, United States",
    },
    {
      "Record ID": "23168958488",
      company_name: "RODEO ATHLETIC CLUB",
      "Create Date": "2024-09-22 21:07",
      Type: "",
      "Street Address": "2516 Hyperion Avenue",
      "Street Address 2": "",
      city: "Los Angeles",
      "State/Region": "CA",
      "Postal Code": "90027",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-118.272946479573, 34.1046944839827],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Machines", "Courts", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "rodeo_athletic_club_los_angeles",
      images: [
        "https://live.staticflickr.com/65535/53961445901_968658d208.jpg",
      ],
      name: "RODEO ATHLETIC CLUB",
      location: "Los Angeles, CA, United States",
    },
    {
      "Record ID": "23169020840",
      company_name: "Bolder Fitness",
      "Create Date": "2024-09-22 21:08",
      Type: "",
      "Street Address": "8810 Pico Boulevard",
      "Street Address 2": "",
      city: "Los Angeles",
      "State/Region": "CA",
      "Postal Code": "90035",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-118.384142712274, 34.0546799065201],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Powerlifting", "Weights", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "bolder_fitness_los_angeles",
      images: [
        "https://live.staticflickr.com/65535/53961899760_8142c9a965.jpg",
      ],
      name: "Bolder Fitness",
      location: "Los Angeles, CA, United States",
    },
    {
      "Record ID": "23169020853",
      company_name: "STLA Gym",
      "Create Date": "2024-09-22 21:09",
      Type: "",
      "Street Address": "4365 West Pico Boulevard",
      "Street Address 2": "",
      city: "Los Angeles",
      "State/Region": "CA",
      "Postal Code": "90019",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-118.329980760208, 34.0484315],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Machines", "Female Only", "Weights"],
      rating: 5,
      reviews: 0,
      id: "stla_gym_los_angeles",
      images: [
        "https://live.staticflickr.com/65535/53961899665_cf4729f728.jpg",
      ],
      name: "STLA Gym",
      location: "Los Angeles, CA, United States",
    },
    {
      "Record ID": "23169020932",
      company_name: "Ganbatte Fitness",
      "Create Date": "2024-09-22 21:10",
      Type: "",
      "Street Address": "3235 North San Fernando Road",
      "Street Address 2": "bldg 4",
      city: "Los Angeles",
      "State/Region": "CA",
      "Postal Code": "90065",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-118.247833251953, 34.1171684265137],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Pool", "Machines", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "ganbatte_fitness_los_angeles",
      images: [
        "https://live.staticflickr.com/65535/53961691363_55e914cce1.jpg",
      ],
      name: "Ganbatte Fitness",
      location: "Los Angeles, CA, United States",
    },
    {
      "Record ID": "23169020953",
      company_name: "The Gym Venice",
      "Create Date": "2024-09-22 21:11",
      Type: "",
      "Street Address": "12257 Venice Boulevard",
      "Street Address 2": "",
      city: "Los Angeles",
      "State/Region": "CA",
      "Postal Code": "90066",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-118.432403564453, 34.0046501159668],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Cardio", "Female Only", "Pool"],
      rating: 5,
      reviews: 0,
      id: "the_gym_venice_los_angeles",
      images: [
        "https://live.staticflickr.com/65535/53961445551_a7853031fa.jpg",
      ],
      name: "The Gym Venice",
      location: "Los Angeles, CA, United States",
    },
    {
      "Record ID": "23169020944",
      company_name: "Wilfit",
      "Create Date": "2024-09-22 21:11",
      Type: "",
      "Street Address": "3470 Wilshire Boulevard",
      "Street Address 2": "100",
      city: "Los Angeles",
      "State/Region": "CA",
      "Postal Code": "90005",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "+1 213-788-3316",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-118.299896240234, 34.0609283447266],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "LGBTQIA+", "Pool", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "wilfit_los_angeles",
      images: [
        "https://live.staticflickr.com/65535/53960557177_73e2028bf5.jpg",
      ],
      name: "Wilfit",
      location: "Los Angeles, CA, United States",
    },
    {
      "Record ID": "23169020968",
      company_name: "FORTUNE GYM",
      "Create Date": "2024-09-22 21:12",
      Type: "",
      "Street Address": "7574 Sunset Boulevard",
      "Street Address 2": "",
      city: "Los Angeles",
      "State/Region": "CA",
      "Postal Code": "90046",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "2.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-118.354700058104, 34.0978669673125],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Courts", "Special", "Weights"],
      rating: 5,
      reviews: 0,
      id: "fortune_gym_los_angeles",
      images: [
        "https://live.staticflickr.com/65535/53961766804_1a5e6f365a.jpg",
      ],
      name: "FORTUNE GYM",
      location: "Los Angeles, CA, United States",
    },
    {
      "Record ID": "23169020960",
      company_name: "Stronghold Climbing Gym",
      "Create Date": "2024-09-22 21:12",
      Type: "",
      "Street Address": "650 South Avenue 21",
      "Street Address 2": "",
      city: "Los Angeles",
      "State/Region": "CA",
      "Postal Code": "90031",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-118.217174983639, 34.064114],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Pool", "Cardio", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "stronghold_climbing_gym_los_angeles",
      images: [
        "https://live.staticflickr.com/65535/53956227918_9308757e82.jpg",
      ],
      name: "Stronghold Climbing Gym",
      location: "Los Angeles, CA, United States",
    },
    {
      "Record ID": "23168958616",
      company_name: "HOWL Fitness",
      "Create Date": "2024-09-22 21:18",
      Type: "",
      "Street Address": "333 North La Cienega Boulevard",
      "Street Address 2": "",
      city: "Los Angeles",
      "State/Region": "CA",
      "Postal Code": "90048",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "+1 347-831-7681",
      Industry: "Telecommunications",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-118.376795519556, 34.0771783905666],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Pool", "Cardio", "Machines"],
      rating: 5,
      reviews: 0,
      id: "howl_fitness_los_angeles",
      images: [
        "https://live.staticflickr.com/65535/53956428430_b7664d9f90.jpg",
      ],
      name: "HOWL Fitness",
      location: "Los Angeles, CA, United States",
    },
    {
      "Record ID": "23169021049",
      company_name: "Do It Now! Fitness Club",
      "Create Date": "2024-09-22 21:19",
      Type: "",
      "Street Address": "12625 Washington Boulevard",
      "Street Address 2": "",
      city: "Los Angeles",
      "State/Region": "CA",
      "Postal Code": "90066",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-118.434466912921, 33.9976825],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Accessibility", "Machines", "Courts"],
      rating: 5,
      reviews: 0,
      id: "do_it_now_fitness_club_los_angeles",
      images: [
        "https://live.staticflickr.com/65535/53955083747_0d0ec0af9c.jpg",
      ],
      name: "Do It Now! Fitness Club",
      location: "Los Angeles, CA, United States",
    },
    {
      "Record ID": "23169021057",
      company_name: "Transform Fitness",
      "Create Date": "2024-09-22 21:20",
      Type: "",
      "Street Address": "8166 West 3rd Street",
      "Street Address 2": "",
      city: "Los Angeles",
      "State/Region": "CA",
      "Postal Code": "90048",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "+1 908-358-9911",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-118.367703204127, 34.0721077741654],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Accessibility", "Machines", "Special"],
      rating: 5,
      reviews: 0,
      id: "transform_fitness_los_angeles",
      images: [
        "https://live.staticflickr.com/65535/53956302649_bdd872aa88.jpg",
      ],
      name: "Transform Fitness",
      location: "Los Angeles, CA, United States",
    },
    {
      "Record ID": "23168958722",
      company_name: "Atrium Training Facility",
      "Create Date": "2024-09-22 21:21",
      Type: "",
      "Street Address": "6201 Hollywood Boulevard",
      "Street Address 2": "Unit 2014",
      city: "Los Angeles",
      "State/Region": "CA",
      "Postal Code": "90028",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "+1 323-515-2504",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-118.32461388669, 34.1017952918818],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "LGBTQIA+", "Pool", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "atrium_training_facility_los_angeles",
      images: [
        "https://live.staticflickr.com/65535/53956227723_515174fa83.jpg",
      ],
      name: "Atrium Training Facility",
      location: "Los Angeles, CA, United States",
    },
    {
      "Record ID": "23168959496",
      company_name: "Sweatheory",
      "Create Date": "2024-09-22 22:07",
      Type: "",
      "Street Address": "1503 North Cahuenga Boulevard",
      "Street Address 2": "",
      city: "Los Angeles",
      "State/Region": "CA",
      "Postal Code": "90028",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Consumer Services",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "2.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-118.329666354171, 34.0982234689134],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Female Only", "LGBTQIA+", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "sweatheory_los_angeles",
      images: [
        "https://live.staticflickr.com/65535/53948216868_5233ef435b.jpg",
      ],
      name: "Sweatheory",
      location: "Los Angeles, CA, United States",
    },
  ],
  Glendale: [
    {
      "Record ID": "23169012397",
      company_name: "WADE Strength Systems",
      "Create Date": "2024-09-21 19:08",
      Type: "",
      "Street Address": "23439 North 35th Drive",
      "Street Address 2": "1",
      city: "Glendale",
      "State/Region": "AZ",
      "Postal Code": "85310",
      "Country/Region": "United States",
      "Time Zone": "America/Phoenix",
      "Phone Number": "+1 623-800-0444",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-112.137721759632, 33.699611669238],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Powerlifting", "Machines", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "wade_strength_systems_glendale",
      images: [
        "https://live.staticflickr.com/65535/54003374016_5ae31ab619.jpg",
      ],
      name: "WADE Strength Systems",
      location: "Glendale, AZ, United States",
    },
    {
      "Record ID": "23209299086",
      company_name: "Top Line Gym",
      "Create Date": "2024-09-23 18:53",
      Type: "",
      "Street Address": "2012 West Bender Road",
      "Street Address 2": "",
      city: "Glendale",
      "State/Region": "WI",
      "Postal Code": "53209",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-87.9366223632846, 43.131252],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Powerlifting", "Machines", "Weights"],
      rating: 5,
      reviews: 0,
      id: "top_line_gym_glendale",
      images: [
        "https://live.staticflickr.com/65535/54002467027_beaab6d6a3.jpg",
      ],
      name: "Top Line Gym",
      location: "Glendale, WI, United States",
    },
  ],
  San_Jose: [
    {
      "Record ID": "23169012400",
      company_name: "WARCAT Strength",
      "Create Date": "2024-09-21 19:09",
      Type: "",
      "Street Address": "299 Bassett Street",
      "Street Address 2": "",
      city: "San Jose",
      "State/Region": "CA",
      "Postal Code": "95110",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "+1 408-780-6303",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "2.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-121.9005305, 37.3390515],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "Special", "Weights", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "warcat_strength_san_jose",
      images: [
        "https://live.staticflickr.com/65535/54087445415_f01e0815d5.jpg",
      ],
      name: "WARCAT Strength",
      location: "San Jose, CA, United States",
    },
    {
      "Record ID": "23169012486",
      company_name: "Backyard Barbell Club",
      "Create Date": "2024-09-21 19:12",
      Type: "",
      "Street Address": "865 South 8th Street",
      "Street Address 2": "",
      city: "San Jose",
      "State/Region": "CA",
      "Postal Code": "95112",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-121.87372653271, 37.32659348648],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Accessibility", "Powerlifting", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "backyard_barbell_club_san_jose",
      images: [
        "https://live.staticflickr.com/65535/52775044619_c7c8a709e6.jpg",
      ],
      name: "Backyard Barbell Club",
      location: "San Jose, CA, United States",
    },
    {
      "Record ID": "23168950426",
      company_name: "Hella Strength",
      "Create Date": "2024-09-21 19:53",
      Type: "",
      "Street Address": "824 Malone Road",
      "Street Address 2": "",
      city: "San Jose",
      "State/Region": "CA",
      "Postal Code": "95125",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-121.882574910411, 37.2963136319211],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Female Only", "Pool", "Machines"],
      rating: 5,
      reviews: 0,
      id: "hella_strength_san_jose",
      images: [
        "https://live.staticflickr.com/65535/52213395448_c159d6a4ec.jpg",
      ],
      name: "Hella Strength",
      location: "San Jose, CA, United States",
    },
    {
      "Record ID": "23168950628",
      company_name: "HSC Gym",
      active: true,
      "Create Date": "2024-09-21 20:43",
      Type: "",
      "Street Address": "404 North 13th Street",
      "Street Address 2": "",
      city: "San Jose",
      "State/Region": "CA",
      "Postal Code": "95112",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "+1 408-663-3561",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-121.882858447461, 37.348891487722],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "Special", "Weights", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "hsc_gym_san_jose",
      images: [
        "https://live.staticflickr.com/65535/52186392178_dea5b636ae.jpg",
      ],
      name: "HSC Gym",
      location: "San Jose, CA, United States",
    },
    {
      "Record ID": "23168958477",
      company_name: "Ultra Body",
      active: true,
      "Create Date": "2024-09-22 21:06",
      Type: "",
      "Street Address": "265 Lewis Road",
      "Street Address 2": "",
      city: "San Jose",
      "State/Region": "CA",
      "Postal Code": "95111",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "+1 323-464-5300",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-121.845062255859, 37.2948417663574],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Weights", "Cardio", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "ultra_body_san_jose",
      images: [
        "https://live.staticflickr.com/65535/52186865435_5cbe069eac.jpg",
      ],
      name: "Ultra Body",
      location: "San Jose, CA, United States",
    },
  ],
  Cary: [
    {
      "Record ID": "23169012407",
      company_name: "NC Strength Gym",
      "Create Date": "2024-09-21 19:10",
      Type: "",
      "Street Address": "594 East Chatham Street",
      "Street Address 2": "122",
      city: "Cary",
      "State/Region": "NC",
      "Postal Code": "27511",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 984-464-0446",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-78.7683709335324, 35.79014],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Machines", "LGBTQIA+", "Special"],
      rating: 5,
      reviews: 0,
      id: "nc_strength_gym_cary",
      images: [
        "https://live.staticflickr.com/65535/53287172829_8f88206d85.jpg",
      ],
      name: "NC Strength Gym",
      location: "Cary, NC, United States",
    },
  ],
  Cincinnati: [
    {
      "Record ID": "23168950319",
      company_name: "RTS Barbell",
      "Create Date": "2024-09-21 19:11",
      Type: "",
      "Street Address": "2411 Symmes Street",
      "Street Address 2": "",
      city: "Cincinnati",
      "State/Region": "OH",
      "Postal Code": "45206",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 513-608-3071",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-84.4983386268318, 39.1253604079657],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Special", "Pool", "Courts"],
      rating: 5,
      reviews: 0,
      id: "rts_barbell_cincinnati",
      images: ["https://live.staticflickr.com/0/54000331500_5ef0e6da2d.jpg"],
      name: "RTS Barbell",
      location: "Cincinnati, OH, United States",
    },
    {
      "Record ID": "23169012492",
      company_name: "Five Thirteen Strength",
      "Create Date": "2024-09-21 19:15",
      Type: "",
      "Street Address": "1220 Harrison Ave",
      "Street Address 2": "1B",
      city: "Cincinnati",
      "State/Region": "OH",
      "Postal Code": "45214",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 513-557-6433",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "2.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-84.5373551098188, 39.1236139866046],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Accessibility", "Special", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "five_thirteen_strength_cincinnati",
      images: ["https://live.staticflickr.com/0/54000228509_8f48d0914d.jpg"],
      name: "Five Thirteen Strength",
      location: "Cincinnati, OH, United States",
    },
  ],
  Madison: [
    {
      "Record ID": "23169012481",
      company_name: "Ford's Gym",
      "Create Date": "2024-09-21 19:12",
      Type: "",
      "Street Address": "2114 Winnebago Street",
      "Street Address 2": "",
      city: "Madison",
      "State/Region": "WI",
      "Postal Code": "53704",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "+1 608-729-5990",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-89.3536757477757, 43.0944375],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Courts", "Cardio", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "ford_s_gym_madison",
      images: [
        "https://live.staticflickr.com/65535/54069080728_1afedef607.jpg",
      ],
      name: "Ford's Gym",
      location: "Madison, WI, United States",
    },
  ],
  Appleton: [
    {
      "Record ID": "23168950333",
      company_name: "Limitless USA",
      active: true,
      "Create Date": "2024-09-21 19:17",
      Type: "",
      "Street Address": "3003 West Prospect Avenue",
      "Street Address 2": "",
      city: "Appleton",
      "State/Region": "WI",
      "Postal Code": "54914",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-88.4522075, 44.2436985],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Special", "Weights", "Pool"],
      rating: 5,
      reviews: 0,
      id: "limitless_usa_appleton",
      images: [
        "https://live.staticflickr.com/65535/53882442562_f47eaccbf2.jpg",
      ],
      name: "Limitless USA",
      location: "Appleton, WI, United States",
    },
  ],
  Sacramento: [
    {
      "Record ID": "23169012575",
      company_name: "Third Street Barbell",
      "Create Date": "2024-09-21 19:43",
      Type: "",
      "Street Address": "2010 3rd Street",
      "Street Address 2": "",
      city: "Sacramento",
      "State/Region": "CA",
      "Postal Code": "95818",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "+1 916-822-4305",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-121.507965089335, 38.5714222538785],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "Weights", "Special", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "third_street_barbell_sacramento",
      images: [
        "https://live.staticflickr.com/65535/53804589943_98329317be.jpg",
      ],
      name: "Third Street Barbell",
      location: "Sacramento, CA, United States",
    },
    {
      "Record ID": "23168950402",
      company_name: "Untamed Strength",
      "Create Date": "2024-09-21 19:45",
      Type: "",
      "Street Address": "5780 Auburn Boulevard",
      "Street Address 2": "d",
      city: "Sacramento",
      "State/Region": "CA",
      "Postal Code": "95841",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-121.3294855, 38.6710395],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "Weights", "Female Only", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "untamed_strength_sacramento",
      images: [
        "https://live.staticflickr.com/65535/53553098232_d585b433aa.jpg",
      ],
      name: "Untamed Strength",
      location: "Sacramento, CA, United States",
    },
    {
      "Record ID": "23169012797",
      company_name: "Nightmare Muscle",
      "Create Date": "2024-09-21 20:32",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Sacramento",
      "State/Region": "CA",
      "Postal Code": "95823",
      "Country/Region": "",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-121.443454, 38.474099],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Machines", "LGBTQIA+", "Courts"],
      rating: 5,
      reviews: 0,
      id: "nightmare_muscle_sacramento",
      images: [
        "https://live.staticflickr.com/65535/53458099640_bf9c7be994.jpg",
      ],
      name: "Nightmare Muscle",
      location: "Sacramento, CA, ",
    },
  ],
  Bloomington: [
    {
      "Record ID": "23168950400",
      company_name: "Iron Pit Gym",
      "Create Date": "2024-09-21 19:44",
      Type: "",
      "Street Address": "5006 S Rogers St",
      "Street Address 2": "",
      city: "Bloomington",
      "State/Region": "IN",
      "Postal Code": "47403",
      "Country/Region": "United States",
      "Time Zone": "America/Indiana/Indianapolis",
      "Phone Number": "+1 812-339-1679",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "0.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-86.5401032631818, 39.1112485],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Cardio", "Powerlifting", "Courts"],
      rating: 5,
      reviews: 0,
      id: "iron_pit_gym_bloomington",
      images: [
        "https://live.staticflickr.com/65535/52616302368_876b107d91.jpg",
      ],
      name: "Iron Pit Gym",
      location: "Bloomington, IN, United States",
    },
    {
      "Record ID": "23209302296",
      company_name: "Indiana Fitness Club",
      "Create Date": "2024-09-23 21:18",
      Type: "",
      "Street Address": "3643 State Road 46",
      "Street Address 2": "",
      city: "Bloomington",
      "State/Region": "IN",
      "Postal Code": "47404",
      "Country/Region": "United States",
      "Time Zone": "America/Indiana/Indianapolis",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-86.5855407714844, 39.2099800109863],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Powerlifting", "Weights", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "indiana_fitness_club_bloomington",
      images: [
        "https://live.staticflickr.com/65535/52612626952_f131380bba.jpg",
      ],
      name: "Indiana Fitness Club",
      location: "Bloomington, IN, United States",
    },
  ],
  Alpharetta: [
    {
      "Record ID": "23169012584",
      company_name: "TruBorn Strong",
      "Create Date": "2024-09-21 19:46",
      Type: "",
      "Street Address": "5356 McGinnis Ferry Road",
      "Street Address 2": "300",
      city: "Alpharetta",
      "State/Region": "GA",
      "Postal Code": "30005",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-84.2032562666667, 34.09167],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Courts", "Special", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "truborn_strong_alpharetta",
      images: [
        "https://live.staticflickr.com/65535/48009058448_edcab7e5ac.jpg",
      ],
      name: "TruBorn Strong",
      location: "Alpharetta, GA, United States",
    },
  ],
  South_San_Francisco: [
    {
      "Record ID": "23168950411",
      company_name: "FullGrip Barbell",
      "Create Date": "2024-09-21 19:47",
      Type: "",
      "Street Address": "1387 Lowrie Avenue",
      "Street Address 2": "",
      city: "South San Francisco",
      "State/Region": "CA",
      "Postal Code": "94080",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.410768694187, 37.6485387909516],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Cardio", "Accessibility", "Pool"],
      rating: 5,
      reviews: 0,
      id: "fullgrip_barbell_south_san_francisco",
      images: [
        "https://live.staticflickr.com/65535/52004905152_6b5637a9be.jpg",
      ],
      name: "FullGrip Barbell",
      location: "South San Francisco, CA, United States",
    },
  ],
  Rochester: [
    {
      "Record ID": "23168950416",
      company_name: "East Ave. Barbell",
      "Create Date": "2024-09-21 19:49",
      Type: "",
      "Street Address": "1717 East Avenue",
      "Street Address 2": "",
      city: "Rochester",
      "State/Region": "NY",
      "Postal Code": "14610",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-77.5584565384094, 43.1457564922824],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "Courts", "Female Only", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "east_ave_barbell_rochester",
      images: [
        "https://live.staticflickr.com/65535/53492639894_b2d4aa6354.jpg",
      ],
      name: "East Ave. Barbell",
      location: "Rochester, NY, United States",
    },
  ],
  Denver: [
    {
      "Record ID": "23169012595",
      company_name: "Denver Barbell Club",
      "Create Date": "2024-09-21 19:50",
      Type: "",
      "Street Address": "3595 Wynkoop Street",
      "Street Address 2": "",
      city: "Denver",
      "State/Region": "CO",
      "Postal Code": "80216",
      "Country/Region": "United States",
      "Time Zone": "America/Denver",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-104.977012634277, 39.770580291748],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Machines", "Courts", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "denver_barbell_club_denver",
      images: [
        "https://live.staticflickr.com/65535/53452349460_4597d19c49.jpg",
      ],
      name: "Denver Barbell Club",
      location: "Denver, CO, United States",
    },
  ],
  Shawnee: [
    {
      "Record ID": "23169012596",
      company_name: "Strong Barbell Club",
      "Create Date": "2024-09-21 19:51",
      Type: "",
      "Street Address": "4209 Merriam Drive",
      "Street Address 2": "",
      city: "Shawnee",
      "State/Region": "KS",
      "Postal Code": "66203",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-94.677799, 39.040726],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Powerlifting", "LGBTQIA+", "Machines"],
      rating: 5,
      reviews: 0,
      id: "strong_barbell_club_shawnee",
      images: [
        "https://live.staticflickr.com/65535/52955239931_44240a8091.jpg",
      ],
      name: "Strong Barbell Club",
      location: "Shawnee, KS, United States",
    },
  ],
  Carol_Stream: [
    {
      "Record ID": "23168950423",
      company_name: "Surge To New Levels",
      "Create Date": "2024-09-21 19:51",
      Type: "",
      "Street Address": "314 Saint Paul Boulevard",
      "Street Address 2": "",
      city: "Carol Stream",
      "State/Region": "IL",
      "Postal Code": "60188",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Health, Wellness and Fitness",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-88.112764439, 41.9115860370001],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "LGBTQIA+", "Powerlifting", "Special"],
      rating: 5,
      reviews: 0,
      id: "surge_to_new_levels_carol_stream",
      images: ["https://live.staticflickr.com/4121/4762874749_0e4e32c47b.jpg"],
      name: "Surge To New Levels",
      location: "Carol Stream, IL, United States",
    },
  ],
  Bridgeview: [
    {
      "Record ID": "23169012603",
      company_name: "USA Gym",
      "Create Date": "2024-09-21 19:52",
      Type: "",
      "Street Address": "7621 West 100th Place",
      "Street Address 2": "",
      city: "Bridgeview",
      "State/Region": "IL",
      "Postal Code": "60455",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "+1 708-598-3846",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-87.8093169623242, 41.7091230499946],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Female Only", "LGBTQIA+", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "usa_gym_bridgeview",
      images: ["https://live.staticflickr.com/4138/4818861031_74f0f89ea7.jpg"],
      name: "USA Gym",
      location: "Bridgeview, IL, United States",
    },
  ],
  Oakland: [
    {
      "Record ID": "23169012611",
      company_name: "Speed Power Strength",
      "Create Date": "2024-09-21 19:53",
      Type: "",
      "Street Address": "2140 Livingston Street",
      "Street Address 2": "",
      city: "Oakland",
      "State/Region": "CA",
      "Postal Code": "94606",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.239856076, 37.7810924280001],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Machines", "Weights", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "speed_power_strength_oakland",
      images: [
        "https://live.staticflickr.com/65535/53746873940_bbba774e8e.jpg",
      ],
      name: "Speed Power Strength",
      location: "Oakland, CA, United States",
    },
  ],
  St_Louis: [
    {
      "Record ID": "23168950438",
      company_name: "Team StrengthWorks",
      "Create Date": "2024-09-21 19:57",
      Type: "",
      "Street Address": "8516 Manchester Road",
      "Street Address 2": "",
      city: "St. Louis",
      "State/Region": "MO",
      "Postal Code": "63144",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "+1 502-905-2189",
      Industry: "Health, Wellness and Fitness",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-90.3437326426376, 38.6118149933023],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Weights", "Pool", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "team_strengthworks_st_louis",
      images: [
        "https://live.staticflickr.com/65535/54069080728_1afedef607.jpg",
      ],
      name: "Team StrengthWorks",
      location: "St. Louis, MO, United States",
    },
  ],
  Santa_Clara: [
    {
      "Record ID": "23168950445",
      company_name: "House of Muscle USA",
      "Create Date": "2024-09-21 19:58",
      Type: "",
      "Street Address": "3330 El Camino Real",
      "Street Address 2": "Suite #201",
      city: "Santa Clara",
      "State/Region": "CA",
      "Postal Code": "95051",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-121.987523926113, 37.3519915],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Weights", "Machines", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "house_of_muscle_usa_santa_clara",
      images: [
        "https://live.staticflickr.com/65535/53526345523_a90e6b02b7.jpg",
      ],
      name: "House of Muscle USA",
      location: "Santa Clara, CA, United States",
    },
  ],
  Chicago: [
    {
      "Record ID": "23168950448",
      company_name: "Goals Not Dreams",
      "Create Date": "2024-09-21 20:00",
      Type: "",
      "Street Address": "328 South Jefferson Street",
      "Street Address 2": "106",
      city: "Chicago",
      "State/Region": "IL",
      "Postal Code": "60661",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "2.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-87.6429270778947, 41.8771311682185],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Cardio", "Machines", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "goals_not_dreams_chicago",
      images: [
        "https://live.staticflickr.com/65535/54070648066_5b552c62dd.jpg",
      ],
      name: "Goals Not Dreams",
      location: "Chicago, IL, United States",
    },
    {
      "Record ID": "23169012632",
      company_name: "Lance's Gym",
      "Create Date": "2024-09-21 20:02",
      Type: "",
      "Street Address": "1200 West 35th Street",
      "Street Address 2": "",
      city: "Chicago",
      "State/Region": "IL",
      "Postal Code": "60609",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "+1 773-294-1550",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-87.656553297973, 41.830998],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Weights", "Cardio", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "lance_s_gym_chicago",
      images: [
        "https://live.staticflickr.com/65535/54036429541_54f79420f6.jpg",
      ],
      name: "Lance's Gym",
      location: "Chicago, IL, United States",
    },
    {
      "Record ID": "23168950820",
      company_name: "East Bank Club",
      "Create Date": "2024-09-21 21:27",
      Type: "",
      "Street Address": "500 North Kingsbury Street",
      "Street Address 2": "",
      city: "Chicago",
      "State/Region": "IL",
      "Postal Code": "60654",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "+1 312-527-5800",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-87.6393115758888, 41.8901078435573],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Weights", "Pool", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "east_bank_club_chicago",
      images: [
        "https://live.staticflickr.com/65535/54036429551_904320f549.jpg",
      ],
      name: "East Bank Club",
      location: "Chicago, IL, United States",
    },
    {
      "Record ID": "23209297833",
      company_name: "Midtown Athletic Clubs",
      "Create Date": "2024-09-23 18:11",
      Type: "",
      "Street Address": "3611 North Kedzie Avenue",
      "Street Address 2": "",
      city: "Chicago",
      "State/Region": "IL",
      "Postal Code": "60618",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "+1 773-463-1234",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-87.7074844015946, 41.9470750848236],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Machines", "Special", "Weights"],
      rating: 5,
      reviews: 0,
      id: "midtown_athletic_clubs_chicago",
      images: [
        "https://live.staticflickr.com/65535/54028446787_3885971536.jpg",
      ],
      name: "Midtown Athletic Clubs",
      location: "Chicago, IL, United States",
    },
    {
      "Record ID": "23209297816",
      company_name: "Mode Gym",
      "Create Date": "2024-09-23 18:11",
      Type: "",
      "Street Address": "1301 West Madison Street",
      "Street Address 2": "",
      city: "Chicago",
      "State/Region": "IL",
      "Postal Code": "60607",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Health, Wellness and Fitness",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-87.6603189407323, 41.8812415],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Accessibility", "Pool", "Weights"],
      rating: 5,
      reviews: 0,
      id: "mode_gym_chicago",
      images: [
        "https://live.staticflickr.com/65535/54003374016_5ae31ab619.jpg",
      ],
      name: "Mode Gym",
      location: "Chicago, IL, United States",
    },
    {
      "Record ID": "23209297848",
      company_name: "South Loop Strength & Conditioning",
      "Create Date": "2024-09-23 18:12",
      Type: "",
      "Street Address": "645 South Clark Street",
      "Street Address 2": "",
      city: "Chicago",
      "State/Region": "IL",
      "Postal Code": "60605",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-87.6303171205556, 41.8736615327135],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "LGBTQIA+", "Accessibility", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "south_loop_strength_conditioning_chicago",
      images: [
        "https://live.staticflickr.com/65535/54002467027_beaab6d6a3.jpg",
      ],
      name: "South Loop Strength & Conditioning",
      location: "Chicago, IL, United States",
    },
    {
      "Record ID": "23210358151",
      company_name: "V Tone Fitness",
      "Create Date": "2024-09-23 18:14",
      Type: "",
      "Street Address": "1550 West Jarvis Avenue",
      "Street Address 2": "",
      city: "Chicago",
      "State/Region": "IL",
      "Postal Code": "60626",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-87.6699110422505, 42.0161827029729],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "LGBTQIA+", "Machines", "Courts"],
      rating: 5,
      reviews: 0,
      id: "v_tone_fitness_chicago",
      images: [
        "https://live.staticflickr.com/65535/54002467022_f5e28f7a73.jpg",
      ],
      name: "V Tone Fitness",
      location: "Chicago, IL, United States",
    },
    {
      "Record ID": "23209297998",
      company_name: "Chicago Fitness Center",
      "Create Date": "2024-09-23 18:16",
      Type: "",
      "Street Address": "3131 North Lincoln Avenue",
      "Street Address 2": "",
      city: "Chicago",
      "State/Region": "IL",
      "Postal Code": "60657",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "+1 773-549-8181",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-87.6670466197005, 41.9387767066163],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "LGBTQIA+", "Cardio", "Weights"],
      rating: 5,
      reviews: 0,
      id: "chicago_fitness_center_chicago",
      images: [
        "https://live.staticflickr.com/65535/54002467007_e7e71f7ddf.jpg",
      ],
      name: "Chicago Fitness Center",
      location: "Chicago, IL, United States",
    },
    {
      "Record ID": "23209298038",
      company_name: "Iron Mine Chicago",
      "Create Date": "2024-09-23 18:17",
      Type: "",
      "Street Address": "6628 West Irving Park Road",
      "Street Address 2": "",
      city: "Chicago",
      "State/Region": "IL",
      "Postal Code": "60634",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "+1 773-979-0034",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-87.7931462096627, 41.9531559485328],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Special", "Female Only", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "iron_mine_chicago_chicago",
      images: [
        "https://live.staticflickr.com/65535/54003606608_5507afc815.jpg",
      ],
      name: "Iron Mine Chicago",
      location: "Chicago, IL, United States",
    },
    {
      "Record ID": "23209298438",
      company_name: "Fitness Formula Clubs",
      "Create Date": "2024-09-23 18:29",
      Type: "",
      "Street Address": "619 W Jackson Blvd",
      "Street Address 2": "",
      city: "Chicago",
      "State/Region": "IL",
      "Postal Code": "60661",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "+1 312-648-4666",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "2.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-87.6432723999023, 41.8778381347656],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Courts", "Cardio", "Weights"],
      rating: 5,
      reviews: 0,
      id: "fitness_formula_clubs_chicago",
      images: [
        "https://live.staticflickr.com/65535/53926759910_059d58aa20.jpg",
      ],
      name: "Fitness Formula Clubs",
      location: "Chicago, IL, United States",
    },
    {
      "Record ID": "23210358675",
      company_name: "Lateral Fitness",
      "Create Date": "2024-09-23 18:30",
      Type: "",
      "Street Address": "314 West Superior Street",
      "Street Address 2": "",
      city: "Chicago",
      "State/Region": "IL",
      "Postal Code": "60654",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-87.6365876140018, 41.8958381952954],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Cardio", "Machines", "Special"],
      rating: 5,
      reviews: 0,
      id: "lateral_fitness_chicago",
      images: [
        "https://live.staticflickr.com/65535/53896890903_51382165fa.jpg",
      ],
      name: "Lateral Fitness",
      location: "Chicago, IL, United States",
    },
    {
      "Record ID": "23209298810",
      company_name: "Midwest Sport and Spine",
      "Create Date": "2024-09-23 18:43",
      Type: "",
      "Street Address": "730 N Franklin St",
      "Street Address 2": "602",
      city: "Chicago",
      "State/Region": "IL",
      "Postal Code": "60654",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Hospital & Health Care",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-87.636002, 41.895859],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Pool", "Female Only", "Special"],
      rating: 5,
      reviews: 0,
      id: "midwest_sport_and_spine_chicago",
      images: [
        "https://live.staticflickr.com/65535/53896890893_db1671e2c3.jpg",
      ],
      name: "Midwest Sport and Spine",
      location: "Chicago, IL, United States",
    },
    {
      "Record ID": "23209298861",
      company_name: "RSTR",
      "Create Date": "2024-09-23 18:45",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Chicago",
      "State/Region": "IL",
      "Postal Code": "",
      "Country/Region": "",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-87.66063, 41.87897],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Pool", "Weights", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "rstr_chicago",
      images: [
        "https://live.staticflickr.com/65535/53896646496_c634ec0be7.jpg",
      ],
      name: "RSTR",
      location: "Chicago, IL, ",
    },
    {
      "Record ID": "23209298952",
      company_name: "Chicago Athletic Clubs",
      "Create Date": "2024-09-23 18:46",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Chicago",
      "State/Region": "IL",
      "Postal Code": "",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "+1 312-850-9490",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-87.65005, 41.85003],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Powerlifting", "LGBTQIA+", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "chicago_athletic_clubs_chicago",
      images: [
        "https://live.staticflickr.com/65535/53896987624_395c2e2083.jpg",
      ],
      name: "Chicago Athletic Clubs",
      location: "Chicago, IL, United States",
    },
    {
      "Record ID": "23209298989",
      company_name: "Pilsen's Gym",
      "Create Date": "2024-09-23 18:48",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Chicago",
      "State/Region": "IL",
      "Postal Code": "",
      "Country/Region": "",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-87.66063, 41.87897],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Pool", "Cardio", "Courts"],
      rating: 5,
      reviews: 0,
      id: "pilsen_s_gym_chicago",
      images: [
        "https://live.staticflickr.com/65535/53896890508_0a4cb67532.jpg",
      ],
      name: "Pilsen's Gym",
      location: "Chicago, IL, ",
    },
  ],
  Concord: [
    {
      "Record ID": "23168950452",
      company_name: "The Resistance Gym",
      "Create Date": "2024-09-21 20:01",
      Type: "",
      "Street Address": "3442 Clayton Road",
      "Street Address 2": "",
      city: "Concord",
      "State/Region": "CA",
      "Postal Code": "94519",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "+1 925-338-1731",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.013977050781, 37.9710388183594],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Pool", "Cardio", "Courts"],
      rating: 5,
      reviews: 0,
      id: "the_resistance_gym_concord",
      images: [
        "https://live.staticflickr.com/65535/54084363889_dd70d7cd28.jpg",
      ],
      name: "The Resistance Gym",
      location: "Concord, CA, United States",
    },
  ],
  Montgomery: [
    {
      "Record ID": "23168950532",
      company_name: "Jakked Hardcore Gym",
      "Create Date": "2024-09-21 20:03",
      Type: "",
      "Street Address": "1450 Southeast River Road",
      "Street Address 2": "d",
      city: "Montgomery",
      "State/Region": "IL",
      "Postal Code": "60538",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-88.3388977050781, 41.7280883789063],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Machines", "Cardio", "Special"],
      rating: 5,
      reviews: 0,
      id: "jakked_hardcore_gym_montgomery",
      images: [
        "https://live.staticflickr.com/65535/53644161942_2ff77ea2a5.jpg",
      ],
      name: "Jakked Hardcore Gym",
      location: "Montgomery, IL, United States",
    },
  ],
  Buford: [
    {
      "Record ID": "23168950533",
      company_name: "Elite Iron Sports Performance",
      "Create Date": "2024-09-21 20:04",
      Type: "",
      "Street Address": "4296 Old Suwanee Road",
      "Street Address 2": "#10",
      city: "Buford",
      "State/Region": "GA",
      "Postal Code": "30518",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Education Management",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-84.0286033256579, 34.0845455],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Female Only", "Special", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "elite_iron_sports_performance_buford",
      images: [
        "https://live.staticflickr.com/65535/49755200372_459e34527c.jpg",
      ],
      name: "Elite Iron Sports Performance",
      location: "Buford, GA, United States",
    },
  ],
  Colorado_Springs: [
    {
      "Record ID": "23168950585",
      company_name: "Mountain Man Performance",
      "Create Date": "2024-09-21 20:25",
      Type: "",
      "Street Address": "3672 East Bijou Street",
      "Street Address 2": "a",
      city: "Colorado Springs",
      "State/Region": "CO",
      "Postal Code": "80909",
      "Country/Region": "United States",
      "Time Zone": "America/Denver",
      "Phone Number": "",
      Industry: "Sporting Goods",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-104.76231269102, 38.836028168145],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Courts", "Weights", "Special"],
      rating: 5,
      reviews: 0,
      id: "mountain_man_performance_colorado_springs",
      images: [
        "https://live.staticflickr.com/65535/53617949568_fbe607a590.jpg",
      ],
      name: "Mountain Man Performance",
      location: "Colorado Springs, CO, United States",
    },
    {
      "Record ID": "23169012790",
      company_name: "Vardanian Weightlifting",
      "Create Date": "2024-09-21 20:29",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Colorado Springs",
      "State/Region": "CO",
      "Postal Code": "",
      "Country/Region": "",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-104.813228, 38.875356],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Cardio", "Accessibility", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "vardanian_weightlifting_colorado_springs",
      images: [
        "https://live.staticflickr.com/65535/53601350274_9e01072d36.jpg",
      ],
      name: "Vardanian Weightlifting",
      location: "Colorado Springs, CO, ",
    },
  ],
  Redwood_city: [
    {
      "Record ID": "23169012782",
      company_name: "Fullsterkur Barbell",
      "Create Date": "2024-09-21 20:26",
      Type: "",
      "Street Address": "2766 Bay Road",
      "Street Address 2": "d",
      city: "Redwood City",
      "State/Region": "CA",
      "Postal Code": "94063",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.205612964199, 37.4834065],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Machines", "Female Only", "Special"],
      rating: 5,
      reviews: 0,
      id: "fullsterkur_barbell_redwood_city",
      images: [
        "https://live.staticflickr.com/65535/51992069714_dfb9858e98.jpg",
      ],
      name: "Fullsterkur Barbell",
      location: "Redwood City, CA, United States",
    },
  ],
  Dayton: [
    {
      "Record ID": "23169012784",
      company_name: "River City Barbell",
      "Create Date": "2024-09-21 20:27",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Dayton",
      "State/Region": "KY",
      "Postal Code": "",
      "Country/Region": "",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "Sporting Goods",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-84.469443, 39.112701],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Female Only", "Powerlifting", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "river_city_barbell_dayton",
      images: [
        "https://live.staticflickr.com/65535/52014615535_127efea763.jpg",
      ],
      name: "River City Barbell",
      location: "Dayton, KY, ",
    },
  ],
  Modesto: [
    {
      "Record ID": "23168950610",
      company_name: "Barbell Alliance",
      active: true,
      "Create Date": "2024-09-21 20:34",
      Type: "",
      "Street Address": "2009 Tully Road",
      "Street Address 2": "",
      city: "Modesto",
      "State/Region": "CA",
      "Postal Code": "95350",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-121.014765, 37.667655],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Courts", "Pool", "Weights"],
      rating: 5,
      reviews: 0,
      id: "barbell_alliance_modesto",
      images: [
        "https://live.staticflickr.com/65535/52100808614_43783689c5.jpg",
      ],
      name: "Barbell Alliance",
      location: "Modesto, CA, United States",
    },
  ],
  Lawrence: [
    {
      "Record ID": "23169012801",
      company_name: "Barbell Strength",
      "Create Date": "2024-09-21 20:34",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Lawrence",
      "State/Region": "KS",
      "Postal Code": "66046",
      "Country/Region": "",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-95.210578, 38.903195],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Machines", "Pool", "Courts"],
      rating: 5,
      reviews: 0,
      id: "barbell_strength_lawrence",
      images: [
        "https://live.staticflickr.com/65535/53667430726_a02866907c.jpg",
      ],
      name: "Barbell Strength",
      location: "Lawrence, KS, ",
    },
    {
      "Record ID": "23210362552",
      company_name: "Animal Kingdom Athletics",
      "Create Date": "2024-09-23 21:20",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Lawrence",
      "State/Region": "KS",
      "Postal Code": "",
      "Country/Region": "",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "Sporting Goods",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-95.266212, 38.958184],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "Courts", "Accessibility", "Pool"],
      rating: 5,
      reviews: 0,
      id: "animal_kingdom_athletics_lawrence",
      images: [
        "https://live.staticflickr.com/65535/53667647628_ee72ed57b3.jpg",
      ],
      name: "Animal Kingdom Athletics",
      location: "Lawrence, KS, ",
    },
    {
      "Record ID": "23210362636",
      company_name: "Body Boutique",
      "Create Date": "2024-09-23 21:20",
      Type: "",
      "Street Address": "2330 Yale Rd",
      "Street Address 2": "",
      city: "Lawrence",
      "State/Region": "KS",
      "Postal Code": "66049",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "+1 785-749-2424",
      Industry: "Health, Wellness and Fitness",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-95.2635093935446, 38.9669001360459],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Cardio", "Courts", "Weights"],
      rating: 5,
      reviews: 0,
      id: "body_boutique_lawrence",
      images: [
        "https://live.staticflickr.com/65535/53667430621_2185310713.jpg",
      ],
      name: "Body Boutique",
      location: "Lawrence, KS, United States",
    },
    {
      "Record ID": "23210362644",
      company_name: "The Jayhawk Club",
      "Create Date": "2024-09-23 21:21",
      Type: "",
      "Street Address": "1809 Birdie Way",
      "Street Address 2": "",
      city: "Lawrence",
      "State/Region": "KS",
      "Postal Code": "66047",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "2.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-95.2920359860119, 38.9510504182812],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Machines", "Courts", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "the_jayhawk_club_lawrence",
      images: [
        "https://live.staticflickr.com/65535/53667890640_371a21015f.jpg",
      ],
      name: "The Jayhawk Club",
      location: "Lawrence, KS, United States",
    },
  ],
  Milwaukee: [
    {
      "Record ID": "23169012806",
      company_name: "Midwest Power Gym",
      "Create Date": "2024-09-21 20:35",
      Type: "",
      "Street Address": "230 East Lincoln Avenue",
      "Street Address 2": "",
      city: "Milwaukee",
      "State/Region": "WI",
      "Postal Code": "53207",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-87.9069754762703, 43.0030932211598],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Courts", "LGBTQIA+", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "midwest_power_gym_milwaukee",
      images: [
        "https://live.staticflickr.com/65535/54013349694_48854eda53.jpg",
      ],
      name: "Midwest Power Gym",
      location: "Milwaukee, WI, United States",
    },
    {
      "Record ID": "23209299038",
      company_name: "Brickyard Gym",
      "Create Date": "2024-09-23 18:50",
      Type: "",
      "Street Address": "2651 South Kinnickinnic Avenue",
      "Street Address 2": "",
      city: "Milwaukee",
      "State/Region": "WI",
      "Postal Code": "53207",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "+1 414-481-7113",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Customer",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-87.8982476476419, 42.9964824691246],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Weights", "Powerlifting", "Machines"],
      rating: 5,
      reviews: 0,
      id: "brickyard_gym_milwaukee",
      images: [
        "https://live.staticflickr.com/65535/54013023056_207d340cfa.jpg",
      ],
      name: "Brickyard Gym",
      location: "Milwaukee, WI, United States",
    },
    {
      "Record ID": "23210359396",
      company_name: "RWSC GYM",
      "Create Date": "2024-09-23 18:55",
      Type: "",
      "Street Address": "2672 North Holton Street",
      "Street Address 2": "",
      city: "Milwaukee",
      "State/Region": "WI",
      "Postal Code": "53212",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-87.9050216674805, 43.067310333252],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Pool", "Special", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "rwsc_gym_milwaukee",
      images: [
        "https://live.staticflickr.com/65535/54013349689_76ce276ecb.jpg",
      ],
      name: "RWSC GYM",
      location: "Milwaukee, WI, United States",
    },
    {
      "Record ID": "23209299125",
      company_name: "Sweeney's Gym",
      "Create Date": "2024-09-23 18:55",
      Type: "",
      "Street Address": "6050 South Howell Avenue",
      "Street Address 2": "",
      city: "Milwaukee",
      "State/Region": "WI",
      "Postal Code": "53207",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "0.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-87.9089965820313, 42.9344596862793],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Weights", "Female Only", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "sweeney_s_gym_milwaukee",
      images: [
        "https://live.staticflickr.com/65535/54013023016_65094272ba.jpg",
      ],
      name: "Sweeney's Gym",
      location: "Milwaukee, WI, United States",
    },
    {
      "Record ID": "23209299225",
      company_name: "Bay View Fitness",
      "Create Date": "2024-09-23 18:56",
      Type: "",
      "Street Address": "2121 South Kinnickinnic Avenue",
      "Street Address 2": "",
      city: "Milwaukee",
      "State/Region": "WI",
      "Postal Code": "53207",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-87.9073791503906, 43.0061798095703],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "LGBTQIA+", "Cardio", "Weights"],
      rating: 5,
      reviews: 0,
      id: "bay_view_fitness_milwaukee",
      images: [
        "https://live.staticflickr.com/65535/54013246578_2e28b68e96.jpg",
      ],
      name: "Bay View Fitness",
      location: "Milwaukee, WI, United States",
    },
    {
      "Record ID": "23209299253",
      company_name: "AWELL Fitness",
      "Create Date": "2024-09-23 18:58",
      Type: "",
      "Street Address": "222 East Erie Street",
      "Street Address 2": "",
      city: "Milwaukee",
      "State/Region": "WI",
      "Postal Code": "53202",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Consumer Services",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-87.9086475342019, 43.031877],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Powerlifting", "Weights", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "awell_fitness_milwaukee",
      images: [
        "https://live.staticflickr.com/65535/54013349639_57fc529d13.jpg",
      ],
      name: "AWELL Fitness",
      location: "Milwaukee, WI, United States",
    },
  ],
  Gurnee: [
    {
      "Record ID": "23169012812",
      company_name: "Monster Garage Gym",
      "Create Date": "2024-09-21 20:37",
      Type: "",
      "Street Address": "18071 West Hampshire Drive",
      "Street Address 2": "",
      city: "Gurnee",
      "State/Region": "IL",
      "Postal Code": "60031",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-87.9843225191781, 42.39004],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Pool", "Weights", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "monster_garage_gym_gurnee",
      images: ["https://live.staticflickr.com/5055/5465422539_d1df4153bd.jpg"],
      name: "Monster Garage Gym",
      location: "Gurnee, IL, United States",
    },
  ],
  Rochester_Hills: [
    {
      "Record ID": "23169012811",
      company_name: "Rochester Performance Gym",
      "Create Date": "2024-09-21 20:37",
      Type: "",
      "Street Address": "1962 Star Batt Dr",
      "Street Address 2": "",
      city: "Rochester Hills",
      "State/Region": "MI",
      "Postal Code": "48309",
      "Country/Region": "United States",
      "Time Zone": "America/Detroit",
      "Phone Number": "+1 248-709-1258",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-83.1694085538644, 42.6489645],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Special", "Female Only", "Machines"],
      rating: 5,
      reviews: 0,
      id: "rochester_performance_gym_rochester_hills",
      images: [null],
      name: "Rochester Performance Gym",
      location: "Rochester Hills, MI, United States",
    },
  ],
  Port_St_Lucie: [
    {
      "Record ID": "23168950615",
      company_name: "Iron Knight Gym",
      active: true,
      "Create Date": "2024-09-21 20:38",
      Type: "",
      "Street Address": "1601 Northwest Courtyard Circle",
      "Street Address 2": "",
      city: "Port St. Lucie",
      "State/Region": "FL",
      "Postal Code": "34986",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-80.408896944501, 27.3136775],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Powerlifting", "LGBTQIA+", "Pool"],
      rating: 5,
      reviews: 0,
      id: "iron_knight_gym_port_st_lucie",
      images: [
        "https://live.staticflickr.com/65535/53972980171_8dc3763f95.jpg",
      ],
      name: "Iron Knight Gym",
      location: "Port St. Lucie, FL, United States",
    },
  ],
  Downers_Grove: [
    {
      "Record ID": "23169012818",
      company_name: "Ironclad Performance",
      "Create Date": "2024-09-21 20:38",
      Type: "",
      "Street Address": "1911 Ogden Avenue",
      "Street Address 2": "",
      city: "Downers Grove",
      "State/Region": "IL",
      "Postal Code": "60515",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Education Management",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-88.032551255, 41.8074970770001],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Pool", "Accessibility", "Special"],
      rating: 5,
      reviews: 0,
      id: "ironclad_performance_downers_grove",
      images: [null],
      name: "Ironclad Performance",
      location: "Downers Grove, IL, United States",
    },
  ],
  San_Angelo: [
    {
      "Record ID": "23168950625",
      company_name: "The Bar Athletic Club",
      active: true,
      "Create Date": "2024-09-21 20:42",
      Type: "",
      "Street Address": "432 North Bell Street",
      "Street Address 2": "",
      city: "San Angelo",
      "State/Region": "TX",
      "Postal Code": "76903",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "+1 325-703-6540",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-100.412940006364, 31.471917],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "Female Only", "Pool", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "the_bar_athletic_club_san_angelo",
      images: [
        "https://live.staticflickr.com/65535/51926907811_eca90e3073.jpg",
      ],
      name: "The Bar Athletic Club",
      location: "San Angelo, TX, United States",
    },
  ],
  West_Valley_city: [
    {
      "Record ID": "23169012828",
      company_name: "Butler Pro Gym",
      "Create Date": "2024-09-21 20:43",
      Type: "",
      "Street Address": "2616 South 1030 West",
      "Street Address 2": "",
      city: "West Valley City",
      "State/Region": "UT",
      "Postal Code": "84119",
      "Country/Region": "United States",
      "Time Zone": "America/Denver",
      "Phone Number": "+1 385-415-2276",
      Industry: "Sporting Goods",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-111.922933527, 40.714772241],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Weights", "Cardio", "Courts"],
      rating: 5,
      reviews: 0,
      id: "butler_pro_gym_west_valley_city",
      images: ["https://live.staticflickr.com/8542/8642107427_b21e34e9be.jpg"],
      name: "Butler Pro Gym",
      location: "West Valley City, UT, United States",
    },
  ],
  Orem: [
    {
      "Record ID": "23168950634",
      company_name: "Barbell Therapy",
      "Create Date": "2024-09-21 20:44",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Orem",
      "State/Region": "UT",
      "Postal Code": "",
      "Country/Region": "",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-111.700103, 40.300894],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Accessibility", "Courts", "Machines"],
      rating: 5,
      reviews: 0,
      id: "barbell_therapy_orem",
      images: ["https://live.staticflickr.com/8197/8221014116_7feffacc1e.jpg"],
      name: "Barbell Therapy",
      location: "Orem, UT, ",
    },
    {
      "Record ID": "23210359309",
      company_name: "VASA Fitness",
      "Create Date": "2024-09-23 18:51",
      Type: "",
      "Street Address": "1259 South 800 East",
      "Street Address 2": "",
      city: "Orem",
      "State/Region": "UT",
      "Postal Code": "84097",
      "Country/Region": "United States",
      "Time Zone": "America/Denver",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-111.673753322658, 40.2746580917976],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Pool", "Courts", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "vasa_fitness_orem",
      images: ["https://live.staticflickr.com/7417/10909226515_cf324479c2.jpg"],
      name: "VASA Fitness",
      location: "Orem, UT, United States",
    },
  ],
  Wheat_Ridge: [
    {
      "Record ID": "23168950635",
      company_name: "Armbrust Pro Gym",
      "Create Date": "2024-09-21 20:45",
      Type: "",
      "Street Address": "4240 Kipling Street",
      "Street Address 2": "",
      city: "Wheat Ridge",
      "State/Region": "CO",
      "Postal Code": "80033",
      "Country/Region": "United States",
      "Time Zone": "America/Denver",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-105.108919824023, 39.7758631416535],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Courts", "Accessibility", "Pool"],
      rating: 5,
      reviews: 0,
      id: "armbrust_pro_gym_wheat_ridge",
      images: ["https://live.staticflickr.com/4588/38501635104_38a7e20e6e.jpg"],
      name: "Armbrust Pro Gym",
      location: "Wheat Ridge, CO, United States",
    },
  ],
  Malden: [
    {
      "Record ID": "23168950639",
      company_name: "Total Performance Sports",
      "Create Date": "2024-09-21 20:46",
      Type: "",
      "Street Address": "150 Charles Street",
      "Street Address 2": "",
      city: "Malden",
      "State/Region": "MA",
      "Postal Code": "2148",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-71.0732511049492, 42.423061],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Accessibility", "Machines", "Special"],
      rating: 5,
      reviews: 0,
      id: "total_performance_sports_malden",
      images: [
        "https://live.staticflickr.com/65535/53236179527_f99430658d.jpg",
      ],
      name: "Total Performance Sports",
      location: "Malden, MA, United States",
    },
  ],
  Fort_Lauderdale: [
    {
      "Record ID": "23169012860",
      company_name: "Reset Athletics",
      "Create Date": "2024-09-21 20:53",
      Type: "",
      "Street Address": "1200 Northeast 4th Avenue",
      "Street Address 2": "",
      city: "Fort Lauderdale",
      "State/Region": "FL",
      "Postal Code": "33304",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-80.1401593655216, 26.1407925],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "Special", "LGBTQIA+", "Courts"],
      rating: 5,
      reviews: 0,
      id: "reset_athletics_fort_lauderdale",
      images: [
        "https://live.staticflickr.com/65535/53461745805_95d5b851ee.jpg",
      ],
      name: "Reset Athletics",
      location: "Fort Lauderdale, FL, United States",
    },
    {
      "Record ID": "23169012864",
      company_name: "Titan Gym",
      "Create Date": "2024-09-21 20:54",
      Type: "",
      "Street Address": "1440 N Federal Hwy",
      "Street Address 2": "",
      city: "Fort Lauderdale",
      "State/Region": "FL",
      "Postal Code": "33304",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 954-566-2683",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-80.1196996719456, 26.1457598996345],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Courts", "Female Only", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "titan_gym_fort_lauderdale",
      images: [
        "https://live.staticflickr.com/65535/52589316983_5c9f64b206.jpg",
      ],
      name: "Titan Gym",
      location: "Fort Lauderdale, FL, United States",
    },
  ],
  Oakland_Park: [
    {
      "Record ID": "23169012866",
      company_name: "Intense Fitness",
      "Create Date": "2024-09-21 20:55",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Oakland Park",
      "State/Region": "FL",
      "Postal Code": "",
      "Country/Region": "",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "0.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-81.07296, 29.292722],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Courts", "Pool", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "intense_fitness_oakland_park",
      images: [
        "https://live.staticflickr.com/65535/52529261275_8d825f3b9f.jpg",
      ],
      name: "Intense Fitness",
      location: "Oakland Park, FL, ",
    },
  ],
  Miami: [
    {
      "Record ID": "23168950669",
      company_name: "Flex Appeal Miami",
      active: true,
      "Create Date": "2024-09-21 20:57",
      Type: "",
      "Street Address": "12814 Southwest 122nd Avenue",
      "Street Address 2": "",
      city: "Miami",
      "State/Region": "FL",
      "Postal Code": "33186",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-80.3917046783756, 25.6485401596485],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Pool", "Courts", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "flex_appeal_miami_miami",
      images: [
        "https://live.staticflickr.com/65535/53919323211_d776e4afba.jpg",
      ],
      name: "Flex Appeal Miami",
      location: "Miami, FL, United States",
    },
    {
      "Record ID": "23168987004",
      company_name: "Elev8tion Fitness",
      "Create Date": "2024-09-23 11:27",
      Type: "",
      "Street Address": "1625 North Miami Avenue",
      "Street Address 2": "",
      city: "Miami",
      "State/Region": "FL",
      "Postal Code": "33136",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-80.1944792181841, 25.7909820637937],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Accessibility", "LGBTQIA+", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "elev8tion_fitness_miami",
      images: [
        "https://live.staticflickr.com/65535/53865245743_9bc1a59623.jpg",
      ],
      name: "Elev8tion Fitness",
      location: "Miami, FL, United States",
    },
  ],
  Syosset: [
    {
      "Record ID": "23168950671",
      company_name: "Bev Francis Powerhouse Gym",
      "Create Date": "2024-09-21 20:58",
      Type: "",
      "Street Address": "235 Robbins Lane",
      "Street Address 2": "",
      city: "Syosset",
      "State/Region": "NY",
      "Postal Code": "11791",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-73.5175645319896, 40.798829],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Cardio", "Powerlifting", "Weights"],
      rating: 5,
      reviews: 0,
      id: "bev_francis_powerhouse_gym_syosset",
      images: ["https://live.staticflickr.com/3710/11878914735_5949b50fc9.jpg"],
      name: "Bev Francis Powerhouse Gym",
      location: "Syosset, NY, United States",
    },
  ],
  Columbia: [
    {
      "Record ID": "23168950675",
      company_name: "Brickhouse Gym",
      "Create Date": "2024-09-21 20:59",
      Type: "",
      "Street Address": "519 Huger Street",
      "Street Address 2": "",
      city: "Columbia",
      "State/Region": "SC",
      "Postal Code": "29201",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-81.0413795099256, 33.988693],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "LGBTQIA+", "Cardio", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "brickhouse_gym_columbia",
      images: [
        "https://live.staticflickr.com/65535/53959280060_68366851b6.jpg",
      ],
      name: "Brickhouse Gym",
      location: "Columbia, SC, United States",
    },
    {
      "Record ID": "23168950788",
      company_name: "The Colosseum Gym",
      "Create Date": "2024-09-21 21:17",
      Type: "",
      "Street Address": "9159 Red Branch Road",
      "Street Address 2": "f",
      city: "Columbia",
      "State/Region": "MD",
      "Postal Code": "21045",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-76.8211622626031, 39.2304681346428],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Accessibility", "Pool", "Weights"],
      rating: 5,
      reviews: 0,
      id: "the_colosseum_gym_columbia",
      images: [
        "https://live.staticflickr.com/65535/53928757914_7251d1b927.jpg",
      ],
      name: "The Colosseum Gym",
      location: "Columbia, MD, United States",
    },
    {
      "Record ID": "23209302619",
      company_name: "Iron House",
      "Create Date": "2024-09-23 21:33",
      Type: "",
      "Street Address": "5505 Bull Run Drive",
      "Street Address 2": "",
      city: "Columbia",
      "State/Region": "MO",
      "Postal Code": "65201",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "+1 573-814-0444",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-92.2532272908625, 38.9578618359349],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Weights", "Pool", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "iron_house_columbia",
      images: [
        "https://live.staticflickr.com/65535/53916556088_b204e02fb7.jpg",
      ],
      name: "Iron House",
      location: "Columbia, MO, United States",
    },
    {
      "Record ID": "23209302608",
      company_name: "Body Refinery Gym",
      "Create Date": "2024-09-23 21:33",
      Type: "",
      "Street Address": "3400 West Broadway Business Park Court",
      "Street Address 2": "110",
      city: "Columbia",
      "State/Region": "MO",
      "Postal Code": "65203",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-92.3869537292857, 38.9537405],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Weights", "Accessibility", "Pool"],
      rating: 5,
      reviews: 0,
      id: "body_refinery_gym_columbia",
      images: [
        "https://live.staticflickr.com/65535/53912508033_d6e0c4cd75.jpg",
      ],
      name: "Body Refinery Gym",
      location: "Columbia, MO, United States",
    },
    {
      "Record ID": "23210362906",
      company_name: "Iron Fortress Gym",
      "Create Date": "2024-09-23 21:34",
      Type: "",
      "Street Address": "4250 E Broadway",
      "Street Address 2": "860",
      city: "Columbia",
      "State/Region": "MO",
      "Postal Code": "65201",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-92.2753694697733, 38.938412],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Powerlifting", "Pool", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "iron_fortress_gym_columbia",
      images: [
        "https://live.staticflickr.com/65535/53890058799_990bd5ca62.jpg",
      ],
      name: "Iron Fortress Gym",
      location: "Columbia, MO, United States",
    },
    {
      "Record ID": "23209302634",
      company_name: "Wilson's Fitness",
      "Create Date": "2024-09-23 21:35",
      Type: "",
      "Street Address": "2601 Rangeline Street",
      "Street Address 2": "",
      city: "Columbia",
      "State/Region": "MO",
      "Postal Code": "65202",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "2.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-92.3271557777778, 38.977881],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Machines", "Accessibility", "Pool"],
      rating: 5,
      reviews: 0,
      id: "wilson_s_fitness_columbia",
      images: [
        "https://live.staticflickr.com/65535/53833333590_1939d599cb.jpg",
      ],
      name: "Wilson's Fitness",
      location: "Columbia, MO, United States",
    },
    {
      "Record ID": "23210362950",
      company_name: "Vigor MVMNT",
      "Create Date": "2024-09-23 21:36",
      Type: "",
      "Street Address": "1413 Grindstone Plaza Drive",
      "Street Address 2": "",
      city: "Columbia",
      "State/Region": "MO",
      "Postal Code": "65201",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "+1 901-848-9656",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-92.3201610906114, 38.9120445],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Weights", "Pool", "Special"],
      rating: 5,
      reviews: 0,
      id: "vigor_mvmnt_columbia",
      images: [
        "https://live.staticflickr.com/65535/53816805217_7acef006b3.jpg",
      ],
      name: "Vigor MVMNT",
      location: "Columbia, MO, United States",
    },
  ],
  Orlando: [
    {
      "Record ID": "23169012889",
      company_name: "Iron Religion Gym",
      "Create Date": "2024-09-21 21:01",
      Type: "",
      "Street Address": "5247 International Drive",
      "Street Address 2": "",
      city: "Orlando",
      "State/Region": "FL",
      "Postal Code": "32819",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-81.4533996582031, 28.4689407348633],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Special", "Weights", "Courts"],
      rating: 5,
      reviews: 0,
      id: "iron_religion_gym_orlando",
      images: [
        "https://live.staticflickr.com/65535/53886171011_4f33b28e0d.jpg",
      ],
      name: "Iron Religion Gym",
      location: "Orlando, FL, United States",
    },
  ],
  Tampa: [
    {
      "Record ID": "23168950682",
      company_name: "PG Athletic Club",
      "Create Date": "2024-09-21 21:01",
      Type: "",
      "Street Address": "3251 West Hillsborough Avenue",
      "Street Address 2": "",
      city: "Tampa",
      "State/Region": "FL",
      "Postal Code": "33614",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-82.4960013191185, 27.9973985],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "Special", "Powerlifting", "Pool"],
      rating: 5,
      reviews: 0,
      id: "pg_athletic_club_tampa",
      images: [
        "https://live.staticflickr.com/65535/53831334925_399133e20b.jpg",
      ],
      name: "PG Athletic Club",
      location: "Tampa, FL, United States",
    },
    {
      "Record ID": "23169020430",
      company_name: "The Forge 24/7 Fitness",
      active: true,
      "Create Date": "2024-09-22 20:41",
      Type: "",
      "Street Address": "4107 Henderson Boulevard",
      "Street Address 2": "",
      city: "Tampa",
      "State/Region": "FL",
      "Postal Code": "33629",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-82.5127410888672, 27.9281997680664],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Powerlifting", "Machines", "Weights"],
      rating: 5,
      reviews: 0,
      id: "the_forge_24_7_fitness_tampa",
      images: [
        "https://live.staticflickr.com/65535/53531390095_5935586567.jpg",
      ],
      name: "The Forge 24/7 Fitness",
      location: "Tampa, FL, United States",
    },
  ],
  Las_Vegas: [
    {
      "Record ID": "23168950689",
      company_name: "Dragon's Lair Gym",
      "Create Date": "2024-09-21 21:03",
      Type: "",
      "Street Address": "7850 Dean Martin Drive",
      "Street Address 2": "suite 506",
      city: "Las Vegas",
      "State/Region": "NV",
      "Postal Code": "89139",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "+1 702-462-6545",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-115.182964202689, 36.0471115],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Pool", "Female Only", "Special"],
      rating: 5,
      reviews: 0,
      id: "dragon_s_lair_gym_las_vegas",
      images: [
        "https://live.staticflickr.com/65535/53791301486_422c095b3d.jpg",
      ],
      name: "Dragon's Lair Gym",
      location: "Las Vegas, NV, United States",
    },
    {
      "Record ID": "23204645693",
      company_name: "Kilo Club",
      "Create Date": "2024-09-23 11:22",
      Type: "",
      "Street Address": "410 South Rampart Boulevard",
      "Street Address 2": "",
      city: "Las Vegas",
      "State/Region": "NV",
      "Postal Code": "89145",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-115.286141678466, 36.167302],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Machines", "Special", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "kilo_club_las_vegas",
      images: [
        "https://live.staticflickr.com/65535/53680465938_c7f272c6d1.jpg",
      ],
      name: "Kilo Club",
      location: "Las Vegas, NV, United States",
    },
    {
      "Record ID": "23168986732",
      company_name: "Rebel Fitness",
      "Create Date": "2024-09-23 11:23",
      Type: "",
      "Street Address": "2950 South Durango Drive",
      "Street Address 2": "",
      city: "Las Vegas",
      "State/Region": "NV",
      "Postal Code": "89117",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "+1 702-818-5800",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-115.2784235, 36.135508],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Accessibility", "Special", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "rebel_fitness_las_vegas",
      images: [
        "https://live.staticflickr.com/65535/53643561471_f7a49abf32.jpg",
      ],
      name: "Rebel Fitness",
      location: "Las Vegas, NV, United States",
    },
    {
      "Record ID": "23204645720",
      company_name: "Yak's Fitness",
      "Create Date": "2024-09-23 11:23",
      Type: "",
      "Street Address": "7375 South Durango Drive",
      "Street Address 2": "101",
      city: "Las Vegas",
      "State/Region": "NV",
      "Postal Code": "89113",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "+1 702-483-5412",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-115.279708862305, 36.0545806884766],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Accessibility", "Weights", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "yak_s_fitness_las_vegas",
      images: [
        "https://live.staticflickr.com/65535/53643900279_a60d4eb1d4.jpg",
      ],
      name: "Yak's Fitness",
      location: "Las Vegas, NV, United States",
    },
    {
      "Record ID": "23204645801",
      company_name: "Open Gym Fitness",
      "Create Date": "2024-09-23 11:24",
      Type: "",
      "Street Address": "10010 West Cheyenne Avenue",
      "Street Address 2": "110",
      city: "Las Vegas",
      "State/Region": "NV",
      "Postal Code": "89129",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "+1 702-907-8907",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-115.314291866101, 36.2187825],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Accessibility", "Powerlifting", "Pool"],
      rating: 5,
      reviews: 0,
      id: "open_gym_fitness_las_vegas",
      images: [
        "https://live.staticflickr.com/65535/53643771543_67eacb2349.jpg",
      ],
      name: "Open Gym Fitness",
      location: "Las Vegas, NV, United States",
    },
    {
      "Record ID": "23204645775",
      company_name: "Movement Fitness",
      "Create Date": "2024-09-23 11:24",
      Type: "",
      "Street Address": "3075 E Flamingo Rd",
      "Street Address 2": "102",
      city: "Las Vegas",
      "State/Region": "NV",
      "Postal Code": "89121",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "+1 702-803-3777",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-115.108219169, 36.1140266460001],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Pool", "LGBTQIA+", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "movement_fitness_las_vegas",
      images: [
        "https://live.staticflickr.com/65535/53642680147_8b812784a3.jpg",
      ],
      name: "Movement Fitness",
      location: "Las Vegas, NV, United States",
    },
    {
      "Record ID": "23168986934",
      company_name: "The Lift Factory",
      "Create Date": "2024-09-23 11:26",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Las Vegas",
      "State/Region": "NV",
      "Postal Code": "",
      "Country/Region": "",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-115.279104, 36.190364],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Female Only", "Cardio", "Pool"],
      rating: 5,
      reviews: 0,
      id: "the_lift_factory_las_vegas",
      images: [
        "https://live.staticflickr.com/65535/53643900244_d0f4de1cac.jpg",
      ],
      name: "The Lift Factory",
      location: "Las Vegas, NV, ",
    },
    {
      "Record ID": "23204646060",
      company_name: "ZEROlevel Fitness & Wellness",
      "Create Date": "2024-09-23 11:28",
      Type: "",
      "Street Address": "5757 Wayne Newton Boulevard",
      "Street Address 2": "",
      city: "Las Vegas",
      "State/Region": "NV",
      "Postal Code": "89119",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-115.150573730469, 36.0851402282715],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Weights", "Special", "Pool"],
      rating: 5,
      reviews: 0,
      id: "zerolevel_fitness_wellness_las_vegas",
      images: [
        "https://live.staticflickr.com/65535/53643899819_b4f7ec1b31.jpg",
      ],
      name: "ZEROlevel Fitness & Wellness",
      location: "Las Vegas, NV, United States",
    },
    {
      "Record ID": "23210338986",
      company_name: "Sin City Iron",
      "Create Date": "2024-09-23 13:16",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Las Vegas",
      "State/Region": "NV",
      "Postal Code": "",
      "Country/Region": "",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-115.279104, 36.190364],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Pool", "Special", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "sin_city_iron_las_vegas",
      images: [
        "https://live.staticflickr.com/65535/53643770893_87b8a1d6b5.jpg",
      ],
      name: "Sin City Iron",
      location: "Las Vegas, NV, ",
    },
    {
      "Record ID": "23210339143",
      company_name: "Fight or Quit Gym",
      "Create Date": "2024-09-23 13:18",
      Type: "",
      "Street Address": "2410 North Decatur Boulevard",
      "Street Address 2": "",
      city: "Las Vegas",
      "State/Region": "NV",
      "Postal Code": "89108",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "+1 314-471-6396",
      Industry: "Apparel & Fashion",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-115.20442199707, 36.2038688659668],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "LGBTQIA+", "Female Only", "Machines"],
      rating: 5,
      reviews: 0,
      id: "fight_or_quit_gym_las_vegas",
      images: [
        "https://live.staticflickr.com/65535/53643560421_5dac49617c.jpg",
      ],
      name: "Fight or Quit Gym",
      location: "Las Vegas, NV, United States",
    },
    {
      "Record ID": "23209278565",
      company_name: "PRSVR",
      "Create Date": "2024-09-23 13:20",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Las Vegas",
      "State/Region": "NV",
      "Postal Code": "",
      "Country/Region": "",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-115.279104, 36.190364],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Machines", "Cardio", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "prsvr_las_vegas",
      images: [
        "https://live.staticflickr.com/65535/53643770638_89d32d0132.jpg",
      ],
      name: "PRSVR",
      location: "Las Vegas, NV, ",
    },
    {
      "Record ID": "23210339339",
      company_name: "Refuge Climbing and Fitness",
      "Create Date": "2024-09-23 13:21",
      Type: "",
      "Street Address": "6283 South Valley View Boulevard",
      "Street Address 2": "Suite C",
      city: "Las Vegas",
      "State/Region": "NV",
      "Postal Code": "89118",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "+1 702-383-0175",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-115.192268734763, 36.076806],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Weights", "Female Only", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "refuge_climbing_and_fitness_las_vegas",
      images: [
        "https://live.staticflickr.com/65535/53643560376_2a2abe50ae.jpg",
      ],
      name: "Refuge Climbing and Fitness",
      location: "Las Vegas, NV, United States",
    },
  ],
  Spring: [
    {
      "Record ID": "23169012921",
      company_name: "Ironcity Gym",
      active: true,
      "Create Date": "2024-09-21 21:15",
      Type: "",
      "Street Address": "7135 Louetta Road",
      "Street Address 2": "l",
      city: "Spring",
      "State/Region": "TX",
      "Postal Code": "77379",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-95.5204840472028, 30.0218525],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "LGBTQIA+", "Courts", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "ironcity_gym_spring",
      images: [
        "https://live.staticflickr.com/65535/54085112724_f5b611da00.jpg",
      ],
      name: "Ironcity Gym",
      location: "Spring, TX, United States",
    },
  ],
  Tempe: [
    {
      "Record ID": "23169012993",
      company_name: "Muscle Factory Gym AZ",
      "Create Date": "2024-09-21 21:18",
      Type: "",
      "Street Address": "1301 East University Drive",
      "Street Address 2": "UNIT 125",
      city: "Tempe",
      "State/Region": "AZ",
      "Postal Code": "85281",
      "Country/Region": "United States",
      "Time Zone": "America/Phoenix",
      "Phone Number": "+1 480-921-1110",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-111.958808898926, 33.4216690063477],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Female Only", "Courts", "Weights"],
      rating: 5,
      reviews: 0,
      id: "muscle_factory_gym_az_tempe",
      images: [
        "https://live.staticflickr.com/65535/52645955240_4e89b21d42.jpg",
      ],
      name: "Muscle Factory Gym AZ",
      location: "Tempe, AZ, United States",
    },
  ],
  Missouri_city: [
    {
      "Record ID": "23169012999",
      company_name: "Alphaland",
      "Create Date": "2024-09-21 21:20",
      Type: "",
      "Street Address": "1502 Industrial Drive",
      "Street Address 2": "",
      city: "Missouri City",
      "State/Region": "TX",
      "Postal Code": "77489",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Sporting Goods",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-95.5377251865265, 29.6226287487177],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Female Only", "Courts", "Weights"],
      rating: 5,
      reviews: 0,
      id: "alphaland_missouri_city",
      images: [
        "https://live.staticflickr.com/65535/52263652372_2eb18e9494.jpg",
      ],
      name: "Alphaland",
      location: "Missouri City, TX, United States",
    },
  ],
  South_Salt_Lake: [
    {
      "Record ID": "23168950800",
      company_name: "Gym Jones",
      "Create Date": "2024-09-21 21:21",
      Type: "",
      "Street Address": "3322 300 East",
      "Street Address 2": "",
      city: "South Salt Lake",
      "State/Region": "UT",
      "Postal Code": "84115",
      "Country/Region": "United States",
      "Time Zone": "America/Denver",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-111.882908972, 40.699276604],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Cardio", "Machines", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "gym_jones_south_salt_lake",
      images: ["https://live.staticflickr.com/4125/5042359142_332d331137.jpg"],
      name: "Gym Jones",
      location: "South Salt Lake, UT, United States",
    },
  ],
  Tuluum: [
    {
      "Record ID": "23168950807",
      company_name: "Tulum Jungle Gym",
      "Create Date": "2024-09-21 21:24",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Tuluum",
      "State/Region": "",
      "Postal Code": "",
      "Country/Region": "Mexico",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "Sporting Goods",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-108.15213, 31.00435],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Weights", "Cardio", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "tulum_jungle_gym_tuluum",
      images: [null],
      name: "Tulum Jungle Gym",
      location: "Tuluum, , Mexico",
    },
  ],
  Ketchum: [
    {
      "Record ID": "23169013019",
      company_name: "Zenergy Health Club",
      "Create Date": "2024-09-21 21:28",
      Type: "",
      "Street Address": "245 Raven Road",
      "Street Address 2": "",
      city: "Ketchum",
      "State/Region": "ID",
      "Postal Code": "83340",
      "Country/Region": "United States",
      "Time Zone": "America/Boise",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-114.365180969238, 43.694751739502],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Weights", "Female Only", "Machines"],
      rating: 5,
      reviews: 0,
      id: "zenergy_health_club_ketchum",
      images: [
        "https://live.staticflickr.com/65535/53794459369_3351c9623f.jpg",
      ],
      name: "Zenergy Health Club",
      location: "Ketchum, ID, United States",
    },
  ],
  Bellevue: [
    {
      "Record ID": "23169013025",
      company_name: "PRO Club",
      "Create Date": "2024-09-21 21:30",
      Type: "",
      "Street Address": "4455 148th Avenue Northeast",
      "Street Address 2": "",
      city: "Bellevue",
      "State/Region": "WA",
      "Postal Code": "98007",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "+1 425-869-4798",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.145858696913, 47.6511365],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Special", "Cardio", "Courts"],
      rating: 5,
      reviews: 0,
      id: "pro_club_bellevue",
      images: [
        "https://live.staticflickr.com/65535/53663756468_67ccd9e80e.jpg",
      ],
      name: "PRO Club",
      location: "Bellevue, WA, United States",
    },
  ],
  Boca_Raton: [
    {
      "Record ID": "23168950829",
      company_name: "BocaWest CC",
      "Create Date": "2024-09-21 21:31",
      Type: "",
      "Street Address": "20583 Boca West Dr",
      "Street Address 2": "",
      city: "Boca Raton",
      "State/Region": "FL",
      "Postal Code": "33434",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "3.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-80.1568732377778, 26.375844],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Female Only", "Powerlifting", "Weights"],
      rating: 5,
      reviews: 0,
      id: "bocawest_cc_boca_raton",
      images: [
        "https://live.staticflickr.com/65535/52683435345_92d2d9977b.jpg",
      ],
      name: "BocaWest CC",
      location: "Boca Raton, FL, United States",
    },
  ],
  Paris: [
    {
      "Record ID": "23168950842",
      company_name: "Blanche",
      "Create Date": "2024-09-21 21:36",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Paris",
      "State/Region": "",
      "Postal Code": "",
      "Country/Region": "France",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [2.342865, 48.858705],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Powerlifting", "Weights", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "blanche_paris",
      images: [
        "https://live.staticflickr.com/65535/54065169648_c5203daa35.jpg",
      ],
      name: "Blanche",
      location: "Paris, , France",
    },
  ],
  Beograd: [
    {
      "Record ID": "23168950843",
      company_name: "Sky Experience",
      "Create Date": "2024-09-21 21:37",
      Type: "",
      "Street Address": "63 Tadeuša Košćuška",
      "Street Address 2": "",
      city: "Beograd",
      "State/Region": "",
      "Postal Code": "",
      "Country/Region": "Serbia",
      "Time Zone": "Europe/Belgrade",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [0, 0],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Powerlifting", "Machines", "Weights"],
      rating: 5,
      reviews: 0,
      id: "sky_experience_beograd",
      images: [
        "https://live.staticflickr.com/65535/52429916489_0ac80596de.jpg",
      ],
      name: "Sky Experience",
      location: "Beograd, , Serbia",
    },
  ],
  Phoenix: [
    {
      "Record ID": "23169013047",
      company_name: "Exos",
      "Create Date": "2024-09-21 21:41",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Phoenix",
      "State/Region": "AZ",
      "Postal Code": "85050",
      "Country/Region": "United States",
      "Time Zone": "America/Phoenix",
      "Phone Number": "+1 781-616-6202",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-112.07404, 33.44838],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Courts", "Female Only", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "exos_phoenix",
      images: [
        "https://live.staticflickr.com/65535/54019829461_e28204de59.jpg",
      ],
      name: "Exos",
      location: "Phoenix, AZ, United States",
    },
  ],
  Philadelphia: [
    {
      "Record ID": "23168956211",
      company_name: "The Sporting Club at The Bellevue",
      "Create Date": "2024-09-22 17:27",
      Type: "",
      "Street Address": "224 South Broad Street",
      "Street Address 2": "",
      city: "Philadelphia",
      "State/Region": "PA",
      "Postal Code": "19102",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "2.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-75.16452, 39.948483],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Cardio", "Courts", "Weights"],
      rating: 5,
      reviews: 0,
      id: "the_sporting_club_at_the_bellevue_philadelphia",
      images: [
        "https://live.staticflickr.com/65535/54070653029_4c15b74b14.jpg",
      ],
      name: "The Sporting Club at The Bellevue",
      location: "Philadelphia, PA, United States",
    },
    {
      "Record ID": "23209301879",
      company_name: "Fusion Gyms",
      "Create Date": "2024-09-23 21:01",
      Type: "",
      "Street Address": "1425 Arch Street",
      "Street Address 2": "",
      city: "Philadelphia",
      "State/Region": "PA",
      "Postal Code": "19102",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 844-289-6226",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-75.164293, 39.954997],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Female Only", "Special", "Courts"],
      rating: 5,
      reviews: 0,
      id: "fusion_gyms_philadelphia",
      images: [
        "https://live.staticflickr.com/65535/54019829461_e28204de59.jpg",
      ],
      name: "Fusion Gyms",
      location: "Philadelphia, PA, United States",
    },
    {
      "Record ID": "23210362236",
      company_name: "Iron Works Fitness",
      "Create Date": "2024-09-23 21:03",
      Type: "",
      "Street Address": "454 North 12th Street",
      "Street Address 2": "Basement B",
      city: "Philadelphia",
      "State/Region": "PA",
      "Postal Code": "19123",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-75.1581958482402, 39.9608533065256],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Female Only", "Special", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "iron_works_fitness_philadelphia",
      images: [
        "https://live.staticflickr.com/65535/54018932157_3312d31039.jpg",
      ],
      name: "Iron Works Fitness",
      location: "Philadelphia, PA, United States",
    },
    {
      "Record ID": "23209301940",
      company_name: "BAMN Fitness",
      "Create Date": "2024-09-23 21:04",
      Type: "",
      "Street Address": "1617 North 2nd Street",
      "Street Address 2": "",
      city: "Philadelphia",
      "State/Region": "PA",
      "Postal Code": "19122",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 267-560-7426",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-75.138329, 39.975243],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Cardio", "Female Only", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "bamn_fitness_philadelphia",
      images: [
        "https://live.staticflickr.com/65535/54020063483_75fb2fc9a5.jpg",
      ],
      name: "BAMN Fitness",
      location: "Philadelphia, PA, United States",
    },
    {
      "Record ID": "23209301933",
      company_name: "FIT GYM PHILLY",
      "Create Date": "2024-09-23 21:04",
      Type: "",
      "Street Address": "4415 Chestnut Street",
      "Street Address 2": "",
      city: "Philadelphia",
      "State/Region": "PA",
      "Postal Code": "19104",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 215-386-3333",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-75.2101821880128, 39.957136],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Female Only", "Weights", "Special"],
      rating: 5,
      reviews: 0,
      id: "fit_gym_philly_philadelphia",
      images: [
        "https://live.staticflickr.com/65535/54019829426_097e8cae1b.jpg",
      ],
      name: "FIT GYM PHILLY",
      location: "Philadelphia, PA, United States",
    },
    {
      "Record ID": "23209302020",
      company_name: "MASS F.I.T.",
      "Create Date": "2024-09-23 21:05",
      Type: "",
      "Street Address": "409 South 2nd Street",
      "Street Address 2": "302",
      city: "Philadelphia",
      "State/Region": "PA",
      "Postal Code": "19147",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-75.144974, 39.942711],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Cardio", "Courts", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "mass_f_i_t__philadelphia",
      images: [
        "https://live.staticflickr.com/65535/54020272965_f5a4eff119.jpg",
      ],
      name: "MASS F.I.T.",
      location: "Philadelphia, PA, United States",
    },
    {
      "Record ID": "23210362368",
      company_name: "Astro Gym",
      "Create Date": "2024-09-23 21:07",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Philadelphia",
      "State/Region": "PA",
      "Postal Code": "",
      "Country/Region": "",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-75.168428, 39.990821],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Special", "Courts", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "astro_gym_philadelphia",
      images: [
        "https://live.staticflickr.com/65535/54020272915_4f03be8369.jpg",
      ],
      name: "Astro Gym",
      location: "Philadelphia, PA, ",
    },
    {
      "Record ID": "23209302040",
      company_name: "Sweat Fitness",
      "Create Date": "2024-09-23 21:07",
      Type: "",
      "Street Address": "1509 East Passyunk Avenue",
      "Street Address 2": "",
      city: "Philadelphia",
      "State/Region": "PA",
      "Postal Code": "19147",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-75.1621617206695, 39.930612122172],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Weights", "LGBTQIA+", "Pool"],
      rating: 5,
      reviews: 0,
      id: "sweat_fitness_philadelphia",
      images: [
        "https://live.staticflickr.com/65535/54020063408_453b0dc17e.jpg",
      ],
      name: "Sweat Fitness",
      location: "Philadelphia, PA, United States",
    },
  ],
  Kahuku: [
    {
      "Record ID": "23168956231",
      company_name: "Turtle Bay Resort",
      "Create Date": "2024-09-22 17:31",
      Type: "",
      "Street Address": "57-091 Kamehameha Highway",
      "Street Address 2": "",
      city: "Kahuku",
      "State/Region": "HI",
      "Postal Code": "96731",
      "Country/Region": "United States",
      "Time Zone": "Pacific/Honolulu",
      "Phone Number": "+1 808-293-6042",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "0.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-157.992249, 21.697687],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Female Only", "Pool", "Machines"],
      rating: 5,
      reviews: 0,
      id: "turtle_bay_resort_kahuku",
      images: ["https://live.staticflickr.com/2948/15350157936_fc69a9e3b7.jpg"],
      name: "Turtle Bay Resort",
      location: "Kahuku, HI, United States",
    },
  ],
  Baltimore: [
    {
      "Record ID": "23168957012",
      company_name: "Mt Vernon Fitness",
      "Create Date": "2024-09-22 19:12",
      Type: "",
      "Street Address": "1016 North Charles Street",
      "Street Address 2": "",
      city: "Baltimore",
      "State/Region": "MD",
      "Postal Code": "21201",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-76.6162185668945, 39.3017311096191],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Powerlifting", "Female Only", "Special"],
      rating: 5,
      reviews: 0,
      id: "mt_vernon_fitness_baltimore",
      images: [
        "https://live.staticflickr.com/65535/53795708320_0d28f879f1.jpg",
      ],
      name: "Mt Vernon Fitness",
      location: "Baltimore, MD, United States",
    },
  ],
  Ħ_Attard: [
    {
      "Record ID": "23169019398",
      company_name: "24/7 Fitness Club",
      "Create Date": "2024-09-22 19:16",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Ħ'Attard",
      "State/Region": "",
      "Postal Code": "ATD4000",
      "Country/Region": "Malta",
      "Time Zone": "Europe/Malta",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [14.424667, 35.88752],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Special", "Courts", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "24_7_fitness_club__attard",
      images: [null],
      name: "24/7 Fitness Club",
      location: "Ħ'Attard, , Malta",
    },
  ],
  Raħal_Ġdid: [
    {
      "Record ID": "23168957049",
      company_name: "Gymnasia",
      "Create Date": "2024-09-22 19:17",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Raħal Ġdid",
      "State/Region": "",
      "Postal Code": "",
      "Country/Region": "Malta",
      "Time Zone": "Europe/Malta",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [14.507455, 35.87703],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Powerlifting", "Female Only", "Pool"],
      rating: 5,
      reviews: 0,
      id: "gymnasia_ra_al_did",
      images: [null],
      name: "Gymnasia",
      location: "Raħal Ġdid, , Malta",
    },
  ],
  Luqa: [
    {
      "Record ID": "23168957078",
      company_name: "Sky Spirit Fitness Lounge",
      "Create Date": "2024-09-22 19:19",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Luqa",
      "State/Region": "",
      "Postal Code": "LQA4000",
      "Country/Region": "Malta",
      "Time Zone": "Europe/Malta",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [14.48861, 35.85889],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Accessibility", "Courts", "Machines"],
      rating: 5,
      reviews: 0,
      id: "sky_spirit_fitness_lounge_luqa",
      images: [null],
      name: "Sky Spirit Fitness Lounge",
      location: "Luqa, , Malta",
    },
  ],
  Ħaż_Żebbuġ: [
    {
      "Record ID": "23169019457",
      company_name: "SCRAM Gym",
      "Create Date": "2024-09-22 19:23",
      Type: "",
      "Street Address": "2 Triq Dun Karm Psaila",
      "Street Address 2": "",
      city: "Ħaż-Żebbuġ",
      "State/Region": "",
      "Postal Code": "",
      "Country/Region": "Malta",
      "Time Zone": "Europe/Malta",
      "Phone Number": "",
      Industry: "Health, Wellness and Fitness",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "2.0",
      "Company owner": "Jordan Robinson",
      coordinates: [0, 0],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "LGBTQIA+", "Machines", "Weights"],
      rating: 5,
      reviews: 0,
      id: "scram_gym__a_ebbu_",
      images: [null],
      name: "SCRAM Gym",
      location: "Ħaż-Żebbuġ, , Malta",
    },
  ],
  Spotswood: [
    {
      "Record ID": "23169020051",
      company_name: "BFT",
      "Create Date": "2024-09-22 20:17",
      Type: "",
      "Street Address": "208 Hall Street",
      "Street Address 2": "",
      city: "Spotswood",
      "State/Region": "Victoria",
      "Postal Code": "3015",
      "Country/Region": "Australia",
      "Time Zone": "Australia/Melbourne",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [144.887315, -37.828361],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Female Only", "Accessibility", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "bft_spotswood",
      images: [
        "https://live.staticflickr.com/65535/53362168121_affa49f731.jpg",
      ],
      name: "BFT",
      location: "Spotswood, Victoria, Australia",
    },
  ],
  Auckland: [
    {
      "Record ID": "23169020071",
      company_name: "Flex Fitness New Zealand",
      "Create Date": "2024-09-22 20:19",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Auckland",
      "State/Region": "Auckland",
      "Postal Code": "",
      "Country/Region": "New Zealand",
      "Time Zone": "Pacific/Auckland",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [174.76349, -36.84853],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Cardio", "Special", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "flex_fitness_new_zealand_auckland",
      images: [
        "https://live.staticflickr.com/65535/53496846417_3b92faf5ff.jpg",
      ],
      name: "Flex Fitness New Zealand",
      location: "Auckland, Auckland, New Zealand",
    },
    {
      "Record ID": "23169020162",
      company_name: "Tomfit Gym",
      "Create Date": "2024-09-22 20:22",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Auckland",
      "State/Region": "Auckland",
      "Postal Code": "",
      "Country/Region": "New Zealand",
      "Time Zone": "Pacific/Auckland",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [174.76349, -36.84853],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Special", "Courts", "Pool"],
      rating: 5,
      reviews: 0,
      id: "tomfit_gym_auckland",
      images: [
        "https://live.staticflickr.com/65535/53423839983_1f2d782e1a.jpg",
      ],
      name: "Tomfit Gym",
      location: "Auckland, Auckland, New Zealand",
    },
    {
      "Record ID": "23168957849",
      company_name: "The Atrium Club",
      "Create Date": "2024-09-22 20:24",
      Type: "",
      "Street Address": "190 Federal Street",
      "Street Address 2": "",
      city: "Auckland",
      "State/Region": "Auckland",
      "Postal Code": "1010",
      "Country/Region": "New Zealand",
      "Time Zone": "Pacific/Auckland",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [174.761873, -36.850597],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Courts", "LGBTQIA+", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "the_atrium_club_auckland",
      images: [
        "https://live.staticflickr.com/65535/53422751317_01f2e025e3.jpg",
      ],
      name: "The Atrium Club",
      location: "Auckland, Auckland, New Zealand",
    },
    {
      "Record ID": "23169020219",
      company_name: "The Peoples Gym",
      "Create Date": "2024-09-22 20:28",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Auckland",
      "State/Region": "",
      "Postal Code": "",
      "Country/Region": "",
      "Time Zone": "New Zealand",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [174.672404, -36.905451],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Accessibility", "Female Only", "Courts"],
      rating: 5,
      reviews: 0,
      id: "the_peoples_gym_auckland",
      images: [
        "https://live.staticflickr.com/65535/53295771392_1b99f71627.jpg",
      ],
      name: "The Peoples Gym",
      location: "Auckland, , ",
    },
    {
      "Record ID": "23168957965",
      company_name: "AUT University - City Gym",
      "Create Date": "2024-09-22 20:29",
      Type: "",
      "Street Address": "55 Wellesley Street East",
      "Street Address 2": "",
      city: "Auckland",
      "State/Region": "Auckland",
      "Postal Code": "1010",
      "Country/Region": "New Zealand",
      "Time Zone": "Pacific/Auckland",
      "Phone Number": "+64 9-921 9999",
      Industry: "Higher Education",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [174.766787, -36.852634],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Powerlifting", "Courts", "Machines"],
      rating: 5,
      reviews: 0,
      id: "aut_university_city_gym_auckland",
      images: [
        "https://live.staticflickr.com/65535/53267557725_c7aa8e85e2.jpg",
      ],
      name: "AUT University - City Gym",
      location: "Auckland, Auckland, New Zealand",
    },
    {
      "Record ID": "23169020247",
      company_name: "The Cobalt Club",
      "Create Date": "2024-09-22 20:30",
      Type: "",
      "Street Address": "19 Auburn Street",
      "Street Address 2": "",
      city: "Auckland",
      "State/Region": "Auckland",
      "Postal Code": "1023",
      "Country/Region": "New Zealand",
      "Time Zone": "Pacific/Auckland",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [174.765381, -36.866094],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Machines", "Courts", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "the_cobalt_club_auckland",
      images: [
        "https://live.staticflickr.com/65535/52991408019_1740a80785.jpg",
      ],
      name: "The Cobalt Club",
      location: "Auckland, Auckland, New Zealand",
    },
    {
      "Record ID": "23169020277",
      company_name: "NZ Iron Strength",
      "Create Date": "2024-09-22 20:32",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Auckland",
      "State/Region": "Auckland",
      "Postal Code": "",
      "Country/Region": "New Zealand",
      "Time Zone": "Pacific/Auckland",
      "Phone Number": "+64 21 586 928",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "0.0",
      "Company owner": "Jordan Robinson",
      coordinates: [174.76349, -36.84853],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Machines", "Female Only", "Pool"],
      rating: 5,
      reviews: 0,
      id: "nz_iron_strength_auckland",
      images: [
        "https://live.staticflickr.com/65535/52482262094_061b797617.jpg",
      ],
      name: "NZ Iron Strength",
      location: "Auckland, Auckland, New Zealand",
    },
    {
      "Record ID": "23168958024",
      company_name: "The Hawks' Nest",
      "Create Date": "2024-09-22 20:34",
      Type: "",
      "Street Address": "492 Queen Street",
      "Street Address 2": "Level 2",
      city: "Auckland",
      "State/Region": "Auckland",
      "Postal Code": "1010",
      "Country/Region": "New Zealand",
      "Time Zone": "Pacific/Auckland",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [174.762193, -36.856675],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Powerlifting", "Courts", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "the_hawks_nest_auckland",
      images: [
        "https://live.staticflickr.com/65535/52482537613_24de744920.jpg",
      ],
      name: "The Hawks' Nest",
      location: "Auckland, Auckland, New Zealand",
    },
    {
      "Record ID": "23168958012",
      company_name: "Jetts New Zealand",
      "Create Date": "2024-09-22 20:34",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Auckland",
      "State/Region": "Auckland",
      "Postal Code": "",
      "Country/Region": "New Zealand",
      "Time Zone": "Pacific/Auckland",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [174.76349, -36.84853],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Weights", "Accessibility", "Courts"],
      rating: 5,
      reviews: 0,
      id: "jetts_new_zealand_auckland",
      images: [
        "https://live.staticflickr.com/65535/52473282866_8c73c6cc8c.jpg",
      ],
      name: "Jetts New Zealand",
      location: "Auckland, Auckland, New Zealand",
    },
    {
      "Record ID": "23169020326",
      company_name: "Supercity Fitness",
      "Create Date": "2024-09-22 20:37",
      Type: "",
      "Street Address": "25 Druces Road",
      "Street Address 2": "",
      city: "Auckland",
      "State/Region": "Auckland",
      "Postal Code": "2104",
      "Country/Region": "New Zealand",
      "Time Zone": "Pacific/Auckland",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [174.877968, -37.000631],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Special", "Powerlifting", "Courts"],
      rating: 5,
      reviews: 0,
      id: "supercity_fitness_auckland",
      images: [
        "https://live.staticflickr.com/65535/52473279521_13b04b14aa.jpg",
      ],
      name: "Supercity Fitness",
      location: "Auckland, Auckland, New Zealand",
    },
    {
      "Record ID": "23169020329",
      company_name: "NZ Muscle Gym",
      "Create Date": "2024-09-22 20:38",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Auckland",
      "State/Region": "Auckland",
      "Postal Code": "",
      "Country/Region": "New Zealand",
      "Time Zone": "Pacific/Auckland",
      "Phone Number": "",
      Industry: "Health, Wellness and Fitness",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [174.76349, -36.84853],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Female Only", "Cardio", "Machines"],
      rating: 5,
      reviews: 0,
      id: "nz_muscle_gym_auckland",
      images: [
        "https://live.staticflickr.com/65535/52473736190_ca5a831051.jpg",
      ],
      name: "NZ Muscle Gym",
      location: "Auckland, Auckland, New Zealand",
    },
  ],
  Waiuku: [
    {
      "Record ID": "23168957837",
      company_name: "Getstrength Gym",
      "Create Date": "2024-09-22 20:23",
      Type: "",
      "Street Address": "21 Martyn Street",
      "Street Address 2": "",
      city: "Waiuku",
      "State/Region": "Auckland",
      "Postal Code": "2123",
      "Country/Region": "New Zealand",
      "Time Zone": "Pacific/Auckland",
      "Phone Number": "",
      Industry: "Sporting Goods",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [174.728518, -37.252364],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "LGBTQIA+", "Weights", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "getstrength_gym_waiuku",
      images: [null],
      name: "Getstrength Gym",
      location: "Waiuku, Auckland, New Zealand",
    },
  ],
  Porirua: [
    {
      "Record ID": "23169020212",
      company_name: "CityFitness",
      "Create Date": "2024-09-22 20:27",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Porirua",
      "State/Region": "Wellington",
      "Postal Code": "",
      "Country/Region": "New Zealand",
      "Time Zone": "Pacific/Auckland",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [174.874031, -41.121272],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Female Only", "Pool", "Weights"],
      rating: 5,
      reviews: 0,
      id: "cityfitness_porirua",
      images: ["https://live.staticflickr.com/7741/27285920176_8b6216f735.jpg"],
      name: "CityFitness",
      location: "Porirua, Wellington, New Zealand",
    },
  ],
  Athens: [
    {
      "Record ID": "23169020344",
      company_name: "Fitness @ Five of Athens",
      "Create Date": "2024-09-22 20:39",
      Type: "",
      "Street Address": "1260 South Milledge Avenue",
      "Street Address 2": "",
      city: "Athens",
      "State/Region": "GA",
      "Postal Code": "30605",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-83.3861314234829, 33.9379437484278],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Cardio", "Courts", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "fitness_five_of_athens_athens",
      images: ["https://live.staticflickr.com/0/54051976679_42552da860.jpg"],
      name: "Fitness @ Five of Athens",
      location: "Athens, GA, United States",
    },
  ],
  Weston: [
    {
      "Record ID": "23169020439",
      company_name: "House of Athlete",
      "Create Date": "2024-09-22 20:42",
      Type: "",
      "Street Address": "1495 North Park Drive",
      "Street Address 2": "",
      city: "Weston",
      "State/Region": "FL",
      "Postal Code": "33326",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "0.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-80.3648245933042, 26.100372],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Powerlifting", "Cardio", "Machines"],
      rating: 5,
      reviews: 0,
      id: "house_of_athlete_weston",
      images: [
        "https://live.staticflickr.com/65535/53010367980_249102c76f.jpg",
      ],
      name: "House of Athlete",
      location: "Weston, FL, United States",
    },
  ],
  Palm_Springs: [
    {
      "Record ID": "23169020446",
      company_name: "Steel Gym",
      "Create Date": "2024-09-22 20:44",
      Type: "",
      "Street Address": "1751 North Sunrise Way",
      "Street Address 2": "e",
      city: "Palm Springs",
      "State/Region": "CA",
      "Postal Code": "92262",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "+1 212-352-9876",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-116.528579711914, 33.8450889587402],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "Cardio", "Weights", "Pool"],
      rating: 5,
      reviews: 0,
      id: "steel_gym_palm_springs",
      images: [
        "https://live.staticflickr.com/65535/53776102418_a5953cace4.jpg",
      ],
      name: "Steel Gym",
      location: "Palm Springs, CA, United States",
    },
  ],
  Palm_Desert: [
    {
      "Record ID": "23169020479",
      company_name: "Fit Factory Studio",
      active: true,
      "Create Date": "2024-09-22 20:46",
      Type: "",
      "Street Address": "75153 Merle Drive",
      "Street Address 2": "1",
      city: "Palm Desert",
      "State/Region": "CA",
      "Postal Code": "92211",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "+1 760-565-5414",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "0.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-116.353732698697, 33.7393775],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "Pool", "Powerlifting", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "fit_factory_studio_palm_desert",
      images: [
        "https://live.staticflickr.com/65535/52809088664_42b3ab09e9.jpg",
      ],
      name: "Fit Factory Studio",
      location: "Palm Desert, CA, United States",
    },
    {
      "Record ID": "23168958124",
      company_name: "Get Fit Training Facility",
      "Create Date": "2024-09-22 20:46",
      Type: "",
      "Street Address": "74804 Joni Drive",
      "Street Address 2": "12a",
      city: "Palm Desert",
      "State/Region": "CA",
      "Postal Code": "92260",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-116.359621740226, 33.741706266243],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Machines", "Weights", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "get_fit_training_facility_palm_desert",
      images: ["https://live.staticflickr.com/929/43898293512_e6d82799d5.jpg"],
      name: "Get Fit Training Facility",
      location: "Palm Desert, CA, United States",
    },
  ],
  Dallas: [
    {
      "Record ID": "23169020700",
      company_name: "Gold's Gym",
      "Create Date": "2024-09-22 20:58",
      Type: "",
      "Street Address": "4001 Maple Avenue",
      "Street Address 2": "200",
      city: "Dallas",
      "State/Region": "TX",
      "Postal Code": "75219",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "+1 214-296-5026",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-96.8163025613964, 32.8057625],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Weights", "Cardio", "Machines"],
      rating: 5,
      reviews: 0,
      id: "gold_s_gym_dallas",
      images: [
        "https://live.staticflickr.com/65535/54084029671_2de778a709.jpg",
      ],
      name: "Gold's Gym",
      location: "Dallas, TX, United States",
    },
    {
      "Record ID": "23169022611",
      company_name: "Paradigm Gym",
      "Create Date": "2024-09-22 22:54",
      Type: "",
      "Street Address": "5815 Live Oak Street",
      "Street Address 2": "#104",
      city: "Dallas",
      "State/Region": "TX",
      "Postal Code": "75214",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-96.7614898681641, 32.8124313354492],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Weights", "Pool", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "paradigm_gym_dallas",
      images: [
        "https://live.staticflickr.com/65535/54073805883_9795dc5686.jpg",
      ],
      name: "Paradigm Gym",
      location: "Dallas, TX, United States",
    },
    {
      "Record ID": "23169022617",
      company_name: "Recess Fitness Club",
      "Create Date": "2024-09-22 22:55",
      Type: "",
      "Street Address": "5706 East Mockingbird Lane",
      "Street Address 2": "#310",
      city: "Dallas",
      "State/Region": "TX",
      "Postal Code": "75206",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-96.7698593139648, 32.8362617492676],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Cardio", "Powerlifting", "Special"],
      rating: 5,
      reviews: 0,
      id: "recess_fitness_club_dallas",
      images: [
        "https://live.staticflickr.com/65535/54072667842_04e84172a7.jpg",
      ],
      name: "Recess Fitness Club",
      location: "Dallas, TX, United States",
    },
    {
      "Record ID": "23169022621",
      company_name: "Lake Highlands Strength & Conditioning",
      "Create Date": "2024-09-22 22:55",
      Type: "",
      "Street Address": "9850 Walnut Hill Lane",
      "Street Address 2": "405",
      city: "Dallas",
      "State/Region": "TX",
      "Postal Code": "75238",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Health, Wellness and Fitness",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-96.7158105, 32.8782015],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Machines", "Powerlifting", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "lake_highlands_strength_conditioning_dallas",
      images: [
        "https://live.staticflickr.com/65535/54073877449_f4e0706804.jpg",
      ],
      name: "Lake Highlands Strength & Conditioning",
      location: "Dallas, TX, United States",
    },
    {
      "Record ID": "23168960170",
      company_name: "Inspire Fitness Dallas",
      "Create Date": "2024-09-22 22:56",
      Type: "",
      "Street Address": "535 West Jefferson Boulevard",
      "Street Address 2": "",
      city: "Dallas",
      "State/Region": "TX",
      "Postal Code": "75208",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "+1 214-430-3993",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-96.831672668457, 32.7434997558594],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "LGBTQIA+", "Accessibility", "Weights"],
      rating: 5,
      reviews: 0,
      id: "inspire_fitness_dallas_dallas",
      images: [
        "https://live.staticflickr.com/65535/54073551546_db14b4db3c.jpg",
      ],
      name: "Inspire Fitness Dallas",
      location: "Dallas, TX, United States",
    },
    {
      "Record ID": "23168960270",
      company_name: "Hunger in the Wild",
      "Create Date": "2024-09-22 22:58",
      Type: "",
      "Street Address": "810 South Saint Paul Street",
      "Street Address 2": "",
      city: "Dallas",
      "State/Region": "TX",
      "Postal Code": "75201",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-96.7932510375977, 32.7765693664551],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Accessibility", "Powerlifting", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "hunger_in_the_wild_dallas",
      images: [
        "https://live.staticflickr.com/65535/54073551536_f0f75a4fa0.jpg",
      ],
      name: "Hunger in the Wild",
      location: "Dallas, TX, United States",
    },
    {
      "Record ID": "23168960259",
      company_name: "Verandah Fitness Club",
      "Create Date": "2024-09-22 22:58",
      Type: "",
      "Street Address": "1400 Wycliff Avenue",
      "Street Address 2": "",
      city: "Dallas",
      "State/Region": "TX",
      "Postal Code": "75207",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-96.80667, 32.78306],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "LGBTQIA+", "Accessibility", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "verandah_fitness_club_dallas",
      images: [
        "https://live.staticflickr.com/65535/54058088348_7896651942.jpg",
      ],
      name: "Verandah Fitness Club",
      location: "Dallas, TX, United States",
    },
    {
      "Record ID": "23169022730",
      company_name: "Precision Fitness",
      "Create Date": "2024-09-22 22:59",
      Type: "",
      "Street Address": "17390 Preston Road",
      "Street Address 2": "ste 360",
      city: "Dallas",
      "State/Region": "TX",
      "Postal Code": "75252",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "+1 469-466-8113",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-96.801513671875, 32.9900817871094],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Powerlifting", "Special", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "precision_fitness_dallas",
      images: ["https://live.staticflickr.com/0/53750360495_3c3ae54bb9.jpg"],
      name: "Precision Fitness",
      location: "Dallas, TX, United States",
    },
    {
      "Record ID": "23168960275",
      company_name: "MVEMENT",
      "Create Date": "2024-09-22 22:59",
      Type: "",
      "Street Address": "7560 Greenville Avenue",
      "Street Address 2": "",
      city: "Dallas",
      "State/Region": "TX",
      "Postal Code": "75231",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "+1 214-214-6831",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-96.7580822267759, 32.885019],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Cardio", "Pool", "Weights"],
      rating: 5,
      reviews: 0,
      id: "mvement_dallas",
      images: [
        "https://live.staticflickr.com/65535/53704814479_6cf8d70b1b.jpg",
      ],
      name: "MVEMENT",
      location: "Dallas, TX, United States",
    },
    {
      "Record ID": "23169022742",
      company_name: "EVOLVE",
      "Create Date": "2024-09-22 23:00",
      Type: "",
      "Street Address": "500 South Ervay Street",
      "Street Address 2": "",
      city: "Dallas",
      "State/Region": "TX",
      "Postal Code": "75201",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-96.7955017089844, 32.7773284912109],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "LGBTQIA+", "Accessibility", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "evolve_dallas",
      images: [
        "https://live.staticflickr.com/65535/53466434694_029f045857.jpg",
      ],
      name: "EVOLVE",
      location: "Dallas, TX, United States",
    },
    {
      "Record ID": "23169022737",
      company_name: "SWEAT",
      "Create Date": "2024-09-22 23:00",
      Type: "",
      "Street Address": "5470 West Lovers Lane",
      "Street Address 2": "335",
      city: "Dallas",
      "State/Region": "TX",
      "Postal Code": "75209",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-96.819607, 32.849614],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "Special", "Weights", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "sweat_dallas",
      images: [
        "https://live.staticflickr.com/65535/53465209157_41166ba630.jpg",
      ],
      name: "SWEAT",
      location: "Dallas, TX, United States",
    },
    {
      "Record ID": "23168960325",
      company_name: "Diesel Fitness",
      "Create Date": "2024-09-22 23:03",
      Type: "",
      "Street Address": "2901 Cityplace West Boulevard",
      "Street Address 2": "100",
      city: "Dallas",
      "State/Region": "TX",
      "Postal Code": "75204",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-96.7964693532864, 32.806315],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "LGBTQIA+", "Female Only", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "diesel_fitness_dallas",
      images: [
        "https://live.staticflickr.com/65535/53466538835_aff2eafe87.jpg",
      ],
      name: "Diesel Fitness",
      location: "Dallas, TX, United States",
    },
    {
      "Record ID": "23168960543",
      company_name: "Dallas Workout The Gym",
      "Create Date": "2024-09-22 23:18",
      Type: "",
      "Street Address": "3300 Oak Lawn Avenue",
      "Street Address 2": "100",
      city: "Dallas",
      "State/Region": "TX",
      "Postal Code": "75219",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "+1 214-826-1466",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-96.8071899414063, 32.8101387023926],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "LGBTQIA+", "Powerlifting", "Weights"],
      rating: 5,
      reviews: 0,
      id: "dallas_workout_the_gym_dallas",
      images: [
        "https://live.staticflickr.com/65535/53466254328_9d606b1e8a.jpg",
      ],
      name: "Dallas Workout The Gym",
      location: "Dallas, TX, United States",
    },
    {
      "Record ID": "23168960555",
      company_name: "Clairevista Vitality",
      "Create Date": "2024-09-22 23:19",
      Type: "",
      "Street Address": "707 Seale Street",
      "Street Address 2": "145",
      city: "Dallas",
      "State/Region": "TX",
      "Postal Code": "75208",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-96.8348569111955, 32.771788749813],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Weights", "Cardio", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "clairevista_vitality_dallas",
      images: [
        "https://live.staticflickr.com/65535/53395386436_bec22a0bf6.jpg",
      ],
      name: "Clairevista Vitality",
      location: "Dallas, TX, United States",
    },
    {
      "Record ID": "23169023029",
      company_name: "Svelte Performance",
      "Create Date": "2024-09-22 23:19",
      Type: "",
      "Street Address": "4101 Bryan St",
      "Street Address 2": "",
      city: "Dallas",
      "State/Region": "TX",
      "Postal Code": "75204",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "+1 214-736-9161",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-96.7810668945313, 32.7978782653809],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Powerlifting", "Machines", "Pool"],
      rating: 5,
      reviews: 0,
      id: "svelte_performance_dallas",
      images: [
        "https://live.staticflickr.com/65535/53337503014_580167208a.jpg",
      ],
      name: "Svelte Performance",
      location: "Dallas, TX, United States",
    },
  ],
  Birmingham: [
    {
      "Record ID": "23168958344",
      company_name: "John Reed Fitness",
      "Create Date": "2024-09-22 21:01",
      Type: "",
      "Street Address": "170 Edmund Street",
      "Street Address 2": "",
      city: "Birmingham",
      "State/Region": "West Midlands",
      "Postal Code": "B3 2JA",
      "Country/Region": "United Kingdom",
      "Time Zone": "Europe/London",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "3.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-1.900146, 52.482756],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Pool", "Machines", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "john_reed_fitness_birmingham",
      images: [
        "https://live.staticflickr.com/65535/54029979464_257f66dfd3.jpg",
      ],
      name: "John Reed Fitness",
      location: "Birmingham, West Midlands, United Kingdom",
    },
  ],
  Burbank: [
    {
      "Record ID": "23168958745",
      company_name: "FunXional Fitness",
      "Create Date": "2024-09-22 21:23",
      Type: "",
      "Street Address": "4111 West Alameda Avenue",
      "Street Address 2": "",
      city: "Burbank",
      "State/Region": "CA",
      "Postal Code": "91505",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-118.345174791429, 34.1528575],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Weights", "LGBTQIA+", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "funxional_fitness_burbank",
      images: [
        "https://live.staticflickr.com/65535/52078373422_1884edaac1.jpg",
      ],
      name: "FunXional Fitness",
      location: "Burbank, CA, United States",
    },
    {
      "Record ID": "23210340747",
      company_name: "Burbank Fitness Club",
      "Create Date": "2024-09-23 13:40",
      Type: "",
      "Street Address": "226 East Palm Avenue",
      "Street Address 2": "",
      city: "Burbank",
      "State/Region": "CA",
      "Postal Code": "91502",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-118.31028, 34.182544],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "Courts", "Female Only", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "burbank_fitness_club_burbank",
      images: [
        "https://live.staticflickr.com/65535/51002100961_c761a1ceac.jpg",
      ],
      name: "Burbank Fitness Club",
      location: "Burbank, CA, United States",
    },
  ],
  West_Hollywood: [
    {
      "Record ID": "23169021573",
      company_name: "FulHaus",
      "Create Date": "2024-09-22 21:51",
      Type: "",
      "Street Address": "8380 Santa Monica Boulevard",
      "Street Address 2": "Suite 105",
      city: "West Hollywood",
      "State/Region": "CA",
      "Postal Code": "90069",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "+1 424-285-0323",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-118.372821492958, 34.090496],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Special", "Cardio", "Weights"],
      rating: 5,
      reviews: 0,
      id: "fulhaus_west_hollywood",
      images: [
        "https://live.staticflickr.com/65535/53022885919_c8f644da13.jpg",
      ],
      name: "FulHaus",
      location: "West Hollywood, CA, United States",
    },
  ],
  Kansas_city: [
    {
      "Record ID": "23169021746",
      company_name: "Metro 24 Fitness",
      "Create Date": "2024-09-22 21:59",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Kansas City",
      "State/Region": "KS",
      "Postal Code": "",
      "Country/Region": "",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-94.731189, 39.106581],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Pool", "Machines", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "metro_24_fitness_kansas_city",
      images: [
        "https://live.staticflickr.com/65535/52554573907_bfa68fb78f.jpg",
      ],
      name: "Metro 24 Fitness",
      location: "Kansas City, KS, ",
    },
    {
      "Record ID": "23169021758",
      company_name: "Brickyard Fitness",
      "Create Date": "2024-09-22 22:01",
      Type: "",
      "Street Address": "2830 Guinotte Avenue",
      "Street Address 2": "",
      city: "Kansas City",
      "State/Region": "MO",
      "Postal Code": "64120",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "0.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-94.5468521118164, 39.1194915771484],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "LGBTQIA+", "Female Only", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "brickyard_fitness_kansas_city",
      images: [
        "https://live.staticflickr.com/65535/52551715146_614243b2d2.jpg",
      ],
      name: "Brickyard Fitness",
      location: "Kansas City, MO, United States",
    },
    {
      "Record ID": "23168959358",
      company_name: "Union Hill Athletic Club",
      "Create Date": "2024-09-22 22:02",
      Type: "",
      "Street Address": "2900 Gillham Road",
      "Street Address 2": "",
      city: "Kansas City",
      "State/Region": "MO",
      "Postal Code": "64108",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "+1 816-777-5970",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "0.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-94.5790481567383, 39.0740013122559],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Female Only", "Machines", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "union_hill_athletic_club_kansas_city",
      images: [
        "https://live.staticflickr.com/65535/52551693891_1380183ae0.jpg",
      ],
      name: "Union Hill Athletic Club",
      location: "Kansas City, MO, United States",
    },
    {
      "Record ID": "23169021787",
      company_name: "The Foundation Gyms",
      "Create Date": "2024-09-22 22:03",
      Type: "",
      "Street Address": "9521 Northeast 79th Street",
      "Street Address 2": "",
      city: "Kansas City",
      "State/Region": "MO",
      "Postal Code": "64158",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "2.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-94.4646145, 39.234295],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Powerlifting", "Accessibility", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "the_foundation_gyms_kansas_city",
      images: [
        "https://live.staticflickr.com/65535/51111673727_09f335d7b7.jpg",
      ],
      name: "The Foundation Gyms",
      location: "Kansas City, MO, United States",
    },
    {
      "Record ID": "23169021799",
      company_name: "Freight House Fitness",
      "Create Date": "2024-09-22 22:04",
      Type: "",
      "Street Address": "1618 Wyoming Street",
      "Street Address 2": "",
      city: "Kansas City",
      "State/Region": "MO",
      "Postal Code": "64102",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-94.6039964077061, 39.0938520127356],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Female Only", "Special", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "freight_house_fitness_kansas_city",
      images: [
        "https://live.staticflickr.com/65535/51113021720_9a8876f72a.jpg",
      ],
      name: "Freight House Fitness",
      location: "Kansas City, MO, United States",
    },
    {
      "Record ID": "23169021826",
      company_name: "Wellness Warehouse KC",
      "Create Date": "2024-09-22 22:05",
      Type: "",
      "Street Address": "1400 West 9th Street",
      "Street Address 2": "",
      city: "Kansas City",
      "State/Region": "MO",
      "Postal Code": "64101",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-94.6014013154684, 39.1045532506518],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Cardio", "Weights", "Special"],
      rating: 5,
      reviews: 0,
      id: "wellness_warehouse_kc_kansas_city",
      images: [
        "https://live.staticflickr.com/65535/51065654346_dae55fac11.jpg",
      ],
      name: "Wellness Warehouse KC",
      location: "Kansas City, MO, United States",
    },
    {
      "Record ID": "23169021848",
      company_name: "Olympic Gym KC",
      "Create Date": "2024-09-22 22:07",
      Type: "",
      "Street Address": "7932 North Oak Trafficway",
      "Street Address 2": "",
      city: "Kansas City",
      "State/Region": "MO",
      "Postal Code": "64118",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "+1 816-216-1578",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-94.5768190917565, 39.238739],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Machines", "Special", "Courts"],
      rating: 5,
      reviews: 0,
      id: "olympic_gym_kc_kansas_city",
      images: [
        "https://live.staticflickr.com/65535/51040250598_524de2f1e7.jpg",
      ],
      name: "Olympic Gym KC",
      location: "Kansas City, MO, United States",
    },
    {
      "Record ID": "23169021866",
      company_name: "Fountain City Fitness",
      "Create Date": "2024-09-22 22:08",
      Type: "",
      "Street Address": "7525 Wornall Road",
      "Street Address 2": "",
      city: "Kansas City",
      "State/Region": "MO",
      "Postal Code": "64114",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-94.5929887583855, 38.9908629021777],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Weights", "Courts", "Pool"],
      rating: 5,
      reviews: 0,
      id: "fountain_city_fitness_kansas_city",
      images: [
        "https://live.staticflickr.com/65535/51041072662_976274f2ed.jpg",
      ],
      name: "Fountain City Fitness",
      location: "Kansas City, MO, United States",
    },
  ],
  North_Kansas_city: [
    {
      "Record ID": "23169021748",
      company_name: "Flex Fitness Gym",
      "Create Date": "2024-09-22 22:00",
      Type: "",
      "Street Address": "1536 Burlington Street",
      "Street Address 2": "",
      city: "North Kansas City",
      "State/Region": "MO",
      "Postal Code": "64116",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-94.58058268425842, 39.13648138307553],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Pool", "Special", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "flex_fitness_gym_north_kansas_city",
      images: [null],
      name: "Flex Fitness Gym",
      location: "North Kansas City, MO, United States",
    },
    {
      "Record ID": "23168959415",
      company_name: "Zone 6 Fitness",
      "Create Date": "2024-09-22 22:06",
      Type: "",
      "Street Address": "2700 Burlington Street",
      "Street Address 2": "",
      city: "North Kansas City",
      "State/Region": "MO",
      "Postal Code": "64116",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "+1 816-956-1300",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-94.581377, 39.14871],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Accessibility", "Female Only", "Machines"],
      rating: 5,
      reviews: 0,
      id: "zone_6_fitness_north_kansas_city",
      images: [null],
      name: "Zone 6 Fitness",
      location: "North Kansas City, MO, United States",
    },
  ],
  Puerto_Vallarta: [
    {
      "Record ID": "23168959527",
      company_name: "Big Apple Gym",
      "Create Date": "2024-09-22 22:10",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Puerto Vallarta",
      "State/Region": "",
      "Postal Code": "",
      "Country/Region": "Mexico",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-105.217206, 20.647833],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Weights", "Courts", "Special"],
      rating: 5,
      reviews: 0,
      id: "big_apple_gym_puerto_vallarta",
      images: [
        "https://live.staticflickr.com/65535/51266518467_21bbac07cd.jpg",
      ],
      name: "Big Apple Gym",
      location: "Puerto Vallarta, , Mexico",
    },
  ],
  Atlanta: [
    {
      "Record ID": "23168959615",
      company_name: "The Refinery",
      "Create Date": "2024-09-22 22:18",
      Type: "",
      "Street Address": "2260 Marietta Boulevard Northwest",
      "Street Address 2": "108",
      city: "Atlanta",
      "State/Region": "GA",
      "Postal Code": "30318",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-84.448620669, 33.8172492420001],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "Special", "Pool", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "the_refinery_atlanta",
      images: [
        "https://live.staticflickr.com/65535/54085144725_1889de12c0.jpg",
      ],
      name: "The Refinery",
      location: "Atlanta, GA, United States",
    },
    {
      "Record ID": "23168959629",
      company_name: "Liv Fitness",
      "Create Date": "2024-09-22 22:20",
      Type: "",
      "Street Address": "1853 Memorial Drive Southeast",
      "Street Address 2": "",
      city: "Atlanta",
      "State/Region": "GA",
      "Postal Code": "30317",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 404-549-7767",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-84.3266630262584, 33.7459189288918],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Cardio", "Weights", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "liv_fitness_atlanta",
      images: [
        "https://live.staticflickr.com/65535/53965085520_4dc5dd86aa.jpg",
      ],
      name: "Liv Fitness",
      location: "Atlanta, GA, United States",
    },
    {
      "Record ID": "23168959648",
      company_name: "The Forum Athletic Club",
      active: true,
      "Create Date": "2024-09-22 22:21",
      Type: "",
      "Street Address": "3393 Peachtree Road Northeast",
      "Street Address 2": "2010a",
      city: "Atlanta",
      "State/Region": "GA",
      "Postal Code": "30326",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "0.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-84.3625183105469, 33.8466606140137],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Cardio", "Weights", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "the_forum_athletic_club_atlanta",
      images: [
        "https://live.staticflickr.com/65535/53951148120_b0467b70e3.jpg",
      ],
      name: "The Forum Athletic Club",
      location: "Atlanta, GA, United States",
    },
    {
      "Record ID": "23169022092",
      company_name: "The Hidden Gym",
      "Create Date": "2024-09-22 22:22",
      Type: "",
      "Street Address": "2011 Bolton Road Northwest",
      "Street Address 2": "#100",
      city: "Atlanta",
      "State/Region": "GA",
      "Postal Code": "30318",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 678-557-6005",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-84.4768425839728, 33.810365],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Machines", "Accessibility", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "the_hidden_gym_atlanta",
      images: [
        "https://live.staticflickr.com/65535/53897026225_ef775cab27.jpg",
      ],
      name: "The Hidden Gym",
      location: "Atlanta, GA, United States",
    },
    {
      "Record ID": "23168959668",
      company_name: "Gravity Fitness",
      "Create Date": "2024-09-22 22:23",
      Type: "",
      "Street Address": "2201 Faulkner Road Northeast",
      "Street Address 2": "",
      city: "Atlanta",
      "State/Region": "GA",
      "Postal Code": "30324",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-84.3577181200178, 33.813036],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Weights", "Courts", "Machines"],
      rating: 5,
      reviews: 0,
      id: "gravity_fitness_atlanta",
      images: [
        "https://live.staticflickr.com/65535/53896844658_15f1b679e5.jpg",
      ],
      name: "Gravity Fitness",
      location: "Atlanta, GA, United States",
    },
    {
      "Record ID": "23169022109",
      company_name: "Urban Body Fitness",
      "Create Date": "2024-09-22 22:24",
      Type: "",
      "Street Address": "500 Amsterdam Avenue Northeast",
      "Street Address 2": "",
      city: "Atlanta",
      "State/Region": "GA",
      "Postal Code": "30306",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-84.369518387, 33.7887226290001],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Cardio", "Weights", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "urban_body_fitness_atlanta",
      images: [
        "https://live.staticflickr.com/65535/53896600246_3370fd46a4.jpg",
      ],
      name: "Urban Body Fitness",
      location: "Atlanta, GA, United States",
    },
    {
      "Record ID": "23169022126",
      company_name: "E.F.F.E.C.T",
      "Create Date": "2024-09-22 22:25",
      Type: "",
      "Street Address": "1995b Metropolitan Parkway Southwest",
      "Street Address 2": "",
      city: "Atlanta",
      "State/Region": "GA",
      "Postal Code": "30315",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 404-254-0684",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-84.408416, 33.698916],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Courts", "Cardio", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "e_f_f_e_c_t_atlanta",
      images: [
        "https://live.staticflickr.com/65535/53895682452_eaf1e17ba2.jpg",
      ],
      name: "E.F.F.E.C.T",
      location: "Atlanta, GA, United States",
    },
    {
      "Record ID": "23168959776",
      company_name: "Village Fitness EAV",
      "Create Date": "2024-09-22 22:26",
      Type: "",
      "Street Address": "1231 Glenwood Avenue Southeast",
      "Street Address 2": "",
      city: "Atlanta",
      "State/Region": "GA",
      "Postal Code": "30316",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 678-705-4363",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-84.3472936896815, 33.7398036971557],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Female Only", "Pool", "Courts"],
      rating: 5,
      reviews: 0,
      id: "village_fitness_eav_atlanta",
      images: [
        "https://live.staticflickr.com/65535/53897026200_83a18e64be.jpg",
      ],
      name: "Village Fitness EAV",
      location: "Atlanta, GA, United States",
    },
    {
      "Record ID": "23169022221",
      company_name: "Resolution Fitness",
      "Create Date": "2024-09-22 22:27",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Atlanta",
      "State/Region": "GA",
      "Postal Code": "30303",
      "Country/Region": "",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "2.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-84.389938, 33.75327],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "Pool", "Courts", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "resolution_fitness_atlanta",
      images: [
        "https://live.staticflickr.com/65535/53896844633_f3b097bdd7.jpg",
      ],
      name: "Resolution Fitness",
      location: "Atlanta, GA, ",
    },
    {
      "Record ID": "23169022234",
      company_name: "RPM Fitness Center",
      "Create Date": "2024-09-22 22:28",
      Type: "",
      "Street Address": "600 Peachtree Street Northeast",
      "Street Address 2": "",
      city: "Atlanta",
      "State/Region": "GA",
      "Postal Code": "30308",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 404-874-4584",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-84.3861238333333, 33.770802],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Cardio", "Weights", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "rpm_fitness_center_atlanta",
      images: [
        "https://live.staticflickr.com/65535/53814201094_959ae9ac8b.jpg",
      ],
      name: "RPM Fitness Center",
      location: "Atlanta, GA, United States",
    },
    {
      "Record ID": "23169022331",
      company_name: "The Rack Athletic Performance Center",
      "Create Date": "2024-09-22 22:36",
      Type: "",
      "Street Address": "1824 Briarwood Industrial Court Northeast",
      "Street Address 2": "",
      city: "Atlanta",
      "State/Region": "GA",
      "Postal Code": "30329",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-84.3213179779373, 33.8414833261032],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Accessibility", "Machines", "Pool"],
      rating: 5,
      reviews: 0,
      id: "the_rack_athletic_performance_center_atlanta",
      images: [
        "https://live.staticflickr.com/65535/53814306745_a89f441da2.jpg",
      ],
      name: "The Rack Athletic Performance Center",
      location: "Atlanta, GA, United States",
    },
    {
      "Record ID": "23169022324",
      company_name: "Catalyst Fitness",
      "Create Date": "2024-09-22 22:36",
      Type: "",
      "Street Address": "2233 Peachtree Road",
      "Street Address 2": "Suite M",
      city: "Atlanta",
      "State/Region": "GA",
      "Postal Code": "30309",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-84.3910024812657, 33.8151585],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "LGBTQIA+", "Weights", "Machines"],
      rating: 5,
      reviews: 0,
      id: "catalyst_fitness_atlanta",
      images: [
        "https://live.staticflickr.com/65535/53814107833_6cc1791143.jpg",
      ],
      name: "Catalyst Fitness",
      location: "Atlanta, GA, United States",
    },
  ],
  Decatur: [
    {
      "Record ID": "23168959636",
      company_name: "Atlanta Barbell",
      "Create Date": "2024-09-22 22:20",
      Type: "",
      "Street Address": "185 Sams Street",
      "Street Address 2": "Suite B",
      city: "Decatur",
      "State/Region": "GA",
      "Postal Code": "30030",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 770-827-0287",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-84.2830485, 33.7717575],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Accessibility", "Special", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "atlanta_barbell_decatur",
      images: [
        "https://live.staticflickr.com/65535/51988730983_800024b29a.jpg",
      ],
      name: "Atlanta Barbell",
      location: "Decatur, GA, United States",
    },
  ],
  Pflugerville: [
    {
      "Record ID": "23169022359",
      company_name: "Metroflex Austin",
      "Create Date": "2024-09-22 22:38",
      Type: "",
      "Street Address": "16300 Central Commerce Drive",
      "Street Address 2": "BLDG 1A",
      city: "Pflugerville",
      "State/Region": "TX",
      "Postal Code": "78660",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-97.6614134110587, 30.4604085],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Cardio", "LGBTQIA+", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "metroflex_austin_pflugerville",
      images: ["https://live.staticflickr.com/530/20276189810_4536a5bc43.jpg"],
      name: "Metroflex Austin",
      location: "Pflugerville, TX, United States",
    },
  ],
  Austin: [
    {
      "Record ID": "23169022368",
      company_name: "Iron Vault Gym",
      "Create Date": "2024-09-22 22:39",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Austin",
      "State/Region": "TX",
      "Postal Code": "",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-97.74306, 30.26715],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Female Only", "LGBTQIA+", "Courts"],
      rating: 5,
      reviews: 0,
      id: "iron_vault_gym_austin",
      images: [
        "https://live.staticflickr.com/65535/53985607465_2db5332d32.jpg",
      ],
      name: "Iron Vault Gym",
      location: "Austin, TX, United States",
    },
    {
      "Record ID": "23169022466",
      company_name: "Castle Hill Fitness",
      "Create Date": "2024-09-22 22:41",
      Type: "",
      "Street Address": "1112 North Lamar Boulevard",
      "Street Address 2": "b",
      city: "Austin",
      "State/Region": "TX",
      "Postal Code": "78703",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-97.7520828406233, 30.276705],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "LGBTQIA+", "Accessibility", "Weights"],
      rating: 5,
      reviews: 0,
      id: "castle_hill_fitness_austin",
      images: [
        "https://live.staticflickr.com/65535/53984286182_1e8cf437dd.jpg",
      ],
      name: "Castle Hill Fitness",
      location: "Austin, TX, United States",
    },
    {
      "Record ID": "23168960010",
      company_name: "Onnit Gym",
      "Create Date": "2024-09-22 22:41",
      Type: "",
      "Street Address": "4401 Freidrich Lane",
      "Street Address 2": "301",
      city: "Austin",
      "State/Region": "TX",
      "Postal Code": "78744",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "+1 512-593-5437",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-97.7498579280156, 30.2084445],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Cardio", "Weights", "Machines"],
      rating: 5,
      reviews: 0,
      id: "onnit_gym_austin",
      images: [
        "https://live.staticflickr.com/65535/53985490654_2c3eb5ab27.jpg",
      ],
      name: "Onnit Gym",
      location: "Austin, TX, United States",
    },
    {
      "Record ID": "23168960019",
      company_name: "Big Tex Gym",
      "Create Date": "2024-09-22 22:42",
      Type: "",
      "Street Address": "1921 Cedar Bend Drive",
      "Street Address 2": "130a",
      city: "Austin",
      "State/Region": "TX",
      "Postal Code": "78758",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-97.6982433183854, 30.4083223318067],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Machines", "Accessibility", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "big_tex_gym_austin",
      images: [
        "https://live.staticflickr.com/65535/53985419793_1e70cd4193.jpg",
      ],
      name: "Big Tex Gym",
      location: "Austin, TX, United States",
    },
    {
      "Record ID": "23169022487",
      company_name: "The Austin Barbell Club",
      "Create Date": "2024-09-22 22:43",
      Type: "",
      "Street Address": "714 Shelby Lane",
      "Street Address 2": "#G",
      city: "Austin",
      "State/Region": "TX",
      "Postal Code": "78745",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "+1 512-522-7287",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-97.7597816280846, 30.2127733469021],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Powerlifting", "Weights", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "the_austin_barbell_club_austin",
      images: [
        "https://live.staticflickr.com/65535/53985419803_95fb7a636d.jpg",
      ],
      name: "The Austin Barbell Club",
      location: "Austin, TX, United States",
    },
    {
      "Record ID": "23168960035",
      company_name: "Gameday Barbell",
      "Create Date": "2024-09-22 22:44",
      Type: "",
      "Street Address": "1106 Smith Road",
      "Street Address 2": "108 110",
      city: "Austin",
      "State/Region": "TX",
      "Postal Code": "78721",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-97.6812183682008, 30.255576],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "LGBTQIA+", "Weights", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "gameday_barbell_austin",
      images: [
        "https://live.staticflickr.com/65535/53985419798_c107c74521.jpg",
      ],
      name: "Gameday Barbell",
      location: "Austin, TX, United States",
    },
    {
      "Record ID": "23169022505",
      company_name: "Athletic Outcomes",
      "Create Date": "2024-09-22 22:45",
      Type: "",
      "Street Address": "2301 East Riverside Drive",
      "Street Address 2": "300",
      city: "Austin",
      "State/Region": "TX",
      "Postal Code": "78741",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "+1 512-960-1913",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-97.726036338739, 30.2364905],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "Courts", "LGBTQIA+", "Pool"],
      rating: 5,
      reviews: 0,
      id: "athletic_outcomes_austin",
      images: [
        "https://live.staticflickr.com/65535/53985419788_05ca8d599b.jpg",
      ],
      name: "Athletic Outcomes",
      location: "Austin, TX, United States",
    },
  ],
  Buda: [
    {
      "Record ID": "23168960091",
      company_name: "Factory Gym",
      "Create Date": "2024-09-22 22:49",
      Type: "",
      "Street Address": "2581 South Loop 4",
      "Street Address 2": "",
      city: "Buda",
      "State/Region": "TX",
      "Postal Code": "78610",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "+1 512-545-3589",
      Industry: "Sporting Goods",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-97.8435367562478, 30.0460389173075],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Cardio", "Powerlifting", "Weights"],
      rating: 5,
      reviews: 0,
      id: "factory_gym_buda",
      images: ["https://live.staticflickr.com/4704/39592077772_f1057f441b.jpg"],
      name: "Factory Gym",
      location: "Buda, TX, United States",
    },
  ],
  Plano: [
    {
      "Record ID": "23168960108",
      company_name: "Destination Dallas",
      "Create Date": "2024-09-22 22:51",
      Type: "",
      "Street Address": "2655 Premier Drive",
      "Street Address 2": "",
      city: "Plano",
      "State/Region": "TX",
      "Postal Code": "75075",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Sporting Goods",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-96.7097017423547, 33.0335665],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "Accessibility", "Cardio", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "destination_dallas_plano",
      images: [
        "https://live.staticflickr.com/65535/53713640452_eea29a3a43.jpg",
      ],
      name: "Destination Dallas",
      location: "Plano, TX, United States",
    },
  ],
  Bartlesville: [
    {
      "Record ID": "23168960114",
      company_name: "Colaw Fitness",
      "Create Date": "2024-09-22 22:52",
      Type: "",
      "Street Address": "584 Southeast Washington Boulevard",
      "Street Address 2": "",
      city: "Bartlesville",
      "State/Region": "OK",
      "Postal Code": "74006",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-95.932551, 36.747892],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Courts", "Machines", "Pool"],
      rating: 5,
      reviews: 0,
      id: "colaw_fitness_bartlesville",
      images: [
        "https://live.staticflickr.com/65535/51591331942_938e6a6886.jpg",
      ],
      name: "Colaw Fitness",
      location: "Bartlesville, OK, United States",
    },
  ],
  Minato_ku: [
    {
      "Record ID": "23169022834",
      company_name: "Club 360",
      "Create Date": "2024-09-22 23:09",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Minato-ku",
      "State/Region": "Tōkyō-to",
      "Postal Code": "106-0046",
      "Country/Region": "Japan",
      "Time Zone": "Asia/Tokyo",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [139.7386, 35.6588],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Courts", "Pool", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "club_360_minato_ku",
      images: [
        "https://live.staticflickr.com/65535/51273368187_62420d74a1.jpg",
      ],
      name: "Club 360",
      location: "Minato-ku, Tōkyō-to, Japan",
    },
  ],
  Boston: [
    {
      "Record ID": "23204640781",
      company_name: "Beacon Hill Athletic Clubs",
      "Create Date": "2024-09-23 10:18",
      Type: "",
      "Street Address": "261 Friend Street",
      "Street Address 2": "",
      city: "Boston",
      "State/Region": "MA",
      "Postal Code": "2114",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-71.0613910297236, 42.3649981976079],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Special", "Accessibility", "Courts"],
      rating: 5,
      reviews: 0,
      id: "beacon_hill_athletic_clubs_boston",
      images: [
        "https://live.staticflickr.com/65535/53667430726_a02866907c.jpg",
      ],
      name: "Beacon Hill Athletic Clubs",
      location: "Boston, MA, United States",
    },
    {
      "Record ID": "23168982057",
      company_name: "Women’s Fitness of Boston",
      "Create Date": "2024-09-23 10:23",
      Type: "",
      "Street Address": "27 School Street",
      "Street Address 2": "",
      city: "Boston",
      "State/Region": "MA",
      "Postal Code": "2108",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 617-227-1221",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "0.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-71.1432050869565, 42.3596445],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Courts", "Special", "Weights"],
      rating: 5,
      reviews: 0,
      id: "women_s_fitness_of_boston_boston",
      images: [
        "https://live.staticflickr.com/65535/53667647628_ee72ed57b3.jpg",
      ],
      name: "Women’s Fitness of Boston",
      location: "Boston, MA, United States",
    },
    {
      "Record ID": "23168982176",
      company_name: "Iron Gym Fitness Center",
      "Create Date": "2024-09-23 10:25",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Boston",
      "State/Region": "MA",
      "Postal Code": "",
      "Country/Region": "USA",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-71.05977, 42.35843],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Weights", "Machines", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "iron_gym_fitness_center_boston",
      images: [
        "https://live.staticflickr.com/65535/53667430621_2185310713.jpg",
      ],
      name: "Iron Gym Fitness Center",
      location: "Boston, MA, USA",
    },
    {
      "Record ID": "23204641338",
      company_name: "SoWa Health + Wellness",
      "Create Date": "2024-09-23 10:26",
      Type: "",
      "Street Address": "560 Harrison Avenue",
      "Street Address 2": "",
      city: "Boston",
      "State/Region": "MA",
      "Postal Code": "2118",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 617-431-6262",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-71.0674357229751, 42.340837],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "LGBTQIA+", "Accessibility", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "sowa_health_wellness_boston",
      images: [
        "https://live.staticflickr.com/65535/53667890640_371a21015f.jpg",
      ],
      name: "SoWa Health + Wellness",
      location: "Boston, MA, United States",
    },
    {
      "Record ID": "23168982357",
      company_name: "Regan’s Motivated Fitness",
      "Create Date": "2024-09-23 10:27",
      Type: "",
      "Street Address": "315 West Broadway",
      "Street Address 2": "",
      city: "Boston",
      "State/Region": "MA",
      "Postal Code": "2127",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 617-269-5555",
      Industry: "Health, Wellness and Fitness",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-71.0505610594071, 42.3383881809562],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Cardio", "Weights", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "regan_s_motivated_fitness_boston",
      images: [
        "https://live.staticflickr.com/65535/53610363948_e745765eaa.jpg",
      ],
      name: "Regan’s Motivated Fitness",
      location: "Boston, MA, United States",
    },
    {
      "Record ID": "23204641434",
      company_name: "Commonwealth Sports",
      "Create Date": "2024-09-23 10:28",
      Type: "",
      "Street Address": "1079 Commonwealth Avenue",
      "Street Address 2": "",
      city: "Boston",
      "State/Region": "MA",
      "Postal Code": "2215",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-71.1244499558475, 42.3529535],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Special", "Machines", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "commonwealth_sports_boston",
      images: [
        "https://live.staticflickr.com/65535/53555692507_a892041798.jpg",
      ],
      name: "Commonwealth Sports",
      location: "Boston, MA, United States",
    },
    {
      "Record ID": "23204641549",
      company_name: "1 More Rep Athletics",
      "Create Date": "2024-09-23 10:29",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Boston",
      "State/Region": "MA",
      "Postal Code": "",
      "Country/Region": "",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-71.048611, 42.355492],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Special", "LGBTQIA+", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "1_more_rep_athletics_boston",
      images: [
        "https://live.staticflickr.com/65535/53556995760_f0cbda12c3.jpg",
      ],
      name: "1 More Rep Athletics",
      location: "Boston, MA, ",
    },
    {
      "Record ID": "23168982609",
      company_name: "Wave Health & Fitness",
      "Create Date": "2024-09-23 10:31",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Boston",
      "State/Region": "MA",
      "Postal Code": "2210",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "2.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-71.05977, 42.35843],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Powerlifting", "Courts", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "wave_health_fitness_boston",
      images: [
        "https://live.staticflickr.com/65535/53556543126_2bee33315b.jpg",
      ],
      name: "Wave Health & Fitness",
      location: "Boston, MA, United States",
    },
    {
      "Record ID": "23204641838",
      company_name: "Back Bay Wellness",
      "Create Date": "2024-09-23 10:32",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Boston",
      "State/Region": "MA",
      "Postal Code": "",
      "Country/Region": "USA",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-71.05977, 42.35843],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Cardio", "Special", "Weights"],
      rating: 5,
      reviews: 0,
      id: "back_bay_wellness_boston",
      images: [
        "https://live.staticflickr.com/65535/53556543111_a720b0e10b.jpg",
      ],
      name: "Back Bay Wellness",
      location: "Boston, MA, USA",
    },
    {
      "Record ID": "23204641888",
      company_name: "The Clubs at Charles River Park",
      "Create Date": "2024-09-23 10:33",
      Type: "",
      "Street Address": "10 Whittier Place",
      "Street Address 2": "",
      city: "Boston",
      "State/Region": "MA",
      "Postal Code": "2114",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "2.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-71.0674943570057, 42.3655147364192],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "Powerlifting", "Female Only", "Courts"],
      rating: 5,
      reviews: 0,
      id: "the_clubs_at_charles_river_park_boston",
      images: [
        "https://live.staticflickr.com/65535/53556995690_3fdd58184f.jpg",
      ],
      name: "The Clubs at Charles River Park",
      location: "Boston, MA, United States",
    },
    {
      "Record ID": "23204642129",
      company_name: "Healthworks Fitness",
      "Create Date": "2024-09-23 10:36",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Boston",
      "State/Region": "MA",
      "Postal Code": "2116",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "2.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-71.05977, 42.35843],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Pool", "Accessibility", "Weights"],
      rating: 5,
      reviews: 0,
      id: "healthworks_fitness_boston",
      images: [
        "https://live.staticflickr.com/65535/53556742523_783bbd3543.jpg",
      ],
      name: "Healthworks Fitness",
      location: "Boston, MA, United States",
    },
  ],
  Cambridge: [
    {
      "Record ID": "23204641206",
      company_name: "Cambridge Athletic Club",
      "Create Date": "2024-09-23 10:25",
      Type: "",
      "Street Address": "101 Main Street",
      "Street Address 2": "",
      city: "Cambridge",
      "State/Region": "MA",
      "Postal Code": "2142",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-71.0815716765251, 42.3623948789091],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Cardio", "Machines", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "cambridge_athletic_club_cambridge",
      images: [
        "https://live.staticflickr.com/65535/53875452176_b6258c00ac.jpg",
      ],
      name: "Cambridge Athletic Club",
      location: "Cambridge, MA, United States",
    },
  ],
  Brookline: [
    {
      "Record ID": "23168982926",
      company_name: "Benefitness Health Club",
      "Create Date": "2024-09-23 10:35",
      Type: "",
      "Street Address": "62 Harvard Street",
      "Street Address 2": "",
      city: "Brookline",
      "State/Region": "MA",
      "Postal Code": "2445",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-71.1200042632886, 42.3350828426438],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Courts", "Weights", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "benefitness_health_club_brookline",
      images: [
        "https://live.staticflickr.com/65535/54071787355_32226eaf4d.jpg",
      ],
      name: "Benefitness Health Club",
      location: "Brookline, MA, United States",
    },
  ],
  Seattle: [
    {
      "Record ID": "23168983472",
      company_name: "Cap Hill Fitness",
      "Create Date": "2024-09-23 10:42",
      Type: "",
      "Street Address": "1618 Bellevue Avenue",
      "Street Address 2": "",
      city: "Seattle",
      "State/Region": "WA",
      "Postal Code": "98122",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "+1 877-657-0004",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.326459177536, 47.6159105],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Accessibility", "Special", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "cap_hill_fitness_seattle",
      images: [
        "https://live.staticflickr.com/65535/54084548602_1f34cc2287.jpg",
      ],
      name: "Cap Hill Fitness",
      location: "Seattle, WA, United States",
    },
    {
      "Record ID": "23168983597",
      company_name: "Seattle Athletic Club",
      "Create Date": "2024-09-23 10:43",
      Type: "",
      "Street Address": "2020 Western Avenue",
      "Street Address 2": "",
      city: "Seattle",
      "State/Region": "WA",
      "Postal Code": "98121",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.343857332512, 47.610815],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Pool", "Machines", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "seattle_athletic_club_seattle",
      images: [
        "https://live.staticflickr.com/65535/53941533371_dc036c4451.jpg",
      ],
      name: "Seattle Athletic Club",
      location: "Seattle, WA, United States",
    },
    {
      "Record ID": "23204642736",
      company_name: "Henry's Gymnasium",
      "Create Date": "2024-09-23 10:44",
      Type: "",
      "Street Address": "14 Mercer Street",
      "Street Address 2": "",
      city: "Seattle",
      "State/Region": "WA",
      "Postal Code": "98109",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "+1 206-644-0969",
      Industry: "Recreational Facilities and Services",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.35615753644, 47.6247853679535],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Powerlifting", "LGBTQIA+", "Courts"],
      rating: 5,
      reviews: 0,
      id: "henry_s_gymnasium_seattle",
      images: [
        "https://live.staticflickr.com/65535/53791329529_ec305ffbb0.jpg",
      ],
      name: "Henry's Gymnasium",
      location: "Seattle, WA, United States",
    },
    {
      "Record ID": "23168983612",
      company_name: "Washington Athletic Club",
      "Create Date": "2024-09-23 10:44",
      Type: "",
      "Street Address": "1325 6th Avenue",
      "Street Address 2": "",
      city: "Seattle",
      "State/Region": "WA",
      "Postal Code": "98101",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "+1 206-622-7900",
      Industry: "Consumer Services",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.333584180375, 47.6097366710908],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Pool", "Special", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "washington_athletic_club_seattle",
      images: [
        "https://live.staticflickr.com/65535/53592775162_39fe392b14.jpg",
      ],
      name: "Washington Athletic Club",
      location: "Seattle, WA, United States",
    },
    {
      "Record ID": "23168983690",
      company_name: "Flow Fitness",
      "Create Date": "2024-09-23 10:45",
      Type: "",
      "Street Address": "311 Terry Avenue North",
      "Street Address 2": "",
      city: "Seattle",
      "State/Region": "WA",
      "Postal Code": "98109",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.337472545429, 47.6212213336608],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "LGBTQIA+", "Pool", "Special"],
      rating: 5,
      reviews: 0,
      id: "flow_fitness_seattle",
      images: [
        "https://live.staticflickr.com/65535/53593851873_6b8246d4e9.jpg",
      ],
      name: "Flow Fitness",
      location: "Seattle, WA, United States",
    },
    {
      "Record ID": "23204642832",
      company_name: "NORTHWEST STRONG",
      "Create Date": "2024-09-23 10:45",
      Type: "",
      "Street Address": "116 Vine Street",
      "Street Address 2": "",
      city: "Seattle",
      "State/Region": "WA",
      "Postal Code": "98121",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "+1 253-548-5005",
      Industry: "Health, Wellness and Fitness",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.349241631594, 47.6157495835896],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "LGBTQIA+", "Machines", "Pool"],
      rating: 5,
      reviews: 0,
      id: "northwest_strong_seattle",
      images: [
        "https://live.staticflickr.com/65535/53594095900_781f6d22c7.jpg",
      ],
      name: "NORTHWEST STRONG",
      location: "Seattle, WA, United States",
    },
    {
      "Record ID": "23204642914",
      company_name: "Olympic Athletic Club",
      "Create Date": "2024-09-23 10:46",
      Type: "",
      "Street Address": "5301 Leary Avenue Northwest",
      "Street Address 2": "",
      city: "Seattle",
      "State/Region": "WA",
      "Postal Code": "98107",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.382579356548, 47.6664767229507],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Powerlifting", "Accessibility", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "olympic_athletic_club_seattle",
      images: [
        "https://live.staticflickr.com/65535/53593649681_75893cb9c8.jpg",
      ],
      name: "Olympic Athletic Club",
      location: "Seattle, WA, United States",
    },
    {
      "Record ID": "23168983863",
      company_name: "Magnuson Athletic Club",
      "Create Date": "2024-09-23 10:47",
      Type: "",
      "Street Address": "7751 63rd Avenue Northeast",
      "Street Address 2": "",
      city: "Seattle",
      "State/Region": "WA",
      "Postal Code": "98115",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "+1 206-452-1500",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.26326575396, 47.686926],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Weights", "Pool", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "magnuson_athletic_club_seattle",
      images: [
        "https://live.staticflickr.com/65535/53594095915_24d6647c49.jpg",
      ],
      name: "Magnuson Athletic Club",
      location: "Seattle, WA, United States",
    },
    {
      "Record ID": "23204643161",
      company_name: "Transform 180",
      "Create Date": "2024-09-23 10:48",
      Type: "",
      "Street Address": "300 Fairview Avenue North",
      "Street Address 2": "",
      city: "Seattle",
      "State/Region": "WA",
      "Postal Code": "98109",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.333928185509, 47.6209734203274],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Weights", "Powerlifting", "Special"],
      rating: 5,
      reviews: 0,
      id: "transform_180_seattle",
      images: [
        "https://live.staticflickr.com/65535/53593969349_2347d2e1e1.jpg",
      ],
      name: "Transform 180",
      location: "Seattle, WA, United States",
    },
    {
      "Record ID": "23204643125",
      company_name: "ZŪM Fitness",
      "Create Date": "2024-09-23 10:48",
      Type: "",
      "Street Address": "1813 7th Avenue",
      "Street Address 2": "",
      city: "Seattle",
      "State/Region": "WA",
      "Postal Code": "98101",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "0.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.335727872822, 47.6138124697227],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Machines", "Powerlifting", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "z_m_fitness_seattle",
      images: [
        "https://live.staticflickr.com/65535/53593851778_442163e18c.jpg",
      ],
      name: "ZŪM Fitness",
      location: "Seattle, WA, United States",
    },
    {
      "Record ID": "23168984136",
      company_name: "Embody Strength + Wellness",
      "Create Date": "2024-09-23 10:50",
      Type: "",
      "Street Address": "4100 Aurora Ave N",
      "Street Address 2": "",
      city: "Seattle",
      "State/Region": "WA",
      "Postal Code": "98103",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Health, Wellness and Fitness",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.346871632903, 47.6568701907479],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Special", "Machines", "Pool"],
      rating: 5,
      reviews: 0,
      id: "embody_strength_wellness_seattle",
      images: [
        "https://live.staticflickr.com/65535/53593851798_c4417466f7.jpg",
      ],
      name: "Embody Strength + Wellness",
      location: "Seattle, WA, United States",
    },
    {
      "Record ID": "23168984201",
      company_name: "Rain City Fit",
      "Create Date": "2024-09-23 10:51",
      Type: "",
      "Street Address": "1205 East Pike Street",
      "Street Address 2": "",
      city: "Seattle",
      "State/Region": "WA",
      "Postal Code": "98122",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "+1 206-569-8601",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.316432822532, 47.6139207496597],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Female Only", "Accessibility", "Weights"],
      rating: 5,
      reviews: 0,
      id: "rain_city_fit_seattle",
      images: [
        "https://live.staticflickr.com/65535/53593649606_0d2054e7b7.jpg",
      ],
      name: "Rain City Fit",
      location: "Seattle, WA, United States",
    },
    {
      "Record ID": "23204643443",
      company_name: "Ballard Health Club",
      "Create Date": "2024-09-23 10:52",
      Type: "",
      "Street Address": "2208 Northwest Market Street",
      "Street Address 2": "",
      city: "Seattle",
      "State/Region": "WA",
      "Postal Code": "98107",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.385219316441, 47.6688378158414],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Pool", "Special", "Courts"],
      rating: 5,
      reviews: 0,
      id: "ballard_health_club_seattle",
      images: [
        "https://live.staticflickr.com/65535/53593649601_cdb5fa5fd3.jpg",
      ],
      name: "Ballard Health Club",
      location: "Seattle, WA, United States",
    },
    {
      "Record ID": "23168984340",
      company_name: "Innovative Health",
      "Create Date": "2024-09-23 10:52",
      Type: "",
      "Street Address": "1916 23rd Avenue South",
      "Street Address 2": "",
      city: "Seattle",
      "State/Region": "WA",
      "Postal Code": "98144",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "+1 206-390-6088",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.302506001121, 47.585805],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Special", "Powerlifting", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "innovative_health_seattle",
      images: [
        "https://live.staticflickr.com/65535/53594095815_fc6ff12289.jpg",
      ],
      name: "Innovative Health",
      location: "Seattle, WA, United States",
    },
    {
      "Record ID": "23204643473",
      company_name: "Demco Fitness",
      "Create Date": "2024-09-23 10:53",
      Type: "",
      "Street Address": "2324 Eastlake Avenue East",
      "Street Address 2": "",
      city: "Seattle",
      "State/Region": "WA",
      "Postal Code": "98102",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.325530264361, 47.6403973677417],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "LGBTQIA+", "Machines", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "demco_fitness_seattle",
      images: [
        "https://live.staticflickr.com/65535/53593851808_e231993869.jpg",
      ],
      name: "Demco Fitness",
      location: "Seattle, WA, United States",
    },
    {
      "Record ID": "23168984604",
      company_name: "Kyzen Athletic Club",
      "Create Date": "2024-09-23 10:55",
      Type: "",
      "Street Address": "1533 Minor Avenue",
      "Street Address 2": "",
      city: "Seattle",
      "State/Region": "WA",
      "Postal Code": "98101",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "+1 425-998-7531",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.328557599479, 47.6145597450549],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Special", "LGBTQIA+", "Machines"],
      rating: 5,
      reviews: 0,
      id: "kyzen_athletic_club_seattle",
      images: [
        "https://live.staticflickr.com/65535/53593649586_a3b9b3ea7d.jpg",
      ],
      name: "Kyzen Athletic Club",
      location: "Seattle, WA, United States",
    },
    {
      "Record ID": "23204643875",
      company_name: "Pro Fit Training Gym",
      "Create Date": "2024-09-23 10:57",
      Type: "",
      "Street Address": "915 8th Avenue North",
      "Street Address 2": "",
      city: "Seattle",
      "State/Region": "WA",
      "Postal Code": "98109",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.34124111461, 47.6275369525758],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Female Only", "Special", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "pro_fit_training_gym_seattle",
      images: [
        "https://live.staticflickr.com/65535/53593851693_26670ba9a9.jpg",
      ],
      name: "Pro Fit Training Gym",
      location: "Seattle, WA, United States",
    },
    {
      "Record ID": "23204643932",
      company_name: "West Seattle Health Club",
      "Create Date": "2024-09-23 10:58",
      Type: "",
      "Street Address": "2629 Southwest Andover Street",
      "Street Address 2": "",
      city: "Seattle",
      "State/Region": "WA",
      "Postal Code": "98126",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.367682659319, 47.5680666068474],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Special", "Female Only", "Weights"],
      rating: 5,
      reviews: 0,
      id: "west_seattle_health_club_seattle",
      images: [
        "https://live.staticflickr.com/65535/53593649546_669475cdb3.jpg",
      ],
      name: "West Seattle Health Club",
      location: "Seattle, WA, United States",
    },
    {
      "Record ID": "23168984871",
      company_name: "Fremont Health Club",
      "Create Date": "2024-09-23 10:59",
      Type: "",
      "Street Address": "920 North 34th Street",
      "Street Address 2": "200",
      city: "Seattle",
      "State/Region": "WA",
      "Postal Code": "98103",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.346164522089, 47.649306],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Powerlifting", "Cardio", "Special"],
      rating: 5,
      reviews: 0,
      id: "fremont_health_club_seattle",
      images: [
        "https://live.staticflickr.com/65535/53593649526_d3ff61b77f.jpg",
      ],
      name: "Fremont Health Club",
      location: "Seattle, WA, United States",
    },
    {
      "Record ID": "23168985004",
      company_name: "Belltown Strength and Conditioning",
      "Create Date": "2024-09-23 11:01",
      Type: "",
      "Street Address": "2315 Western Avenue",
      "Street Address 2": "",
      city: "Seattle",
      "State/Region": "WA",
      "Postal Code": "98121",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.348176639113, 47.612848],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "LGBTQIA+", "Powerlifting", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "belltown_strength_and_conditioning_seattle",
      images: [
        "https://live.staticflickr.com/65535/53593969249_a25d361709.jpg",
      ],
      name: "Belltown Strength and Conditioning",
      location: "Seattle, WA, United States",
    },
    {
      "Record ID": "23204644369",
      company_name: "Axtion Club",
      "Create Date": "2024-09-23 11:04",
      Type: "",
      "Street Address": "2602 2nd Avenue",
      "Street Address 2": "",
      city: "Seattle",
      "State/Region": "WA",
      "Postal Code": "98121",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.348941828344, 47.6161967479262],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Special", "Courts", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "axtion_club_seattle",
      images: [
        "https://live.staticflickr.com/65535/53593649551_983086b332.jpg",
      ],
      name: "Axtion Club",
      location: "Seattle, WA, United States",
    },
    {
      "Record ID": "23204644406",
      company_name: "Athletic Form",
      "Create Date": "2024-09-23 11:05",
      Type: "",
      "Street Address": "1031 6th Avenue South",
      "Street Address 2": "",
      city: "Seattle",
      "State/Region": "WA",
      "Postal Code": "98134",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.326460286662, 47.5934007108532],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Courts", "Special", "Machines"],
      rating: 5,
      reviews: 0,
      id: "athletic_form_seattle",
      images: [
        "https://live.staticflickr.com/65535/53593851688_0aa5906eb0.jpg",
      ],
      name: "Athletic Form",
      location: "Seattle, WA, United States",
    },
    {
      "Record ID": "23204644451",
      company_name: "FUELhouse",
      "Create Date": "2024-09-23 11:06",
      Type: "",
      "Street Address": "1320 North 35th Street",
      "Street Address 2": "",
      city: "Seattle",
      "State/Region": "WA",
      "Postal Code": "98103",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "+1 425-766-2834",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.341724, 47.6495125],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Pool", "Courts", "Weights"],
      rating: 5,
      reviews: 0,
      id: "fuelhouse_seattle",
      images: [
        "https://live.staticflickr.com/65535/53593851728_4195b22446.jpg",
      ],
      name: "FUELhouse",
      location: "Seattle, WA, United States",
    },
    {
      "Record ID": "23204644537",
      company_name: "Columbia City Fitness Center",
      "Create Date": "2024-09-23 11:08",
      Type: "",
      "Street Address": "4860 Rainier Avenue South",
      "Street Address 2": "",
      city: "Seattle",
      "State/Region": "WA",
      "Postal Code": "98118",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.28485375, 47.558327],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Accessibility", "Weights", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "columbia_city_fitness_center_seattle",
      images: [
        "https://live.staticflickr.com/65535/53593649471_bf96e4bb7a.jpg",
      ],
      name: "Columbia City Fitness Center",
      location: "Seattle, WA, United States",
    },
    {
      "Record ID": "23168985668",
      company_name: "Flex Training Gym",
      "Create Date": "2024-09-23 11:09",
      Type: "",
      "Street Address": "1920 East Madison Street",
      "Street Address 2": "",
      city: "Seattle",
      "State/Region": "WA",
      "Postal Code": "98122",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "0.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.306685772952, 47.617327547625],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Cardio", "Special", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "flex_training_gym_seattle",
      images: [
        "https://live.staticflickr.com/65535/53592774957_53b575bbc9.jpg",
      ],
      name: "Flex Training Gym",
      location: "Seattle, WA, United States",
    },
    {
      "Record ID": "23168985693",
      company_name: "Village Fitness Seattle",
      "Create Date": "2024-09-23 11:09",
      Type: "",
      "Street Address": "3111 West McGraw Street",
      "Street Address 2": "",
      city: "Seattle",
      "State/Region": "WA",
      "Postal Code": "98199",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "+1 206-552-9482",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.397514196718, 47.639329],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "LGBTQIA+", "Female Only", "Special"],
      rating: 5,
      reviews: 0,
      id: "village_fitness_seattle_seattle",
      images: [
        "https://live.staticflickr.com/65535/53593969199_1f0f8fd3ac.jpg",
      ],
      name: "Village Fitness Seattle",
      location: "Seattle, WA, United States",
    },
    {
      "Record ID": "23204644995",
      company_name: "Community Fitness",
      "Create Date": "2024-09-23 11:13",
      Type: "",
      "Street Address": "6108 Roosevelt Way Northeast",
      "Street Address 2": "",
      city: "Seattle",
      "State/Region": "WA",
      "Postal Code": "98115",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "2.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.317191099694, 47.6729751487235],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Accessibility", "Powerlifting", "Special"],
      rating: 5,
      reviews: 0,
      id: "community_fitness_seattle",
      images: [
        "https://live.staticflickr.com/65535/53594095695_202064d98b.jpg",
      ],
      name: "Community Fitness",
      location: "Seattle, WA, United States",
    },
  ],
  Everett: [
    {
      "Record ID": "23168984451",
      company_name: "Big Iron Gym",
      "Create Date": "2024-09-23 10:54",
      Type: "",
      "Street Address": "2201 Everett Avenue",
      "Street Address 2": "",
      city: "Everett",
      "State/Region": "WA",
      "Postal Code": "98201",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "+1 425-212-9151",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.199679737425, 47.9822685],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Pool", "Accessibility", "Weights"],
      rating: 5,
      reviews: 0,
      id: "big_iron_gym_everett",
      images: [
        "https://live.staticflickr.com/65535/53463136829_7c292907cb.jpg",
      ],
      name: "Big Iron Gym",
      location: "Everett, WA, United States",
    },
    {
      "Record ID": "23168984952",
      company_name: "Emerald City Athletics",
      "Create Date": "2024-09-23 11:00",
      Type: "",
      "Street Address": "10110 Evergreen Way",
      "Street Address 2": "",
      city: "Everett",
      "State/Region": "WA",
      "Postal Code": "98204",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "0.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-122.24405000074, 47.9061015],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Cardio", "Courts", "Weights"],
      rating: 5,
      reviews: 0,
      id: "emerald_city_athletics_everett",
      images: [
        "https://live.staticflickr.com/65535/51835923917_f119e1aecd.jpg",
      ],
      name: "Emerald City Athletics",
      location: "Everett, WA, United States",
    },
  ],
  North_Las_Vegas: [
    {
      "Record ID": "23168986522",
      company_name: "Las Vegas Athletic Clubs",
      active: true,
      "Create Date": "2024-09-23 11:20",
      Type: "",
      "Street Address": "6050 North Decatur Boulevard",
      "Street Address 2": "",
      city: "North Las Vegas",
      "State/Region": "NV",
      "Postal Code": "89031",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "+1 702-734-8944",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-115.203598365, 36.269617038],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Weights", "Powerlifting", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "las_vegas_athletic_clubs_north_las_vegas",
      images: ["https://live.staticflickr.com/7255/7631049290_a4a1330e54.jpg"],
      name: "Las Vegas Athletic Clubs",
      location: "North Las Vegas, NV, United States",
    },
  ],
  Henderson: [
    {
      "Record ID": "23209278355",
      company_name: "PROTOGYM",
      "Create Date": "2024-09-23 13:17",
      Type: "",
      "Street Address": "72 West Horizon Ridge Parkway",
      "Street Address 2": "110",
      city: "Henderson",
      "State/Region": "NV",
      "Postal Code": "89012",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-114.992593041543, 36.013171],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Cardio", "Pool", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "protogym_henderson",
      images: [
        "https://live.staticflickr.com/65535/53745469412_58e071828d.jpg",
      ],
      name: "PROTOGYM",
      location: "Henderson, NV, United States",
    },
  ],
  Amsterdam: [
    {
      "Record ID": "23210339630",
      company_name: "The Gym Amsterdam",
      "Create Date": "2024-09-23 13:24",
      Type: "",
      "Street Address": "161 Ruysdaelkade",
      "Street Address 2": "",
      city: "Amsterdam",
      "State/Region": "Noord-Holland",
      "Postal Code": "1072AS",
      "Country/Region": "Netherlands",
      "Time Zone": "Europe/Amsterdam",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [4.887189, 52.353214],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Cardio", "Pool", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "the_gym_amsterdam_amsterdam",
      images: [
        "https://live.staticflickr.com/65535/53916017773_4dc6d329fa.jpg",
      ],
      name: "The Gym Amsterdam",
      location: "Amsterdam, Noord-Holland, Netherlands",
    },
    {
      "Record ID": "23210339592",
      company_name: "Olympic Gym Amsterdam",
      "Create Date": "2024-09-23 13:24",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Amsterdam",
      "State/Region": "",
      "Postal Code": "",
      "Country/Region": "Amsterdam",
      "Time Zone": "Europe/Amsterdam",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [4.89973, 52.37243],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Special", "Cardio", "Machines"],
      rating: 5,
      reviews: 0,
      id: "olympic_gym_amsterdam_amsterdam",
      images: [
        "https://live.staticflickr.com/65535/53143916998_e5dd6583a0.jpg",
      ],
      name: "Olympic Gym Amsterdam",
      location: "Amsterdam, , Amsterdam",
    },
    {
      "Record ID": "23210339753",
      company_name: "Health Club Jordaan",
      "Create Date": "2024-09-23 13:26",
      Type: "",
      "Street Address": "10 Eerste Rozendwarsstraat",
      "Street Address 2": "",
      city: "Amsterdam",
      "State/Region": "Noord-Holland",
      "Postal Code": "1016PC",
      "Country/Region": "Netherlands",
      "Time Zone": "Europe/Amsterdam",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [4.880329, 52.372939],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Courts", "Powerlifting", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "health_club_jordaan_amsterdam",
      images: [
        "https://live.staticflickr.com/65535/53131921342_d8931c74eb.jpg",
      ],
      name: "Health Club Jordaan",
      location: "Amsterdam, Noord-Holland, Netherlands",
    },
    {
      "Record ID": "23210339913",
      company_name: "The Garage Gym",
      active: true,
      "Create Date": "2024-09-23 13:28",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Amsterdam",
      "State/Region": "",
      "Postal Code": "",
      "Country/Region": "The Netherlands",
      "Time Zone": "Europe/Amsterdam",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [4.89973, 52.37243],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "LGBTQIA+", "Special", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "the_garage_gym_amsterdam",
      images: [
        "https://live.staticflickr.com/65535/53053253145_3ea99b3748.jpg",
      ],
      name: "The Garage Gym",
      location: "Amsterdam, , The Netherlands",
    },
    {
      "Record ID": "23210339944",
      company_name: "Vondelgym",
      "Create Date": "2024-09-23 13:29",
      Type: "",
      "Street Address": "143 Overtoom",
      "Street Address 2": "",
      city: "Amsterdam",
      "State/Region": "Noord-Holland",
      "Postal Code": "",
      "Country/Region": "Netherlands",
      "Time Zone": "Europe/Amsterdam",
      "Phone Number": "",
      Industry: "Sporting Goods",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [4.87285, 52.36191],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Female Only", "Accessibility", "Courts"],
      rating: 5,
      reviews: 0,
      id: "vondelgym_amsterdam",
      images: [
        "https://live.staticflickr.com/65535/53028116079_382474377d.jpg",
      ],
      name: "Vondelgym",
      location: "Amsterdam, Noord-Holland, Netherlands",
    },
    {
      "Record ID": "23209282912",
      company_name: "Het Gymlokaal",
      "Create Date": "2024-09-23 14:16",
      Type: "",
      "Street Address": "1 Doctor Jan van Breemenstraat",
      "Street Address 2": "",
      city: "Amsterdam",
      "State/Region": "Noord-Holland",
      "Postal Code": "1056AB",
      "Country/Region": "Netherlands",
      "Time Zone": "Europe/Amsterdam",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "2.0",
      "Company owner": "Jordan Robinson",
      coordinates: [4.843713, 52.369955],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Accessibility", "Female Only", "Machines"],
      rating: 5,
      reviews: 0,
      id: "het_gymlokaal_amsterdam",
      images: [
        "https://live.staticflickr.com/65535/52935035337_b279b54327.jpg",
      ],
      name: "Het Gymlokaal",
      location: "Amsterdam, Noord-Holland, Netherlands",
    },
    {
      "Record ID": "23210343691",
      company_name: "The Temple",
      "Create Date": "2024-09-23 14:18",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Amsterdam",
      "State/Region": "",
      "Postal Code": "",
      "Country/Region": "The Netherlands",
      "Time Zone": "Europe/Amsterdam",
      "Phone Number": "",
      Industry: "Individual & Family Services",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [4.89973, 52.37243],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Weights", "Special", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "the_temple_amsterdam",
      images: [
        "https://live.staticflickr.com/65535/52931815784_2f3c014a12.jpg",
      ],
      name: "The Temple",
      location: "Amsterdam, , The Netherlands",
    },
    {
      "Record ID": "23210343811",
      company_name: "FemmeGym",
      "Create Date": "2024-09-23 14:20",
      Type: "",
      "Street Address": "6 Vancouverstraat",
      "Street Address 2": "",
      city: "Amsterdam",
      "State/Region": "Noord-Holland",
      "Postal Code": "1056DV",
      "Country/Region": "Netherlands",
      "Time Zone": "Europe/Amsterdam",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [4.854656, 52.373312],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Powerlifting", "Female Only", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "femmegym_amsterdam",
      images: [
        "https://live.staticflickr.com/65535/52889004513_1dd36b7c4c.jpg",
      ],
      name: "FemmeGym",
      location: "Amsterdam, Noord-Holland, Netherlands",
    },
    {
      "Record ID": "23209283329",
      company_name: "Studio Forty Five",
      "Create Date": "2024-09-23 14:21",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Amsterdam",
      "State/Region": "",
      "Postal Code": "",
      "Country/Region": "The Netherlands",
      "Time Zone": "Europe/Amsterdam",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [4.89973, 52.37243],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Cardio", "Machines", "Courts"],
      rating: 5,
      reviews: 0,
      id: "studio_forty_five_amsterdam",
      images: [
        "https://live.staticflickr.com/65535/52864729167_f444c612a9.jpg",
      ],
      name: "Studio Forty Five",
      location: "Amsterdam, , The Netherlands",
    },
    {
      "Record ID": "23209283466",
      company_name: "The Crunch",
      "Create Date": "2024-09-23 14:24",
      Type: "",
      "Street Address": "44 Vlierweg",
      "Street Address 2": "",
      city: "Amsterdam",
      "State/Region": "Noord-Holland",
      "Postal Code": "1032LG",
      "Country/Region": "Netherlands",
      "Time Zone": "Europe/Amsterdam",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [4.910712, 52.396137],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Weights", "Accessibility", "Machines"],
      rating: 5,
      reviews: 0,
      id: "the_crunch_amsterdam",
      images: [
        "https://live.staticflickr.com/65535/52678530624_3c3447b39d.jpg",
      ],
      name: "The Crunch",
      location: "Amsterdam, Noord-Holland, Netherlands",
    },
    {
      "Record ID": "23210344369",
      company_name: "MonsterGym",
      "Create Date": "2024-09-23 14:27",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Amsterdam",
      "State/Region": "",
      "Postal Code": "",
      "Country/Region": "Netherlands",
      "Time Zone": "Europe/Amsterdam",
      "Phone Number": "",
      Industry: "Sporting Goods",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [4.89973, 52.37243],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Weights", "Special", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "monstergym_amsterdam",
      images: [
        "https://live.staticflickr.com/65535/49768386726_33f6d6ec5f.jpg",
      ],
      name: "MonsterGym",
      location: "Amsterdam, , Netherlands",
    },
    {
      "Record ID": "23209283877",
      company_name: "Wijs Gym & Fysio",
      "Create Date": "2024-09-23 14:28",
      Type: "",
      "Street Address": "45A Tweede Hugo de Grootstraat",
      "Street Address 2": "",
      city: "Amsterdam",
      "State/Region": "Noord-Holland",
      "Postal Code": "1052LB",
      "Country/Region": "Netherlands",
      "Time Zone": "Europe/Amsterdam",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [4.870961, 52.374892],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Weights", "Pool", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "wijs_gym_fysio_amsterdam",
      images: [
        "https://live.staticflickr.com/65535/51488433636_1def2d1b3e.jpg",
      ],
      name: "Wijs Gym & Fysio",
      location: "Amsterdam, Noord-Holland, Netherlands",
    },
    {
      "Record ID": "23209283943",
      company_name: "Gympact",
      "Create Date": "2024-09-23 14:30",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Amsterdam",
      "State/Region": "",
      "Postal Code": "",
      "Country/Region": "The Netherlands",
      "Time Zone": "Europe/Amsterdam",
      "Phone Number": "",
      Industry: "Education Management",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [4.89973, 52.37243],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Cardio", "Special", "Courts"],
      rating: 5,
      reviews: 0,
      id: "gympact_amsterdam",
      images: [
        "https://live.staticflickr.com/65535/52434416674_6819c6e9b1.jpg",
      ],
      name: "Gympact",
      location: "Amsterdam, , The Netherlands",
    },
    {
      "Record ID": "23210346128",
      company_name: "B GYM",
      "Create Date": "2024-09-23 14:52",
      Type: "",
      "Street Address": "Johan Huizingalaan 763",
      "Street Address 2": "",
      city: "Amsterdam",
      "State/Region": "",
      "Postal Code": "",
      "Country/Region": "Netherlands",
      "Time Zone": "Europe/Amsterdam",
      "Phone Number": "+31 20 244 1608",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [4.829479, 52.343023],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Powerlifting", "Courts", "Machines"],
      rating: 5,
      reviews: 0,
      id: "b_gym_amsterdam",
      images: [
        "https://live.staticflickr.com/65535/46842182695_9ba5127e47.jpg",
      ],
      name: "B GYM",
      location: "Amsterdam, , Netherlands",
    },
    {
      "Record ID": "23209285713",
      company_name: "STRETCH & fold",
      "Create Date": "2024-09-23 14:53",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Amsterdam",
      "State/Region": "",
      "Postal Code": "",
      "Country/Region": "Netherlands",
      "Time Zone": "Europe/Amsterdam",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [4.89973, 52.37243],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Weights", "Powerlifting", "Special"],
      rating: 5,
      reviews: 0,
      id: "stretch_fold_amsterdam",
      images: [
        "https://live.staticflickr.com/65535/52407653358_5ef8183520.jpg",
      ],
      name: "STRETCH & fold",
      location: "Amsterdam, , Netherlands",
    },
    {
      "Record ID": "23209285751",
      company_name: "Sportquest",
      "Create Date": "2024-09-23 14:54",
      Type: "",
      "Street Address": "7 Hobbemakade",
      "Street Address 2": "",
      city: "Amsterdam",
      "State/Region": "Noord-Holland",
      "Postal Code": "1071XK",
      "Country/Region": "Netherlands",
      "Time Zone": "Europe/Amsterdam",
      "Phone Number": "",
      Industry: "Education Management",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [4.886131, 52.358374],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Powerlifting", "Special", "Machines"],
      rating: 5,
      reviews: 0,
      id: "sportquest_amsterdam",
      images: [
        "https://live.staticflickr.com/65535/52400341427_70f2637516.jpg",
      ],
      name: "Sportquest",
      location: "Amsterdam, Noord-Holland, Netherlands",
    },
  ],
  South_Pasadena: [
    {
      "Record ID": "23210340456",
      company_name: "Southpas Fitness",
      "Create Date": "2024-09-23 13:36",
      Type: "",
      "Street Address": "220 Pasadena Avenue",
      "Street Address 2": "",
      city: "South Pasadena",
      "State/Region": "CA",
      "Postal Code": "91030",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "+1 626-345-5425",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-118.169610257061, 34.1126446833867],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Machines", "Cardio", "Special"],
      rating: 5,
      reviews: 0,
      id: "southpas_fitness_south_pasadena",
      images: ["https://live.staticflickr.com/3451/3359306752_13dfde6ea7.jpg"],
      name: "Southpas Fitness",
      location: "South Pasadena, CA, United States",
    },
  ],
  Long_Beach: [
    {
      "Record ID": "23210341038",
      company_name: "Iconix Fitness",
      "Create Date": "2024-09-23 13:44",
      Type: "",
      "Street Address": "4101 East Olympic Plaza",
      "Street Address 2": "",
      city: "Long Beach",
      "State/Region": "CA",
      "Postal Code": "90803",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-118.145335301611, 33.7590774407509],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Machines", "Special", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "iconix_fitness_long_beach",
      images: [
        "https://live.staticflickr.com/65535/54028785595_63946421aa.jpg",
      ],
      name: "Iconix Fitness",
      location: "Long Beach, CA, United States",
    },
  ],
  Santa_Monica: [
    {
      "Record ID": "23209281054",
      company_name: "IRON LA",
      "Create Date": "2024-09-23 13:52",
      Type: "",
      "Street Address": "1919 Broadway",
      "Street Address 2": "",
      city: "Santa Monica",
      "State/Region": "CA",
      "Postal Code": "90404",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "0.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-118.479329477132, 34.02699],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Courts", "Cardio", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "iron_la_santa_monica",
      images: [
        "https://live.staticflickr.com/65535/53961445901_968658d208.jpg",
      ],
      name: "IRON LA",
      location: "Santa Monica, CA, United States",
    },
  ],
  Torrance: [
    {
      "Record ID": "23209281142",
      company_name: "Dave's Powerhouse Gym",
      "Create Date": "2024-09-23 13:53",
      Type: "",
      "Street Address": "22715 Hawthorne Blvd",
      "Street Address 2": "",
      city: "Torrance",
      "State/Region": "CA",
      "Postal Code": "90505",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-118.351599522667, 33.8219756271634],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Courts", "Special", "Weights"],
      rating: 5,
      reviews: 0,
      id: "dave_s_powerhouse_gym_torrance",
      images: [
        "https://live.staticflickr.com/65535/54042581466_8378033aed.jpg",
      ],
      name: "Dave's Powerhouse Gym",
      location: "Torrance, CA, United States",
    },
  ],
  Aledo: [
    {
      "Record ID": "23209281294",
      company_name: "MadHouse Gains Asylum",
      "Create Date": "2024-09-23 13:55",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Aledo",
      "State/Region": "IL",
      "Postal Code": "61231",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-90.74931, 41.19976],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Machines", "Courts", "Special"],
      rating: 5,
      reviews: 0,
      id: "madhouse_gains_asylum_aledo",
      images: [null],
      name: "MadHouse Gains Asylum",
      location: "Aledo, IL, United States",
    },
  ],
  Hoofddorp: [
    {
      "Record ID": "23210344273",
      company_name: "Basic-Fit",
      "Create Date": "2024-09-23 14:26",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Hoofddorp",
      "State/Region": "North Holland",
      "Postal Code": "2131HC",
      "Country/Region": "Netherlands",
      "Time Zone": "Europe/Amsterdam",
      "Phone Number": "",
      Industry: "Health, Wellness and Fitness",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [4.68889, 52.3025],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Machines", "Special", "Weights"],
      rating: 5,
      reviews: 0,
      id: "basic_fit_hoofddorp",
      images: ["https://live.staticflickr.com/5187/5661560416_46835acf6b.jpg"],
      name: "Basic-Fit",
      location: "Hoofddorp, North Holland, Netherlands",
    },
  ],
  Leiderdorp: [
    {
      "Record ID": "23209283915",
      company_name: "SportCity",
      "Create Date": "2024-09-23 14:29",
      Type: "",
      "Street Address": "19 Elisabethhof",
      "Street Address 2": "",
      city: "Leiderdorp",
      "State/Region": "Zuid-Holland",
      "Postal Code": "2353EW",
      "Country/Region": "Netherlands",
      "Time Zone": "Europe/Amsterdam",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [4.546031, 52.159128],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Cardio", "Powerlifting", "Weights"],
      rating: 5,
      reviews: 0,
      id: "sportcity_leiderdorp",
      images: [null],
      name: "SportCity",
      location: "Leiderdorp, Zuid-Holland, Netherlands",
    },
  ],
  El_Paso: [
    {
      "Record ID": "23210357074",
      company_name: "Just Play Fitness",
      "Create Date": "2024-09-23 17:45",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "El Paso",
      "State/Region": "Texas",
      "Postal Code": "",
      "Country/Region": "",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "Health, Wellness and Fitness",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-106.488793, 31.818409],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Weights", "LGBTQIA+", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "just_play_fitness_el_paso",
      images: [
        "https://live.staticflickr.com/65535/50113640402_33e72ee794.jpg",
      ],
      name: "Just Play Fitness",
      location: "El Paso, Texas, ",
    },
    {
      "Record ID": "23210357170",
      company_name: "Sun City Athletic Club",
      "Create Date": "2024-09-23 17:48",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "El Paso",
      "State/Region": "Texas",
      "Postal Code": "",
      "Country/Region": "",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-106.488793, 31.818409],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Pool", "Machines", "Weights"],
      rating: 5,
      reviews: 0,
      id: "sun_city_athletic_club_el_paso",
      images: ["https://live.staticflickr.com/1850/42583739180_cbdd859950.jpg"],
      name: "Sun City Athletic Club",
      location: "El Paso, Texas, ",
    },
    {
      "Record ID": "23210357266",
      company_name: "Uncommon Fitness West",
      "Create Date": "2024-09-23 17:49",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "El Paso",
      "State/Region": "Texas",
      "Postal Code": "",
      "Country/Region": "",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-106.488793, 31.818409],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Powerlifting", "Accessibility", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "uncommon_fitness_west_el_paso",
      images: ["https://live.staticflickr.com/4321/35927403226_072215ebdf.jpg"],
      name: "Uncommon Fitness West",
      location: "El Paso, Texas, ",
    },
    {
      "Record ID": "23210357292",
      company_name: "Redskull Fitness",
      "Create Date": "2024-09-23 17:50",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "El Paso",
      "State/Region": "Texas",
      "Postal Code": "",
      "Country/Region": "",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-106.488793, 31.818409],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Powerlifting", "Machines", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "redskull_fitness_el_paso",
      images: ["https://live.staticflickr.com/4295/35579721570_186de3005f.jpg"],
      name: "Redskull Fitness",
      location: "El Paso, Texas, ",
    },
    {
      "Record ID": "23210357316",
      company_name: "IronGrind True Fitness",
      "Create Date": "2024-09-23 17:51",
      Type: "",
      "Street Address": "10934 Montana Avenue",
      "Street Address 2": "",
      city: "El Paso",
      "State/Region": "TX",
      "Postal Code": "79936",
      "Country/Region": "United States",
      "Time Zone": "America/Denver",
      "Phone Number": "+1 915-206-5953",
      Industry: "Health, Wellness and Fitness",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-106.31949434667, 31.7990645],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Powerlifting", "Cardio", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "irongrind_true_fitness_el_paso",
      images: [null],
      name: "IronGrind True Fitness",
      location: "El Paso, TX, United States",
    },
  ],
  Mesa: [
    {
      "Record ID": "23210357345",
      company_name: "Fitness 1440 Mesa",
      "Create Date": "2024-09-23 17:51",
      Type: "",
      "Street Address": "1020 West Southern Avenue",
      "Street Address 2": "",
      city: "Mesa",
      "State/Region": "AZ",
      "Postal Code": "85210",
      "Country/Region": "United States",
      "Time Zone": "America/Phoenix",
      "Phone Number": "+1 480-464-6380",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-111.853408813477, 33.3935317993164],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Cardio", "LGBTQIA+", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "fitness_1440_mesa_mesa",
      images: [
        "https://live.staticflickr.com/65535/53738350437_7efec6774c.jpg",
      ],
      name: "Fitness 1440 Mesa",
      location: "Mesa, AZ, United States",
    },
  ],
  Honolulu: [
    {
      "Record ID": "23209297284",
      company_name: "Iron Hawaiian Fitness",
      "Create Date": "2024-09-23 17:54",
      Type: "",
      "Street Address": "1727 Republican Street",
      "Street Address 2": "",
      city: "Honolulu",
      "State/Region": "HI",
      "Postal Code": "96819",
      "Country/Region": "United States",
      "Time Zone": "Pacific/Honolulu",
      "Phone Number": "+1 808-291-0262",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-157.883279211306, 21.3243605],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Machines", "Weights", "Courts"],
      rating: 5,
      reviews: 0,
      id: "iron_hawaiian_fitness_honolulu",
      images: [
        "https://live.staticflickr.com/65535/52824057560_83d8768e9b.jpg",
      ],
      name: "Iron Hawaiian Fitness",
      location: "Honolulu, HI, United States",
    },
    {
      "Record ID": "23210357525",
      company_name: "Honolulu Fitness Center",
      "Create Date": "2024-09-23 17:55",
      Type: "",
      "Street Address": "1146 Fort Street Mall",
      "Street Address 2": "",
      city: "Honolulu",
      "State/Region": "HI",
      "Postal Code": "96813",
      "Country/Region": "United States",
      "Time Zone": "Pacific/Honolulu",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-157.860503687129, 21.3106735],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Courts", "Special", "Weights"],
      rating: 5,
      reviews: 0,
      id: "honolulu_fitness_center_honolulu",
      images: [
        "https://live.staticflickr.com/65535/52824095008_a91d14d366.jpg",
      ],
      name: "Honolulu Fitness Center",
      location: "Honolulu, HI, United States",
    },
    {
      "Record ID": "23209297297",
      company_name: "UNYQE FITNESS",
      "Create Date": "2024-09-23 17:55",
      Type: "",
      "Street Address": "745 Fort Street Mall",
      "Street Address 2": "105",
      city: "Honolulu",
      "State/Region": "HI",
      "Postal Code": "96813",
      "Country/Region": "United States",
      "Time Zone": "Pacific/Honolulu",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-157.863374376966, 21.3078865],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Pool", "LGBTQIA+", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "unyqe_fitness_honolulu",
      images: [
        "https://live.staticflickr.com/65535/52824057435_e907fa6c4d.jpg",
      ],
      name: "UNYQE FITNESS",
      location: "Honolulu, HI, United States",
    },
    {
      "Record ID": "23209297311",
      company_name: "Mofit",
      "Create Date": "2024-09-23 17:55",
      Type: "",
      "Street Address": "2463 King Street",
      "Street Address 2": "",
      city: "Honolulu",
      "State/Region": "HI",
      "Postal Code": "96816",
      "Country/Region": "United States",
      "Time Zone": "Pacific/Honolulu",
      "Phone Number": "+1 808-285-5200",
      Industry: "Health, Wellness and Fitness",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-157.85833, 21.30694],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "LGBTQIA+", "Pool", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "mofit_honolulu",
      images: [
        "https://live.staticflickr.com/65535/52823656986_5c17d6b059.jpg",
      ],
      name: "Mofit",
      location: "Honolulu, HI, United States",
    },
    {
      "Record ID": "23210357605",
      company_name: "ʻAlohilani Resort Waikiki Beach",
      "Create Date": "2024-09-23 17:57",
      Type: "",
      "Street Address": "2490 Kalakaua Avenue",
      "Street Address 2": "",
      city: "Honolulu",
      "State/Region": "HI",
      "Postal Code": "96815",
      "Country/Region": "United States",
      "Time Zone": "Pacific/Honolulu",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-157.823214179, 21.2746571120001],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Powerlifting", "LGBTQIA+", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "_alohilani_resort_waikiki_beach_honolulu",
      images: [
        "https://live.staticflickr.com/65535/52824057280_b43d647617.jpg",
      ],
      name: "ʻAlohilani Resort Waikiki Beach",
      location: "Honolulu, HI, United States",
    },
    {
      "Record ID": "23209297436",
      company_name: "Ikaika Fitness",
      "Create Date": "2024-09-23 17:58",
      Type: "",
      "Street Address": "815 Waimanu Street",
      "Street Address 2": "",
      city: "Honolulu",
      "State/Region": "HI",
      "Postal Code": "96813",
      "Country/Region": "United States",
      "Time Zone": "Pacific/Honolulu",
      "Phone Number": "+1 808-497-3022",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-157.854984654301, 21.300136],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Machines", "Weights", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "ikaika_fitness_honolulu",
      images: [
        "https://live.staticflickr.com/65535/52824094703_d774729440.jpg",
      ],
      name: "Ikaika Fitness",
      location: "Honolulu, HI, United States",
    },
    {
      "Record ID": "23209297429",
      company_name: "Flexx Fitness Hawaii",
      "Create Date": "2024-09-23 17:58",
      Type: "",
      "Street Address": "650 Iwilei Road",
      "Street Address 2": "125a",
      city: "Honolulu",
      "State/Region": "HI",
      "Postal Code": "96817",
      "Country/Region": "United States",
      "Time Zone": "Pacific/Honolulu",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-157.870700542, 21.316363037],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Powerlifting", "Accessibility", "Weights"],
      rating: 5,
      reviews: 0,
      id: "flexx_fitness_hawaii_honolulu",
      images: [
        "https://live.staticflickr.com/65535/52823830149_a95e43b01e.jpg",
      ],
      name: "Flexx Fitness Hawaii",
      location: "Honolulu, HI, United States",
    },
    {
      "Record ID": "23210357661",
      company_name: "The Oahu Club",
      active: true,
      "Create Date": "2024-09-23 17:59",
      Type: "",
      "Street Address": "6800 Hawaii Kai Drive",
      "Street Address 2": "",
      city: "Honolulu",
      "State/Region": "HI",
      "Postal Code": "96825",
      "Country/Region": "United States",
      "Time Zone": "Pacific/Honolulu",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-157.706701, 21.290291],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "Cardio", "Weights", "Courts"],
      rating: 5,
      reviews: 0,
      id: "the_oahu_club_honolulu",
      images: [
        "https://live.staticflickr.com/65535/52823830119_08587016af.jpg",
      ],
      name: "The Oahu Club",
      location: "Honolulu, HI, United States",
    },
  ],
  St_Petersburg: [
    {
      "Record ID": "23209297516",
      company_name: "Amped Fitness",
      "Create Date": "2024-09-23 18:01",
      Type: "",
      "Street Address": "830 3rd Avenue South",
      "Street Address 2": "",
      city: "St. Petersburg",
      "State/Region": "FL",
      "Postal Code": "33701",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-82.645759, 27.767859],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Powerlifting", "Pool", "Weights"],
      rating: 5,
      reviews: 0,
      id: "amped_fitness_st_petersburg",
      images: [
        "https://live.staticflickr.com/65535/52251130307_d431e98697.jpg",
      ],
      name: "Amped Fitness",
      location: "St. Petersburg, FL, United States",
    },
  ],
  Daytona_Beach: [
    {
      "Record ID": "23210357800",
      company_name: "Sharper Edge Fitness",
      "Create Date": "2024-09-23 18:02",
      Type: "",
      "Street Address": "124 North Beach Street",
      "Street Address 2": "",
      city: "Daytona Beach",
      "State/Region": "FL",
      "Postal Code": "32114",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-81.019973707035, 29.2128571418827],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "LGBTQIA+", "Weights", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "sharper_edge_fitness_daytona_beach",
      images: [
        "https://live.staticflickr.com/65535/49206885308_e73261409d.jpg",
      ],
      name: "Sharper Edge Fitness",
      location: "Daytona Beach, FL, United States",
    },
    {
      "Record ID": "23209297536",
      company_name: "Greater Fitness",
      "Create Date": "2024-09-23 18:02",
      Type: "",
      "Street Address": "1500 Beville Road",
      "Street Address 2": "",
      city: "Daytona Beach",
      "State/Region": "FL",
      "Postal Code": "32114",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-81.041591279661, 29.171494],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Cardio", "Female Only", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "greater_fitness_daytona_beach",
      images: [
        "https://live.staticflickr.com/65535/40844776693_78462a113c.jpg",
      ],
      name: "Greater Fitness",
      location: "Daytona Beach, FL, United States",
    },
  ],
  South_Daytona: [
    {
      "Record ID": "23209297557",
      company_name: "Elite Strength and Performance",
      "Create Date": "2024-09-23 18:03",
      Type: "",
      "Street Address": "2330 South Nova Road",
      "Street Address 2": "",
      city: "South Daytona",
      "State/Region": "FL",
      "Postal Code": "32119",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 386-405-8410",
      Industry: "Education Management",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-81.022973, 29.158075],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Machines", "Weights", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "elite_strength_and_performance_south_daytona",
      images: ["https://live.staticflickr.com/6121/5940660595_a238820b78.jpg"],
      name: "Elite Strength and Performance",
      location: "South Daytona, FL, United States",
    },
  ],
  Cancun: [
    {
      "Record ID": "23210357861",
      company_name: "Goldens Gym",
      "Create Date": "2024-09-23 18:04",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Cancun",
      "State/Region": "",
      "Postal Code": "",
      "Country/Region": "",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-86.861727, 21.163829],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "LGBTQIA+", "Pool", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "goldens_gym_cancun",
      images: [
        "https://live.staticflickr.com/65535/53668197699_3f2359e68e.jpg",
      ],
      name: "Goldens Gym",
      location: "Cancun, , ",
    },
    {
      "Record ID": "23209297719",
      company_name: "Hustle Fitness",
      "Create Date": "2024-09-23 18:07",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Cancun",
      "State/Region": "",
      "Postal Code": "",
      "Country/Region": "Mexico",
      "Time Zone": "America/Mexico_City",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-86.861727, 21.163829],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Courts", "Accessibility", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "hustle_fitness_cancun",
      images: [
        "https://live.staticflickr.com/65535/53657447408_3448530040.jpg",
      ],
      name: "Hustle Fitness",
      location: "Cancun, , Mexico",
    },
    {
      "Record ID": "23209297745",
      company_name: "Iron Republic",
      "Create Date": "2024-09-23 18:08",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Cancun",
      "State/Region": "",
      "Postal Code": "",
      "Country/Region": "",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "Health, Wellness and Fitness",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-86.861727, 21.163829],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Courts", "Cardio", "Pool"],
      rating: 5,
      reviews: 0,
      id: "iron_republic_cancun",
      images: [null],
      name: "Iron Republic",
      location: "Cancun, , ",
    },
  ],
  Sofia: [
    {
      "Record ID": "23209297785",
      company_name: "Academica Gym",
      "Create Date": "2024-09-23 18:09",
      Type: "",
      "Street Address": '125 Boulevard "Tsarigradsko shose"',
      "Street Address 2": "",
      city: "Sofia",
      "State/Region": "Sofia City Province",
      "Postal Code": "1113",
      "Country/Region": "Bulgaria",
      "Time Zone": "Europe/Sofia",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [23.4079635, 42.6403778],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Powerlifting", "LGBTQIA+", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "academica_gym_sofia",
      images: [
        "https://live.staticflickr.com/65535/54019829461_e28204de59.jpg",
      ],
      name: "Academica Gym",
      location: "Sofia, Sofia City Province, Bulgaria",
    },
    {
      "Record ID": "23210358037",
      company_name: "Pulse Fitness",
      "Create Date": "2024-09-23 18:09",
      Type: "",
      "Street Address": '132 Bulevard "Bulgaria"',
      "Street Address 2": "",
      city: "Sofia",
      "State/Region": "Sofia City Province",
      "Postal Code": "1618",
      "Country/Region": "Bulgaria",
      "Time Zone": "Europe/Sofia",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [23.32415, 42.69751],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "LGBTQIA+", "Female Only", "Special"],
      rating: 5,
      reviews: 0,
      id: "pulse_fitness_sofia",
      images: [
        "https://live.staticflickr.com/65535/54018932157_3312d31039.jpg",
      ],
      name: "Pulse Fitness",
      location: "Sofia, Sofia City Province, Bulgaria",
    },
  ],
  Calumet_city: [
    {
      "Record ID": "23210358177",
      company_name: "Quads Gym",
      "Create Date": "2024-09-23 18:15",
      Type: "",
      "Street Address": "745 Torrence Avenue",
      "Street Address 2": "",
      city: "Calumet City",
      "State/Region": "IL",
      "Postal Code": "60409",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-87.5585885, 41.6135085],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "LGBTQIA+", "Powerlifting", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "quads_gym_calumet_city",
      images: [null],
      name: "Quads Gym",
      location: "Calumet City, IL, United States",
    },
  ],
  Joliet: [
    {
      "Record ID": "23209298024",
      company_name: "Chicago Sports & Fitness Club",
      "Create Date": "2024-09-23 18:16",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Joliet",
      "State/Region": "IL",
      "Postal Code": "",
      "Country/Region": "",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-88.10341, 41.530814],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Pool", "Powerlifting", "Weights"],
      rating: 5,
      reviews: 0,
      id: "chicago_sports_fitness_club_joliet",
      images: ["https://live.staticflickr.com/7848/46645636155_876a6fb42d.jpg"],
      name: "Chicago Sports & Fitness Club",
      location: "Joliet, IL, ",
    },
  ],
  Rye: [
    {
      "Record ID": "23210358350",
      company_name: "Flight House Gym",
      "Create Date": "2024-09-23 18:19",
      Type: "",
      "Street Address": "6 Airfield Drive",
      "Street Address 2": "",
      city: "Rye",
      "State/Region": "NH",
      "Postal Code": "3870",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 603-601-7195",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-70.801643371582, 43.0087814331055],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Machines", "Pool", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "flight_house_gym_rye",
      images: [
        "https://live.staticflickr.com/65535/53141425465_63771f595c.jpg",
      ],
      name: "Flight House Gym",
      location: "Rye, NH, United States",
    },
  ],
  Portsmouth: [
    {
      "Record ID": "23210358384",
      company_name: "Portsmouth Athletic Club",
      "Create Date": "2024-09-23 18:20",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Portsmouth",
      "State/Region": "NH",
      "Postal Code": "",
      "Country/Region": "",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-70.783383, 43.059323],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Pool", "LGBTQIA+", "Machines"],
      rating: 5,
      reviews: 0,
      id: "portsmouth_athletic_club_portsmouth",
      images: [
        "https://live.staticflickr.com/65535/53998799877_f858e4526e.jpg",
      ],
      name: "Portsmouth Athletic Club",
      location: "Portsmouth, NH, ",
    },
    {
      "Record ID": "23210358395",
      company_name: "Gain Strength and Conditioning",
      "Create Date": "2024-09-23 18:21",
      Type: "",
      "Street Address": "270 West Road",
      "Street Address 2": "",
      city: "Portsmouth",
      "State/Region": "NH",
      "Postal Code": "3801",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Health, Wellness and Fitness",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-70.7800723389571, 43.0410035],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Pool", "Weights", "Cardio"],
      rating: 5,
      reviews: 0,
      id: "gain_strength_and_conditioning_portsmouth",
      images: [
        "https://live.staticflickr.com/65535/53998799872_6d1b3b2ca3.jpg",
      ],
      name: "Gain Strength and Conditioning",
      location: "Portsmouth, NH, United States",
    },
  ],
  Provincetown: [
    {
      "Record ID": "23209298220",
      company_name: "Provincetown Gym and Fitness",
      "Create Date": "2024-09-23 18:22",
      Type: "",
      "Street Address": "81 Shank Painter Road",
      "Street Address 2": "",
      city: "Provincetown",
      "State/Region": "MA",
      "Postal Code": "2657",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-70.1951315, 42.052177],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "LGBTQIA+", "Pool", "Weights"],
      rating: 5,
      reviews: 0,
      id: "provincetown_gym_and_fitness_provincetown",
      images: ["https://live.staticflickr.com/7536/15427901753_a794590bf7.jpg"],
      name: "Provincetown Gym and Fitness",
      location: "Provincetown, MA, United States",
    },
    {
      "Record ID": "23210358446",
      company_name: "Mussel Beach Health Club",
      "Create Date": "2024-09-23 18:23",
      Type: "",
      "Street Address": "35 Bradford Street",
      "Street Address 2": "",
      city: "Provincetown",
      "State/Region": "MA",
      "Postal Code": "2657",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-70.192566533105, 42.0469305],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Powerlifting", "Accessibility", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "mussel_beach_health_club_provincetown",
      images: ["https://live.staticflickr.com/7528/15861800237_bb97053186.jpg"],
      name: "Mussel Beach Health Club",
      location: "Provincetown, MA, United States",
    },
    {
      "Record ID": "23210358454",
      company_name: "Helltown Fitness",
      "Create Date": "2024-09-23 18:24",
      Type: "",
      "Street Address": "247 Commercial St",
      "Street Address 2": "",
      city: "Provincetown",
      "State/Region": "MA",
      "Postal Code": "2657",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-70.187557141516, 42.0501733790005],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Pool", "LGBTQIA+", "Machines"],
      rating: 5,
      reviews: 0,
      id: "helltown_fitness_provincetown",
      images: [null],
      name: "Helltown Fitness",
      location: "Provincetown, MA, United States",
    },
  ],
  Rehoboth_Beach: [
    {
      "Record ID": "23209298273",
      company_name: "Everyone Fitness",
      "Create Date": "2024-09-23 18:25",
      Type: "",
      "Street Address": "18908 Rehoboth Mall Boulevard",
      "Street Address 2": "5",
      city: "Rehoboth Beach",
      "State/Region": "DE",
      "Postal Code": "19971",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 302-226-4653",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-75.133138784562, 38.7285142277861],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "Female Only", "Cardio", "Special"],
      rating: 5,
      reviews: 0,
      id: "everyone_fitness_rehoboth_beach",
      images: [
        "https://live.staticflickr.com/65535/53930885145_865c4ecde4.jpg",
      ],
      name: "Everyone Fitness",
      location: "Rehoboth Beach, DE, United States",
    },
    {
      "Record ID": "23210358594",
      company_name: "RISE Fitness",
      "Create Date": "2024-09-23 18:26",
      Type: "",
      "Street Address": "35770 Airport Road",
      "Street Address 2": "",
      city: "Rehoboth Beach",
      "State/Region": "DE",
      "Postal Code": "19971",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-75.1219735, 38.720426],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Machines", "Female Only", "Pool"],
      rating: 5,
      reviews: 0,
      id: "rise_fitness_rehoboth_beach",
      images: ["https://live.staticflickr.com/5593/15081800321_cc85eb2911.jpg"],
      name: "RISE Fitness",
      location: "Rehoboth Beach, DE, United States",
    },
    {
      "Record ID": "23210358577",
      company_name: "BODY SHOP FITNESS CENTER",
      "Create Date": "2024-09-23 18:26",
      Type: "",
      "Street Address": "70 Rehoboth Avenue",
      "Street Address 2": "Suite 112",
      city: "Rehoboth Beach",
      "State/Region": "DE",
      "Postal Code": "19971",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 302-226-0920",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-75.0784908458782, 38.7161604382983],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Cardio", "Special", "Weights"],
      rating: 5,
      reviews: 0,
      id: "body_shop_fitness_center_rehoboth_beach",
      images: ["https://live.staticflickr.com/3874/14892348050_01427bf092.jpg"],
      name: "BODY SHOP FITNESS CENTER",
      location: "Rehoboth Beach, DE, United States",
    },
    {
      "Record ID": "23209298313",
      company_name: "REHOBOTH BEACH BARBELL CLUB",
      "Create Date": "2024-09-23 18:27",
      Type: "",
      "Street Address": "20819 Coastal Highway",
      "Street Address 2": "",
      city: "Rehoboth Beach",
      "State/Region": "DE",
      "Postal Code": "19971",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Sporting Goods",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-75.0836985559619, 38.703681],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Weights", "LGBTQIA+", "Machines"],
      rating: 5,
      reviews: 0,
      id: "rehoboth_beach_barbell_club_rehoboth_beach",
      images: ["https://live.staticflickr.com/3720/9968718834_351bccaab4.jpg"],
      name: "REHOBOTH BEACH BARBELL CLUB",
      location: "Rehoboth Beach, DE, United States",
    },
    {
      "Record ID": "23209298329",
      company_name: "Midway Motion & Fitness",
      "Create Date": "2024-09-23 18:27",
      Type: "",
      "Street Address": "34823 Derrickson Drive",
      "Street Address 2": "",
      city: "Rehoboth Beach",
      "State/Region": "DE",
      "Postal Code": "19971",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-75.1394833240741, 38.738528],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["LGBTQIA+", "Pool", "Machines", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "midway_motion_fitness_rehoboth_beach",
      images: ["https://live.staticflickr.com/2817/9241184434_316b682fef.jpg"],
      name: "Midway Motion & Fitness",
      location: "Rehoboth Beach, DE, United States",
    },
  ],
  Brookfield: [
    {
      "Record ID": "23209299079",
      company_name: "MKE Muscle",
      "Create Date": "2024-09-23 18:52",
      Type: "",
      "Street Address": "3313 North 124th Street",
      "Street Address 2": "",
      city: "Brookfield",
      "State/Region": "WI",
      "Postal Code": "53005",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "+1 262-879-8089",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-88.0674657461988, 43.0788705],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Weights", "Special", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "mke_muscle_brookfield",
      images: [
        "https://live.staticflickr.com/65535/54003652856_e89f813810.jpg",
      ],
      name: "MKE Muscle",
      location: "Brookfield, WI, United States",
    },
  ],
  Minneapolis: [
    {
      "Record ID": "23209299280",
      company_name: "Los Campeones Gym",
      "Create Date": "2024-09-23 18:59",
      Type: "",
      "Street Address": "2721 East Franklin Avenue",
      "Street Address 2": "",
      city: "Minneapolis",
      "State/Region": "MN",
      "Postal Code": "55406",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-93.2317062353065, 44.9624826788438],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "LGBTQIA+", "Pool", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "los_campeones_gym_minneapolis",
      images: [
        "https://live.staticflickr.com/65535/53368162719_246a6590bc.jpg",
      ],
      name: "Los Campeones Gym",
      location: "Minneapolis, MN, United States",
    },
  ],
  Rajkot: [
    {
      "Record ID": "23210362229",
      company_name: "City Fitness",
      "Create Date": "2024-09-23 21:03",
      Type: "",
      "Street Address": "209 Doctor Yagnik Road",
      "Street Address 2": "",
      city: "Rajkot",
      "State/Region": "Gujarat",
      "Postal Code": "360001",
      "Country/Region": "India",
      "Time Zone": "Asia/Kolkata",
      "Phone Number": "",
      Industry: "Consumer Services",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [70.79322, 22.29161],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Accessibility", "Courts", "Weights", "Pool"],
      rating: 5,
      reviews: 0,
      id: "city_fitness_rajkot",
      images: [
        "https://live.staticflickr.com/65535/51263647696_e2c22ff7c4.jpg",
      ],
      name: "City Fitness",
      location: "Rajkot, Gujarat, India",
    },
  ],
  Ithaca: [
    {
      "Record ID": "23209302121",
      company_name: "The Gym Ithaca",
      "Create Date": "2024-09-23 21:11",
      Type: "",
      "Street Address": "950 Danby Road",
      "Street Address 2": "104",
      city: "Ithaca",
      "State/Region": "NY",
      "Postal Code": "14850",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-76.5023818896451, 42.4226555],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Courts", "Machines", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "the_gym_ithaca_ithaca",
      images: [
        "https://live.staticflickr.com/65535/53742532381_031b503384.jpg",
      ],
      name: "The Gym Ithaca",
      location: "Ithaca, NY, United States",
    },
    {
      "Record ID": "23210362412",
      company_name: "Island Health & Fitness",
      "Create Date": "2024-09-23 21:11",
      Type: "",
      "Street Address": "310 Taughannock Boulevard",
      "Street Address 2": "",
      city: "Ithaca",
      "State/Region": "NY",
      "Postal Code": "14850",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-76.5134453487722, 42.4422097779592],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Weights", "Courts", "Special"],
      rating: 5,
      reviews: 0,
      id: "island_health_fitness_ithaca",
      images: [
        "https://live.staticflickr.com/65535/53742945800_e6dc2b0a8c.jpg",
      ],
      name: "Island Health & Fitness",
      location: "Ithaca, NY, United States",
    },
    {
      "Record ID": "23209302160",
      company_name: "Pallas Fitness",
      "Create Date": "2024-09-23 21:13",
      Type: "",
      "Street Address": "241 Cherry Street",
      "Street Address 2": "",
      city: "Ithaca",
      "State/Region": "NY",
      "Postal Code": "14850",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-76.5170455, 42.4335735],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Weights", "Courts", "Pool"],
      rating: 5,
      reviews: 0,
      id: "pallas_fitness_ithaca",
      images: [
        "https://live.staticflickr.com/65535/53742858089_88506738d0.jpg",
      ],
      name: "Pallas Fitness",
      location: "Ithaca, NY, United States",
    },
    {
      "Record ID": "23210362479",
      company_name: "East Shore Gym 24/7",
      "Create Date": "2024-09-23 21:14",
      Type: "",
      "Street Address": "1767 East Shore Drive",
      "Street Address 2": "",
      city: "Ithaca",
      "State/Region": "NY",
      "Postal Code": "14850",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 607-592-7356",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-76.5033423743718, 42.518001],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Machines", "Special", "Weights"],
      rating: 5,
      reviews: 0,
      id: "east_shore_gym_24_7_ithaca",
      images: [
        "https://live.staticflickr.com/65535/53742943475_3431af4bc9.jpg",
      ],
      name: "East Shore Gym 24/7",
      location: "Ithaca, NY, United States",
    },
  ],
  State_College: [
    {
      "Record ID": "23210362509",
      company_name: "East Coast Health & Fitness",
      "Create Date": "2024-09-23 21:16",
      Type: "",
      "Street Address": "250 West Hamilton Avenue",
      "Street Address 2": "",
      city: "State College",
      "State/Region": "PA",
      "Postal Code": "16801",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 814-234-9400",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-77.8564533596688, 40.7871455],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Machines", "Powerlifting", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "east_coast_health_fitness_state_college",
      images: [
        "https://live.staticflickr.com/65535/53986897463_75626b812e.jpg",
      ],
      name: "East Coast Health & Fitness",
      location: "State College, PA, United States",
    },
    {
      "Record ID": "23210362499",
      company_name: "The North Club",
      "Create Date": "2024-09-23 21:16",
      Type: "",
      "Street Address": "1510 Martin Street",
      "Street Address 2": "",
      city: "State College",
      "State/Region": "PA",
      "Postal Code": "16803",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 814-237-1510",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-77.8920438309124, 40.8044768110918],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Accessibility", "Cardio", "Machines"],
      rating: 5,
      reviews: 0,
      id: "the_north_club_state_college",
      images: [
        "https://live.staticflickr.com/65535/53985765472_b040d2c374.jpg",
      ],
      name: "The North Club",
      location: "State College, PA, United States",
    },
    {
      "Record ID": "23210362520",
      company_name: "Ki'netik Fitness",
      "Create Date": "2024-09-23 21:17",
      Type: "",
      "Street Address": "2301 Commercial Boulevard",
      "Street Address 2": "",
      city: "State College",
      "State/Region": "PA",
      "Postal Code": "16801",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 814-238-4677",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "0.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-77.8127604293478, 40.8242475],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Special", "Machines", "Weights"],
      rating: 5,
      reviews: 0,
      id: "ki_netik_fitness_state_college",
      images: [
        "https://live.staticflickr.com/65535/53986656366_c7b34ee674.jpg",
      ],
      name: "Ki'netik Fitness",
      location: "State College, PA, United States",
    },
    {
      "Record ID": "23209302273",
      company_name: "Victory Sports & Fitness",
      "Create Date": "2024-09-23 21:17",
      Type: "",
      "Street Address": "178 Rolling Ridge Drive",
      "Street Address 2": "",
      city: "State College",
      "State/Region": "PA",
      "Postal Code": "16801",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "+1 814-235-7676",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-77.8284351470973, 40.7860274290307],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Cardio", "Machines", "Special", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "victory_sports_fitness_state_college",
      images: [
        "https://live.staticflickr.com/65535/53985764782_0f90ed2ebc.jpg",
      ],
      name: "Victory Sports & Fitness",
      location: "State College, PA, United States",
    },
  ],
  Tonganoxie: [
    {
      "Record ID": "23209302369",
      company_name: "Free State Fitness",
      "Create Date": "2024-09-23 21:22",
      Type: "",
      "Street Address": "1198 Front Street",
      "Street Address 2": "",
      city: "Tonganoxie",
      "State/Region": "KS",
      "Postal Code": "66086",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-95.078048, 39.1154835],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Weights", "Powerlifting", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "free_state_fitness_tonganoxie",
      images: [
        "https://live.staticflickr.com/65535/50431808198_0a4bfc3812.jpg",
      ],
      name: "Free State Fitness",
      location: "Tonganoxie, KS, United States",
    },
  ],
  Blacksburg: [
    {
      "Record ID": "23210362665",
      company_name: "Blacksburg Health and Fitness at the Weight Club",
      "Create Date": "2024-09-23 21:23",
      Type: "",
      "Street Address": "801 University City Boulevard",
      "Street Address 2": "",
      city: "Blacksburg",
      "State/Region": "VA",
      "Postal Code": "24060",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-80.4342421926804, 37.2333955],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "LGBTQIA+", "Female Only", "Weights"],
      rating: 5,
      reviews: 0,
      id: "blacksburg_health_and_fitness_at_the_weight_club_blacksburg",
      images: ["https://live.staticflickr.com/0/52728923747_c12d02e1a6.jpg"],
      name: "Blacksburg Health and Fitness at the Weight Club",
      location: "Blacksburg, VA, United States",
    },
  ],
  Omaha: [
    {
      "Record ID": "23210362715",
      company_name: "Tru Fit Athletic Clubs",
      "Create Date": "2024-09-23 21:25",
      Type: "",
      "Street Address": "11550 I Street",
      "Street Address 2": "200",
      city: "Omaha",
      "State/Region": "NE",
      "Postal Code": "68137",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "+1 806-803-2302",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-96.0928983911817, 41.2188695],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Accessibility", "Female Only", "Machines"],
      rating: 5,
      reviews: 0,
      id: "tru_fit_athletic_clubs_omaha",
      images: [
        "https://live.staticflickr.com/65535/53897837636_c45cfd408b.jpg",
      ],
      name: "Tru Fit Athletic Clubs",
      location: "Omaha, NE, United States",
    },
  ],
  College_Station: [
    {
      "Record ID": "23210362728",
      company_name: "Brazos Valley Barbell",
      "Create Date": "2024-09-23 21:26",
      Type: "",
      "Street Address": "303 Dominik Drive",
      "Street Address 2": "",
      city: "College Station",
      "State/Region": "TX",
      "Postal Code": "77840",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-96.320572, 30.619904],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Female Only", "Machines", "LGBTQIA+", "Pool"],
      rating: 5,
      reviews: 0,
      id: "brazos_valley_barbell_college_station",
      images: ["https://live.staticflickr.com/0/53907311381_ab052fc6c4.jpg"],
      name: "Brazos Valley Barbell",
      location: "College Station, TX, United States",
    },
    {
      "Record ID": "23209302546",
      company_name: "MaretHouse Fitness",
      "Create Date": "2024-09-23 21:29",
      Type: "",
      "Street Address": "1411 Wellborn Road",
      "Street Address 2": "300",
      city: "College Station",
      "State/Region": "TX",
      "Postal Code": "77840",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "+1 979-999-1820",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-96.3301175, 30.5908325],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Machines", "Weights", "Special"],
      rating: 5,
      reviews: 0,
      id: "marethouse_fitness_college_station",
      images: [
        "https://live.staticflickr.com/65535/49205356481_952b02800f.jpg",
      ],
      name: "MaretHouse Fitness",
      location: "College Station, TX, United States",
    },
    {
      "Record ID": "23210362785",
      company_name: "Helioss Fitness",
      "Create Date": "2024-09-23 21:30",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "College Station",
      "State/Region": "TX",
      "Postal Code": "",
      "Country/Region": "",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-96.294573, 30.589787],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "LGBTQIA+", "Accessibility", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "helioss_fitness_college_station",
      images: [
        "https://live.staticflickr.com/65535/49204872948_3105d6c45e.jpg",
      ],
      name: "Helioss Fitness",
      location: "College Station, TX, ",
    },
  ],
  College_Station_Bryan: [
    {
      "Record ID": "23209302432",
      company_name: "The ARC Gym",
      "Create Date": "2024-09-23 21:27",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "College Station-Bryan",
      "State/Region": "TX",
      "Postal Code": "",
      "Country/Region": "",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-96.294573, 30.589787],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Female Only", "LGBTQIA+", "Pool"],
      rating: 5,
      reviews: 0,
      id: "the_arc_gym_college_station_bryan",
      images: [null],
      name: "The ARC Gym",
      location: "College Station-Bryan, TX, ",
    },
  ],
  Bryan: [
    {
      "Record ID": "23210362760",
      company_name: "BCS Fitness",
      "Create Date": "2024-09-23 21:28",
      Type: "",
      "Street Address": "4301 Texas Avenue",
      "Street Address 2": "",
      city: "Bryan",
      "State/Region": "TX",
      "Postal Code": "77802",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-96.3394095547619, 30.6328075],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Accessibility", "Female Only", "Courts"],
      rating: 5,
      reviews: 0,
      id: "bcs_fitness_bryan",
      images: [
        "https://live.staticflickr.com/65535/54019829461_e28204de59.jpg",
      ],
      name: "BCS Fitness",
      location: "Bryan, TX, United States",
    },
  ],
  Champaign: [
    {
      "Record ID": "23209302676",
      company_name: "Champaign Fitness Center",
      "Create Date": "2024-09-23 21:37",
      Type: "",
      "Street Address": "1914 Round Barn Rd",
      "Street Address 2": "",
      city: "Champaign",
      "State/Region": "IL",
      "Postal Code": "61821",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "+1 217-356-1616",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-88.279470597003, 40.1117943109612],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Pool", "Courts", "Machines", "Accessibility"],
      rating: 5,
      reviews: 0,
      id: "champaign_fitness_center_champaign",
      images: [
        "https://live.staticflickr.com/65535/53318215482_4ec4bfbfea.jpg",
      ],
      name: "Champaign Fitness Center",
      location: "Champaign, IL, United States",
    },
    {
      "Record ID": "23209302669",
      company_name: "Refinery",
      "Create Date": "2024-09-23 21:37",
      Type: "",
      "Street Address": "2302 West John Street",
      "Street Address 2": "",
      city: "Champaign",
      "State/Region": "IL",
      "Postal Code": "61821",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "+1 217-355-4444",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-88.2843536197247, 40.1095247326359],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Courts", "Pool", "Accessibility", "LGBTQIA+"],
      rating: 5,
      reviews: 0,
      id: "refinery_champaign",
      images: [
        "https://live.staticflickr.com/65535/53318215472_a08d161543.jpg",
      ],
      name: "Refinery",
      location: "Champaign, IL, United States",
    },
    {
      "Record ID": "23209302690",
      company_name: "JSM Fitness Center",
      "Create Date": "2024-09-23 21:38",
      Type: "",
      "Street Address": "616 East Green Street",
      "Street Address 2": "d",
      city: "Champaign",
      "State/Region": "IL",
      "Postal Code": "61820",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Real Estate",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-88.2293117190349, 40.1104364261065],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Powerlifting", "Weights", "Machines", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "jsm_fitness_center_champaign",
      images: [
        "https://live.staticflickr.com/65535/53319439224_723d9ee177.jpg",
      ],
      name: "JSM Fitness Center",
      location: "Champaign, IL, United States",
    },
    {
      "Record ID": "23210363007",
      company_name: "Luyando Boxing",
      "Create Date": "2024-09-23 21:39",
      Type: "",
      "Street Address": "52 East Washington Street",
      "Street Address 2": "",
      city: "Champaign",
      "State/Region": "IL",
      "Postal Code": "61820",
      "Country/Region": "United States",
      "Time Zone": "America/Chicago",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-88.24054525, 40.120097],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Powerlifting", "LGBTQIA+", "Pool"],
      rating: 5,
      reviews: 0,
      id: "luyando_boxing_champaign",
      images: [
        "https://live.staticflickr.com/65535/53319439174_8b4790c0da.jpg",
      ],
      name: "Luyando Boxing",
      location: "Champaign, IL, United States",
    },
  ],
  Gainesville: [
    {
      "Record ID": "23209302803",
      company_name: "Gainesville Health & Fitness",
      "Create Date": "2024-09-23 21:40",
      Type: "",
      "Street Address": "4820 West Newberry Road",
      "Street Address 2": "",
      city: "Gainesville",
      "State/Region": "FL",
      "Postal Code": "32607",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "2.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-82.3938004284638, 29.6578475],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Courts", "Powerlifting", "Female Only"],
      rating: 5,
      reviews: 0,
      id: "gainesville_health_fitness_gainesville",
      images: [
        "https://live.staticflickr.com/65535/51582931821_5c26f8358f.jpg",
      ],
      name: "Gainesville Health & Fitness",
      location: "Gainesville, FL, United States",
    },
  ],
  Gainsville: [
    {
      "Record ID": "23210363053",
      company_name: "Gainesville Barbell",
      "Create Date": "2024-09-23 21:42",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Gainsville",
      "State/Region": "FL",
      "Postal Code": "",
      "Country/Region": "",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-82.32483, 29.65163],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "Cardio", "Courts", "Special"],
      rating: 5,
      reviews: 0,
      id: "gainesville_barbell_gainsville",
      images: ["https://live.staticflickr.com/428/31463564854_c3416a026f.jpg"],
      name: "Gainesville Barbell",
      location: "Gainsville, FL, ",
    },
    {
      "Record ID": "23210363180",
      company_name: "Senkai Strength",
      "Create Date": "2024-09-23 21:45",
      Type: "",
      "Street Address": "",
      "Street Address 2": "",
      city: "Gainsville",
      "State/Region": "FL",
      "Postal Code": "",
      "Country/Region": "",
      "Time Zone": "",
      "Phone Number": "",
      Industry: "",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-82.32483, 29.65163],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Weights", "LGBTQIA+", "Pool", "Special"],
      rating: 5,
      reviews: 0,
      id: "senkai_strength_gainsville",
      images: ["https://live.staticflickr.com/7389/27379595486_2b0936585f.jpg"],
      name: "Senkai Strength",
      location: "Gainsville, FL, ",
    },
  ],
  Tustin: [
    {
      "Record ID": "23209302873",
      company_name: "The Ark Fit",
      "Create Date": "2024-09-23 21:43",
      Type: "",
      "Street Address": "15761 Tustin Village Way",
      "Street Address 2": "b101",
      city: "Tustin",
      "State/Region": "CA",
      "Postal Code": "92780",
      "Country/Region": "United States",
      "Time Zone": "America/Los_Angeles",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-117.836398956547, 33.73494898464],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Machines", "Weights", "Female Only", "Special"],
      rating: 5,
      reviews: 0,
      id: "the_ark_fit_tustin",
      images: [
        "https://live.staticflickr.com/65535/51095919750_65135edf79.jpg",
      ],
      name: "The Ark Fit",
      location: "Tustin, CA, United States",
    },
  ],
  Alachua: [
    {
      "Record ID": "23210363188",
      company_name: "Infinite Wellness",
      "Create Date": "2024-09-23 21:45",
      Type: "",
      "Street Address": "15043 Main Street",
      "Street Address 2": "",
      city: "Alachua",
      "State/Region": "FL",
      "Postal Code": "32615",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-82.4953177652825, 29.7929315383802],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Powerlifting", "Pool", "Machines"],
      rating: 5,
      reviews: 0,
      id: "infinite_wellness_alachua",
      images: [
        "https://live.staticflickr.com/65535/50911836213_3a68084b3e.jpg",
      ],
      name: "Infinite Wellness",
      location: "Alachua, FL, United States",
    },
  ],
  Brooklyn: [
    {
      "Record ID": "24994130851",
      company_name: "The Ohio Gym",
      "Create Date": "2024-10-21 08:08",
      Type: "",
      "Street Address": "6700 Biddulph Road",
      "Street Address 2": "",
      city: "Brooklyn",
      "State/Region": "OH",
      "Postal Code": "44144",
      "Country/Region": "United States",
      "Time Zone": "America/New_York",
      "Phone Number": "14409347440",
      Industry: "Leisure, Travel & Tourism",
      "Lifecycle Stage": "Lead",
      "Number of Associated Contacts": "1.0",
      "Company owner": "Jordan Robinson",
      coordinates: [-81.732445, 41.431707],
      price_day: 15,
      price_week: 30,
      price_month: 50,
      amenities: ["Shower", "Wifi"],
      categories: ["Special", "Machines", "Pool", "Powerlifting"],
      rating: 5,
      reviews: 0,
      id: "the_ohio_gym_brooklyn",
      images: [
        "https://live.staticflickr.com/65535/54073676565_0a9d8498a0.jpg",
      ],
      name: "The Ohio Gym",
      location: "Brooklyn, OH, United States",
    },
  ],
};
