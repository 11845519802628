// In your directive.ts
import { Directive, ElementRef, Renderer2, AfterViewInit } from "@angular/core";

@Directive({
  selector: "[iosPadding]",
})
export class IosBottomDirective implements AfterViewInit {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this.loader();
  }

  private isIos(): boolean {
    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      return true;
    } else {
      return (
        navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2 &&
        /MacIntel/.test(navigator.userAgent)
      );
    }
  }

  private loader() {
    if (this.isIos()) {
      this.renderer.addClass(this.el.nativeElement, "ios-bottom");
    }
  }
}
