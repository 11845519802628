import { Injectable } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class SeoService {
  constructor(
    public title: Title,
    private meta: Meta,
    private router: Router,
    public activatedRoute: ActivatedRoute
  ) {}
  generateTags({ title = "" }) {
    // this.title.setTitle(title);
    // this.meta.addTags([
    //   // Open Graph
    //   {
    //     name: "og:url",
    //     content: `https://ftnss.fit${this.router.url}`,
    //   },
    //   { name: "og:title", content: title },
    //   {
    //     name: "og:description",
    //     content: `We're Ftnss`,
    //   },
    //   {
    //     name: "og:image",
    //     content:
    //       "https://ftnss.fit/og_image.jpg",
    //   },
    //   { name: "og:site_name", content: "Ftnss" },
    //   // Twitter Card
    //   {
    //     name: "twitter:card",
    //     content: "summary_large_image",
    //   },
    //   {
    //     name: "twitter:site",
    //     content: `We're Ftnss`,
    //   },
    //   {
    //     name: "twitter:image",
    //     content:
    //       "https://ftnss.fit/og_image.jpg",
    //   },
    //   { name: "twitter: title", content: title },
    // ]);
  }

  seoService() {
    // this.router.events
    //   .filter((event) => event instanceof NavigationEnd)
    //   .map(() => this.activatedRoute)
    //   .map((route) => {
    //     while (route.firstChild) route = route.firstChild;
    //     return route;
    //   })
    //   .filter((route) => route.outlet === "primary")
    //   .mergeMap((route) => route.data)
    //   .subscribe((event) => {
    //     this.generateTags(event);
    //     this.theemer();
    //   });
  }

  theemer() {
    // let isHost = window.location.pathname.includes("host");
    // document.documentElement.style.setProperty(
    //   "--col",
    //   isHost ? "#ff6aea" : ""
    // );
  }
}
