export const dummySports = {
  boyer: {
    name: "Boyer LLC",
    id: "boyer",
    location: "Paterson, TN",
    images: [
      "https://i.imgur.com/v4d7MiBh.jpg",
      "https://i.imgur.com/v4d7MiBh.jpg",
      "https://i.imgur.com/v4d7MiBh.jpg",
    ],
    price: 57,
    rating: 3,
    categories: "Swimming",
    reviews: 67,
    phone: "613.951.5486",
    coordinates: [-89.20223849074621, 44.72456756361021],
  },
  collison: {
    name: "Collison",
    id: "collison",
    location: "South Dameon, PH",
    images: [
      "https://i.imgur.com/N3I6qt4h.jpg",
      "https://i.imgur.com/N3I6qt4h.jpg",
      "https://i.imgur.com/N3I6qt4h.jpg",
    ],
    price: 49,
    rating: 5,
    categories: "Hockey",
    reviews: 150,
    phone: "767-627-2158 x82755",
    coordinates: [-88.47378270598361, 40.438840133995484],
  },
  luettgen: {
    name: "Luettgen",
    id: "luettgen",
    location: "Heaneychester, GA",
    images: [
      "https://i.imgur.com/WMrYjp8h.jpg",
      "https://i.imgur.com/WMrYjp8h.jpg",
      "https://i.imgur.com/WMrYjp8h.jpg",
    ],
    price: 45,
    rating: 5,
    categories: "Basketball",
    reviews: 54,
    phone: "1-453-505-0525",
    coordinates: [-111.82221893390933, 38.84856355965917],
  },
  hilll: {
    name: "Hilll Group",
    id: "hilll",
    location: "New Rosemary, JM",
    images: [
      "https://i.imgur.com/U697wFNh.jpg",
      "https://i.imgur.com/U697wFNh.jpg",
      "https://i.imgur.com/U697wFNh.jpg",
    ],
    price: 67,
    rating: 3,
    categories: "Rugby",
    reviews: 148,
    phone: "(513) 450-8419 x0847",
    coordinates: [-117.46082230026254, 43.387361071726],
  },
  zhing: {
    name: "Zhing's Tables",
    id: "zhing",
    location: "Cedar Park, HN",
    images: [
      "https://i.imgur.com/ubbiToSh.jpg",
      "https://i.imgur.com/ubbiToSh.jpg",
      "https://i.imgur.com/ubbiToSh.jpg",
    ],
    price: 65,
    rating: 4,
    categories: "Ping Pong",
    reviews: 128,
    phone: "(783) 407-5477 x88904",
    coordinates: [-90.98521044111813, 40.175356929424524],
  },
  merlin: {
    name: "Merlin's",
    id: "merlin",
    location: "Lawton, PE",
    images: [
      "https://i.imgur.com/SQknHvgh.jpg",
      "https://i.imgur.com/SQknHvgh.jpg",
      "https://i.imgur.com/SQknHvgh.jpg",
    ],
    price: 72,
    rating: 5,
    categories: "Hockey",
    reviews: 170,
    phone: "337-848-7935 x4094",
    coordinates: [-84.54217723439154, 41.380146195665745],
  },
  gutmann: {
    name: "Gutmann & Heller",
    id: "gutmann",
    location: "Port Jettstad, RW",
    images: [
      "https://i.imgur.com/oBlSwwNh.jpg",
      "https://i.imgur.com/oBlSwwNh.jpg",
      "https://i.imgur.com/oBlSwwNh.jpg",
    ],
    price: 47,
    rating: 5,
    categories: "Skiing",
    reviews: 33,
    phone: "1-933-458-6194 x5716",
    coordinates: [-119.08478139918391, 43.44722813155984],
  },
  princeton: {
    name: "Princeton Group",
    id: "princeton",
    location: "Mrazfield, AD",
    images: [
      "https://i.imgur.com/nwiCoxZh.jpg",
      "https://i.imgur.com/nwiCoxZh.jpg",
      "https://i.imgur.com/nwiCoxZh.jpg",
    ],
    price: 74,
    rating: 4,
    categories: "Ping Pong",
    reviews: 111,
    phone: "1-330-264-0785 x337",
    coordinates: [-77.6780887498573, 43.911817940592],
  },
  rinkers: {
    name: "Rinkers",
    id: "rinkers",
    location: "Waterloo, SJ",
    images: [
      "https://i.imgur.com/e94LlfAh.jpg",
      "https://i.imgur.com/e94LlfAh.jpg",
      "https://i.imgur.com/e94LlfAh.jpg",
    ],
    price: 45,
    rating: 4,
    categories: "Tennis",
    reviews: 31,
    phone: "1-563-889-6283 x2724",
    coordinates: [-78.67391803470147, 41.61806809984224],
  },
  walker: {
    name: "Walker Track",
    id: "walker",
    location: "Lake Jacynthestead, CC",
    images: [
      "https://i.imgur.com/aV1iKqMh.jpg",
      "https://i.imgur.com/aV1iKqMh.jpg",
      "https://i.imgur.com/aV1iKqMh.jpg",
    ],
    price: 62,
    rating: 4,
    categories: "Cycling",
    reviews: 26,
    phone: "577-668-4777 x6404",
    coordinates: [-73.13169267485296, 41.4483436625121],
  },
  stehr: {
    name: "Stehr Group",
    id: "stehr",
    location: "Gleichnerland, MV",
    images: [
      "https://i.imgur.com/ZTY0hauh.jpg",
      "https://i.imgur.com/ZTY0hauh.jpg",
      "https://i.imgur.com/ZTY0hauh.jpg",
    ],
    price: 77,
    rating: 4,
    categories: "Soccer",
    reviews: 199,
    phone: "1-317-502-4252 x263",
    coordinates: [-74.53412366025917, 44.6195779891838],
  },
  tmobile: {
    name: "T Mobile Arena",
    id: "tmobile",
    location: "East Tony, MO",
    images: [
      "https://i.imgur.com/e94LlfAh.jpg",
      "https://i.imgur.com/e94LlfAh.jpg",
      "https://i.imgur.com/e94LlfAh.jpg",
    ],
    price: 64,
    rating: 4,
    categories: "Hockey",
    reviews: 154,
    phone: "1-407-672-2107 x762",
    coordinates: [-119.64697683681923, 38.61760125173923],
  },
  waltman: {
    name: "Waltman Forest",
    id: "waltman",
    location: "East Camylleport, SK",
    images: [
      "https://i.imgur.com/j4ZXOlNh.jpg",
      "https://i.imgur.com/j4ZXOlNh.jpg",
      "https://i.imgur.com/j4ZXOlNh.jpg",
    ],
    price: 69,
    rating: 4,
    categories: "Ice Skating",
    reviews: 54,
    phone: "(927) 616-1422",
    coordinates: [-97.07188857506945, 36.5411392232967],
  },
  rising: {
    name: "Rising Stars",
    id: "rising",
    location: "Demetrischester, BZ",
    images: [
      "https://i.imgur.com/CSrbeu3h.jpg",
      "https://i.imgur.com/CSrbeu3h.jpg",
      "https://i.imgur.com/CSrbeu3h.jpg",
    ],
    price: 45,
    rating: 4,
    categories: "Cricket",
    reviews: 189,
    phone: "588.590.5765 x279",
    coordinates: [-119.82409455772786, 37.0520510602345],
  },
  munster: {
    name: "Munster",
    id: "munster",
    location: "Franklin, UG",
    images: [
      "https://i.imgur.com/11vne9ch.jpg",
      "https://i.imgur.com/11vne9ch.jpg",
      "https://i.imgur.com/11vne9ch.jpg",
    ],
    price: 45,
    rating: 3,
    categories: "Rugby",
    reviews: 67,
    phone: "482.605.7314 x85561",
    coordinates: [-101.37053549350782, 47.41006231336954],
  },
  dupont: {
    name: "Dupont Tennis Courts",
    id: "dupont",
    location: "Borerworth, SS",
    images: [
      "https://i.imgur.com/xu6Bj0nh.jpg",
      "https://i.imgur.com/xu6Bj0nh.jpg",
      "https://i.imgur.com/xu6Bj0nh.jpg",
    ],
    price: 53,
    rating: 3,
    categories: "Tennis",
    reviews: 67,
    phone: "880.739.3058",
    coordinates: [-75.86360669688386, 42.882562202464406],
  },
  pavella: {
    name: "Pavella",
    id: "pavella",
    location: "West Sierra, MD",
    images: [
      "https://i.imgur.com/MnXfhHrh.jpg",
      "https://i.imgur.com/MnXfhHrh.jpg",
      "https://i.imgur.com/MnXfhHrh.jpg",
    ],
    price: 50,
    rating: 5,
    categories: "Ice Skating",
    reviews: 162,
    phone: "(353) 383-1546",
    coordinates: [-82.06604856058554, 42.22277952926271],
  },
  bengal: {
    name: "Bengal Box",
    id: "bengal",
    location: "Batzchester, CX",
    images: [
      "https://i.imgur.com/p5kfDkmh.jpg",
      "https://i.imgur.com/p5kfDkmh.jpg",
      "https://i.imgur.com/p5kfDkmh.jpg",
    ],
    price: 55,
    rating: 4,
    categories: "Football",
    reviews: 133,
    phone: "634.735.5748 x26501",
    coordinates: [-84.6220398378293, 40.13752431007976],
  },
  queens: {
    name: "Queens University",
    id: "queens",
    location: "Bennyfield, MO",
    images: [
      "https://i.imgur.com/6IBJtPbh.jpg",
      "https://i.imgur.com/6IBJtPbh.jpg",
      "https://i.imgur.com/6IBJtPbh.jpg",
    ],
    price: 48,
    rating: 3,
    categories: "Baseball",
    reviews: 125,
    phone: "1-716-210-2097 x5988",
    coordinates: [-122.6442663023236, 44.2200634550016],
  },
  beebee: {
    name: "Beebee's",
    id: "beebee",
    location: "Fort Deltafield, GU",
    images: [
      "https://i.imgur.com/N4yYDpih.jpg",
      "https://i.imgur.com/N4yYDpih.jpg",
      "https://i.imgur.com/N4yYDpih.jpg",
    ],
    price: 64,
    rating: 4,
    categories: "Basketball",
    reviews: 112,
    phone: "1-555-844-2222",
    coordinates: [-110.45509390514718, 38.13789728829431],
  },
  patrick: {
    name: "Patricksons",
    id: "patrick",
    location: "Tamarastead, ST",
    images: [
      "https://i.imgur.com/8ix5ygmh.jpg",
      "https://i.imgur.com/8ix5ygmh.jpg",
      "https://i.imgur.com/8ix5ygmh.jpg",
    ],
    price: 59,
    rating: 3,
    categories: "Cricket",
    reviews: 105,
    phone: "1-759-304-6057 x3673",
    coordinates: [-89.79833354050677, 35.635861013377095],
  },
  wagner: {
    name: "Wagner Court ",
    id: "wagner",
    location: "East Orange, BY",
    images: [
      "https://i.imgur.com/I5YYSTzh.jpg",
      "https://i.imgur.com/I5YYSTzh.jpg",
      "https://i.imgur.com/I5YYSTzh.jpg",
    ],
    price: 51,
    rating: 5,
    categories: "Hockey",
    reviews: 55,
    phone: "293.272.2497 x3471",
    coordinates: [-119.6058521086514, 47.0927999770003],
  },
  feest: {
    name: "Feest LLC",
    id: "feest",
    location: "Gorczanystead, BB",
    images: [
      "https://i.imgur.com/CShjflZh.jpg",
      "https://i.imgur.com/CShjflZh.jpg",
      "https://i.imgur.com/CShjflZh.jpg",
    ],
    price: 64,
    rating: 3,
    categories: "Tennis",
    reviews: 163,
    phone: "1-905-642-7838 x16623",
    coordinates: [-84.96045761711454, 41.309781320256164],
  },
  powlowski: {
    name: "Powlowski Sports",
    id: "powlowski",
    location: "Elenoraview, AL",
    images: [
      "https://i.imgur.com/mAfbxG7h.jpg",
      "https://i.imgur.com/mAfbxG7h.jpg",
      "https://i.imgur.com/mAfbxG7h.jpg",
    ],
    price: 46,
    rating: 4,
    categories: "Tennis",
    reviews: 179,
    phone: "(295) 491-2156",
    coordinates: [-118.52835445361038, 45.55487066293841],
  },
  erudite: {
    name: "Erudite",
    id: "erudite",
    location: "North Dominic, YT",
    images: [
      "https://i.imgur.com/DgxYihkh.jpg",
      "https://i.imgur.com/DgxYihkh.jpg",
      "https://i.imgur.com/DgxYihkh.jpg",
    ],
    price: 56,
    rating: 3,
    categories: "Tennis",
    reviews: 189,
    phone: "628.482.8570 x0502",
    coordinates: [-77.62891459065304, 37.10547146899472],
  },
  vegass: {
    name: "Vegas Sport Court",
    id: "vegass",
    location: "Bashirianstead, IN",
    images: [
      "https://i.imgur.com/7QV9S7gh.jpg",
      "https://i.imgur.com/7QV9S7gh.jpg",
      "https://i.imgur.com/7QV9S7gh.jpg",
    ],
    price: 60,
    rating: 4,
    categories: "Basketball",
    reviews: 55,
    phone: "484-258-0136 x42486",
    coordinates: [-107.05383424574316, 36.17439791587252],
  },
  middletown: {
    name: "Middletown Pools",
    id: "middletown",
    location: "West Laurence, SE",
    images: [
      "https://i.imgur.com/bQbvdEOh.jpg",
      "https://i.imgur.com/bQbvdEOh.jpg",
      "https://i.imgur.com/bQbvdEOh.jpg",
    ],
    price: 67,
    rating: 3,
    categories: "Swimming",
    reviews: 139,
    phone: "924-371-7368 x5471",
    coordinates: [-74.87832197129521, 42.89480067106429],
  },
  rempel: {
    name: "Rempel and Bosco",
    id: "rempel",
    location: "Fritschburgh, BM",
    images: [
      "https://i.imgur.com/UjIoqqjh.jpg",
      "https://i.imgur.com/UjIoqqjh.jpg",
      "https://i.imgur.com/UjIoqqjh.jpg",
    ],
    price: 59,
    rating: 3,
    categories: "Ping Pong",
    reviews: 155,
    phone: "1-702-838-9268",
    coordinates: [-97.36085045713278, 39.385613764536295],
  },
  marvin: {
    name: "Marvin's Room",
    id: "marvin",
    location: "North Iliana, SA",
    images: [
      "https://i.imgur.com/QkPS9Xrh.jpg",
      "https://i.imgur.com/QkPS9Xrh.jpg",
      "https://i.imgur.com/QkPS9Xrh.jpg",
    ],
    price: 71,
    rating: 3,
    categories: "Basketball",
    reviews: 77,
    phone: "379.934.7057 x36628",
    coordinates: [-90.12283549497941, 43.069456599679995],
  },
  chicago: {
    name: "Chicago High",
    id: "chicago",
    location: "Lake Antoniamouth, DM",
    images: [
      "https://i.imgur.com/kdrIPnlh.jpg",
      "https://i.imgur.com/kdrIPnlh.jpg",
      "https://i.imgur.com/kdrIPnlh.jpg",
    ],
    price: 72,
    rating: 5,
    categories: "Volleyball",
    reviews: 87,
    phone: "621-300-9702 x29888",
    coordinates: [-96.87234713505995, 31.32446560496662],
  },
  coffee: {
    name: "Coffee and Surf",
    id: "coffee",
    location: "West Crystal, AT",
    images: [
      "https://i.imgur.com/NdK96aLh.jpg",
      "https://i.imgur.com/NdK96aLh.jpg",
      "https://i.imgur.com/NdK96aLh.jpg",
    ],
    price: 49,
    rating: 5,
    categories: "Surfing",
    reviews: 32,
    phone: "569-450-2459 x991",
    coordinates: [-100.59350580670555, 39.16214167384271],
  },
  bayer: {
    name: "Bayer Pitch",
    id: "bayer",
    location: "Lake Yoshikofurt, JM",
    images: [
      "https://i.imgur.com/671G56jh.jpg",
      "https://i.imgur.com/671G56jh.jpg",
      "https://i.imgur.com/671G56jh.jpg",
    ],
    price: 68,
    rating: 4,
    categories: "Volleyball",
    reviews: 90,
    phone: "1-850-365-0302 x0572",
    coordinates: [-101.63620525810087, 42.587600476272655],
  },
  franklin: {
    name: "Franklin Ski Lounge",
    id: "franklin",
    location: "Mrazfurt, RU",
    images: [
      "https://i.imgur.com/rtSnVAGh.jpg",
      "https://i.imgur.com/rtSnVAGh.jpg",
      "https://i.imgur.com/rtSnVAGh.jpg",
    ],
    price: 79,
    rating: 4,
    categories: "Skiing",
    reviews: 70,
    phone: "1-592-446-7219 x60447",
    coordinates: [-104.28601011645537, 41.72797156975257],
  },
  mohr_inc: {
    name: "Mohr Inc",
    id: "mohr_inc",
    location: "Casperport, MR",
    images: [
      "https://i.imgur.com/9logOznh.jpg",
      "https://i.imgur.com/9logOznh.jpg",
      "https://i.imgur.com/9logOznh.jpg",
    ],
    price: 78,
    rating: 4,
    categories: "Swimming",
    reviews: 188,
    phone: "(774) 304-2603",
    coordinates: [-101.17039401026331, 41.968858896059096],
  },
  koch: {
    name: "The Koch's",
    id: "koch",
    location: "Lake Jarrodside, BR",
    images: [
      "https://i.imgur.com/LPkqT3Hh.jpg",
      "https://i.imgur.com/LPkqT3Hh.jpg",
      "https://i.imgur.com/LPkqT3Hh.jpg",
    ],
    price: 74,
    rating: 3,
    categories: "Basketball",
    reviews: 35,
    phone: "223.636.1012",
    coordinates: [-73.6448354773572, 41.851797660704634],
  },
  sweepers: {
    name: "Sweepers",
    id: "sweepers",
    location: "Visalia, AF",
    images: [
      "https://i.imgur.com/hDLEXUNh.jpg",
      "https://i.imgur.com/hDLEXUNh.jpg",
      "https://i.imgur.com/hDLEXUNh.jpg",
    ],
    price: 46,
    rating: 5,
    categories: "Hockey",
    reviews: 117,
    phone: "286.787.6217",
    coordinates: [-111.01087586729614, 32.60104658548966],
  },
  pallazo: {
    name: "The Pallazo",
    id: "pallazo",
    location: "Port Ryannfort, KR",
    images: [
      "https://i.imgur.com/JUtNoZuh.jpg",
      "https://i.imgur.com/JUtNoZuh.jpg",
      "https://i.imgur.com/JUtNoZuh.jpg",
    ],
    price: 63,
    rating: 4,
    categories: "Golf",
    reviews: 82,
    phone: "743-273-5310 x253",
    coordinates: [-89.68542616293446, 36.31007340662255],
  },
  upton: {
    name: "Upton's",
    id: "upton",
    location: "Cary, BA",
    images: [
      "https://i.imgur.com/hk0KwChh.jpg",
      "https://i.imgur.com/hk0KwChh.jpg",
      "https://i.imgur.com/hk0KwChh.jpg",
    ],
    price: 72,
    rating: 5,
    categories: "Ping Pong",
    reviews: 105,
    phone: "695.480.4286",
    coordinates: [-87.49271037381914, 41.43481049032845],
  },
  davis: {
    name: "Davis Ski Resort",
    id: "davis",
    location: "Carmel, IS",
    images: [
      "https://i.imgur.com/99vNhSvh.jpg",
      "https://i.imgur.com/99vNhSvh.jpg",
      "https://i.imgur.com/99vNhSvh.jpg",
    ],
    price: 49,
    rating: 3,
    categories: "Skiing",
    reviews: 200,
    phone: "(786) 484-4118 x63433",
    coordinates: [-106.77775882886377, 38.869408386839865],
  },
  skipper: {
    name: "Skipper",
    id: "skipper",
    location: "Harveyview, TV",
    images: [
      "https://i.imgur.com/KSuCCtrh.jpg",
      "https://i.imgur.com/KSuCCtrh.jpg",
      "https://i.imgur.com/KSuCCtrh.jpg",
    ],
    price: 75,
    rating: 5,
    categories: "Swimming",
    reviews: 121,
    phone: "205.908.8309 x33912",
    coordinates: [-113.6906780407725, 36.91539077795429],
  },
};
