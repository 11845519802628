import { Component, Input, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";
import { Validators, FormGroup } from "@angular/forms";

@Component({
  selector: "p-edit",
  templateUrl: "./p-edit.html",
  styleUrls: ["../partners.scss"],
})
export class PartnerEdit implements OnInit {
  @Input() partner;

  @Input() gym;

  loader = false;

  partner_edit_form: FormGroup;
  constructor(public util: Utilities) {}

  get pf() {
    return this.partner_edit_form.controls;
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm() {
    this.partner_edit_form = this.util.fb.group({
      partner_name: [this.partner.manager, Validators.required],
      gym_name: [this.gym.name, Validators.required],
      gym_address: [this.gym.location, Validators.required],
      price_day: [this.gym.price_day, Validators.required],
      price_week: [this.gym.price_week, Validators.required],
      price_month: [this.gym.price_month, Validators.required],
    });
  }

  async updateProfile() {
    const partner_form = this.partner_edit_form.value;

    await this.util.afd.object(`v2/partners/${this.partner.id}`).update({
      manager: partner_form.partner_name,
    });
    await this.util.afd.object(`v2/gyms/${this.gym.id}`).update({
      name: partner_form.gym_name,
      location: partner_form.gym_address,
      price_day: partner_form.price_day,
      price_week: partner_form.price_week,
      price_month: partner_form.price_month,
    });
    this.util.successToast("Success", "Your Partner Profile has been updated");
    this.util.closeAllDialogs();
  }
}
