import { DOCUMENT } from "@angular/common";
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NavigationEnd } from "@angular/router";
import { mo_social } from "@models/social.mo";

import { Utilities } from "@services/utilities";

@Component({
  selector: "ftnss",
  templateUrl: "./ftnss.c.html",
  styleUrls: ["./ftnss.c.scss"],
})
export class Ftnss implements OnInit {
  lander = false;
  theme;
  loaded = false;
  version = this.util.snappy().version;

  socials = mo_social;

  @ViewChild("mainContent")
  private mainContentDiv!: ElementRef<HTMLElement>;

  constructor(
    public util: Utilities,
    private el: ElementRef,
    @Inject(DOCUMENT) private _document
  ) {}

  ngOnInit() {
    this.util.brk.observe(["(max-width: 980px)"]).subscribe((x) => {
      this.util.setMobile(x.matches);
    });
    this.getGyms();
    this.routeListen();
    this.util.setIsConnected(localStorage.getItem("user") !== null);
    this.util.afa.user.subscribe(async (user) => {
      if (user) {
        this.getPmts();
        this.patSync(user);
        this.syncUser(user);
        localStorage.setItem("user", JSON.stringify(user));
        JSON.parse(localStorage.getItem("user")!);
        this.util.setLoggedInUser(JSON.parse(JSON.stringify(user)));
      } else {
        this.util.setLoggedInUser(null);
        localStorage.setItem("user", "null");
        if (this.util.storey.role !== "guest") {
          await this.util.sto.dispatch(new this.util.axs.ClearState());
          this.util.router.navigate(["/"]);
        }
      }
    });
    this.util.selly().subscribe((x) => {
      this.util.setStorey(x);
      let old_class = x.theme === "dark" ? "light" : "dark";

      this._document.body.classList.remove(old_class);
      this._document.body.classList.add(x.theme);
    });
    this.versionize();
  }

  // *  V E R S I O N I N G   *****************
  async versionize() {
    let version = this.util.snappy().version;
    if (version !== "1.1.9") {
      console.log("Wrong Version");
      this.util.signOut();
    }
  }
  // *  G E T   D A T A B A S E  *****************

  async getGyms() {
    this.util.subLink("v2/gyms").subscribe(async (db: any) => {
      this.util.setGyms(db);
    });
  }

  async getPmts() {
    this.util.subLink("v2/payments").subscribe(async (pmts) => {
      this.util.setPayments(pmts);
    });
  }

  async syncUser(user) {
    this.util.subLink(`v2/users/${user?.uid}`).subscribe(async (user: any) => {
      await this.util.sto.dispatch(
        new this.util.axs.UpdateUserDB({
          ...user,
        })
      );
    });
  }

  async patSync(partner) {
    if (this.util.snappy().role === "partner") {
      this.util
        .subLink(`v2/partners/${partner?.uid}`)
        .subscribe(async (pat: any) => {
          await this.util.sto.dispatch(
            new this.util.axs.SetPartner({
              ...pat,
            })
          );
        });
    }
  }

  routeListen() {
    this.util.router.events.subscribe((x) => {
      if (x instanceof NavigationEnd) {
        this.lander = x.url === "/";
        this.util.sto.dispatch(new this.util.axs.UpdateRoute(x));
      }
    });
  }
}
