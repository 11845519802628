<div *ngLet="util.storey.partner_db as partner" class="px-2 pt-0 pb-5">
  <div>
    <h4 *ngIf="!is_verified" class="text-center">
      <button
        class="bmenu px-4 py-3 brad2 shadow bg-ccr bg1 fw-5 mt-3"
        (click)="verifyEmail()"
      >
        <i class="mx-2 fi fi-sr-hexagon-exclamation"></i>
        Verify your Email
      </button>
    </h4>

    <h4 *ngIf="is_verified" class="text-center">
      <button
        class="bmenu px-4 py-3 brad2 shadow bg-c0 bg1 fw-5 my-3"
        (click)="addGym(dlog)"
      >
        <i class="mx-2 fi fi-sr-marker"></i>
        Setup New Location
      </button>
    </h4>
    <div
      *ngFor="let p_gym of partner.gyms"
      class="con-fin csw my-3 bg-bg1 p-4 shadow brad"
    >
      <div class="row crow" *ngLet="util.storey.gyms[p_gym] as gym">
        <div
          *ngLet="gym.images ?? dummy_images as images"
          class="col-12 col-lg-5"
        >
          <p>
            <img
              width="100%"
              class="brad mimg"
              [src]="getMainImage(gym.id, images)"
              alt="Main Gym Image"
            />
          </p>
          <div class="thumbs-container">
            <div class="thumbs">
              <img
                class="bo3-b9 brads mx-1"
                [ngClass]="{'active': getMainImage(gym.id, images) === image}"
                *ngFor="let image of images"
                [src]="image"
                alt="Gym Thumbnail"
                (click)="setMainImage(gym.id, image)"
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-7">
          <h6 class="fw-6 display-4">{{gym.name}}</h6>
          <h5 class="fw-4">
            <i class="fi fi-sr-marker b9"></i> &nbsp; {{gym.location}}
          </h5>
          <h5 class="fw-6">
            <i class="fi fi-sr-boss b9"></i> &nbsp; {{partner.manager}} &nbsp;
            &nbsp; <i class="fi fi-sr-envelope b9"></i> &nbsp; {{partner.email}}
          </h5>
          <h4 class="my-3">
            <button
              class="mr-4 d-inline-flex align-items-end px-3 py-2 brad text-center my-2"
            >
              <span class="c0 fw-7">${{ gym.price_day | number: '1.0-2'}}</span>
              &nbsp; <span class="fs07"> / day</span>
            </button>
            <button
              class="mr-4 d-inline-flex align-items-end px-3 py-2 brad text-center my-2"
            >
              <span class="c0 fw-7"
                >${{ gym.price_week | number: '1.0-2'}}</span
              >
              &nbsp; <span class="fs07"> / wk</span>
            </button>
            <button
              class="mr-4 d-inline-flex align-items-end px-3 py-2 brad text-center my-2"
            >
              <span class="c0 fw-7"
                >${{ gym.price_month | number: '1.0-2'}}</span
              >
              &nbsp; <span class="fs07"> / mo</span>
            </button>
          </h4>
          <h4>
            <button class="mr-4 d-inline-flex align-items-end px-3 py-2 brad">
              <i class="fi fi-sr-usd-circle ccp"></i>
              &nbsp; {{0 | number: '1.2-2'}}
              <span class="fs07 b9 px-2"> Earned</span>
            </button>
            <button class="px-3 py-2 brad d-inline-flex align-items-end">
              <i class="fi fi-sr-star c0"></i> &nbsp;{{gym.rating | number :
              '1.2-2'}} <span class="fs07 b9 px-2"> Rating</span>
            </button>
          </h4>
          <h5 class="mt-4">
            <button
              class="bmenu px-4 py-3 brad2 shadow bg1 fw-5 m-2"
              [ngClass]="b.bgc ?  b.bgc : 'bg-c0'"
              (click)="openMenu(b.name, gym, dlog)"
              *ngFor="let b of p_home_options"
            >
              <i class="mx-2" [ngClass]="b.icon"></i>
              {{b.name}}
            </button>
          </h5>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #dlog>
  <div class="position-relative p-1 brad bg-bg1 shadow wim6 text-center csw">
    <span matDialogClose class="h3 poi exiter"
      ><i class="fi fi-sr-times-hexagon"></i
    ></span>
    <p-add-gym
      [partner]="util.storey.partner_db"
      *ngIf="current_dialog === 'NewGym'"
    ></p-add-gym>
    <p-edit
      [gym]="current_gym"
      [partner]="util.storey.partner_db"
      *ngIf="current_dialog === 'Edit'"
    ></p-edit>
    <p-photo-edit
      [dagym]="current_gym"
      *ngIf="current_dialog === 'Photos'"
    ></p-photo-edit>
    <p-inventory
      [gym]="current_gym"
      *ngIf="current_dialog === 'Inventory'"
    ></p-inventory>
  </div>
</ng-template>
