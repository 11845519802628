import { Component, OnInit, ViewChild } from "@angular/core";
import { Utilities } from "@services/utilities";

import { switchMap } from "rxjs/operators";

import { StripeService } from "ngx-stripe";
import { pid } from "process";

@Component({
  selector: "buyer",
  templateUrl: "./buyer.html",
  styleUrls: ["./buyer.scss"],
})
export class Buyer implements OnInit {
  user = this.util.snappy().user;
  paymentHandler: any = null;

  wallet;

  constructor(public util: Utilities, private stripeService: StripeService) {}

  ngOnInit() {
    console.log(window.location.href);
  }

  async checkout(pid) {
    // Check the server.js tab to see an example implementation
    this.util.http
      .post("https://api.techceed.org:5201/newPayment", {
        surl: window.location.href,
        curl: window.location.href,
        price_id: pid,
        email: this.util.storey.user.email,
        metadata: {
          uid: this.util.storey.user.uid,
          pid: pid,
        },
      })
      .pipe(
        switchMap((session: any) => {
          return this.stripeService.redirectToCheckout({
            sessionId: session.id,
          });
        })
      )
      .subscribe((result) => {
        // If `redirectToCheckout` fails due to a browser or network
        // error, you should display the localized error message to your
        // customer using `error.message`.
        if (result.error) {
          alert(result.error.message);
        }
      });
  }

  buyFingz(t) {
    // this.util.warningToast("Coming Soon", "Really Soon");
    console.log(t);
    this.checkout(t.pid);
  }
}
