import { Component, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";

import { StripeService } from "ngx-stripe";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "gym",
  templateUrl: "./gym.html",
  styleUrls: ["./gyms.scss"],
})
export class Gym implements OnInit {
  curr_user = this.util.storey.user_db;
  joining = false;
  wallet;
  curr_times = {};
  total_time = 1;
  pass_cost;
  pass_type;
  is_booking;

  scrollx = [0, 0, 4000];
  total_cost = 0;

  days = [
    { name: "Sunday", hours: "7:00 AM - 7:00 PM" },
    { name: "Monday", hours: "5:00 AM - 9:00 PM" },
    { name: "Tuesday", hours: "5:00 AM - 9:00 PM" },
    { name: "Wednesday", hours: "5:00 AM - 9:00 PM" },
    { name: "Thursday", hours: "5:00 AM - 9:00 PM" },
    { name: "Friday", hours: "5:00 AM - 9:00 PM" },
    { name: "Saturday", hours: "7:00 AM - 7:00 PM" },
  ];

  current_schedule = {};

  catMap = this.util.gymCategories.reduce((acc, category) => {
    acc[category.name] = category;
    return acc;
  }, {});

  social = [
    {
      icon: "fi fi-sr-share",
      bg: "bg-b4 cw",
    },
    {
      icon: "fi fi-sr-site-alt",
      bg: "bg-b4 cw",
    },
    {
      icon: "fi fi-sr-phone-call",
      bg: "bg-b4 cw",
    },
    {
      icon: "fi fi-sr-navigation",
      bg: "bg-b4 cw",
    },
  ];

  mainImages: { [key: string]: string } = {};

  constructor(public util: Utilities, private stripe: StripeService) {}

  ngOnInit(): void {}

  setMainImage(gymId: string, image: string) {
    this.mainImages[gymId] = image;
  }

  getMainImage(gymId: string, images: string[]): string {
    return this.mainImages[gymId] || images[0];
  }

  bookPass(cost, pass_type, onboard, temp) {
    if (this.util.is_connected) {
      if (this.util.storey.role === "partner") {
        this.util.warningToast(
          "Partner Account Error",
          "You cannot buy passes with a partner account",
          6000
        );
      } else if (!this.util.storey.user_db.name) {
        this.util.warningToast(
          "Add Name",
          "Add your name in the profile to buy a pass"
        );
        this.util.router.navigate(["/user/home"]);
      } else if (!onboard) {
        this.util.warningToast(
          "Gym Offline",
          "This Gym is not yet offering passes. Stay Tuned !!!"
        );
      } else {
        this.total_time = 1;
        this.pass_cost = cost;
        this.pass_type = pass_type;
        this.util.dlogtr(temp);
      }
    } else {
      this.util.warningToast("Sign in", "Sign in to book a pass", 30000);
      this.util.router.navigate(["/signin"]);
    }
  }

  quError() {
    this.util.errorToast("Payment Error", "");
    this.navReset();
  }

  navReset() {
    this.util.router.navigate([this.util.storey.router.url.split("?")[0]]);
  }

  async buyPass(gym) {
    let gym_cost = this.total_time * this.pass_cost;
    let metadatax = {
      uid: this.util.storey.user.uid,
      user_name: this.util.storey.user.name,
      partner_stripe: gym.partner_stripe,
      partner_uid: gym.partner_id,
      partner_id: gym.id,
      partner_name: gym.name,
      cost: gym_cost,
      unit_amount: this.total_time,
      pass_type: this.pass_type,
    };
    let session: any = await firstValueFrom(
      this.util.http.post(`${this.util.apiUrl}/KauvlEmomh`, {
        success_url: window.location.href,
        cancel_url: window.location.href,
        price: gym_cost * 100,
        email: this.util.storey.user.email,
        metadata: metadatax,
        product_id: "prod_R4qJp1LFltCZzT",
      })
    );
    this.stripe
      .redirectToCheckout({
        sessionId: session.id,
      })
      .subscribe((result) => {
        if (result.error) {
          alert(result.error.message);
        }
      });
  }
}
