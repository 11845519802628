export const gymCategories = [
  { name: "Weights", image: "assets/icons/gyms/weights.png" },
  { name: "Machines", image: "assets/icons/gyms/machines.png" },
  { name: "Cardio", image: "assets/icons/gyms/treadmill.png" },
  { name: "Special", image: "assets/icons/gyms/special.png" },
  { name: "Pool", image: "assets/icons/gyms/pool.png" },
  { name: "Powerlifting", image: "assets/icons/gyms/powerlifting.png" },
  { name: "Female Only", image: "assets/icons/gyms/female.png" },
  { name: "LGBTQIA+", image: "assets/icons/gyms/lgbt.png" },
  { name: "Courts", image: "assets/icons/gyms/courts.png" },
  { name: "Accessibility", image: "assets/icons/gyms/accessibility.png" },
  // { name: "Martial-Arts", image: "assets/icons/gyms/martialarts.png" },
  // { name: "Dance", image: "assets/icons/gyms/dance.png" },
  // { name: "Aerial", image: "assets/icons/gyms/aerial.png" },
  // { name: "Tai-Chi", image: "assets/icons/gyms/taichi.png" },
  // { name: "Rock-Climbing", image: "assets/icons/gyms/rocks.png" },
];

export const sportsCategories = [
  { name: "Basketball", image: "assets/icons/sports/basketball.png" },
  { name: "Soccer", image: "assets/icons/sports/soccer.png" },
  { name: "Football", image: "assets/icons/sports/football.png" },
  { name: "Baseball", image: "assets/icons/sports/baseball.png" },
  { name: "Tennis", image: "assets/icons/sports/tennis.png" },
  { name: "Golf", image: "assets/icons/sports/golf.png" },
  { name: "Hockey", image: "assets/icons/sports/hockey.png" },
  { name: "Volleyball", image: "assets/icons/sports/volleyball.png" },
  { name: "Cricket", image: "assets/icons/sports/cricket.png" },
  { name: "Rugby", image: "assets/icons/sports/rugby.png" },
  { name: "Swimming", image: "assets/icons/sports/swimming.png" },
  { name: "Cycling", image: "assets/icons/sports/cycling.png" },
  { name: "Ice Skating", image: "assets/icons/sports/skating.png" },
  { name: "Skiing", image: "assets/icons/sports/skiing.png" },
  { name: "Surfing", image: "assets/icons/sports/surfing.png" },
  { name: "Ping Pong", image: "assets/icons/sports/pingpong.png" },
];

export const amenities = [
  {
    name: "Wifi",
    icon: "fi fi-sr-router",
    image: "assets/icons/amenities/wifi.png",
  },
  {
    name: "Shower",
    icon: "fi fi-sr-shower",
    image: "assets/icons/amenities/shower.png",
  },
  {
    name: "Coffee Shop",
    icon: "fi fi-sr-mug-hot-alt",
    image: "assets/icons/amenities/coffee.png",
  },
  {
    name: "Lockers",
    icon: "fi fi-sr-archive",
    image: "assets/icons/amenities/lockers.png",
  },
  {
    name: "Steam Room",
    icon: "fi fi-sr-air-conditioner",
    image: "assets/icons/amenities/steam.png",
  },
];
