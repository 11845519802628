export const dummyGyms = {
  the_ohio_gym_bk: {
    name: "The Ohio Gym - Brooklyn",
    id: "the_ohio_gym_bk",
    location: "Brooklyn,  Ohio",
    priority: 2,
    images: [
      "https://i.imgur.com/2GV7tp9h.jpg",
      "https://i.imgur.com/2GV7tp9h.jpg",
      "https://i.imgur.com/2GV7tp9h.jpg",
    ],
    price: 400,
    rating: 3,
    categories: ["Martial-Arts", "Boxing", "Dance"],
    reviews: 80,
    phone: "(767) 331-0833 x399",
    coordinates: [-81.7324626034469, 41.43170980297574],
  },
  the_ohio_gym_sh: {
    name: "The Ohio Gym - Sheffield",
    id: "the_ohio_gym_sh",
    location: "Sheffield,  Ohio",
    priority: 3,
    images: [
      "https://i.imgur.com/4umX3a3h.jpg",
      "https://i.imgur.com/4umX3a3h.jpg",
      "https://i.imgur.com/4umX3a3h.jpg",
    ],
    price: 400,
    rating: 3,
    categories: ["Martial-Arts", "Boxing", "Dance"],
    reviews: 80,
    phone: "(767) 331-0833 x399",
    coordinates: [-82.08001000344741, 41.41966849754781],
  },
  unity_fitness_hbf: {
    name: "UNITY Fitness Harbourfront",
    id: "unity_fitness_hbf",
    location: "Toronto, ON",
    priority: 1,
    images: [
      "https://i.imgur.com/1U1yEt4h.jpg",
      "https://i.imgur.com/AlEq06xh.jpg",
      "https://i.imgur.com/K4UvUtPh.jpg",
    ],
    price: 395,
    rating: 3,
    categories: ["Aerial", "Yoga"],
    reviews: 87,
    phone: "(653) 283-7631 x61792",
    coordinates: [-79.37122274566926, 43.64432006249843],
  },
  the_fore: {
    name: "The Fore",
    id: "the_fore",
    location: "Kings Cross, London ",
    priority: 4,
    images: [
      "https://i.imgur.com/h7xFv2fh.jpg",
      "https://i.imgur.com/RKmUttYh.jpg",
      "https://i.imgur.com/oDbfXCJh.jpg",
    ],
    price: 245,
    rating: 4,
    categories: ["Dance", "Tai-Chi", "Boxing"],
    reviews: 150,
    phone: "(536) 683-9786 x5546",
    coordinates: [-0.12698690292443132, 51.53773313179984],
  },
};

export const dummyGym = {
  hansen_group: {
    name: "Hansen Group",
    id: "hansen_group",
    location: "Lake Betsyfort, UG",
    images: [
      "https://i.imgur.com/29z3UpFh.jpg",
      "https://i.imgur.com/29z3UpFh.jpg",
      "https://i.imgur.com/29z3UpFh.jpg",
    ],
    price: 400,
    rating: 3,
    categories: ["Martial-Arts", "Boxing", "Dance"],
    reviews: 80,
    phone: "(767) 331-0833 x399",
    coordinates: [-89.20223849074621, 44.72456756361021],
  },
  kiehn_inc: {
    name: "Kiehn Inc",
    id: "kiehn_inc",
    location: "Redwood City, VC",
    images: [
      "https://i.imgur.com/kk2tyz4h.jpg",
      "https://i.imgur.com/kk2tyz4h.jpg",
      "https://i.imgur.com/kk2tyz4h.jpg",
    ],
    price: 395,
    rating: 3,
    categories: ["Aerial", "Yoga"],
    reviews: 87,
    phone: "(653) 283-7631 x61792",
    coordinates: [-88.47378270598361, 40.438840133995484],
  },
  schimmel_group: {
    name: "Schimmel Group",
    id: "schimmel_group",
    location: "New Antonia, PT",
    images: [
      "https://i.imgur.com/THfsTnDh.jpg",
      "https://i.imgur.com/THfsTnDh.jpg",
      "https://i.imgur.com/THfsTnDh.jpg",
    ],
    price: 245,
    rating: 4,
    categories: ["Dance", "Tai-Chi", "Boxing"],
    reviews: 150,
    phone: "(536) 683-9786 x5546",
    coordinates: [-111.82221893390933, 38.84856355965917],
  },
  staxxy: {
    name: "Staxxy",
    id: "staxxy",
    location: "New Agustin, MQ",
    images: [
      "https://i.imgur.com/gZiGsS9h.jpg",
      "https://i.imgur.com/gZiGsS9h.jpg",
      "https://i.imgur.com/gZiGsS9h.jpg",
    ],
    price: 390,
    rating: 5,
    categories: ["Dance", "Trainer", "Gymnastics", "Crossfit", "Weight"],
    reviews: 143,
    phone: "759-996-2221 x513",
    coordinates: [-117.46082230026254, 43.387361071726],
  },
  ziemann_inc: {
    name: "Ziemann Inc",
    id: "ziemann_inc",
    location: "Margaretteboro, ID",
    images: [
      "https://i.imgur.com/fI33d8sh.jpg",
      "https://i.imgur.com/fI33d8sh.jpg",
      "https://i.imgur.com/fI33d8sh.jpg",
    ],
    price: 300,
    rating: 3,
    categories: ["Aerial", "Pilates"],
    reviews: 189,
    phone: "1-487-578-8628",
    coordinates: [-90.98521044111813, 40.175356929424524],
  },
  williamson_group: {
    name: "Williamson Group",
    id: "williamson_group",
    location: "Port Edythstad, TJ",
    images: [
      "https://i.imgur.com/uozJFEAh.jpg",
      "https://i.imgur.com/uozJFEAh.jpg",
      "https://i.imgur.com/uozJFEAh.jpg",
    ],
    price: 380,
    rating: 5,
    categories: ["Weight", "Aerial", "Martial-Arts"],
    reviews: 249,
    phone: "(579) 646-5920 x100",
    coordinates: [-84.54217723439154, 41.380146195665745],
  },
  trantow_llc: {
    name: "Trantow LLC",
    id: "trantow_llc",
    location: "South Jess, CF",
    images: [
      "https://i.imgur.com/E4EEhU6h.jpg",
      "https://i.imgur.com/E4EEhU6h.jpg",
      "https://i.imgur.com/E4EEhU6h.jpg",
    ],
    price: 345,
    rating: 5,
    categories: ["Trainer", "Gymnastics", "Martial-Arts"],
    reviews: 65,
    phone: "1-843-353-4469 x27242",
    coordinates: [-119.08478139918391, 43.44722813155984],
  },
  "jakubowski,_schiller_and_schoen": {
    name: "Jakubowski, Schiller and Schoen",
    id: "jakubowski,_schiller_and_schoen",
    location: "Wisozkburgh, MN",
    images: [
      "https://i.imgur.com/eT89ZICh.jpg",
      "https://i.imgur.com/eT89ZICh.jpg",
      "https://i.imgur.com/eT89ZICh.jpg",
    ],
    price: 350,
    rating: 4,
    categories: ["Pilates", "Bikes", "Rock-Climbing"],
    reviews: 135,
    phone: "(537) 722-3948 x29678",
    coordinates: [-77.6780887498573, 43.911817940592],
  },
  wiza_and_sons: {
    name: "Wiza and Sons",
    id: "wiza_and_sons",
    location: "Normal, FK",
    images: [
      "https://i.imgur.com/4ah9AxGh.jpg",
      "https://i.imgur.com/4ah9AxGh.jpg",
      "https://i.imgur.com/4ah9AxGh.jpg",
    ],
    price: 400,
    rating: 3,
    categories: ["Gymnastics", "Crossfit", "Yoga"],
    reviews: 59,
    phone: "382-782-9709",
    coordinates: [-78.67391803470147, 41.61806809984224],
  },
  jaskolski: {
    name: "Jaskolski",
    id: "jaskolski",
    location: "Lolaland, IS",
    images: [
      "https://i.imgur.com/4uGYBuoh.jpg",
      "https://i.imgur.com/4uGYBuoh.jpg",
      "https://i.imgur.com/4uGYBuoh.jpg",
    ],
    price: 270,
    rating: 3,
    categories: ["Aerial", "Gymnastics", "Treadmill"],
    reviews: 125,
    phone: "1-650-774-8161 x181",
    coordinates: [-73.13169267485296, 41.4483436625121],
  },
  planet: {
    name: "Planet Fitness",
    id: "planet",
    location: "Betsyfield, GY",
    images: [
      "https://i.imgur.com/d0bI8uph.jpg",
      "https://i.imgur.com/d0bI8uph.jpg",
      "https://i.imgur.com/d0bI8uph.jpg",
    ],
    price: 365,
    rating: 3,
    categories: ["Rock-Climbing", "Pilates", "Weight"],
    reviews: 136,
    phone: "334-347-3942 x185",
    coordinates: [-74.53412366025917, 44.6195779891838],
  },
  rempel_and_sons: {
    name: "Rempel and Sons",
    id: "rempel_and_sons",
    location: "North Isabellcester, MX",
    images: [
      "https://i.imgur.com/EcJnSZMh.jpg",
      "https://i.imgur.com/EcJnSZMh.jpg",
      "https://i.imgur.com/EcJnSZMh.jpg",
    ],
    price: 280,
    rating: 3,
    categories: ["Treadmill", "Aerial", "Yoga", "Crossfit"],
    reviews: 118,
    phone: "1-938-219-0618 x35663",
    coordinates: [-119.64697683681923, 38.61760125173923],
  },
  "muller_-_reichel": {
    name: "Muller & Reichel",
    id: "muller_-_reichel",
    location: "North Dorris, AD",
    images: [
      "https://i.imgur.com/1tivbLkh.jpg",
      "https://i.imgur.com/1tivbLkh.jpg",
      "https://i.imgur.com/1tivbLkh.jpg",
    ],
    price: 330,
    rating: 5,
    categories: ["Gymnastics", "Trainer", "Yoga", "Dance"],
    reviews: 36,
    phone: "917-831-5343",
    coordinates: [-97.07188857506945, 36.5411392232967],
  },
  fisher: {
    name: "Fisher",
    id: "fisher",
    location: "Margretstead, TM",
    images: [
      "https://i.imgur.com/G1Py9Alh.jpg",
      "https://i.imgur.com/G1Py9Alh.jpg",
      "https://i.imgur.com/G1Py9Alh.jpg",
    ],
    price: 330,
    rating: 3,
    categories: ["Yoga", "Bikes", "Gymnastics"],
    reviews: 233,
    phone: "808.605.9524 x2659",
    coordinates: [-119.82409455772786, 37.0520510602345],
  },
  hartmann_and_sons: {
    name: "Hartmann and Sons",
    id: "hartmann_and_sons",
    location: "West Nathanial, FI",
    images: [
      "https://i.imgur.com/t7qAsieh.jpg",
      "https://i.imgur.com/t7qAsieh.jpg",
      "https://i.imgur.com/t7qAsieh.jpg",
    ],
    price: 345,
    rating: 5,
    categories: ["Weight", "Treadmill", "Dance"],
    reviews: 373,
    phone: "(201) 661-2562 x11123",
    coordinates: [-101.37053549350782, 47.41006231336954],
  },
  miche: {
    name: "Michelle's Dancing Studio",
    id: "miche",
    location: "Fort Winnifredbury, UY",
    images: [
      "https://i.imgur.com/707hAfsh.jpg",
      "https://i.imgur.com/707hAfsh.jpg",
      "https://i.imgur.com/707hAfsh.jpg",
    ],
    price: 290,
    rating: 5,
    categories: ["Yoga", "Dance", "Trainer"],
    reviews: 45,
    phone: "(567) 742-1440 x883",
    coordinates: [-75.86360669688386, 42.882562202464406],
  },
  ferry: {
    name: "Ferry Gyms",
    id: "ferry",
    location: "San Francisco, VN",
    images: [
      "https://i.imgur.com/25m8zIEh.jpg",
      "https://i.imgur.com/25m8zIEh.jpg",
      "https://i.imgur.com/25m8zIEh.jpg",
    ],
    price: 395,
    rating: 3,
    categories: ["Outdoor", "Pilates", "Gymnastics"],
    reviews: 78,
    phone: "1-938-648-4751 x081",
    coordinates: [-82.06604856058554, 42.22277952926271],
  },
  kerluke_group: {
    name: "Kerluke Group",
    id: "kerluke_group",
    location: "Huntersville, VG",
    images: [
      "https://i.imgur.com/AtofA37h.jpg",
      "https://i.imgur.com/AtofA37h.jpg",
      "https://i.imgur.com/AtofA37h.jpg",
    ],
    price: 325,
    rating: 4,
    categories: ["Outdoor", "Bikes"],
    reviews: 240,
    phone: "809.750.3476 x957",
    coordinates: [-84.6220398378293, 40.13752431007976],
  },
  ketut: {
    name: "Ketut Swings",
    id: "ketut",
    location: "Opalburgh, KY",
    images: [
      "https://i.imgur.com/T8171Wph.jpg",
      "https://i.imgur.com/T8171Wph.jpg",
      "https://i.imgur.com/T8171Wph.jpg",
    ],
    price: 295,
    rating: 3,
    categories: ["Weight", "Aerial", "Pilates"],
    reviews: 20,
    phone: "1-874-956-0842 x45053",
    coordinates: [-122.6442663023236, 44.2200634550016],
  },
  assault: {
    name: "Assault Fitness",
    id: "assault",
    location: "North Genesisfort, TD",
    images: [
      "https://i.imgur.com/SVeBoith.jpg",
      "https://i.imgur.com/SVeBoith.jpg",
      "https://i.imgur.com/SVeBoith.jpg",
    ],
    price: 310,
    rating: 3,
    categories: ["Treadmill", "Aerial", "Martial-Arts", "Trainer"],
    reviews: 257,
    phone: "702.784.1955 x7765",
    coordinates: [-110.45509390514718, 38.13789728829431],
  },
  calgym: {
    name: "Cal Gymnastics Club",
    id: "calgym",
    location: "Chino, GF",
    images: [
      "https://i.imgur.com/UdD2V2fh.jpg",
      "https://i.imgur.com/UdD2V2fh.jpg",
      "https://i.imgur.com/UdD2V2fh.jpg",
    ],
    price: 305,
    rating: 5,
    categories: ["Gymnastics", "Outdoor", "Martial-Arts"],
    reviews: 54,
    phone: "677-943-9916 x63449",
    coordinates: [-89.79833354050677, 35.635861013377095],
  },
  zeecross: {
    name: "Zee Cross",
    id: "zeecross",
    location: "Schadenport, SV",
    images: [
      "https://i.imgur.com/BENbNO3h.jpg",
      "https://i.imgur.com/BENbNO3h.jpg",
      "https://i.imgur.com/BENbNO3h.jpg",
    ],
    price: 280,
    rating: 3,
    categories: ["Yoga", "Crossfit", "Martial-Arts"],
    reviews: 47,
    phone: "758.908.1892",
    coordinates: [-119.6058521086514, 47.0927999770003],
  },
  hone: {
    name: "Hone Fitness",
    id: "hone",
    location: "Sawaynchester, AD",
    images: [
      "https://i.imgur.com/VWuGqi4h.jpg",
      "https://i.imgur.com/VWuGqi4h.jpg",
      "https://i.imgur.com/VWuGqi4h.jpg",
    ],
    price: 385,
    rating: 5,
    categories: ["Treadmill", "Bikes"],
    reviews: 186,
    phone: "350.440.1579 x64816",
    coordinates: [-84.96045761711454, 41.309781320256164],
  },
  brakus: {
    name: "Brakus Fitness",
    id: "brakus",
    location: "Leslieboro, MQ",
    images: [
      "https://i.imgur.com/bqin6gWh.jpg",
      "https://i.imgur.com/bqin6gWh.jpg",
      "https://i.imgur.com/bqin6gWh.jpg",
    ],
    price: 370,
    rating: 4,
    categories: ["Bikes", "Treadmill", "Aerial"],
    reviews: 398,
    phone: "1-747-617-2316",
    coordinates: [-118.52835445361038, 45.55487066293841],
  },
  harris: {
    name: "Harris Judo",
    id: "harris",
    location: "Okeyburgh, SM",
    images: [
      "https://i.imgur.com/XkX6HMuh.jpg",
      "https://i.imgur.com/XkX6HMuh.jpg",
      "https://i.imgur.com/XkX6HMuh.jpg",
    ],
    price: 360,
    rating: 3,
    categories: ["Martial-Arts", "Yoga", "Rock-Climbing"],
    reviews: 105,
    phone: "(209) 208-9293 x930",
    coordinates: [-77.62891459065304, 37.10547146899472],
  },
  box1: {
    name: "Champ Boxing Studios",
    id: "box1",
    location: "Elgin, SI",
    images: [
      "https://i.imgur.com/iYFeKn4h.jpg",
      "https://i.imgur.com/iYFeKn4h.jpg",
      "https://i.imgur.com/iYFeKn4h.jpg",
    ],
    price: 295,
    rating: 5,
    categories: ["Boxing", "Tai-Chi"],
    reviews: 39,
    phone: "318-711-4022 x70363",
    coordinates: [-107.05383424574316, 36.17439791587252],
  },
  climb1: {
    name: "Windler LLC",
    id: "climb1",
    location: "Goodyear, JP",
    images: [
      "https://i.imgur.com/41rEUQIh.jpg",
      "https://i.imgur.com/41rEUQIh.jpg",
      "https://i.imgur.com/41rEUQIh.jpg",
    ],
    price: 340,
    rating: 5,
    categories: ["Trainer", "Aerial"],
    reviews: 297,
    phone: "1-451-367-1669",
    coordinates: [-74.87832197129521, 42.89480067106429],
  },
  hot8: {
    name: "Hot 8 Yoga",
    id: "hot8",
    location: "East Liamcester, KN",
    images: [
      "https://i.imgur.com/vQTTgRvh.jpg",
      "https://i.imgur.com/vQTTgRvh.jpg",
      "https://i.imgur.com/vQTTgRvh.jpg",
    ],
    price: 305,
    rating: 5,
    categories: ["Yoga", "Dance", "Outdoor"],
    reviews: 186,
    phone: "977.983.0511 x572",
    coordinates: [-97.36085045713278, 39.385613764536295],
  },
  andreas: {
    name: "Andreas",
    id: "andreas",
    location: "Port Heath, BS",
    images: [
      "https://i.imgur.com/vhfoZhnh.jpg",
      "https://i.imgur.com/vhfoZhnh.jpg",
      "https://i.imgur.com/vhfoZhnh.jpg",
    ],
    price: 375,
    rating: 5,
    categories: ["Rock-Climbing", "Aerial", "Yoga"],
    reviews: 304,
    phone: "253.313.4265 x45627",
    coordinates: [-90.12283549497941, 43.069456599679995],
  },
  quantum: {
    name: "Quantum Martial Arts",
    id: "quantum",
    location: "East Assuntaworth, MV",
    images: [
      "https://i.imgur.com/lnbij1ph.jpg",
      "https://i.imgur.com/lnbij1ph.jpg",
      "https://i.imgur.com/lnbij1ph.jpg",
    ],
    price: 355,
    rating: 3,
    categories: ["Martial-Arts", "Trainer"],
    reviews: 293,
    phone: "1-707-349-9491 x2445",
    coordinates: [-96.87234713505995, 31.32446560496662],
  },
  goodlife: {
    name: "GoodLife Fitness",
    id: "goodlife",
    location: "Fort Madie, VI",
    images: [
      "https://i.imgur.com/3ojNoP0h.png",
      "https://i.imgur.com/3ojNoP0h.png",
      "https://i.imgur.com/3ojNoP0h.png",
    ],
    price: 330,
    rating: 4,
    categories: ["Trainer", "Yoga", "Martial-Arts", "Crossfit"],
    reviews: 279,
    phone: "325-492-0706 x1942",
    coordinates: [-100.59350580670555, 39.16214167384271],
  },
  airfit: {
    name: "AirFit",
    id: "airfit",
    location: "Lake Rupertcester, KN",
    images: [
      "https://i.imgur.com/WSYRAzSh.jpg",
      "https://i.imgur.com/WSYRAzSh.jpg",
      "https://i.imgur.com/WSYRAzSh.jpg",
    ],
    price: 225,
    rating: 5,
    categories: ["Pilates", "Outdoor", "Trainer"],
    reviews: 208,
    phone: "280-877-3600 x4774",
    coordinates: [-101.63620525810087, 42.587600476272655],
  },
  trantow: {
    name: "Trantow - Larson",
    id: "trantow",
    location: "Irving, PM",
    images: [
      "https://i.imgur.com/MrRVoUSh.jpg",
      "https://i.imgur.com/MrRVoUSh.jpg",
      "https://i.imgur.com/MrRVoUSh.jpg",
    ],
    price: 370,
    rating: 5,
    categories: ["Pilates", "Aerial"],
    reviews: 259,
    phone: "(259) 925-8812 x35942",
    coordinates: [-104.28601011645537, 41.72797156975257],
  },
  lyft: {
    name: "Lyft Fitness",
    id: "lyft",
    location: "Aliso Viejo, KE",
    images: [
      "https://i.imgur.com/R1mJhWyh.jpg",
      "https://i.imgur.com/R1mJhWyh.jpg",
      "https://i.imgur.com/R1mJhWyh.jpg",
    ],
    price: 265,
    rating: 4,
    categories: ["Treadmill", "Bikes", "Trainer", "Weight"],
    reviews: 39,
    phone: "235-796-5926 x534",
    coordinates: [-101.17039401026331, 41.968858896059096],
  },
  shape: {
    name: "Shape Fitness",
    id: "shape",
    location: "Fort Lilianberg, WS",
    images: [
      "https://i.imgur.com/8s2dQj8h.jpg",
      "https://i.imgur.com/8s2dQj8h.jpg",
      "https://i.imgur.com/8s2dQj8h.jpg",
    ],
    price: 295,
    rating: 5,
    categories: ["Rock-Climbing", "Aerial"],
    reviews: 333,
    phone: "1-651-755-9080 x01249",
    coordinates: [-73.6448354773572, 41.851797660704634],
  },
  laf: {
    name: "L.A. Fitness",
    id: "laf",
    location: "Hopeside, ES",
    images: [
      "https://i.imgur.com/TuKDnyNh.jpg",
      "https://i.imgur.com/TuKDnyNh.jpg",
      "https://i.imgur.com/TuKDnyNh.jpg",
    ],
    price: 290,
    rating: 4,
    categories: ["Trainer", "Aerial", "Yoga"],
    reviews: 292,
    phone: "679-748-2445 x879",
    coordinates: [-111.01087586729614, 32.60104658548966],
  },
  factor: {
    name: "Fit Factory",
    id: "factory",
    location: "South Mafalda, WS",
    images: [
      "https://i.imgur.com/1q0afJwh.jpg",
      "https://i.imgur.com/1q0afJwh.jpg",
      "https://i.imgur.com/1q0afJwh.jpg",
    ],
    price: 225,
    rating: 4,
    categories: ["Rock-Climbing", "Aerial", "Martial-Arts"],
    reviews: 298,
    phone: "616-384-9981",
    coordinates: [-89.68542616293446, 36.31007340662255],
  },
  orange: {
    name: "Orange Theory Fitness",
    id: "orange",
    location: "East Rachelleberg, KN",
    images: [
      "https://i.imgur.com/bgZpSe2h.jpg",
      "https://i.imgur.com/bgZpSe2h.jpg",
      "https://i.imgur.com/bgZpSe2h.jpg",
    ],
    price: 385,
    rating: 3,
    categories: ["Bikes", "Pilates", "Trainer", "Treadmill"],
    reviews: 222,
    phone: "209.430.2556 x11020",
    coordinates: [-87.49271037381914, 41.43481049032845],
  },
  anytime: {
    name: "Anytime Fitness",
    id: "anytime",
    location: "Nataliaworth, TH",
    images: [
      "https://i.imgur.com/O9D7h1Gh.jpg",
      "https://i.imgur.com/O9D7h1Gh.jpg",
      "https://i.imgur.com/O9D7h1Gh.jpg",
    ],
    price: 320,
    rating: 5,
    categories: ["Boxing", "Bikes", "Dance"],
    reviews: 111,
    phone: "(363) 782-1820 x8540",
    coordinates: [-106.77775882886377, 38.869408386839865],
  },
  simply: {
    name: "Simply Fit",
    id: "simply",
    location: "Grahamtown, PF",
    images: [
      "https://i.imgur.com/VJX2w5jh.jpg",
      "https://i.imgur.com/VJX2w5jh.jpg",
      "https://i.imgur.com/VJX2w5jh.jpg",
    ],
    price: 300,
    rating: 4,
    categories: ["Treadmill", "Tai-Chi", "Outdoor"],
    reviews: 59,
    phone: "991-378-3223 x415",
    coordinates: [-113.6906780407725, 36.91539077795429],
  },
};
