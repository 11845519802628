import { Component, Input, OnInit } from "@angular/core";
import { NgxImageCompressService } from "ngx-image-compress";
import { Utilities } from "@services/utilities";
import { finalize } from "rxjs/operators";

@Component({
  selector: "p-photo-edit",
  templateUrl: "./p-photo-edit.html",
  styleUrls: ["../partners.scss"],
})
export class PartnerPhotoEdit implements OnInit {
  @Input() dagym: any;
  gym: any;
  newImageUrl: string = "";

  constructor(
    public util: Utilities,
    private imageCompress: NgxImageCompressService
  ) {}

  ngOnInit(): void {
    this.gym = { ...this.dagym };
  }

  onFileSelected(event: any) {
    console.log("Selected");
    const file = event.target.files[0];
    if (file) {
      this.compressAndUploadImage(file);
    }
  }

  compressAndUploadImage(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const imageData = reader.result as string;
      this.imageCompress
        .compressFile(imageData, -1, 50, 50) // Adjust compression ratio as needed
        .then((compressedImage) => {
          const blob = this.dataURLtoBlob(compressedImage);
          const filePath = `gym_images/${Date.now()}_${file.name}`;
          const fileRef = this.util.storage.ref(filePath);
          const task = this.util.storage.upload(filePath, blob);

          task
            .snapshotChanges()
            .pipe(
              finalize(() => {
                fileRef.getDownloadURL().subscribe((url) => {
                  this.newImageUrl = url; // Save the URL to add to the gym images list
                });
              })
            )
            .subscribe();
        });
    };
  }

  dataURLtoBlob(dataURL: string) {
    const byteString = atob(dataURL.split(",")[1]);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  addImageUrl() {
    if (this.newImageUrl) {
      let images = [...this.gym.images];
      images.push(this.newImageUrl);
      this.gym.images = [...images];
      this.newImageUrl = "";
    }
  }

  deleteImage(index: number) {
    let images = [...this.gym.images];
    images.splice(index, 1);
    this.gym.images = [...images];
  }

  moveImageUp(index: number) {
    let images = [...this.gym.images];
    if (index > 0) {
      [images[index - 1], images[index]] = [images[index], images[index - 1]];
    }
    this.gym.images = [...images];
  }

  moveImageDown(index: number) {
    let images = [...this.gym.images];
    if (index < images.length - 1) {
      [images[index], images[index + 1]] = [images[index + 1], images[index]];
    }
    this.gym.images = [...images];
  }

  async updatePhotos() {
    await this.util.afd.object(`v2/gyms/${this.gym.id}`).update({
      images: this.gym.images,
    });
    this.util.successToast("Success", "Images have been updated");
    this.util.closeAllDialogs();
  }
}
