<div class="container-xl">
  <div class="csw mt-4 p-4 mx-auto">
    <h1 class="">Community Guidelines for Ftnss</h1>
    <p>Last Updated: January 1, 2022</p>

    <p>
      Our Guidelines govern your relationship with Ftnss, your use of Ftnss, as
      well as your access to our services and products. By accessing Ftnss, you
      agree to these terms.
    </p>

    <p>
      These Guidelines and our Terms of Service ( https://ftnss.fit/terms ) are
      designed to enable productive, polite discourse among people with
      differing interests, life experiences, and viewpoints. Failure to adhere
      to these guidelines may result in losing access to our platform.
    </p>

    <p>
      Our goal is to create a welcoming environment for all community members,
      to vote, create and share polls.
    </p>

    <h5>Photo Upload</h5>

    <p>
      We take copyrights very seriously. If you don't own the rights to a photo,
      please don't upload it. Photos uploaded must follow these guidelines:
      <em>1</em> No kids on their own. They must be in the photo with an adult,
      and fully clothed. <br />
      <em>2</em> No pictures in underwear. <br />
      <em>3</em> No Shirtless/underwear Mirror Selfies.
      <br />
      <em>4</em> No pornographic material. <br />
      <em>5</em> No graphic hunting photos. <br />
      <em>6</em> No guns. <br />
    </p>

    <h5>Respect all users</h5>

    <p>
      We're a very diverse community. This means you should respect other
      people's beliefs, interests and property while on Ftnss. Ftnss takes a
      strong stance against hate speech, rude or abusive behaviour, bullying,
      and misogyny. Additionally we encourage all of our users to report anyone
      or poll that does not follow these behavioural guidelines (
      https://ftnss.fit/report ). As a community rooted in kindness and respect,
      we expect all of our users to respect each other, themselves, and the
      Ftnss staff.
    </p>

    <h5>No soliciting</h5>
    <p>
      Ftnss isn't for selling things. If you try to use it as a marketplace,
      you'll be banned.
    </p>
  </div>
</div>
