export const admin_map = {
  users: {
    headers: ["uid", "email", "is_partner"],
    tracker: "id",
  },
  partners: {
    headers: ["id", "email", "stripe_account"],
    tracker: "id",
  },
  gyms: {
    headers: ["id"],
    tracker: "id",
  },
  payments: {
    headers: [
      "data.user_name",
      "status",
      "data.pass_type",
      "data.partner_name",
      "data.uid",
    ],
    tracker: "reff",
  },
};
