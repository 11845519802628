<div class="px-2 py-0 wim8 mx-auto" *ngLet="util.storey.user as profile">
  <div
    class="csw mt-3 bg-bg1 p-4 shadow brad"
    *ngLet="util.storey.user_db as udb"
  >
    <div class="row py-5" *ngLet="util.storey.user_db as udb">
      <!-- $ PROFILE  -->
      <div class="col-12 my-auto">
        <p class="text-center">
          <img
            [src]="profile?.photoURL ?? '/assets/profile.png'"
            width="100px"
            class="bradd"
          />
        </p>
        <h4 class="fw-8 text-center">{{udb.name}}</h4>
        <p class="fw-4 text-center">
          <i class="fi fi-sr-envelope b9"></i> &nbsp; {{profile.email}}
        </p>
      </div>
      <div class="col-12">
        <div class="mt-1">
          <div class="wim3 text-center" *ngFor="let b of p_home_options">
            <button
              class="px-4 py-2 brad3 bo3-b9 fw-5 my-2"
              [ngClass]="b.style"
              (click)="openMenu(b.name, b.link, profile, editor)"
            >
              <i class="mx-2 fs12" [ngClass]="b.icon"></i> {{b.name}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- *EDIT PROFILE MODAL -->
<ng-template #editor>
  <div class="position-relative p-1 brad bg-bg1 shadow wim6 text-center csw">
    <span matDialogClose class="h3 poi exiter"
      ><i class="fi fi-sr-times-hexagon"></i
    ></span>
    <div class="p-4 my-5">
      <div class="shadow p-4 brad bol-csw">
        <input
          width="100"
          class="csw fs2 zinput"
          [(ngModel)]="edit_name"
          type="text"
        />
      </div>

      <div class="text-center mt-5">
        <button
          (click)="updateName()"
          class="mx-auto brad3 px-5 py-3 bg-c0 h3 wim3 bg1 bo3-cwe"
        >
          Update Name
        </button>
      </div>
    </div>
  </div>
</ng-template>
