<div class="wim8 mx-auto">
  <h3 *ngIf="!is_partner" class="display-3 text-center my-4 fw-6 csw">FAQ</h3>
  <h3 *ngIf="is_partner" class="text-center my-4 fw-8 csw spa4">
    COMMON PARTNER QUESTIONS
  </h3>
  <div class="m-3 pt-2">
    <div class="mx-2">
      <mat-accordion>
        <mat-expansion-panel
          *ngFor="let faq of is_partner ? util.partner_faqs :util.faqs   ; let i = index"
          class="my-3 bg-bg1 bol-b4 px-1 py-2 shadow"
        >
          <mat-expansion-panel-header>
            <mat-panel-title class="fw-6 h6 b3">
              {{ faq.question}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p class="csw h6 b9 fans">{{ faq.answer }}</p>
        </mat-expansion-panel>
      </mat-accordion>
      <!-- <p class="mt-5 fs07 b3">{{navvy }}</p> -->
    </div>
  </div>
</div>
