<div class="sticky-top">
  <search-bar [search_type]="search_params"></search-bar>
</div>
<div
  class="con-fin pt-5"
  [ngClass]="{'marg': util.is_connected, 'con-map': util.storey.map_mode}"
>
  <div [ngClass]="{'row' : util.storey.map_mode}">
    <div
      [ngClass]="{'col-12' : util.storey.map_mode, 'd-none': !util.storey.map_mode}"
    >
      <maps [data]="filtered_gyms" [map_init]="map_init" map_type="gyms"></maps>
    </div>
    <div class="" [ngClass]="{' d-none noscroll' : util.storey.map_mode}">
      <div class="frow px-3 pt-5 mt-4">
        <ng-container
          *ngFor="let gym of filtered_gyms | keyvalue | orderBy:'value.priority' | searchFilter: util.storey.search.gym"
        >
          <gym-card *ngIf="!gym.value.inactive" [gym]="gym.value"></gym-card>
        </ng-container>
        <!-- <ng-container *ngFor="let gym2 of randomGyms">
          <gym-card [gym]="gym2"></gym-card>
        </ng-container> -->
      </div>
    </div>
    <div class="max-1vw" *ngIf="empty_gym_list">
      <h4 class="text-center fw-6 py-4">
        There are no gyms for this search criteria
      </h4>
    </div>
  </div>
</div>
