import { Component, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";
import { dummy_evs } from "./p-dummy";
import { dummySports } from "@models/sports.dum";
import { dummyGym } from "@models/gyms.dum";

@Component({
  selector: "sports",
  templateUrl: "./sports.html",
  styleUrls: ["../gyms/gyms.scss"],
})
export class Sports implements OnInit {
  search_params = {
    name: "sports",
    title: "Sports",
    color: "b3",
    border: "bot-b3 opa",
    border_l: "bol-cck",
    profile_icon: "fas fa-house-user",
    profile_bg: "bg-b3",
    profile_link: "/profile/sport",
    categories: this.util.sportsCategories,
  };
  sports = dummySports;
  filtered_sports;
  map_init = {
    lat: 39.8283,
    lng: -98.5795,
    zoom: 3,
  };

  catMap = this.util.sportsCategories.reduce((acc, category) => {
    acc[category.name] = category.image;
    return acc;
  }, {});

  constructor(public util: Utilities) {}

  ngOnInit() {
    this.filtered_sports = this.filterSportsByCategories(
      this.util.storey.filters.sports,
      this.sports
    );
    this.util.selly().subscribe((x) => {
      this.filtered_sports = this.filterSportsByCategories(
        x.filters.sports,
        this.sports
      );
    });
  }

  filterSportsByCategories(filter: Record<string, boolean> = {}, sports) {
    const filteredSports = {};

    for (const sportId in sports) {
      const sport = sports[sportId];
      const shouldInclude = Object.keys(filter).every((category) => {
        return filter[category] === false || filter[sport.categories];
      });

      if (shouldInclude) {
        filteredSports[sportId] = sport;
      }
    }

    return filteredSports;
  }

  sportRoute(gym) {
    this.util.router.navigate([`sports/${gym.id}`]);
  }
}
