import { Component, OnInit } from "@angular/core";
import {
  Validators,
  FormGroup,
  AbstractControl,
  ValidatorFn,
} from "@angular/forms";
import { Utilities } from "@services/utilities";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "p-new",
  templateUrl: "./p-new.html",
  styleUrls: ["../partners.scss"],
})
export class NewPartner implements OnInit {
  mode;
  partner_form: FormGroup;
  loader = false;

  constructor(public util: Utilities) {}

  ngOnInit() {
    this.mode = "signup";
    this.setForm();
  }

  get pf() {
    return this.partner_form.controls;
  }

  setForm() {
    const is_signup = this.mode === "signup";
    this.partner_form = this.util.fb.group({
      partner_name: is_signup ? ["", Validators.required] : [""],
      gym_name: is_signup ? ["", Validators.required] : [""],
      gym_address: is_signup ? ["", Validators.required] : [""],
      email: [
        "",
        [
          Validators.required,
          Validators.email,
          Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$"),
        ],
      ],
      password: !(this.mode === "reset")
        ? ["", [Validators.required, Validators.minLength(6)]]
        : [""],
      password_confirm: is_signup ? ["", Validators.required] : [""],
    });

    this.passwordValidator(is_signup);
  }

  passwordValidator(active) {
    if (active) {
      this.partner_form.addValidators(
        matchValidator(
          this.partner_form.get("password"),
          this.partner_form.get("password_confirm")
        )
      );
    } else {
      this.partner_form.clearValidators();
    }
  }

  async switchAuth() {
    switch (this.mode) {
      case "signin":
        this.mode = "signup";
        break;
      case "signup":
        this.mode = "signin";
        break;
      case "reset":
        this.mode = "signin";
        break;

      default:
        break;
    }
    this.partner_form.markAsPristine();
    await this.util.delay(0.5);
    this.setForm();
  }

  authenticate() {
    if (this.mode === "signup") {
      this.createPartnerAccount();
    } else if (this.mode === "signin") {
      this.partnerSignin();
    } else if (this.mode === "reset") {
      this.resetPassword();
    }
  }

  async createPartnerAccount() {
    this.loader = true;
    const response: any = await firstValueFrom(
      this.util.http.post(`${this.util.apiUrl}/IqGlAJsSCO`, {
        email: this.partner_form.value.email,
        password: this.partner_form.value.password,
        gym_name: this.partner_form.value.gym_name,
        gym_address: this.partner_form.value.gym_address,
        manager: this.partner_form.value.partner_name,
      })
    );
    if (response?.success) {
      this.util.successToast(
        "Success",
        "Your Parter Account has been created. Login with your email & password",
        8000
      );
      this.mode = "signin";
      await this.util.delay(0.5);
      this.setForm();
    } else {
      this.util.errorToast("Error", response?.message, 8000);
      await this.util.delay(0.5);
      this.setForm();
    }
    this.loader = false;
  }

  async partnerSignin() {
    await this.util.afa
      .signInWithEmailAndPassword(
        this.partner_form.value.email,
        this.partner_form.value.password
      )
      .then(async (x) => {
        this.util.closeAllDialogs();
        await this.util.syncPartner(x.user);
      })
      .catch((e) => {
        this.util.errorToast("Error", "Incorrect email or password!");
      });
  }

  resetPassword() {
    this.util.afa
      .sendPasswordResetEmail(this.partner_form.value.email)
      .then((x) => {
        this.util.successToast(
          "Success",
          "Check your email and reset password"
        );

        this.mode = "signin";
      })
      .catch((e) => {
        this.util.errorToast("Error", e.message);
      });
  }
}

function matchValidator(
  control: AbstractControl,
  controlTwo: AbstractControl
): ValidatorFn {
  return () => {
    if (control.value !== controlTwo.value)
      return { match_error: "Value does not match" };
    return null;
  };
}
