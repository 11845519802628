const timex = {
  "0th": "closed",
  "10th": "closed",
  "11th": "closed",
  "12th": "closed",
  "13th": "closed",
  "14th": "closed",
  "15th": "closed",
  "16th": "closed",
  "17th": "closed",
  "18th": "closed",
  "19th": "closed",
  "1st": "closed",
  "20th": "closed",
  "21st": "closed",
  "22nd": "closed",
  "23rd": "closed",
  "2nd": "closed",
  "3rd": "closed",
  "4th": "closed",
  "5th": "closed",
  "6th": "closed",
  "7th": "closed",
  "8th": "closed",
  "9th": "closed",
};

const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const timeq = days.map((x) => {
  let nu: any = {};
  nu.day = x;
  nu.times = { ...timex };
  return nu;
});

export const partner_button_list = [
  {
    name: "Edit",
    icon: "fi fi-sr-file-edit",
  },
  {
    name: "Photos",
    icon: "fi fi-sr-camera",
  },
  {
    name: "Inventory",
    icon: "fi fi-sr-gym",
  },
  {
    name: "Passes",
    icon: "fi fi-sr-ticket",
  },
  {
    name: "Finances",
    icon: "fi fi-sr-money-bills-simple",
    bgc: "bg-ccp",
  },
];

export const u_h_menu = [
  {
    name: "Edit Profile",
    icon: "fi fi-sr-file-edit",
    style: "bg-cwe b4",
  },
  // {
  //   name: "Passes",
  //   icon: "fi fi-sr-ticket",
  //   style: "bg-c0 cw ",
  //   link: "/user/passes",
  // },
  // {
  //   name: "Favorites",
  //   icon: "fi fi-sr-heart",
  //   style: "bg-ccr cw",
  //   link: "/user/favs",
  // },
];
