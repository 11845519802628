<div class="wim8 mx-auto">
  <h4 class="text-center my-5 fw-6 csw display-4">Account Delete Request</h4>

  <!-- If the user has not requested for deletion -->
  <div *ngIf="!util.storey.user_db.delete" class="m-3">
    <h4 class="text-center">Do you want to delete your account?</h4>
    <div class="text-center">
      <button
        class="btn btn-danger mt-3 brad2 px-4 py-3 fw-6 fs12"
        (click)="requestDelete(true)"
      >
        Request Account Deletion
      </button>
    </div>
  </div>

  <!-- If the user has already requested for deletion -->
  <div *ngIf="util.storey.user_db.delete" class="m-3">
    <h4 class="text-center">You have already requested account deletion.</h4>
    <div class="text-center">
      <button
        class="btn btn-warning mt-3 brad2 px-4 py-3 fw-6 fs12"
        (click)="requestDelete(false)"
      >
        Revoke Account Deletion Request
      </button>
    </div>
  </div>
</div>
