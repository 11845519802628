<div class="feeter box2 bg-bg1 wi100" [ngClass]="{'ios-bottom': util.isM}">
  <div *ngIf="util.isM " class="container my-0 mobFoot">
    <div class="row csw">
      <!-- # GYMS  -->
      <div
        *ngIf="util.validLinks() && util.storey.role !== 'partner'"
        routerLink="/"
        class="col p-0 text-center shadow-sm"
      >
        <div
          class="pt-3"
          [ngClass]="util.storey.router.url === '/' ? 'c0 bot-c0' : 'b9 bot-b9'"
        >
          <i class="fi fi-sr-gym fs12"></i>
          <p class="fw-8 fs7">Gyms</p>
        </div>
      </div>
      <!-- # FAQ   -->
      <div
        routerLink="/help/faq"
        class="col p-0 text-center shadow-sm"
        *ngIf="!util.is_connected"
      >
        <div
          class="pt-3"
          [ngClass]="util.storey.router.url === '/help/faq' ? 'c0 bot-c0' : 'b9 bot-b9'"
        >
          <i class="fi fi-sr-interrogation fs12"></i>
          <p class="fw-8 fs7">FAQ</p>
        </div>
      </div>
      <!-- # Sign In  -->
      <div
        routerLink="/signin"
        class="col p-0 text-center shadow-sm"
        *ngIf="!util.is_connected"
      >
        <div
          class="pt-3"
          [ngClass]="util.storey.router.url === '/signin' ? 'c0 bot-c0' : 'b9 bot-b9'"
        >
          <i class="fi fi-sr-circle-user fs12"></i>
          <p class="fw-8 fs7">Sign in</p>
        </div>
      </div>
      <!-- # User Profile  -->
      <div
        routerLink="/user/home"
        class="col p-0 text-center shadow-sm"
        *ngIf="util.storey.role === 'user'"
      >
        <div
          class="pt-3"
          [ngClass]="util.storey.router.url === '/user/home' ? 'c0 bot-c0' : 'b9 bot-b9'"
        >
          <i class="fi fi-sr-user fs12"></i>
          <p class="fw-8 fs7">Profile</p>
        </div>
      </div>
      <!-- # Partner Home  -->
      <div
        routerLink="/partner/home"
        class="col p-0 text-center shadow-sm"
        *ngIf="util.storey.role === 'partner'"
      >
        <div
          class="pt-3"
          [ngClass]="util.storey.router.url === '/partner/home' ? 'c0 bot-c0' : 'b9 bot-b9'"
        >
          <i class="fi fi-sr-house-window fs12"></i>
          <p class="fw-8 fs7">Partner</p>
        </div>
      </div>
      <!-- # Partner Passes  -->
      <div
        routerLink="/partner/passes"
        class="col p-0 text-center"
        *ngIf="util.storey.role === 'partner'"
      >
        <div
          class="pt-3"
          [ngClass]="util.storey.router.url === '/partner/passes' ? 'c0 bot-c0' : 'b9 bot-b9'"
        >
          <i class="fi fi-sr-ticket fs12"></i>
          <p class="fw-8 fs7">Passes</p>
        </div>
      </div>
      <!-- # User Passes  -->
      <div
        routerLink="/user/passes"
        class="col p-0 text-center"
        *ngIf="util.storey.role === 'user'"
      >
        <div
          class="pt-3"
          [ngClass]="util.storey.router.url === '/user/passes' ? 'c0 bot-c0' : 'b9 bot-b9'"
        >
          <i class="fi fi-sr-ticket fs12"></i>
          <p class="fw-8 fs7">Passes</p>
        </div>
      </div>
      <!-- # Menu Trigger  -->
      <div class="col p-0 text-center shadow-sm" [matMenuTriggerFor]="umenu">
        <div class="pt-3 bot-csw">
          <i class="fi fi-sr-bars-staggered fs12"></i>
          <p class="fw-8 fs7">Menu</p>
        </div>
      </div>

      <mat-menu
        #umenu="matMenu"
        xPosition="after"
        class="bg-bg1 mb-4 mr-5 wi3 shadow csw brad p-0 menux text-left"
      >
        <h5 *ngIf="!util.is_connected" routerLink="/signin" class="fw-6">
          Sign in
        </h5>
        <h5 *ngIf="!util.is_connected" routerLink="/signup" class="fw-6">
          Sign up
        </h5>

        <h5 *ngIf="util.is_connected" (click)="util.signOut()" class="fw-6 ccr">
          <i class="fi fi-sr-sign-out-alt mr-2"></i> Sign Out
        </h5>
        <hr class="b9 m-0" />

        <h5 *ngIf="!util.is_connected" routerLink="/partner" class="fw-7 c0">
          <i class="fi fi-sr-home-heart mr-2"></i> Become a Partner
        </h5>

        <h5 routerLink="/help/faq" class="fw-6">
          <i class="fi fi-sr-life-ring mr-2"></i> Help & Support
        </h5>

        <hr class="b9 mb-2" />
        <h5 class="text-center">
          <span class="h6">
            <a *ngFor="let s of socials" [href]="s.href" target="_blank">
              <img class="mx-3" width="24px" [src]="s.image" alt="" />
            </a>
          </span>
        </h5>

        <h5 class="text-center">
          © 2024 FTNSS, Inc. <br />
          <span class="version px-2">{{util.storey.version}}</span>
        </h5>
      </mat-menu>
    </div>
  </div>
</div>
<!-- * FOOTER FINGZ  -->
