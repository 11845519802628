import { Injectable, NgZone } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";
import { environment as env } from "@env/environment";
import { Store } from "@ngxs/store";
import { MatDialog } from "@angular/material/dialog";
import { FormBuilder } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { SeoService } from "./seo.service";
import { AngularFireDatabase } from "@angular/fire/compat/database";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { take } from "rxjs/operators";
import { BreakpointObserver } from "@angular/cdk/layout";
import { Validor } from "./validators";
import { AngularFireAnalytics } from "@angular/fire/compat/analytics";
import { ToastrService } from "ngx-toastr";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import * as actions from "@store/ftnss.actions";
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider,
  OAuthProvider,
} from "firebase/auth";

//* rxjs
import { firstValueFrom, lastValueFrom, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

//* angular-cal
import {
  addDays,
  addWeeks,
  endOfDay,
  startOfDay,
  eachDayOfInterval,
  eachHourOfInterval,
  subDays,
  format,
} from "date-fns";

//* lodash
import setWith from "lodash/setWith";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";

//* models
import { empty_filter, empty_search, ftnssMenu } from "@models/menu";
import { AuthC } from "@components/widgets/auth/auth";
import { hour_map } from "./hour_map";
import { amenities, gymCategories, sportsCategories } from "@models/cats";
import { dummyGyms } from "@models/gyms.dum";
import { dummySports } from "@models/sports.dum";
import { faqs } from "./faqs";

@Injectable({
  providedIn: "root",
})
export class Utilities {
  //* variabless
  uid: any = {};
  hour_maps = hour_map;
  is_connected = false;
  is_admin = JSON.parse(localStorage.getItem("is_admin"));
  categories = ftnssMenu.game_categories;
  shop_categories = ftnssMenu.shop_categories;
  empty_filter = { ...empty_filter };
  env = env;
  axs = actions;
  storey;
  searchy = { ...empty_search };
  dbGyms;
  slot_arr;
  slot_map;
  slots = [];
  hours = Array.from({ length: 24 }, (_, i) => i + 1);
  isM;
  authRef;
  gymCategories = gymCategories;
  sportsCategories = sportsCategories;
  gyms = dummyGyms;
  sports = dummySports;
  amenities = amenities;
  faqs = faqs;
  map_view = false;

  apiUrl = "https://us-central1-ftnss-2.cloudfunctions.net/api";
  apiUrl2 = "http://localhost:5001/ftnss-2/us-central1/api";

  //* rxjs
  rx_Subject = Subject;
  rx_takeUntil = takeUntil;

  //* lodash
  lo_setWith = setWith;
  lo_isEmpty = isEmpty;
  lo_cloneDeep = cloneDeep;

  //* angular-cal
  addDays = addDays;
  subDays = subDays;
  addWeeks = addWeeks;
  startOfDay = startOfDay;
  endOfDay = endOfDay;
  eachDayOfInterval = eachDayOfInterval;
  dformat = format;

  //* constructor
  constructor(
    public afa: AngularFireAuth,
    public afd: AngularFireDatabase,
    public brk: BreakpointObserver,
    public dialog: MatDialog,
    public fb: FormBuilder,
    public http: HttpClient,
    public lyt: AngularFireAnalytics,
    public meta: Meta,
    public router: Router,
    public seo: SeoService,
    public sto: Store,
    public storage: AngularFireStorage,
    public title: Title,
    public toast: ToastrService,
    public vally: Validor,
    public ngZone: NgZone
  ) {}

  repeat(times: number) {
    return Array(times)
      .fill(0)
      .map((x, i) => i);
  }

  validLinks(): Boolean {
    return !["/partner/home", "/partner/passes"].includes(
      this.storey.router.url
    );
  }

  // **************** S E T T E R S ***************

  setStorey(s) {
    this.storey = s;
  }

  async switchView() {
    await this.sto.dispatch(new actions.SetMapMode(!this.storey.map_mode));
  }

  setIsConnected(bool) {
    this.is_connected = bool;
  }

  setMobile(bool) {
    this.isM = bool;
  }

  conscroller(scrollx, el: HTMLElement) {
    scrollx[2] = el.clientWidth;
    scrollx[0] = el.scrollLeft;
  }

  conScrollLeft(scrollx, el: HTMLElement) {
    el.scrollTo({
      left: Math.min((scrollx[0] -= 100), scrollx[2]),
      behavior: "smooth",
    });
  }

  conScrollRight(scrollx, el: HTMLElement) {
    el.scrollTo({
      left: Math.min((scrollx[0] += 100), scrollx[2]),
      behavior: "smooth",
    });
  }

  // **************** S E T T E R S ***************
  // ######################################################

  //  ************ A U T H ***********

  openAuth(mode) {
    if (this.is_connected) {
      this.warningToast("Error", "You are already Logged In");
    } else {
      this.authRef = this.dlogtr(AuthC);
      this.authRef.componentInstance.modex = mode;
    }
  }

  authLogin(provider: any) {
    this.afa
      .signInWithPopup(provider)
      .then((result) => {
        this.successToast("Success", "");
        this.closeAllDialogs();
      })
      .catch((error) => {
        this.errorToast("Error", error.message);
      });
  }

  googleAuth() {
    this.authLogin(new GoogleAuthProvider());
  }

  fbAuth() {
    this.authLogin(new FacebookAuthProvider());
  }
  twAuth() {
    this.authLogin(new TwitterAuthProvider());
  }
  appleAuth() {
    this.authLogin(new OAuthProvider("apple.com"));
  }

  async setLoggedInUser(user) {
    this.is_connected = user !== null;
    await this.sto.dispatch(
      new actions.AddUser({
        ...user,
      })
    );
  }

  async syncPartner(partner) {
    let claim = await this.getClaims();

    if (claim?.role !== "partner") {
      this.errorToast("Error", "This is not a partner account");
      return;
    } else {
      let pdb: any = await this.readLink(`v2/partners/${partner?.uid}`);
      await this.sto.dispatch(
        new this.axs.SetPartner({
          ...pdb,
        })
      );
      await this.sto.dispatch(new this.axs.UpdateRole(claim?.role ?? "user"));
      this.router.navigate(["partner/home"]);
      this.successToast("Success", "Welcome Back");
    }
  }

  async syncUser(user, social?) {
    let claim = await this.getClaims();

    if (claim?.role === "partner") {
      this.errorToast(
        "Error",
        "This is a partner account! Sign in / Sign up with a user account"
      );
      this.signOut();
      return;
    } else {
      let udb: any = await this.readLink(`v2/users/${user?.uid}`);
      if (udb === null) {
        if (social) {
          await this.afd.object(`v2/users/${user.uid}`).update({
            email: user.email,
            uid: user.uid,
            name: user.displayName ?? "Display Name",
          });
        } else {
          return;
        }
      }
      await this.sto.dispatch(new this.axs.UpdateRole("user"));
      await this.sto.dispatch(
        new this.axs.UpdateUserDB({
          ...udb,
        })
      );

      this.router.navigate(["/"]);
    }
  }

  async getClaims() {
    await (await this.afa.currentUser)?.getIdToken(true);
    let zall = await firstValueFrom(this.afa.idTokenResult);
    return zall?.claims;
  }

  async signOut() {
    await this.afa.signOut();
    await this.sto.dispatch(new this.axs.ClearState());
    this.router.navigate(["/"]);
    this.warningToast("Signed Out", "");
    window.location.reload();
  }

  //  ************ A U T H ***********
  // ######################################################

  //  ************ C R U D ***********

  subLink(path) {
    return this.afd.object(path).valueChanges();
  }

  hid(h) {
    return h
      .toLowerCase()
      .replace(/\s+/g, "_")
      .replace("'", "")
      .replace("-", "_");
  }

  checkFav(h) {
    return this.storey.user_db?.favorites?.[h];
  }

  addFav(gym) {
    if (this.is_connected) {
      let val = this.storey.user_db.favorites?.[gym.id];

      this.afd.object(`v2/users/${this.storey.user.uid}/favorites`).update({
        [gym.id]: val ? null : true,
      });
      if (val) {
        this.warningToast(
          "Removed Favorite",
          `Removed ${gym.name} from Favorites`
        );
      } else {
        this.successToast("New Favorite", `Added ${gym.name} to Favorites`);
      }
    } else {
      this.warningToast("Sign in", "Sign in to add Gym to favorites");
      this.router.navigate(["/signin"]);
    }
  }

  async readLink(path) {
    let val = await lastValueFrom(
      this.afd.object(path).valueChanges().pipe(take(1))
    );
    return val;
  }

  map2Arr(obj, type) {
    if (obj) {
      return type === 1
        ? Object.keys(obj)
        : type === 2
        ? Object.values(obj)
        : type === 3
        ? Object.entries(obj)
        : obj;
    } else {
      return [];
    }
  }

  setGyms(gyms) {
    this.sto.dispatch(new this.axs.UpdateGyms(gyms));
  }

  setPayments(pmts) {
    this.sto.dispatch(new this.axs.UpdatePayments(pmts));
  }

  //  ************ C R U D ***********
  // ######################################################

  //  ************ S E A R C H  &  F I L T E R ***********

  searcher() {
    this.searchy = this.storey.search;
  }
  tempSearcher(search_type, search_string) {
    this.sto.dispatch(new this.axs.Searcher(search_type, search_string));
    if (search_string === "") {
      this.searcher();
    }
  }

  getAvailableDates() {
    let now = startOfDay(new Date());
    let last = endOfDay(addDays(new Date(), 13));
    let av_dates = eachDayOfInterval({
      start: now,
      end: last,
    }).map((x) => new Date(x).getTime());
    return av_dates;
  }

  getHoursInDay() {
    return eachHourOfInterval({
      start: new Date(2022, 9, 6, 0),
      end: new Date(2022, 9, 6, 23),
    });
  }

  isFilterGroupActive(filter_group) {
    return Object.values(filter_group).filter((x) => x === true).length > 0;
  }

  filterModify(filter_type, filter_group, template, id) {
    let new_filter = JSON.parse(JSON.stringify(filter_group));
    new_filter[template][id] = !new_filter[template][id];

    this.sto.dispatch(new this.axs.SetFilter(new_filter, filter_type));
  }

  clearFilter(filter_type, filter_group, filter_node) {
    let new_filter = { ...filter_group };

    new_filter[filter_node] = {};
    this.sto.dispatch(new this.axs.SetFilter(new_filter, filter_type));
  }

  filterCategories(filters, categories) {
    for (let cat of categories) {
      if (filters[cat] === true) return true;
    }
    return false;
  }

  filterDate(filters, dates) {
    for (let da of Object.entries(filters)) {
      if (da[1] === true && dates[da[0]] !== undefined) {
        return true;
      }
    }
    return false;
  }

  //  ************ S E A R C H  &  F I L T E R ***********
  // ######################################################

  // ********** T O A S T E R S  **********

  successToast(msg1, msg2, tout?) {
    this.toast.success(msg2, msg1, {
      positionClass: "toast-bottom-right",
      timeOut: tout ?? 2000,
    });
  }

  warningToast(msg1, msg2, tout?) {
    this.toast.warning(msg2, msg1, {
      positionClass: "toast-bottom-right",
      timeOut: tout ?? 2000,
    });
  }

  errorToast(msg1, msg2, tout?) {
    this.toast.error(msg2, msg1, {
      positionClass: "toast-bottom-right",
      timeOut: tout ?? 2000,
    });
  }

  // ********** T O A S T E R S **********
  // ######################################################

  // ********** D I A L O G S  **********

  dlog(comp, a?, b?, c?, d?) {
    return this.dialog.open(comp, {
      width: "95vw",
      maxWidth: "600px",
      panelClass: a || "",
      data: { aa: b, bb: c, cc: d },
    });
  }

  dlogtr(comp, data?) {
    return this.dialog.open(comp, {
      panelClass: "transpad",
      backdropClass: "transbdrop",
      closeOnNavigation: true,
      disableClose: false,
      data: { ...data },
    });
  }

  closeAllDialogs() {
    this.dialog.closeAll();
  }

  // ********** D I A L O G S  **********
  // ######################################################

  //  ************ H E L P E R    F U N C T I O N S ***********

  singleLink(root, node) {
    this.router.navigate([`/${root}/${node}`]);
  }

  getLargeImage(image) {
    return image.replace("m.jpg", "l.jpg");
  }

  imSizer(im) {
    let url = im;
    if (url.endsWith(".jpg")) {
      url = url.replace("h.jpg", "l.jpg");
    } else if (url.endsWith(".png")) {
      url = url.replace("h.png", "l.png");
    }
    return url;
  }

  truthMap(ma) {
    return Object.values(ma ?? {}).filter((z) => z === true).length > 0;
  }

  fingy(pr) {
    return Math.round(pr + (pr * 0.1) / 2);
  }

  analog(ev) {
    console.log(ev);
    this.lyt.logEvent(ev);
  }

  getId(route) {
    return route.split("/")[2];
  }

  selly() {
    return this.sto.select((s) => s.ftnssstate);
  }

  snappy() {
    return this.sto.selectSnapshot((s) => s.ftnssstate);
  }

  show(i) {
    console.log(i);
  }
  copyToClip() {
    this.toast.success("Address Copied", "", {
      positionClass: "toast-bottom-left",
    });
  }

  mobby() {
    return this.brk.observe(["(max-width: 992px)"]);
  }

  isMobile() {
    return this.brk.isMatched("(max-width: 1024px)");
  }

  dater(date) {
    let dat = new Date(date);
    return dat.toDateString();
  }

  resetForm(form) {
    form.reset();
  }

  scrollTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  async delay(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async sync(obj, url) {
    for (let [k, v] of Object.entries(obj)) {
      k = k.replace(".", "_");
      v["id"] = k;
      await this.afd.object(url).update({ [k]: v });
    }
  }

  idlize(a, b, c, d): string {
    let val = a + "::" + b + "::" + c + "::" + d;
    val = val
      .toLowerCase()
      .replace(/\s+/g, "_")
      .replace("'", "")
      .replace("-", "_");
    return val;
  }

  //  ************ H E L P E R    F U N C T I O N S ***********
  // ######################################################
}
