<div
  *ngLet="util.getId(util.storey.router.url.split('?')[0]) as sid"
  class="ftnss-box pt-5"
>
  <ng-container *ngLet="util.sports[sid] as sport">
    <div class="ffrow">
      <div class="ffcol text-center">
        <div class="p-0">
          <img width="100%" class="brad mimg" [src]="sport.images[0]" alt="" />
          <!-- <swiper
                [slidesPerView]="1"
                [pagination]="true"
                [navigation]="true"
                [centeredSlides]="true"
                effect="fade"
                [thumbs]="{ swiper: thum }"
                class="my-swiper"
              >
                <ng-template swiperSlide>
                  <img
                    width="100%"
                    class="brad mimg"
                    [src]="sport.images[0]"
                    alt=""
                  />
                </ng-template>
                <ng-template swiperSlide>
                  <img
                    width="100%"
                    class="brad mimg"
                    [src]="sport.images[0]"
                    alt=""
                  />
                </ng-template>
              </swiper> -->
        </div>
      </div>
      <div class="ffcol">
        <div class="p-1 csw">
          <h3 class="py-1 my-1 fw-6 h2">{{sport.name}}</h3>
          <h6 class="py-1 my-1 fw-4">{{sport.location}}</h6>

          <h4 class="fw-7 ccp my-1">
            <img
              width="20px"
              class="mr-2"
              [src]="catMap[sport.categories].image"
              alt=""
            />
            {{sport.categories}}
          </h4>
          <p class="fw-6 my-2">
            <i
              *ngFor="let i of util.repeat(sport.rating)"
              class="fi fi-sr-star ccy mr-1"
            ></i>
            &nbsp; &nbsp; {{sport.reviews}}
            <span class="ccr">Reviews</span>
          </p>

          <p class="my-4">
            <button class="brad2 px-2 py-3 shadow bg-cw fw-6">
              <span class="fw-8 h5 bg-ccg cw py-2 px-3 brad2"
                >$ {{sport.price }}</span
              >
              <span class="px-2">Book</span>
            </button>
            <button class="brad2 px-4 mx-4 py-3 shadow bg-ccg cw fw-7">
              <i class="fi fi-sr-users-medical pr-2"></i>
              Join Team
            </button>
          </p>

          <h5 class="py-0 my-3">
            <button
              class="box2 px-3 py-2 brad mx-2"
              [ngClass]="1 === 2 ? 'bg-ccr bg1' : 'bg-bg1 ccr bo3-ccr'"
              (click)="util.addFav(gym)"
            >
              <i class="fi fi-rr-heart"></i>
            </button>
            <button
              class="box2 px-3 py-2 brads mx-2"
              [ngClass]="so.bg"
              *ngFor="let so of social"
            >
              <i [ngClass]="so.icon"></i>
            </button>
          </h5>
        </div>
      </div>
      <div class="ffcol p-1">
        <div class="pt-3 px-3">
          <hr class="my-4" />
          <h3 class="csw fw-7 mb-4">Fixtures</h3>
          <table>
            <tbody>
              <tr
                class="bg-cw brad shadow-sm bob-cwe"
                *ngFor="let fixture of fixtures"
              >
                <td class="p-3 fw-7">{{ fixture.team1 }}</td>
                <td class="p-3 ccp">vs</td>
                <td class="p-3 fw-7">{{ fixture.team2 }}</td>
                <td class="p-3 ccg fw-5">
                  {{ fixture.date | date: 'MMM dd' }}
                </td>
                <td class="p-3 ccg fw-5">{{ fixture.time }}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="ffcol p-1">
        <div class="pt-3 px-3">
          <hr class="my-4" />
          <h3 class="csw fw-7 mb-4">Leaderboard</h3>
          <table style="width: 100%">
            <tbody>
              <tr
                class="bg-cw brad shadow-sm bob-cwe"
                *ngFor="let lead of leaderboard"
              >
                <td class="p-3 ccp fw-7">{{ lead.team }}</td>
                <td class="p-3 ccr fw-5">{{ lead.points }}</td>
                <td class="p-3 fw-5">{{ lead.wins }}</td>
                <td class="p-3 fw-5">{{ lead.draws }}</td>
                <td class="p-3 fw-5">{{ lead.losses }}</td>
              </tr>
            </tbody>
          </table>
          <hr class="my-3" />
          <h3 class="my-4 fw-7 csw">Cancellation Policy</h3>
          <p class="p-2">
            Tickets can be cancelled for a full refund up to 11:59 PM the night
            before your visit. Same day reservations CAN NOT be refunded.
          </p>
        </div>
      </div>
    </div>
    <ng-template #bklog>
      <div
        class="position-relative p-1 brad bg-bg1 shadow wim8 text-center csw"
      >
        <span matDialogClose class="h3 poi exiter"
          ><i class="fi fi-sr-times-hexagon"></i
        ></span>
        <div class="pt-5 px-2">
          <div>
            <div class="conbox">
              <div
                class="conlist"
                #conscroll
                (scroll)="util.conscroller(scrollx, conscroll)"
              >
                <ng-container
                  *ngFor="let d of checkAv(sport.schedule); let i = index"
                >
                  <div
                    class="dpk mx-3 poi text-center"
                    [ngClass]="{'bg-c0 cw shadow-sm brad2' : curr_date[0] === i}"
                    (click)="curr_date = [i, d]"
                  >
                    <h5 class="my-2 fw-4">{{d | dayF : 'EEE'}}</h5>
                    <h5 class="m-0 fw-8">{{d | dayF : 'dd'}}</h5>
                  </div>
                </ng-container>
              </div>
              <div
                class="conleft shadow-sm bg-bg2 csw poi"
                (click)="util.conScrollLeft(scrollx, conscroll)"
                *ngIf="scrollx[0] > 20 && !util.isM"
              >
                <i class="fi fi-sr-angle-circle-left"></i>
              </div>
              <div
                class="conright shadow-sm bg-bg2 csw poi text-center"
                (click)="util.conScrollRight(scrollx, conscroll)"
                *ngIf="scrollx[0] < (scrollx[2] - 20)"
              >
                <i class="fi fi-sr-angle-circle-right"></i>
              </div>
            </div>
            <hr />
            <div class="p-5">
              <div class="hgrid">
                <ng-container *ngFor="let h of util.getHoursInDay()">
                  <div
                    class="shadow-sm brad2 py-3 bg-bg1 bo-bg4 poi bo-35"
                    [ngClass]="{'bo3-c0 c0' : checkOpen(h)}"
                    *ngIf="checkHr(gym, bk_map,h)"
                    (click)="addHour(h, sport.game_cost, sport.price)"
                  >
                    {{h | dayF: 'hh '}}
                    <span class="fw-8">{{h | dayF: 'aaa'}}</span>
                  </div>
                </ng-container>
              </div>
            </div>
            <hr />
            <div class="text-center py-4">
              <h3 class="fw-8">
                $ {{total_cost}}
                <button
                  class="shadow bg1 bg-c0 poi brad2 mx-3 px-4 py-2 fw-6"
                  [disabled]="total_cost <= 0"
                  (click)="booker(gym)"
                >
                  Book
                </button>
                <button
                  *ngIf="total_cost > 0"
                  class="shadow bg1 bg-ccr poi brad2 mx-0 px-4 py-2 fw-6"
                  (click)="clearBooks()"
                >
                  <i class="fi fi-sr-times-hexagon"></i>
                </button>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
</div>
