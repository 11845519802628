import { Component, OnDestroy, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "p-passes",
  templateUrl: "./p-passes.html",
  styleUrls: ["../partners.scss"],
})
export class PartnerPasses implements OnInit, OnDestroy {
  uid = this.util.storey.user.uid;

  gyms = this.util.storey.gyms;
  activationCode = "";
  registered_passes;
  is_loading = false;

  constructor(public util: Utilities) {}

  ngOnInit(): void {
    const len = Object.values(this.util.storey.payments).filter((pmt: any) => {
      return pmt.status === "paid" && pmt.data.partner_uid === this.uid;
    }).length;
    this.registered_passes = len;
  }

  myPasses(payments) {
    return Object.values(payments).filter((pmt: any) => {
      return pmt.activation && pmt.data.partner_uid === this.uid;
    });
  }

  scanQR() {
    this.util.warningToast("Coming Soon", "");
  }

  checkActive(pass) {
    const now = new Date().getTime();
    let is_expired = true;
    let expiry_date;

    if (pass.activation) {
      const activation_time = pass.activation;

      switch (pass.type) {
        case "Day":
          expiry_date = new Date(activation_time + 24 * 60 * 60 * 1000); // 1 day ahead
          is_expired = now > expiry_date.getTime();
          break;
        case "Week":
          expiry_date = new Date(activation_time + 7 * 24 * 60 * 60 * 1000); // 1 week ahead
          is_expired = now > expiry_date.getTime();
          break;
        case "Month":
          expiry_date = new Date(activation_time + 30 * 24 * 60 * 60 * 1000); // 1 month ahead
          is_expired = now > expiry_date.getTime();
          break;
        default:
          expiry_date = now;
          is_expired = true;
      }
    }

    return [is_expired, this.util.dformat(expiry_date ?? now, "MMM-dd-yy")];
  }

  async activatePass() {
    this.is_loading = true;
    if (this.activationCode.length === 8) {
      try {
        let session: any = await firstValueFrom(
          this.util.http.post(`${this.util.apiUrl}/BZQNakPRGl`, {
            code: this.activationCode.toUpperCase(),
            partner_id: this.util.storey.partner_db.id,
          })
        );
        console.log(session);

        if (session.success) {
          console.log(session.payment);
          this.util.successToast(
            "Activated",
            "This pass has been successfully activated"
          );
          this.activationCode = "";
        } else {
          this.util.errorToast("Error", "This code is invalid");
        }
      } catch (error) {
        this.util.errorToast("Error", "This code is invalid");
      }
    } else {
      this.util.errorToast("Error", "This code is invalid");
    }
    this.is_loading = false;
  }

  checkIn(pass) {}

  ngOnDestroy() {}
}
