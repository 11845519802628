<nav
  class="navbar navbar-expand-md bg-bg1 shadow-sm fixed-top"
  *ngLet="util.storey.router.url as current_url"
>
  <div class="con-fin">
    <a class="navbar-brand align-items-baseline" routerLink="/">
      <logo></logo>
    </a>

    <!-- * SEARCH BOX  -->

    <div
      *ngIf="util.storey.router.url === '/' || util.storey.router.url === '/gyms'"
    >
      <div
        class="hai bg-bg1 bo-bg3 shadow-sm csw brad2 {{ !util.isM ? 'h6' : 'fs09' }} fw-3 px-1"
      >
        <div class="d-flex" [matMenuTriggerFor]="searchbar">
          <span class="fw-6 px-4 b9">Search Location</span>
          <button
            class="searchx shadow-sm bg-c0"
            [ngClass]="{ 'bg-ccg': util.storey.router.url === '/sports' }"
          >
            <i class="fi fi-sr-search-location mx-2 cw"></i>
          </button>
        </div>

        <button
          class="mapx shadow-sm bg-c0 cw px-3 py-2 brad h5"
          [ngClass]="{ 'bg-ccg': util.storey.router.url === '/sports' }"
          (click)="util.switchView()"
        >
          <i
            [ngClass]="util.storey.map_mode ? 'fi fi-sr-grid' : 'fi fi-sr-map-marker'"
          ></i>
        </button>
      </div>
    </div>

    <!-- * SEARCH MENU  -->
    <mat-menu
      #searchbar="matMenu"
      xPosition="after"
      class="bg-bg1 mt-4 wi4 shadow csw brad p-2 menux"
    >
      <div (click)="$event.stopPropagation()">
        <h6 class="poi fw-7">
          <button class="brad py-2 px-3 b4 bg-cwe mx-2">
            <i class="fi fi-sr-location-arrow"></i>
          </button>
          Current Location
        </h6>
        <hr />
        <h6 class="poi fw-6">
          <button class="brad py-2 px-3 b4 bg-cwe mx-2">
            <i class="fi fi-sr-house-building"></i>
          </button>
          Ontario, Canada
        </h6>
        <h6 class="poi fw-6">
          <button class="brad py-2 px-3 b4 bg-cwe mx-2">
            <i class="fi fi-sr-house-building"></i>
          </button>
          Ohio, USA
        </h6>
        <h6 class="poi fw-6">
          <button class="brad py-2 px-3 b4 bg-cwe mx-2">
            <i class="fi fi-sr-house-building"></i>
          </button>
          London, UK
        </h6>
      </div>
    </mat-menu>

    <div class="tmenu">
      <button
        *ngIf=" !util.isM && util.validLinks()"
        class="mx-2 shadow brad fw-5 px-3"
        [ngClass]="util.storey.router.url === '/' || util.storey.router.url === '/gyms' ? 'bg-c0 cw' : 'bg-cw c0'"
        routerLink="/"
      >
        <i class="fi fi-sr-gym mr-2"></i> GYMS
      </button>
      <button
        *ngIf="util.is_admin && !util.isM"
        routerLink="admin"
        class="bg-bg1 mx-2 brad2 px-2 shadow-sm h5"
        [ngClass]="current_url?.includes('admin') ? ' bo3-ccr ccr' : 'bo-b9 b9'"
      >
        <i class="fi fi-sr-shield mx-2"></i>
      </button>
      <button
        routerLink="user/passes"
        *ngIf="util.is_connected && !util.isM && util.storey.role === 'user'"
        class="mx-2 shadow brad fw-5 px-3"
        [ngClass]="util.storey.router.url === '/user/passes' ? 'bg-c0 cw' : 'bg-cw c0'"
      >
        <i class="fi fi-sr-ticket mx-2"></i> PASSES
      </button>
      <button
        [matMenuTriggerFor]="umenu"
        class="bg-bg1 mx-2 bo-bg3 brad2 csw shadow-sm"
        *ngIf="!util.isM"
      >
        <i class="fi fi-sr-angle-small-down h5 mx-2"></i>
        <i class="fi fi-sr-circle-user h2"></i>
      </button>
      <mat-menu
        #umenu="matMenu"
        xPosition="before"
        class="bg-bg1 mt-4 wi3 shadow csw brad p-0 menux"
      >
        <h5 *ngIf="!util.is_connected" routerLink="/signin" class="fw-4">
          Sign in
        </h5>
        <h5 *ngIf="!util.is_connected" routerLink="/signup" class="fw-6">
          Sign up
        </h5>

        <h5
          *ngIf="util.storey.role === 'user'"
          routerLink="/user/home"
          class="fw-6"
        >
          My Profile
        </h5>

        <h5
          *ngIf="util.storey.role === 'partner'"
          routerLink="/partner/home"
          class="fw-6"
        >
          Partner home
        </h5>

        <h5 *ngIf="util.is_connected" (click)="util.signOut()" class="fw-6 ccr">
          Sign Out
        </h5>
        <hr class="b9 m-0" />

        <h5 *ngIf="!util.is_connected" routerLink="/partner" class="fw-7 c0">
          Become a Partner
        </h5>

        <h5 routerLink="/help/faq" class="fw-4">Help & Support</h5>
      </mat-menu>
    </div>
  </div>
</nav>
