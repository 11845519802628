<div class="sticky-top">
  <search-bar [search_type]="search_params"></search-bar>
</div>

<div
  class="ftnss-box pt-5"
  [ngClass]="{'marg': util.is_connected, 'con-map': util.map_view}"
>
  <div *ngIf="!util.map_view" class="frow px-3 pt-5 mt-4">
    <div
      *ngFor="let sport of filtered_sports | keyvalue | orderBy:'value.location'  | searchFilter: util.storey.search.sport"
      class="fcol p-2 csw poi"
    >
      <div class="slotslide brad" *ngLet="sport.value as asport">
        <span class="slotfav">
          <i
            class="fi fi-sr-heart fs15"
            [ngClass]="util.checkFav(asport.name) ? 'c0' : 'cbtr'"
          ></i>
        </span>
        <span (click)="util.addFav(slot)" class="slotfav">
          <i class="fi fi-sr-heart cw fs15"></i>
        </span>

        <span class="slotrating fw-6">
          <i
            *ngFor="let i of util.repeat(asport.rating)"
            class="fi fi-sr-star ccy mr-1"
          ></i>
          <br />
          {{asport .reviews}}
          <span class="ccr">Reviews</span>
        </span>
        <img
          (click)="sportRoute(asport)"
          [src]="asport.images[2]"
          class="brad ftimg"
          alt=""
        />
        <!-- <swiper
          [slidesPerView]="1"
          [pagination]="true"
          [navigation]="true"
          [centeredSlides]="true"
          effect="fade"
          [thumbs]="{ swiper: thum }"
          class="my-swiper"
        >
          <ng-template swiperSlide>
            <img
              width="100%"
              class="brad mimg"
              [src]="util.imSizer('https://i.imgur.com/bqin6gWh.jpg')"
              alt=""
            />
          </ng-template>

          <ng-template swiperSlide>
            <img
              width="100%"
              class="brad mimg"
              [src]="util.imSizer('https://i.imgur.com/bqin6gWh.jpg')"
              alt=""
            />
          </ng-template>
        </swiper> -->
        <div class="px-3 py-2 b9">
          <p class="text-truncate fw-5 my-1 h5 b2">{{asport.name}}</p>
          <p class="fw-6 my-1 fs09">{{asport.location}}</p>
          <p class="fw-7 ccp my-1">
            <img
              width="20px"
              class="mr-2"
              [src]="catMap[asport.categories]"
              alt=""
            />
            {{asport.categories}}
          </p>
          <p class="mt-3 text-right">
            <button
              class="brad2 px-2 py-3 shadow bg-cw fw-6 b3"
              (click)="sportRoute(asport)"
            >
              <span class="fw-8 h5 bg-ccg cw py-2 px-3 brad2"
                >$ {{asport.price }}</span
              >
              <span class="px-2">Book</span>
            </button>
          </p>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="util.map_view">
    <maps
      [data]="filtered_sports"
      [map_init]="map_init"
      map_type="sports"
    ></maps>
  </div>
</div>
