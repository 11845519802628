import { Component, OnInit } from "@angular/core";
import { u_h_menu } from "@components/partner/p-home/p-data";
import { Utilities } from "@services/utilities";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "u-home",
  templateUrl: "./u-home.html",
  styleUrls: ["./u-home.scss"],
})
export class UserHome implements OnInit {
  wallet = [];
  is_banner = true;
  currentRate = 3.5;

  p_home_options: any = u_h_menu;
  edit_name;

  constructor(public util: Utilities) {}

  ngOnInit() {}

  openMenu(name, link, profile, temp) {
    if (link) {
      this.util.router.navigate([link]);
    } else {
      this.edit_name = profile.displayName;
      this.util.dlogtr(temp);
    }
  }

  async updateName() {
    this.util.afa.currentUser.then(async (user) => {
      if (user) {
        console.log(user.uid);
        await user
          .updateProfile({
            displayName: this.edit_name,
          })

          .then(async () => {
            await this.util.afd.object(`v2/users/${user.uid}`).update({
              name: this.edit_name,
            });
            this.util.setLoggedInUser(JSON.parse(JSON.stringify(user)));
            this.util.successToast(
              "Success",
              "Display name updated successfully"
            );
            this.util.closeAllDialogs();
          })
          .catch((error) => {
            console.error("Error updating display name:", error);
          });
      }
    });
  }
}
