import { Component, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "faq",
  templateUrl: "./faq.html",
  styles: [
    `
      .fans {
        line-height: 2em;
      }

      .bol-b4 {
        border-width: 7px;
      }
    `,
  ],
})
export class Faq implements OnInit {
  navvy = navigator.userAgent;
  constructor(public util: Utilities) {}

  async ngOnInit() {}
}
