<div
  class="position-relative p-1 wim6 text-center csw"
  [ngClass]="{'pt-3':is_link, 'shadow brad bg-bg1 ': !is_link}"
>
  <span *ngIf="!is_link" matDialogClose class="h3 poi exiter"
    ><i class="fas fa-times"></i
  ></span>
  <div>
    <div class="authbox mx-auto mt-3">
      <h3 class="py-3 fw-7 csw">
        {{auth_type | titlecase}} {{ auth_type === 'Reset' ? 'Password' : ''}}
      </h3>

      <form class="mx-1 px-2" [formGroup]="auth_form" (ngSubmit)="authy()">
        <div class="">
          <!-- * FULL NAME INPUT  -->
          <div class="form-group" *ngIf="auth_type === 'Sign up'">
            <input
              class="shadow bol-csw brads py-3 px-4 h5 csw"
              type="text"
              formControlName="fullname"
              placeholder="Full Name"
              autocomplete="new-email"
            />
            <small
              class="ccr"
              *ngIf="af.fullname.dirty && af.fullname.errors && auth_type === 'Sign up'"
              >Full Name is required</small
            >
          </div>
          <!-- * EMAIL INPUT  -->
          <div class="form-group">
            <input
              class="shadow bol-csw brads py-3 px-4 h5 csw"
              type="text"
              formControlName="email"
              placeholder="Email"
              autocomplete="new-email"
            />
            <small
              class="ccr"
              *ngIf="af.email.dirty && af.email.errors && auth_type === 'Sign up'"
              >Please provide a valid email address</small
            >
          </div>
          <!-- * PASSWORD INPUT  -->
          <div *ngIf="!(auth_type === 'Reset')" class="form-group">
            <input
              type="password"
              class="shadow brads py-3 px-4 h5 csw bol-csw"
              formControlName="password"
              placeholder="Password"
              autocomplete="new-password"
            />
            <small
              class="ccr"
              *ngIf="af.password.dirty && af.password.errors && auth_type === 'Sign up'"
              >Password must be at least 6 characters</small
            >
          </div>
          <!-- * CONFIRM PASSWORD INPUT  -->
          <div *ngIf="auth_type === 'Sign up'" class="form-group">
            <input
              type="password"
              class="shadow brads py-3 px-4 h5 csw bol-csw"
              formControlName="passwordConfirm"
              placeholder="Confirm Password"
              autocomplete="new-password"
            />
            <small
              class="ccr"
              *ngIf="af.passwordConfirm.dirty && auth_form.errors !== null"
              >Passwords must match</small
            >
          </div>
          <!-- * FORGOT PASSWORD LINK  -->
          <p class="mt-4">
            <span
              class="ccr poi fw-7"
              *ngIf="auth_type === 'Sign in'"
              (click)="auth_type = 'Reset'; setForm()"
            >
              Forgot Password?
            </span>
          </p>
        </div>
        <!-- * TERMS  -->
        <p *ngIf="auth_type === 'Sign up'" class="my-4 text-center fw-3 csw">
          By clicking
          <span class="fw-6 c0">Sign up</span>, you agree to the Ftnss
          <span
            class="fw-6 c0 poi"
            (click)="curr_out = 'terms'; util.dlogtr(dlog)"
            >Terms</span
          >,
          <span
            class="fw-6 c0 poi"
            (click)="curr_out = 'privacy'; util.dlogtr(dlog)"
            >Privacy Policy</span
          >, and
          <span
            class="fw-6 c0 poi"
            (click)="curr_out = 'cookie'; util.dlogtr(dlog)"
            >Cookie Policy</span
          >.
        </p>
        <!-- * SUBMIT FORM  -->
        <p class="fta text-center">
          <button
            class="brad shadow bg-c0 bg1 px-5 py-3 h4 my-2"
            type="submit"
            [disabled]="auth_form.invalid "
          >
            {{auth_type }}
          </button>
        </p>
        <p (click)="switchAuth()" class="fta text-center csw">
          {{auth_type === "Sign in" ? "Don't have an account ?" : auth_type ===
          "Sign up" ? "Already have an account ?" : ""}}
          <button class="brad bg-bg2 fw-7 csw h6 p-2 my-0" type="button">
            {{auth_type === "Sign in" ? 'Sign up' : 'Sign in' }}
          </button>
        </p>
      </form>

      <hr class="b4" />

      <p class="fta text-center mt-4 mb-1">
        <button
          (click)="googleAuth()"
          class="brad2 bg-cw bo-b9 csw d3 px-4 py-2 h5"
          type="button"
        >
          <img width="40px" src="/assets/social/google.webp" alt="" />
          {{auth_type }} with Google
        </button>
      </p>

      <p class="fta text-center mt-1">
        <button
          (click)="appleAuth()"
          class="brad2 bg-b0 cw bo-b2 d3 px-4 py-2 h5"
          type="button"
        >
          <img class="apple-auth" src="/assets/social/apple.png" alt="" />
          {{auth_type }} with Apple
        </button>
      </p>
    </div>
  </div>
</div>

<ng-template #dlog>
  <div class="position-relative p-1 brad bg-bg1 shadow wim8 text-center csw">
    <span matDialogClose class="h3 poi exiter"
      ><i class="fas fa-times"></i
    ></span>
    <div class="pt-5 px-2 dlogt">
      <terms *ngIf="curr_out === 'terms'"></terms>
      <privacy *ngIf="curr_out === 'privacy'"></privacy>
      <cookies *ngIf="curr_out === 'cookie'"></cookies>
    </div>
  </div>
</ng-template>
