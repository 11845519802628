import { Component, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "u-passes",
  templateUrl: "./u-passes.html",
  styleUrls: ["./u-passes.scss"],
})
export class UserPasses implements OnInit {
  my_id = this.util.storey.user.uid;
  passes = [];

  gyms = this.util.storey.gyms;
  current_code;
  qr_code_url;

  constructor(public util: Utilities) {}

  async ngOnInit() {
    let payments = this.util.storey.payments ?? {};
    this.passes = Object.values(payments).filter((pmt: any) => {
      return (
        pmt?.status === "paid" &&
        pmt?.data?.uid === this.my_id &&
        pmt?.data?.unit_amount > 0
      );
    });
  }

  gymRoute(gymid) {
    this.util.router.navigate([`gyms/${gymid}`]);
  }

  checkActive(pass) {
    const now = new Date().getTime();
    let is_expired = true;
    let expiry_date;

    if (pass.activation) {
      const activation_time = pass.activation;

      switch (pass.type) {
        case "Day":
          expiry_date = new Date(activation_time + 24 * 60 * 60 * 1000); // 1 day ahead
          is_expired = now > expiry_date.getTime();
          break;
        case "Week":
          expiry_date = new Date(activation_time + 7 * 24 * 60 * 60 * 1000); // 1 week ahead
          is_expired = now > expiry_date.getTime();
          break;
        case "Month":
          expiry_date = new Date(activation_time + 30 * 24 * 60 * 60 * 1000); // 1 month ahead
          is_expired = now > expiry_date.getTime();
          break;
        default:
          expiry_date = now;
          is_expired = true;
      }
    }

    return [is_expired, this.util.dformat(expiry_date ?? now, "MMM-dd-yy")];
  }

  checkIn(pass_id, temp) {
    this.current_code = pass_id.slice(-8);
    this.qr_code_url = `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${this.current_code}`;

    this.util.dlogtr(temp);
  }
}
