import { State, Action, StateContext } from "@ngxs/store";
import { Injectable } from "@angular/core";

import * as actions from "@store/ftnss.actions";
import { empty_filter, empty_search } from "@models/menu";

export interface FtnssFace {
  user: any;
  user_db: any;
  partner_db: any;
  role: string;
  theme: string;
  version: string;
  country: string;
  all_db?: any;
  search: any;
  filters: any;
  router: any;
  gyms: any;
  payments: any;
  map_mode: boolean;
}

const defy = {
  user: "",
  user_db: null,
  partner_db: null,
  role: "guest",
  version: "1.2.0",
  country: "",
  gyms: null,
  payments: null,
  map_mode: false,
  search: { ...empty_search },
  filters: { ...empty_filter },
};
const defx = {
  ...defy,
  theme: "light",
  router: {},
};

@State<FtnssFace>({
  name: "ftnssstate",
  defaults: defx,
})
@Injectable()
export class FtnssState {
  constructor() {}

  @Action(actions.AddUser)
  AddUser(ctx: StateContext<FtnssFace>, action: actions.AddUser) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      user: action.user,
    });
  }

  @Action(actions.UpdateUserDB)
  UpdateUserDB(ctx: StateContext<FtnssFace>, action: actions.UpdateUserDB) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      user_db: action.user_db,
    });
  }

  @Action(actions.UpdateRole)
  UpdateRole(ctx: StateContext<FtnssFace>, action: actions.UpdateRole) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      role: action.role,
    });
  }

  @Action(actions.UpdateRoute)
  UpdateRoute(ctx: StateContext<FtnssFace>, action: actions.UpdateRoute) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      router: action.route,
    });
  }

  @Action(actions.SetPartner)
  SetHost(ctx: StateContext<FtnssFace>, action: actions.SetPartner) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      partner_db: action.partner,
    });
  }

  @Action(actions.SetMapMode)
  SetMapMode(ctx: StateContext<FtnssFace>, action: actions.SetMapMode) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      map_mode: action.map_mode,
    });
  }

  @Action(actions.SetTheme)
  SetTheme(ctx: StateContext<FtnssFace>, action: actions.SetTheme) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      theme: action.theme,
    });
  }

  @Action(actions.SetCountry)
  SetCountry(ctx: StateContext<FtnssFace>, action: actions.SetCountry) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      country: action.country,
    });
  }

  @Action(actions.UpdateDB)
  UpdateDB(ctx: StateContext<FtnssFace>, action: actions.UpdateDB) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      all_db: action.db,
    });
  }

  @Action(actions.UpdateGyms)
  UpdateGyms(ctx: StateContext<FtnssFace>, action: actions.UpdateGyms) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      gyms: action.gyms,
    });
  }

  @Action(actions.UpdatePayments)
  UpdatePayments(ctx: StateContext<FtnssFace>, action: actions.UpdatePayments) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      payments: action.payments,
    });
  }

  @Action(actions.SetFilter)
  SetFilter(ctx: StateContext<FtnssFace>, action: actions.SetFilter) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      filters: {
        ...state.filters,
        [action.type]: { ...action.filter },
      },
    });
  }

  @Action(actions.Searcher)
  Searcher(ctx: StateContext<FtnssFace>, action: actions.Searcher) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      search: {
        ...state.search,
        [action.search_type]: action.search_string,
      },
    });
  }

  /* **************************
    C L E A R   :::   A L L
  *************************** */

  @Action(actions.ClearState)
  ClearState(ctx: StateContext<any>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      ...defy,
    });
  }
}
