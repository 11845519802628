import { Component, Input, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";
import { Validators, FormGroup } from "@angular/forms";

@Component({
  selector: "p-add-gym",
  templateUrl: "./p-add-gym.html",
  styleUrls: ["../partners.scss"],
})
export class PartnerAddGym implements OnInit {
  @Input() partner;

  loader = false;

  add_gym_form: FormGroup;
  constructor(public util: Utilities) {}

  get agf() {
    return this.add_gym_form.controls;
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm() {
    this.add_gym_form = this.util.fb.group({
      gym_name: ["", Validators.required],
      gym_address: ["", Validators.required],
      price_day: [0, Validators.required],
      price_week: [0, Validators.required],
      price_month: [0, Validators.required],
    });
  }

  async addNewGym() {
    const new_form = this.add_gym_form.value;

    let new_gym_list = [...this.partner.gyms];

    const gym_id = `${new_form.gym_name}_${new_form.gym_address}`
      .toLowerCase()
      .replace(/[^\w]+/g, "_");

    const new_gym: any = {
      inactive: true,
      id: gym_id,
      name: new_form.gym_name,
      location: new_form.gym_address,
      partner_id: this.util.storey.user.uid,
      partner_stripe: this.partner.stripe_account,
      price_day: new_form.price_day,
      price_week: new_form.price_week,
      price_month: new_form.price_month,
      rating: 5,
      reviews: 0,
    };

    new_gym_list.push(gym_id);

    await this.util.afd.object(`v2/gyms/${gym_id}`).update(new_gym);
    await this.util.afd.object(`v2/partners/${new_gym.partner_id}`).update({
      gyms: new_gym_list,
    });

    this.util.successToast("Success", "Your have created a New Gym");
    this.util.closeAllDialogs();
  }
}
