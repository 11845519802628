<div>
  <hr />
  <div class="ftnss-box pt-4">
    <div class="row">
      <div class="col-12 my-3 text-center">
        <span routerLink="/help/terms" class="px-2 fw-6">Terms</span>
        &#x2022;
        <span routerLink="/help/privacy" class="px-2 fw-6">Privacy</span>
        &#x2022;
        <span routerLink="/help/faq" class="px-2 fw-6">Help & Support</span>
      </div>
      <div class="col-12 my-3 text-center">
        <span class="pb-2">
          <a
            *ngFor="let s of socials"
            [href]="s.href"
            target="_blank"
            class="px-3"
          >
            <img width="20px" [src]="s.image" alt=""
          /></a>
        </span>
      </div>

      <div class="col-12 my-3 text-center">
        © 2024 FTNSS, Inc. <br />
        <span id="version" class="px-2">{{util.storey.version}}</span>
      </div>
    </div>
  </div>
</div>
