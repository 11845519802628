import { Component, OnInit } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/compat/database";
import { Utilities } from "@services/utilities";
import { admin_map } from "./admin-map";
import * as Papa from "papaparse";
import { dummgyms } from "./dummy_gym";
import { HttpHeaders } from "@angular/common/http";
import { firstValueFrom, map } from "rxjs";
import { ddd2 } from "./dummy2";
import { gymCategories } from "@models/cats";

@Component({
  selector: "adminy",
  templateUrl: "./adminy.html",
  styleUrls: ["./adminy.scss"],
})
export class Adminy implements OnInit {
  currentNode: string = "users";
  currentdb;

  alldb;
  selectedItems: { [key: string]: boolean } = {};
  key_map = admin_map;
  dummy = { ...ddd2 };

  constructor(private db: AngularFireDatabase, private util: Utilities) {}

  ngOnInit(): void {
    // this.readCsvFromAssets();
    this.util.subLink("/v2").subscribe((x) => {
      this.alldb = x;
      this.fetchData(this.currentNode);
    });
  }

  async syncer() {
    for (let city in this.dummy) {
      // console.log(`${city} Started`);
      if (city) {
        for (let [idx, location] of this.dummy[city].entries()) {
          this.dummy[city][idx]["name"] = location.company_name;
          this.dummy[city][idx][
            "location"
          ] = `${location.city}, ${location["State/Region"]}, ${location["Country/Region"]}`;
        }
        console.log(this.dummy[city]);
        console.log(`${city} Done`);
      }
    }
    console.log(this.dummy);
  }

  getNestedValue(item: any, key: string) {
    return key
      .split(".")
      .reduce((obj, k) => (obj && obj[k] !== undefined ? obj[k] : null), item);
  }

  changeNode(node: string): void {
    this.currentNode = node;
    this.fetchData(node);
  }

  fetchData(node: string): void {
    this.currentdb = Object.values(this.alldb[node]);
  }

  deleteSelected(): void {
    const selectedKeys = Object.keys(this.selectedItems).filter(
      (key) => this.selectedItems[key]
    );
    selectedKeys.forEach((key) => {
      this.db.list(`v2/${this.currentNode}`).remove(key);
    });
    this.selectedItems = {};
  }

  toggleSelection(key: string): void {
    this.selectedItems[key] = !this.selectedItems[key];
  }

  isSelected(reff: string): boolean {
    return this.selectedItems[reff];
  }
}
